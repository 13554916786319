import React, { useState } from 'react'
import {
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Table,
  TableProps,
  Tag,
  TextField,
} from '@pankod/refine-antd'
import { CrudFilters, LogicalFilter } from '@pankod/refine-core'

import Filter from '@components/ContentFilter'
import { TFormGeneratorDetail } from '@resources/form-generator/interface'
import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { TCommonError } from 'src/interfaces/common'

type Option = { id: string; title: string }

type FormType = 'GENERAL' | 'LIKERT' | 'QUANTITATIVE' | ''

type FormSelectComponentProps = {
  disabled?: boolean
  multiple?: boolean
  value?: Option | Option[]
  onChange?: (value: any) => void
  formTypeOptions?: {
    label: string
    value: FormType | null
  }[]
  hideFilter?: boolean
  defaultFormType?: FormType | null
}
export const FormSelectComponent = ({
  multiple = false,
  defaultFormType = null,
  hideFilter = false,
  disabled,
  formTypeOptions = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'General',
      value: 'GENERAL',
    },
    {
      label: 'Likert',
      value: 'LIKERT',
    },
    {
      label: 'Quantitative',
      value: 'QUANTITATIVE',
    },
  ],
  ...rest
}: FormSelectComponentProps) => {
  const [show, setShow] = useState(false)
  const { tableProps, tableQueryResult, setFilters, searchFormProps, filters } =
    useTableWithMeta<TFormGeneratorDetail, TCommonError, any>({
      resource: 'forms',
      dataProviderName: 'lms',
      queryOptions: {
        enabled: !!show,
      },
      initialFilter: [
        {
          field: 'formType',
          operator: 'eq',
          value: defaultFormType || formTypeOptions?.[0]?.value || null,
        },
      ],
      onSearch: (params) => {
        const filters: CrudFilters = []
        const { title } = params
        filters.push(...filters, {
          field: 'title',
          operator: 'eq',
          value: title,
        })
        return filters
      },
    })

  const getInitialValue = () => {
    if (Array.isArray(rest.value)) {
      return new Map(rest.value.map((v) => [v.id, v]))
    }
    return new Map(rest.value ? [[rest.value?.id, rest.value]] : [])
  }
  const [selectedRowKeys, setSelectedRowKeys] =
    useState<Map<React.Key, Option>>(getInitialValue)

  const rowSelection: TableProps<TFormGeneratorDetail>['rowSelection'] = {
    type: multiple ? 'checkbox' : 'radio',
    hideSelectAll: true,
    onSelect: ({ id, ...form }) => {
      const title = JSON.parse(form.metaForm).title

      if (!multiple) {
        setSelectedRowKeys((values) => {
          values.clear()
          values.set(id, { title, id })
          return new Map(values)
        })
        return
      }

      if (selectedRowKeys.has(id)) {
        setSelectedRowKeys((values) => {
          values.delete(id)
          return new Map(values)
        })
        return
      }

      setSelectedRowKeys((values) => {
        values.set(id, { title, id })
        return new Map(values)
      })
    },
    selectedRowKeys: Array.from(selectedRowKeys, ([id]) => id),
  }

  const handleOkClick = () => {
    if (multiple) {
      rest.onChange?.(Array.from(selectedRowKeys, ([_, option]) => option))
      return
    }
    rest.onChange?.(Array.from(selectedRowKeys, ([_, option]) => option)[0])
    return
  }

  const mapValue = (
    value?: Option | Option[],
  ): { label: string; value: string } | { label: string; value: string }[] => {
    if (!value) return []

    if (Array.isArray(value)) {
      return value.map(({ id, title }) => ({ label: title, value: id }))
    }

    return { label: value.title, value: value.id }
  }

  const parseToJSON = (value: string) => {
    try {
      return JSON.parse(value)
    } catch (error) {
      return {}
    }
  }
  return (
    <>
      <Select<
        { label: string; value: string } | { label: string; value: string }[],
        { label: string; value: string } | { label: string; value: string }[]
      >
        {...rest}
        mode={multiple ? 'multiple' : undefined}
        onClick={() => {
          setShow(true)
        }}
        onChange={() => {}}
        onDeselect={({ value }: { label: string; value: string }) => {
          setSelectedRowKeys((rowKeys) => {
            rowKeys.delete(value)
            return new Map(rowKeys)
          })
          rest.onChange?.(Array.from(selectedRowKeys, ([_, option]) => option))
        }}
        labelInValue
        dropdownRender={() => <></>}
        open={false}
        value={mapValue(rest.value)}
        disabled={disabled}
      />

      <Modal
        title="Pilih Form"
        visible={show}
        destroyOnClose
        onCancel={() => {
          setSelectedRowKeys(getInitialValue)
          setShow(false)
        }}
        onOk={() => {
          handleOkClick()
          setShow(false)
        }}
        okText="Pilih"
        cancelText="Batal"
        maskClosable={false}
        width={800}
      >
        {!hideFilter && (
          <Form.Item label="Filter Tipe Form">
            <Radio.Group
              // @ts-expect-error
              options={formTypeOptions}
              defaultValue={
                defaultFormType || formTypeOptions?.[0]?.value || ''
              }
              onChange={(e) => {
                setFilters([
                  {
                    field: 'formType',
                    operator: 'eq',
                    value: e.target.value || null,
                  },
                  { field: 'page', operator: 'eq', value: 1 },
                ])
              }}
            />
          </Form.Item>
        )}

        <Filter
          layout="inline"
          formProps={searchFormProps}
          filters={filters as LogicalFilter[]}
          items={[
            {
              label: 'Form Title',
              name: 'title',
              component: (
                <Input
                  placeholder="Filter by title"
                  autoComplete="off"
                  allowClear
                />
              ),
            },
          ]}
        />
        <Table<TFormGeneratorDetail>
          {...tableProps}
          rowSelection={rowSelection}
          dataSource={(tableProps.dataSource || []).map((value) => ({
            ...value,
          }))}
          loading={tableQueryResult.isFetching}
          scroll={{ y: 570 }}
          rowKey="id"
        >
          <Table.Column
            dataIndex={'id'}
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={['metaForm']}
            title="Title"
            render={(value) => <TextField value={parseToJSON(value).title} />}
          />
          <Table.Column
            dataIndex={['metaForm']}
            title="Description"
            render={(value) => <TextField value={parseToJSON(value).excerpt} />}
          />
        </Table>

        {selectedRowKeys.size > 0 && (
          <>
            <h3 className="mr-8">Form yang dipilih</h3>
            <div className="flex flex-row items-center mt-4">
              <div className="flex flex-row flex-wrap">
                {Array.from(selectedRowKeys, ([_, option]) => (
                  <Tag
                    key={option.id}
                    className="mb-2 mr-2"
                    closable
                    onClose={() => {
                      setSelectedRowKeys((rowKeys) => {
                        rowKeys.delete(option.id)
                        return new Map(rowKeys)
                      })
                      rest.onChange?.(
                        Array.from(selectedRowKeys, ([_, option]) => option),
                      )
                    }}
                  >
                    {option.title}
                  </Tag>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
