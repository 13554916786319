import {
  Button,
  Descriptions,
  Divider,
  Empty,
  Form,
  Icons,
  Input,
  Popconfirm,
  Tag,
  useForm,
  Image,
  DatePicker,
  Spin,
  Drawer,
  Space,
} from '@pankod/refine-antd'
import { useState } from 'react'
import { useOne } from '@pankod/refine-core'
import dayjs from 'dayjs'

import { CertificateData } from './types'
import { TLMSCommonError } from 'src/interfaces/common'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type SetupCertificateDrawerProps = {
  visible: boolean
  programId?: number | string | string[]
  programLocalId?: number | string | string[]
  onClose: () => void
}
export const SetupCertificateDrawer = ({
  visible,
  programId,
  programLocalId,
  onClose,
}: SetupCertificateDrawerProps) => {
  const [newTrainingInformation, setNewTrainingInformation] = useState<{
    moduleName?: string
    jp?: number
  } | null>(null)

  const [formStatus, setFormStatus] = useState<
    'DRAFT' | 'PUBLISHED' | 'NEED VALIDATE'
  >('DRAFT')

  const { formProps, formLoading } = useForm({
    action: 'create',
    resource: `programs/${programId}/local-programs/${programLocalId}/certificate-settings`,
    dataProviderName: 'lms',
    redirect: false,
    onMutationSuccess: () => {
      setFormStatus(formStatus)
      onClose()
    },
    successNotification: {
      message: 'Pengaturan sertifikat program lokal berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan saat mengubah pengaturan sertifikat program lokal',
      ),
  })

  const { data: certificateData, isLoading } = useOne<CertificateData>({
    resource: `programs/${programId}/local-programs/${programLocalId}`,
    id: `certificate-settings`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && !!programLocalId && visible,
      onSuccess: (data) => {
        const formValue = data?.data

        if (formValue.basic.date) {
          // @ts-ignore
          formValue.basic.date = dayjs(formValue.basic.date)
        }

        formProps?.form?.setFieldsValue(formValue)
        // @ts-ignore
        setFormStatus(data?.data?.status)
      },
    },
    // @ts-ignore
    errorNotification(errorRes: TLMSCommonError) {
      // if (errorRes.statusCode === 404) {
      //   formProps.form?.resetFields()

      //   return {
      //     message: 'No Certificate Setting Found, please create one',
      //     description: 'Cerficiate Settings not found',
      //   }
      // }
      const responseErr = errorRes?.response?.data?.error?.message || ''

      return {
        message: responseErr,
        description: 'please check your input or try again later',
        type: 'error',
      }
    },
  })

  const formTrainingInformation = Form.useWatch(
    'trainingInformation',
    formProps?.form,
  )

  const formImage = Form.useWatch('image', formProps?.form)

  const handleUploadImage = (e: any, key: string) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        formProps?.form?.setFieldsValue({
          image: {
            ...formImage,
            [key]: reader.result,
          },
        })
      }
    }
  }

  const needValidate = formStatus !== 'DRAFT'
  const tagColor = () => {
    switch (formStatus) {
      case 'NEED VALIDATE':
        return 'error'
      case 'PUBLISHED':
        return 'processing'
      default:
        return 'default'
    }
  }

  return (
    <Drawer
      visible={visible}
      title={`Pengaturan Sertifikat`}
      onClose={() => {
        onClose()
        setFormStatus('DRAFT')
        formProps?.form?.resetFields()
      }}
      width={'60%'}
      extra={
        <Space>
          <Tag className="ml-8" color={tagColor()}>
            {formStatus}
          </Tag>
          <Divider type="vertical" />
          <Button
            htmlType="submit"
            loading={formLoading}
            onClick={() => {
              setFormStatus('DRAFT')

              formProps?.form?.setFieldsValue({
                status: 'DRAFT',
              })

              formProps?.form?.submit()
            }}
          >
            Simpan Sebagai Draft
          </Button>

          {formStatus === 'DRAFT' ? (
            <Button
              type="primary"
              loading={formLoading}
              onClick={async () => {
                setFormStatus('PUBLISHED')

                try {
                  await formProps.form?.validateFields()
                  formProps?.form?.setFieldsValue({
                    status: 'PUBLISHED',
                  })

                  formProps?.form?.submit()
                } catch {
                  setFormStatus('NEED VALIDATE')
                }
              }}
            >
              Validate and Publish
            </Button>
          ) : (
            <Button
              type="primary"
              loading={formLoading}
              onClick={() => {
                // check if ant-input-status-error-exist
                const errorExist = document.querySelectorAll(
                  '.ant-input-status-error',
                )

                if (errorExist.length > 0) {
                  // scroll into view
                  errorExist[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })

                  return
                }
                setFormStatus('PUBLISHED')

                formProps?.form?.setFieldsValue({
                  status: 'PUBLISHED',
                })

                formProps?.form?.submit()
              }}
            >
              Validate and Publish
            </Button>
          )}
        </Space>
      }
    >
      <Spin spinning={isLoading}>
        <div className="flex flex-col">
          <div className="flex flex-row w-full">
            <div className="flex flex-col grow max-h-[82vh] overflow-y-auto p-4">
              <Form
                {...formProps}
                layout="vertical"
                onFinish={(data: any) => {
                  const formValue = data
                  if (formValue.basic.date) {
                    formValue.basic.date = dayjs(formValue?.basic?.date).format(
                      'YYYY-MM-DD',
                    )
                  }

                  formProps?.onFinish?.(formValue)
                  // return formValue
                }}
                // initialValues={certificateData?.data}
                validateMessages={{ required: '${label} must be filled.' }}
                scrollToFirstError
              >
                <h3 className="mb-8">Certificate Basic Information</h3>
                <Form.Item name={'status'} hidden>
                  <Input />
                </Form.Item>

                {/* basic section */}
                <Form.Item
                  name={['basic', 'organizationName']}
                  label="Organization Name"
                  rules={[{ required: needValidate, max: 140 }]}
                >
                  <Input
                    className="w-full"
                    defaultValue="KEMENTRIAN PENDIDIKAN, KEBUDAYAAN, RISET, DAN TEKNOLOGI"
                  />
                </Form.Item>

                <Form.Item
                  name={['basic', 'workUnitName']}
                  label="Certificate Work Unit Name"
                  rules={[
                    {
                      required: needValidate,
                      max: 140,
                    },
                  ]}
                >
                  <Input.TextArea className="w-full" />
                </Form.Item>

                <Form.Item
                  name={['basic', 'number']}
                  label="Certificate Number"
                  rules={[
                    { required: needValidate, max: 100 },
                    {
                      pattern: /^\S*$/,
                      message: 'No whitespace allowed',
                    },
                  ]}
                >
                  <Input className="w-full" />
                </Form.Item>

                <Form.Item
                  name={['basic', 'text']}
                  label="Certificate Text"
                  rules={[{ required: needValidate, max: 360 }]}
                >
                  <Input className="w-full" />
                </Form.Item>

                <Form.Item
                  name={['basic', 'date']}
                  label="Certificate Date"
                  rules={[{ required: needValidate }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    defaultValue={dayjs(Date.now())}
                  />
                </Form.Item>

                <Divider />
                <h3 className="mb-8">Front Signer Information</h3>

                <Form.Item
                  name={['frontSigner', 'name']}
                  label="Front Signer Name"
                  rules={[{ required: needValidate, max: 100 }]}
                >
                  <Input className="w-full" />
                </Form.Item>

                <Form.Item
                  name={['frontSigner', 'organization']}
                  label="Front Signer org"
                  rules={[{ required: needValidate, max: 100 }]}
                >
                  <Input.TextArea className="w-full" style={{ height: 100 }} />
                </Form.Item>

                <Form.Item
                  name={['frontSigner', 'id']}
                  label="Front Signer ID"
                  rules={[{ required: needValidate, max: 100 }]}
                >
                  <Input className="w-full" />
                </Form.Item>

                <Divider />
                <h3 className="mb-8">Back Signer Information</h3>

                <Form.Item
                  name={['backSigner', 'name']}
                  label="Back Signer Name"
                  rules={[{ required: needValidate, max: 100 }]}
                >
                  <Input className="w-full" />
                </Form.Item>

                <Form.Item
                  name={['backSigner', 'nip']}
                  label="Back Signer NIP"
                  rules={[{ required: needValidate, max: 100 }]}
                >
                  <Input className="w-full" />
                </Form.Item>

                <Form.Item
                  name={['backSigner', 'occupation']}
                  label="Back Signer Occupation"
                  rules={[{ required: needValidate, max: 100 }]}
                >
                  <Input className="w-full" />
                </Form.Item>

                <Divider />
                <h3 className="mb-8">Training Information</h3>

                <Form.Item
                  name={['trainingProgramTitle']}
                  label="Training Program Title"
                  rules={[{ required: needValidate, max: 100 }]}
                >
                  <Input className="w-full" />
                </Form.Item>

                {/* hiddenFOrm */}
                <Form.Item
                  name="trainingInformation"
                  hidden
                  initialValue={certificateData?.data.trainingInformation}
                  rules={[{ required: needValidate }]}
                >
                  <Input />
                </Form.Item>

                {formTrainingInformation &&
                formTrainingInformation.length > 0 ? (
                  <Descriptions
                    bordered
                    column={3}
                    className="mt-4 mb-6"
                    labelStyle={{ fontWeight: 600 }}
                  >
                    {formTrainingInformation.map((item: any) => (
                      <>
                        <Descriptions.Item>{item.moduleName}</Descriptions.Item>
                        <Descriptions.Item>{item.jp}</Descriptions.Item>
                        <Descriptions.Item>
                          <Button
                            danger
                            icon={<Icons.DeleteOutlined />}
                            onClick={() => {
                              const newTrainingInformation =
                                formTrainingInformation.filter(
                                  (data: any) =>
                                    data.moduleName !== item.moduleName,
                                )

                              formProps?.form?.setFieldsValue({
                                trainingInformation: newTrainingInformation,
                              })
                            }}
                          />
                        </Descriptions.Item>
                      </>
                    ))}
                  </Descriptions>
                ) : (
                  <Empty className="border p-4 rounded-sm" />
                )}

                <Popconfirm
                  icon={null}
                  placement="bottomRight"
                  className="my-2"
                  title={
                    <>
                      <Input
                        placeholder={`nama modul training`}
                        className="mb-2"
                        required
                        maxLength={100}
                        value={newTrainingInformation?.moduleName}
                        onChange={(e) => {
                          if (e.target.value.length > 100) return

                          setNewTrainingInformation({
                            ...newTrainingInformation,
                            moduleName: e.target.value,
                          })
                        }}
                      />
                      <div className="my-4" />
                      <Input
                        placeholder={`jp modul`}
                        required
                        type="number"
                        max={999}
                        value={newTrainingInformation?.jp}
                        onChange={(e) => {
                          if (e.target.value.length > 3) return

                          setNewTrainingInformation({
                            ...newTrainingInformation,
                            jp: Number(e.target.value),
                          })
                        }}
                      />
                    </>
                  }
                  onVisibleChange={(visible) => {
                    if (visible) setNewTrainingInformation(null)
                  }}
                  okButtonProps={{
                    disabled:
                      !newTrainingInformation?.jp ||
                      !newTrainingInformation?.moduleName,

                    onClick: () => {
                      const newTrainingInformationData = []

                      if (formTrainingInformation) {
                        newTrainingInformationData.push(
                          ...formTrainingInformation,
                        )
                      }

                      formProps?.form?.setFieldsValue({
                        trainingInformation: [
                          ...newTrainingInformationData,
                          newTrainingInformation,
                        ],
                      })

                      setNewTrainingInformation(null)
                    },
                  }}
                >
                  <Button>
                    <Icons.PlusOutlined />
                    Tambah Modul Training
                  </Button>
                </Popconfirm>

                <Divider />
                <h3 className="mb-8">Certificate Image</h3>

                {/* base64 image form item */}
                <Form.Item
                  name={['image', 'signature']}
                  hidden
                  initialValue={certificateData?.data.image.signature}
                  rules={[{ required: needValidate }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={['image', 'organization']}
                  hidden
                  initialValue={certificateData?.data.image.organization}
                  rules={[{ required: needValidate }]}
                >
                  <Input />
                </Form.Item>

                <Input
                  type="file"
                  hidden
                  id="image-organization"
                  onChange={(e) => handleUploadImage(e, 'organization')}
                  accept="image/png, image/jpeg"
                />

                <Input
                  type="file"
                  hidden
                  id="image-signature"
                  onChange={(e) => handleUploadImage(e, 'signature')}
                  accept="image/png, image/jpeg"
                />

                <div className="flex flex-row items-center justify-center">
                  <div className="flex flex-col mr-16 items-center">
                    <h4 className="mb-4">Organization Image</h4>

                    {formImage?.organization ? (
                      <span className="ant-upload-picture-card-wrapper upload-avatar">
                        <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
                          <span className="ant-upload">
                            <div className="upload-avatar__preview-button">
                              <img
                                alt="Avatar uploaded image"
                                src={formImage.organization}
                                className="w-full aspect-square object-contain"
                              />
                              <div className="upload-avatar__preview-overlay">
                                <Button
                                  size="large"
                                  onClick={() => {
                                    document &&
                                      document
                                        .getElementById('image-organization')
                                        ?.click()
                                  }}
                                  type="text"
                                  icon={<Icons.SwapOutlined />}
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                      </span>
                    ) : (
                      <Button
                        className="ant-upload ant-upload-select ant-upload-select-picture-card"
                        style={{ transition: 'all ease 0.3s' }}
                        onClick={() => {
                          // handle upload file
                          document &&
                            document
                              .getElementById('image-organization')
                              ?.click()
                        }}
                      >
                        <div>
                          <Icons.PlusOutlined />
                          <div style={{ marginTop: 8 }}>Add Image</div>
                        </div>
                      </Button>
                    )}
                  </div>
                  <div className="flex flex-col items-center">
                    <h4 className="mb-4">Signature Image</h4>
                    {formImage?.signature ? (
                      <span className="ant-upload-picture-card-wrapper upload-avatar">
                        <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
                          <span className="ant-upload">
                            <div className="upload-avatar__preview-button">
                              <img
                                alt="Avatar uploaded image"
                                src={formImage.signature}
                                className="w-full aspect-square object-contain"
                              />
                              <div className="upload-avatar__preview-overlay">
                                <Button
                                  size="large"
                                  onClick={() => {
                                    document &&
                                      document
                                        .getElementById('image-signature')
                                        ?.click()
                                  }}
                                  type="text"
                                  icon={<Icons.SwapOutlined />}
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                      </span>
                    ) : (
                      <Button
                        className="ant-upload ant-upload-select ant-upload-select-picture-card"
                        style={{ transition: 'all ease 0.3s' }}
                        onClick={() => {
                          document &&
                            document.getElementById('image-signature')?.click()
                        }}
                      >
                        <div>
                          <Icons.PlusOutlined />
                          <div style={{ marginTop: 8 }}>Add Image</div>
                        </div>
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </div>
            <div className="flex flex-col w-[15vw] ml-8">
              <h3>Template Preview</h3>

              <Image
                alt="certificate template preview"
                src="/img/static/certificate-template/template.png"
              />
            </div>
          </div>
        </div>
      </Spin>
    </Drawer>
  )
}
