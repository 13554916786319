import {
  DateField,
  Icons,
  Space,
  Table,
  TextField,
  Typography,
} from '@pankod/refine-antd'
import { HttpError, useCan } from '@pankod/refine-core'
import React from 'react'
import { useRouter } from 'next/router'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { PresensiPesertaProgram } from '../../types'
import ExportReportButton from '@components/ExportReportButton'
import { useProgramStore } from '../../store'

const PresensiPesertaTable = () => {
  const router = useRouter()
  const programId = String(router.query?.programId)
  const { data: canExport } = useCan({
    action: 'field',
    resource: 'lms-presensi-report',
    params: {
      field: 'export',
    },
  })
  const isOwner = useProgramStore(({ isOwner }) => isOwner)
  const { tableProps, tableQueryResult } = useTableWithMeta<
    PresensiPesertaProgram,
    HttpError,
    unknown,
    { lastUpdatedAt: string }
  >({
    dataProviderName: 'lms',
    resource: `programs/${programId}/presensi-murid/peserta`,
    queryOptions: {
      enabled: !!programId,
    },
  })
  const lastUpdatedAt = tableQueryResult?.data?.meta?.lastUpdatedAt
  return (
    <>
      <div className="flex gap-3 mb-1">
        <Typography.Title level={5}>Daftar Peserta</Typography.Title>
        <ExportReportButton
          fileName={`LaporanPresensi_ProgramId_${programId}`}
          url={`${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/presensi-murid/peserta/export`}
          can={canExport?.can || isOwner}
          size="small"
        />
      </div>
      <Table
        {...tableProps}
        rowKey="userId"
        bordered
        footer={() => (
          <TextField
            italic
            value={
              <div className="flex gap-2 items-center">
                Diperbarui pada:
                <DateField
                  value={lastUpdatedAt}
                  format="DD MMM YYYY HH:mm:ss"
                />
              </div>
            }
          />
        )}
        columns={[
          { title: 'Nama', dataIndex: 'nama' },
          {
            title: 'Statistik Presensi',
            children: [
              {
                title: (
                  <Space>
                    <Icons.CheckCircleOutlined />
                    <TextField value="Hadir" />
                  </Space>
                ),
                dataIndex: ['statistikPresensi', 'hadir'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.ClockCircleOutlined />
                    <TextField value="Terlambat" />
                  </Space>
                ),
                dataIndex: ['statistikPresensi', 'terlambat'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.CloseCircleOutlined />
                    <TextField value="Absen" />
                  </Space>
                ),
                dataIndex: ['statistikPresensi', 'alpha'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.WarningOutlined />
                    <TextField value="Belum Diisi" />
                  </Space>
                ),
                dataIndex: ['statistikPresensi', 'kosong'],
                align: 'center',
              },
            ],
          },
          {
            title: 'Statistik Lokakarya',
            children: [
              {
                title: (
                  <Space>
                    <Icons.CheckCircleOutlined />
                    <TextField value="Hadir" />
                  </Space>
                ),
                dataIndex: ['statistikLokaKarya', 'hadir'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.ClockCircleOutlined />
                    <TextField value="Terlambat" />
                  </Space>
                ),
                dataIndex: ['statistikLokaKarya', 'terlambat'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.CloseCircleOutlined />
                    <TextField value="Absen" />
                  </Space>
                ),
                dataIndex: ['statistikLokaKarya', 'alpha'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.InfoCircleOutlined />
                    <TextField value="Poin" />
                  </Space>
                ),
                dataIndex: ['statistikLokaKarya', 'point'],
                align: 'center',
              },
            ],
          },
        ]}
      />
    </>
  )
}

export default PresensiPesertaTable
