import React from 'react'
import { useNode, Node } from '@craftjs/core'
import { Form, Input } from '@pankod/refine-antd'
import clsx from 'clsx'
import { Heading as LibraryText } from '@instructure/ui-heading'

import SelectWidgetSettings from '../../editor/widgetSettings/SelectWS'
import { HeadingCustomProps, THeading, WIDGET_NAME } from '../../schema'
import {
  getWidgetSettingsFields,
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
} from '../../utils'

export const defaultProps: THeading = {
  size: 'heading-md',
  text: '[Ini Adalah Heading]',
}

const Heading = ({ text, size = 'heading-md' }: THeading) => {
  const {
    connectors: { connect, drag },
  } = useNode()

  return (
    <div
      className={clsx(
        'widget-component',
        'mb-2 last:mb-0',
        'text-default',
        'widget-component--text',
      )}
      ref={(ref) => connect(drag(ref!))}
    >
      <LibraryText level={size === 'heading-md' ? 'h3' : 'h2'}>
        {text || ' '}
      </LibraryText>
    </div>
  )
}

const HeadingSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item label="Size" name="size">
        <SelectWidgetSettings
          value={props.size}
          onChange={(size) => setProp((props: THeading) => (props.size = size))}
          options={[
            { label: 'Large', value: 'heading-lg' },
            { label: 'Medium', value: 'heading-md' },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Text"
        name="text"
        validateStatus={errors['text'] && 'error'}
        help={errors['text']}
        required
      >
        <Input
          autoComplete="off"
          value={props.text}
          onChange={(e) =>
            setProp((props: THeading) => (props.text = e.target.value))
          }
        />
      </Form.Item>
    </Form>
  )
}

Heading.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: HeadingCustomProps,
  related: {
    settings: HeadingSettings,
  },
}

export { Heading }
