/**
 * Custom AJV resolver, with additional localization and formats, based on:
 * https://github.com/react-hook-form/resolvers/blob/master/ajv/src/ajv.ts
 */

import { toNestError, validateFieldsNatively } from '@hookform/resolvers'
import Ajv, { DefinedError } from 'ajv'
import ajvErrors from 'ajv-errors'
import { appendErrors, FieldError } from 'react-hook-form'
import localize from 'ajv-i18n/localize/id'
import addFormats from 'ajv-formats'

import type { Resolver } from './type'

const parseErrorSchema = (
  ajvErrors: DefinedError[],
  validateAllFieldCriteria: boolean,
) => {
  // Ajv will return empty instancePath when require error
  ajvErrors.forEach((error) => {
    if (error.keyword === 'required') {
      error.instancePath += '/' + error.params.missingProperty
    }
  })

  return ajvErrors.reduce<Record<string, FieldError>>((previous, error) => {
    // `/deepObject/data` -> `deepObject.data`
    const path = error.instancePath.substring(1).replace(/\//g, '.')

    if (!previous[path]) {
      previous[path] = {
        message: error.message?.trim(),
        type: error.keyword,
      }
    }

    if (validateAllFieldCriteria) {
      const types = previous[path].types
      const messages = types && types[error.keyword]

      previous[path] = appendErrors(
        path,
        validateAllFieldCriteria,
        previous,
        error.keyword,
        messages
          ? ([] as string[]).concat(messages as string[], error.message || '')
          : error.message,
      ) as FieldError
    }

    return previous
  }, {})
}

export const ajvI18nResolver: Resolver =
  (schema, schemaOptions, resolverOptions = {}) =>
  async (values, _, options) => {
    /**
     * Replace empty string with `undefined` so that the validator
     * can validate the empty string whether it is a required value or not
     */
    const strippedValues = Object.entries(values).reduce(
      (result, [key, value]) => {
        return {
          ...result,
          [key]: value === '' ? undefined : value,
        }
      },
      {},
    )
    const ajv = new Ajv(
      Object.assign(
        {},
        {
          allErrors: true,
          validateSchema: true,
          messages: false,
        },
        schemaOptions,
      ),
    )

    addFormats(ajv)

    ajvErrors(ajv)

    const validate = ajv.compile(
      Object.assign(
        { $async: resolverOptions && resolverOptions.mode === 'async' },
        schema,
      ),
    )

    const valid = validate(strippedValues)

    options.shouldUseNativeValidation && validateFieldsNatively({}, options)

    if (!valid) {
      /** Localize the error messages here */
      localize(validate.errors)
    }

    return valid
      ? { values, errors: {} }
      : {
          values: {},
          errors: toNestError(
            parseErrorSchema(
              validate.errors as DefinedError[],
              !options.shouldUseNativeValidation &&
                options.criteriaMode === 'all',
            ),
            options,
          ),
        }
  }
