import { Button, Icons, Table, useTable } from '@pankod/refine-antd'
import React, { useState } from 'react'
import { useInvalidate } from '@pankod/refine-core'

import ApprovalModal from './ApprovalModal'

type RequestPesertaTableList = {
  type: 'peserta'
  programLocalId?: string | string[] | number
  programId?: string | string[] | number
}

type RequestNonPesertaTableList = {
  type: 'non-peserta'
  programId?: string | string[] | number
}

type RequestTableList = RequestPesertaTableList | RequestNonPesertaTableList

const RequestTableList = (props: RequestTableList) => {
  const invalidate = useInvalidate()
  const resource =
    props.type === 'non-peserta'
      ? `programs/${props.programId}/participant-consolidation/non-participant/requests`
      : `programs/${props.programId}/program-locals/${props.programLocalId}/participant-consolidation/requests`
  const { tableProps } = useTable({
    resource,
    dataProviderName: 'lms',
    queryOptions: {
      enabled:
        props.type === 'non-peserta'
          ? !!props.programId
          : !!props.programId && !!props.programLocalId,
    },
  })
  const [selectedId, setSelectedId] = useState<string>('')

  const handleInvalidate = () => {
    invalidate({
      invalidates: ['list'],
      dataProviderName: 'lms',
      resource,
    })
    if (props.type === 'peserta') {
      invalidate({
        invalidates: ['list'],
        dataProviderName: 'lms',
        resource: `programs/${props.programId}/participant-consolidation/requests`,
      })
    }
  }

  return (
    <>
      <Table
        {...tableProps}
        rowKey={'id'}
        className="w-full"
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
          },
          {
            title: 'User ID',
            dataIndex: 'userId',
            width: 150,
          },
          {
            title: 'Nama',
            dataIndex: 'name',
            width: 250,
          },
          {
            title: 'Aksi',
            render: (_, record) => (
              <Button
                icon={<Icons.EyeOutlined />}
                onClick={() => {
                  setSelectedId(record.id as string)
                }}
              >
                Detail Perubahan
              </Button>
            ),
          },
        ]}
      />
      <ApprovalModal
        programId={props.programId}
        onClose={() => {
          setSelectedId('')
        }}
        selectedId={selectedId}
        onSubmitSuccess={() => {
          handleInvalidate()
        }}
        onSubmitError={(error) => {
          if (error.response?.status === 404) {
            handleInvalidate()
            setSelectedId('')
          }
        }}
      />
    </>
  )
}

export default RequestTableList
