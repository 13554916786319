import { UploadProps, message, Upload } from '@pankod/refine-antd'
import { parse } from 'papaparse'

// TODO: adjust this to validate the value
export const parseCSVToObject: (
  onParseSuccess: (data: any) => void,
) => UploadProps = (onParseSuccess) => ({
  accept: '.csv',
  maxCount: 1,
  showUploadList: false,
  beforeUpload(file) {
    const isCsv = file.type === 'text/csv'
    if (!isCsv) {
      message.error(`${file.name} bukan file .csv`)
      return isCsv || Upload.LIST_IGNORE
    }

    parse<Record<string, string>>(file, {
      delimiter: ',',
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,

      complete: (results) => {
        const { data } = results
        onParseSuccess({ data })
      },
    })

    return false
  },
})
