import {
  Empty,
  Spin,
  Table,
  TableColumnGroupType,
  TableColumnType,
} from '@pankod/refine-antd'
import { useList } from '@pankod/refine-core'
import React from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { dateToStringFmt } from 'src/helpers/date'

type WebinarSchedule = {
  startTime: string
  endTime: string
}

type InstructorWebinar = {
  id: string
  date: string
  position: number
  webinarSchedule: {
    wib: WebinarSchedule
    wita: WebinarSchedule
    wit: WebinarSchedule
  }
}

type ScheduleTable = {
  date: string
  time: {
    id: string
    position: number
    schedules: InstructorWebinar['webinarSchedule']
    date: string
  }[]
}

const scheduleMapper = (schedules: InstructorWebinar[]): ScheduleTable[] => {
  const result = schedules.reduce(
    (prev, curr) => {
      const date = curr.date
      const schedule = prev.get(date) || []
      schedule.push({
        id: curr.id,
        schedules: curr.webinarSchedule,
        position: curr.position,
        date: curr.date,
      })
      prev.set(date, schedule)
      return prev
    },
    new Map() as Map<
      string,
      {
        id: string
        position: number
        date: string
        schedules: InstructorWebinar['webinarSchedule']
      }[]
    >,
  )
  return Array.from(result).map(([date, time]) => {
    time.sort((a, b) => a.position - b.position)
    return { date, time }
  })
}

type InstructorScheduleProps = {
  onError?: (err: unknown) => void
  selected?: { date: string; sessionId: string }
  onSelected?: (data: {
    date: string
    sessionId: string
    position: number
  }) => void
  resource: string
  enabled: boolean
  initialSchedule?: { date: string; position: number }
}
const InstructorSchedule = ({
  onError,
  selected,
  onSelected,
  enabled,
  resource,
  initialSchedule,
}: InstructorScheduleProps) => {
  const { data, isFetching } = useList<InstructorWebinar>({
    resource,
    queryOptions: {
      enabled,
      onError: (err) => {
        onError?.(err)
      },
      onSuccess: (data) => {
        if (!initialSchedule) return
        const schedule = data.data.find(
          ({ date, position }) =>
            date === initialSchedule.date &&
            position === initialSchedule.position,
        )
        onSelected?.({
          date: schedule?.date || '',
          sessionId: schedule?.id || '',
          position: schedule?.position || -1,
        })
      },
    },
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'Terdapat gangguan saat mengambil data jadwal sesi webinar',
      ),
  })
  const scheduleData = data?.data
  const mappedData = scheduleMapper(scheduleData || [])

  const columns: (
    | TableColumnType<ScheduleTable>
    | TableColumnGroupType<ScheduleTable>
  )[] = [
    {
      title: 'Tanggal Pelaksanaan',
      key: 'date',
      dataIndex: 'date',
      render: (value) => dateToStringFmt(value, 'DD MMMM YYYY'),
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      sortDirections: ['ascend'],
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
  ]

  const handleRadioClick = (data: {
    date: string
    sessionId: string
    position: number
  }) => {
    onSelected?.(data)
  }

  return (
    <div>
      <Spin spinning={isFetching}>
        {mappedData.length === 0 ? (
          <Empty description="Tidak ada jadwal tersedia" />
        ) : (
          <Table
            columns={columns}
            dataSource={mappedData}
            scroll={{ y: 500 }}
            rowKey="date"
            pagination={false}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <Table
                    dataSource={record.time}
                    rowKey={({ date, id }) => `${id}|${date}`}
                    pagination={false}
                    key={record.time.length}
                    bordered
                    rowSelection={{
                      type: 'radio',
                      onChange(_, record) {
                        handleRadioClick({
                          date: record[0].date,
                          sessionId: `${record[0].id}|${record[0].date}`,
                          position: record[0].position,
                        })
                      },
                      columnTitle: 'Pilih',
                      selectedRowKeys: selected ? [selected.sessionId] : [],
                    }}
                    columns={[
                      { title: 'Sesi', dataIndex: 'position' },
                      {
                        title: 'Waktu Pelaksanaan',
                        dataIndex: ['schedules', 'wib'],
                        render: ({ startTime, endTime }) =>
                          `${startTime} - ${endTime} WIB`,
                      },
                    ]}
                  />
                )
              },
              defaultExpandedRowKeys: mappedData.map(({ date }) => date),
            }}
          />
        )}
      </Spin>
    </div>
  )
}

export default InstructorSchedule
