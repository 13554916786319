import {
  Button,
  Drawer,
  Form,
  Icons,
  Input,
  Space,
  Steps,
  Table,
  TablePaginationConfig,
  Tooltip,
  useTable,
} from '@pankod/refine-antd'
import { useUpdate } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const useBlueprintRegistrationDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
  }>({ show: false, programId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, programId: id })
    },
    visible: !!showId.show,
    id: showId.programId,
    onClose,
  }
}

type BlueprintRegistrationDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  initialSisIdAccount?: string
}

const BlueprintRegistrationDrawer = ({
  id,
  onClose,
  visible,
  initialSisIdAccount,
}: BlueprintRegistrationDrawerProps) => {
  const [form] = Form.useForm()
  const [current, setCurrent] = useState(0)
  const {
    mutateAsync: updateSisIdSubaccount,
    isLoading: loadingSisIdSubaccount,
  } = useUpdate()
  const { mutateAsync: updateBlueprint, isLoading: loadingBlueprint } =
    useUpdate()
  const { tableProps, tableQueryResult } = useTable({
    resource: `programs/${id}/blueprints`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!visible && current === 1,
    },
  })
  const onPaginationChange = (type: 'next' | 'prev' | 'reset') => {
    let current = (tableProps.pagination as TablePaginationConfig).current!
    if (type === 'next') {
      current += 1
    }
    if (type === 'prev') {
      current -= 1
    }
    if (type === 'reset') {
      current = 1
    }
    tableProps.onChange?.(
      {
        current,
      },
      {},
      [],
      { action: 'paginate', currentDataSource: [] },
    )
  }
  const steps = [
    {
      title: 'Daftar SIS ID Subaccount',
      content: (
        <Form.Item
          label="SIS ID Subaccount"
          name="sisAccountId"
          required
          rules={[
            { required: true, message: 'SIS ID Subaccount harus diisi.' },
          ]}
          initialValue={initialSisIdAccount}
        >
          <Input autoComplete="off" placeholder="Masukkan SIS ID Subaccount" />
        </Form.Item>
      ),
    },
    {
      title: 'Pilih Blueprint',
      content: (
        <Table
          {...tableProps}
          pagination={false}
          loading={tableProps.loading || loadingBlueprint}
          footer={() => {
            return (
              <Space>
                <Button
                  icon={<Icons.LeftOutlined />}
                  disabled={
                    (tableProps.pagination as TablePaginationConfig).current ===
                    1
                  }
                  onClick={() => onPaginationChange('prev')}
                >
                  Sebelumnya
                </Button>
                <Button
                  icon={<Icons.RightOutlined />}
                  // @ts-expect-error
                  disabled={!tableQueryResult?.data?.next}
                  onClick={() => onPaginationChange('next')}
                >
                  Selanjutnya
                </Button>
              </Space>
            )
          }}
          rowKey="id"
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
            },
            {
              title: 'Nama',
              dataIndex: 'name',
            },
            {
              title: 'Kode Course',
              dataIndex: 'courseCode',
            },
            {
              render(_, record) {
                return (
                  <Tooltip
                    title={!!record.sisCourseId ? '' : 'SIS Course ID kosong'}
                  >
                    <Button
                      type="primary"
                      disabled={!record.sisCourseId}
                      onClick={async () =>
                        updateBlueprint(
                          {
                            id: 'blueprint',
                            resource: `programs/${id}`,
                            values: {
                              blueprintId: record.sisCourseId,
                            },
                            successNotification: {
                              message: 'Berhasil menyimpan Blueprint',
                              type: 'success',
                              description: 'Sukses',
                            },
                            errorNotification: (err: any) =>
                              showErrorNotification(
                                err,
                                'Terdapat kesalahan pada sistem, silahkan coba beberapa saat lagi',
                              ),
                          },
                          {
                            onSuccess: () => {
                              handleClose()
                            },
                          },
                        )
                      }
                    >
                      Pilih
                    </Button>
                  </Tooltip>
                )
              },
            },
          ]}
        />
      ),
    },
  ]

  const next = async () => {
    try {
      await form.validateFields()
      if (current === 0) {
        await updateSisIdSubaccount({
          id: 'sis-account',
          resource: `programs/${id}`,
          values: {
            sisAccountId: form.getFieldValue('sisAccountId'),
          },
          successNotification: {
            message: 'Berhasil menyimpan SIS ID Subaccount',
            type: 'success',
            description: 'Sukses',
          },
          errorNotification: (err: any) =>
            showErrorNotification(
              err,
              'Terdapat kesalahan pada sistem, silahkan coba beberapa saat lagi',
            ),
        })
      }
      setCurrent(current + 1)
    } catch {}
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const items = steps.map((item, index) => ({ key: index, title: item.title }))

  const handleClose = () => {
    form.resetFields()
    setCurrent(0)
    onPaginationChange('reset')
    onClose()
  }
  return (
    <Drawer
      title={`Registrasi Blueprint | Program ID ${id}`}
      visible={visible}
      onClose={handleClose}
      destroyOnClose
      maskClosable={false}
      width="50%"
      footer={
        <Space>
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              loading={loadingSisIdSubaccount}
            >
              Simpan SIS ID
            </Button>
          )}

          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Kembali ke SIS ID Subaccount
            </Button>
          )}
        </Space>
      }
    >
      <Form form={form} layout="vertical">
        <Steps current={current}>
          {items.map(({ key, title }) => (
            <Steps.Step title={title} key={key} />
          ))}
        </Steps>
        <div className="mt-8">{steps[current].content}</div>
      </Form>
    </Drawer>
  )
}

export default BlueprintRegistrationDrawer
