import React from 'react'
import Head from 'next/head'
import {
  Button,
  Icons,
  List,
  Popover,
  Space,
  Table,
  Tag,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  GetListResponse,
  useCan,
  useNavigation,
  useCreate,
} from '@pankod/refine-core'
import { useRouter } from 'next/router'

import { CertificateTemplate } from './types'
import { NestedBreadcrumb } from '../components/NestedBreadcrumb'
import { kapabilitasMapper } from '@components/DataTableParticipants/blocks/utils'
import { TCommonError, TCommonResponse } from 'src/interfaces/common'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const CertificateTemplateList: React.FC<
  IResourceComponentsProps<GetListResponse<CertificateTemplate>>
> = ({ options }) => {
  const { query, push } = useRouter()
  const { listUrl, list, showUrl } = useNavigation()
  const { data: canAccessProgram } = useCan({
    action: 'show',
    resource: 'lms-program-management',
  })
  const programId = query.programId as string

  const { tableProps, tableQueryResult } = useTable({
    resource: `programs/${programId}/certificate-templates`,
    dataProviderName: 'lms',
  })

  const {
    isLoading: generateNewTemplateLoading,
    mutateAsync: generateNewTemplate,
  } = useCreate<
    TCommonResponse<CertificateTemplate>,
    TCommonError,
    { kapabilitas: string }
  >()

  if (!programId) {
    list('lms-program-management')
    return null
  }

  const handleCreateNewTemplate = async (kapabilitas: string) => {
    const { data } = await generateNewTemplate({
      resource: `programs/${programId}/certificate-templates`,
      values: { kapabilitas },
      dataProviderName: 'lms',
      successNotification: false,
      errorNotification: (error) =>
        showErrorNotification(error, 'Terjadi kesalahan saat membuat template'),
    })
    const redirectUrl =
      showUrl('certificate-templates', data.data.id) + `?programId=${programId}`
    push(redirectUrl)
  }

  const renderCreateTemplateButton = () => {
    const roles = [
      {
        label: 'Peserta',
        value: 'peserta',
      },
      {
        label: 'Fasilitator',
        value: 'instruktur',
      },
      {
        label: 'Pengajar Praktik',
        value: 'asisten',
      },
      {
        label: 'Instruktur Webinar',
        value: 'instruktur_webinar',
      },
    ]
    return (
      <Popover
        content={
          <Space direction="vertical">
            {roles.map(({ label, value }) => (
              <Button
                key={value}
                block
                disabled={tableQueryResult?.data?.data?.some(
                  ({ kapabilitas }) => kapabilitas === value,
                )}
                onClick={() => handleCreateNewTemplate(value)}
                loading={generateNewTemplateLoading}
              >
                {label}
              </Button>
            ))}
          </Space>
        }
        title="Pilih kapabilitas"
        trigger="click"
      >
        <Button icon={<Icons.PlusOutlined />} type="primary">
          Buat Template Baru
        </Button>
      </Popover>
    )
  }

  return (
    <>
      <List
        title="Daftar Template Sertifikat"
        headerButtons={() => renderCreateTemplateButton()}
        breadcrumb={
          <NestedBreadcrumb
            injectedItems={[
              ...(canAccessProgram?.can
                ? [
                    {
                      label: 'Program',
                      href: listUrl('lms-program-management'),
                    },
                  ]
                : []),
              {
                label: programId,
                href: canAccessProgram?.can
                  ? showUrl('lms-program-management', programId)
                  : listUrl('lms-program-local-management'),
              },
              {
                label: 'Template Sertifikat',
              },
            ]}
          />
        }
      >
        <Head>
          <title>LMS Admin | {options?.label}</title>
        </Head>

        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="kapabilitas"
            title="Kapabilitas"
            render={(value) => <TextField value={kapabilitasMapper(value)} />}
          />
          <Table.Column
            dataIndex="state"
            title="Status"
            render={(value) =>
              value === 'TEMPLATE_PUBLISHED' ? (
                <Tag color="success">Published</Tag>
              ) : (
                <Tag color="warning">Draft</Tag>
              )
            }
          />
          <Table.Column<CertificateTemplate>
            title="Actions"
            render={(_, record) => (
              <Space>
                <Button
                  icon={<Icons.EditOutlined />}
                  onClick={() => {
                    push(
                      showUrl('certificate-templates', record.id) +
                        `?programId=${programId}`,
                    )
                  }}
                >
                  Edit
                </Button>
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  )
}
