import { UploadProps, message, Upload } from '@pankod/refine-antd'
import { parse, unparse } from 'papaparse'

const MAP_CSV_HEADER = ['no', 'name', 'instance', 'email', 'phone']

export const getUploadProps: (
  onParseSuccess: (data: any) => void,
) => UploadProps = (onParseSuccess) => ({
  accept: '.csv',
  maxCount: 1,
  showUploadList: false,
  beforeUpload(file) {
    const isCsv = file.type === 'text/csv'
    if (!isCsv) {
      message.error(`${file.name} bukan file .csv`)
      return isCsv || Upload.LIST_IGNORE
    }

    parse<Record<string, string>>(file, {
      delimiter: ',',
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      transformHeader(header, index) {
        if (+index < MAP_CSV_HEADER.length) {
          return MAP_CSV_HEADER[+index]
        }
        if (+index >= MAP_CSV_HEADER.length) {
          return `module_${+index - MAP_CSV_HEADER.length}`
        }
        return header
      },
      complete: (results) => {
        const [head, ...items] = results.data
        try {
          const moduleObject = Object.entries(head)
            .filter(([key]) => key.startsWith('module_'))
            .reduce(
              (obj, [key, value]) => ({ ...obj, [key]: value }),
              {} as Record<string, string>,
            )

          const headLength = Object.keys(head).filter((key) =>
            MAP_CSV_HEADER.includes(key),
          ).length

          if (headLength !== MAP_CSV_HEADER.length) {
            throw new Error('Header is not compatible')
          }

          const data = items.map((item) => {
            const eligibleModules = Object.entries(item)
              .filter(([key]) => key.startsWith('module_'))
              .reduce((res, [key, val]) => {
                if (+val === 1) {
                  return [...res, moduleObject[key]]
                }
                return res
              }, [] as string[])
            const filteredItem = Object.entries(item)
              .filter(([key]) => !key.startsWith('module_') && key !== 'no')
              .reduce(
                (res, [key, val]) => ({ ...res, [key]: val }),
                {} as Record<string, string>,
              )
            return {
              ...filteredItem,
              eligibleModules,
            }
          })
          onParseSuccess({ data, modules: Object.values(moduleObject) })
        } catch (e) {
          message.error('Format file CSV tidak sesuai')
        }
      },
    })

    return false
  },
})

type GenerateTemplate = () => string
const generateTemplate: GenerateTemplate = () => {
  const fields = [
    'No',
    'Nama Tim Teknis',
    'Instansi',
    'Alamat Email',
    'Nomor WA',
    'Eligible Modul',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]
  const data: string[][] = []
  const datum = fields.reduce((result, header) => {
    result.push(header)
    return result
  }, [] as string[])

  data[0] = [
    '',
    '',
    '',
    '',
    '',
    '1.1',
    '1.2',
    '2.1',
    '2.2',
    '2.3',
    '3.1',
    '3.2',
    '3.3',
    '3.4',
    '3.5',
  ]

  const EXAMPLE_COUNT = 10
  for (let i = 1; i <= EXAMPLE_COUNT; i++) {
    data.push(
      datum.map((d, idx) => {
        if (idx === 0) return `${i}`
        if (idx === 3) return `email_${i}@domain.com`
        if (idx === 4) return `081XXXXXXXXX`
        if (idx > 4) return String(Math.round(Math.random()))
        return d + ` ${i}`
      }),
    )
  }

  return unparse({
    fields,
    data,
  })
}

export const downloadTemplate = function () {
  const data = generateTemplate()
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', 'csv_template_eligible_instructor.csv')
  a.click()
}
