import { Drawer, Table, Tooltip, useTable } from '@pankod/refine-antd'
import { HttpError } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'
import { useRouter } from 'next/router'

import { PresensiKelompokMuridStatistic } from '@resources/lms-management/program/types'

export const useKelompokMuridDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    kelasId: string | number
    kelasName: string
  }>({ show: false, kelasId: '', kelasName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, kelasName: string) => {
      setShowId({ show: true, kelasId: id, kelasName })
    },
    visible: !!showId.show,
    id: showId.kelasId,
    kelasName: showId.kelasName,
    onClose,
  }
}

type KelompokMuridDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  kelasName: string
}

const KelompokMuridDrawer = ({
  id,
  kelasName,
  onClose,
  visible,
}: KelompokMuridDrawerProps) => {
  const router = useRouter()
  const programId = String(router.query?.programId)
  const [expandedRows, setExpandedRows] = useState<readonly React.Key[]>([])
  const { tableProps } = useTable<
    PresensiKelompokMuridStatistic,
    HttpError,
    unknown
  >({
    dataProviderName: 'lms',
    resource: `programs/${programId}/presensi-murid/classes/${id}/kelompok-murid`,
    queryOptions: {
      enabled: !!programId && !!id,
      select(data) {
        data.data.forEach(({ daftarMurid }) => {
          daftarMurid.forEach(({ daftarLokakarya }) => {
            daftarLokakarya.sort((a, b) => +a.id - +b.id)
          })
        })
        return data
      },
      onSuccess: (data) => {
        setExpandedRows(data?.data?.map(({ id }) => id) || [])
      },
    },
    hasPagination: false,
  })
  const handleClose = () => {
    onClose()
  }

  return (
    <Drawer
      title={`Daftar Kelompok Murid | Kelas: ${kelasName}`}
      visible={visible}
      onClose={handleClose}
      width={'70%'}
      destroyOnClose
    >
      <Table
        {...tableProps}
        rowKey="id"
        columns={[{ title: 'Kelompok Murid', dataIndex: 'name' }]}
        expandable={{
          expandedRowRender: (record) => {
            const lokakaryaColumns =
              record.daftarMurid[0]?.daftarLokakarya?.map(({ name }, i) => ({
                title: (
                  <Tooltip title={name} placement="topLeft">
                    {name}
                  </Tooltip>
                ),
                ellipsis: true,
                width: 150,
                render: (
                  _t: string,
                  _r: PresensiKelompokMuridStatistic['daftarMurid'][0],
                  index: number,
                ) => {
                  return record.daftarMurid?.[index]?.daftarLokakarya?.[i]
                    ?.point
                },
              })) || []
            return (
              <Table
                dataSource={record.daftarMurid}
                rowKey="userId"
                bordered
                pagination={false}
                columns={[
                  {
                    title: 'Nama Murid',
                    dataIndex: 'name',
                    width: '300px',
                  },
                  ...lokakaryaColumns,
                  {
                    title: 'Total Poin',
                    render: (_, murid) => {
                      return murid?.daftarLokakarya?.reduce(
                        (total, { point }) => total + point,
                        0,
                      )
                    },
                    fixed: 'right',
                    width: '100px',
                    align: 'center',
                  },
                ]}
              />
            )
          },
          expandedRowKeys: expandedRows,
          onExpandedRowsChange: setExpandedRows,
        }}
        bordered
      />
    </Drawer>
  )
}

export default KelompokMuridDrawer
