import {
  ModalProps,
  FormProps,
  Modal,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Icons,
  useSelect,
  Select,
  Tooltip,
} from '@pankod/refine-antd'
import React from 'react'
import 'dayjs/locale/id'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import locale from 'antd/lib/locale/id_ID'
import { useResource } from '@pankod/refine-core'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Jakarta')

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
}

const ModalForm = ({
  formProps,
  modalProps,
  mode,
  onClose,
}: ModalFormProps) => {
  const { id: programId } = useResource()
  const isEditMode = mode === 'edit'

  const { selectProps } = useSelect({
    resource: `programs/${programId}/program-modules`,
    dataProviderName: 'lms',
    optionValue: 'id',
    optionLabel: 'name',
    queryOptions: {
      enabled: modalProps.visible,
      select({ data }) {
        return {
          data,
          total: data.length,
        }
      },
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: '-1',
      },
    ],
    defaultValueQueryOptions: {
      enabled: false,
    },
  })

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={isEditMode ? 'Edit Periode Penilaian' : 'Buat Periode Penilaian'}
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => formProps.form?.resetFields()}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues?.data,
          penilaian_period: [
            formProps?.initialValues?.data?.startAt,
            formProps?.initialValues?.data?.endAt,
          ],
          moduleIds: formProps?.initialValues?.data?.modules?.map(
            (data: { id: string; name: string }) => data.id,
          ),
        }}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ penilaian_period, ...data }) => {
          formProps.onFinish?.({
            ...data,
            startAt: penilaian_period[0],
            endAt: penilaian_period[1],
          })
        }}
      >
        <Form.Item
          label="Nama Periode"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Periode Penilaian"
          name="penilaian_period"
          rules={[
            { required: true },
            {
              validator(_, value) {
                if (!value || !value[0] || !value[1]) {
                  return Promise.reject(
                    new Error('Periode Penilaian harus diisi.'),
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(dates) => ({
            value: dates
              ? [
                  dates[0] ? dayjs(dates[0]) : undefined,
                  dates[1] ? dayjs(dates[1]) : undefined,
                ]
              : [],
          })}
          getValueFromEvent={(date) => {
            if (!date) return [null, null]
            const [startDate, endDate]: [Dayjs, Dayjs] = date
            return [
              startDate.tz().startOf('date').toISOString(),
              endDate.tz().endOf('date').toISOString(),
            ]
          }}
        >
          <DatePicker.RangePicker
            format="DD MMM YYYY"
            locale={locale.DatePicker}
            allowEmpty={[false, false]}
            disabledDate={(date) => date.isBefore(dayjs().startOf('date'))}
          />
        </Form.Item>
        <Form.Item
          label="Durasi Asinkronus (Async)"
          name="asyncDuration"
          getValueProps={(value) => ({ value: Math.round(+value / 60) })}
          getValueFromEvent={(value) => +value * 60}
          required
          rules={[{ required: true }]}
        >
          <InputNumber
            style={{ width: '250px' }}
            autoComplete="off"
            controls={false}
            addonAfter="menit"
            addonBefore={<Icons.ClockCircleOutlined />}
            min={0}
            precision={0}
          />
        </Form.Item>
        <Form.Item
          label="Durasi Sinkronus (Sync)"
          name="syncDuration"
          getValueProps={(value) => ({ value: Math.round(+value / 60) })}
          getValueFromEvent={(value) => +value * 60}
          required
          rules={[{ required: true }]}
        >
          <InputNumber
            style={{ width: '250px' }}
            autoComplete="off"
            controls={false}
            addonAfter="menit"
            addonBefore={<Icons.ClockCircleOutlined />}
            min={0}
            precision={0}
          />
        </Form.Item>
        <Form.Item
          label="Modul Program"
          name="moduleIds"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...selectProps}
            options={selectProps?.options?.map(({ label, value }) => ({
              label: (
                <Tooltip title={`ID: ${value}`} placement="topLeft">
                  <div>{label}</div>
                </Tooltip>
              ),
              value,
              text: label,
            }))}
            placeholder="Pilih modul"
            onSearch={() => {}}
            mode="multiple"
            filterOption={(input, option) => {
              return ((option!.text || '') as unknown as string)
                .toLowerCase?.()
                .includes?.(input.toLowerCase())
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalForm
