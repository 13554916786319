import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Icons,
  Table,
} from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'

import { TCommonError, TCommonResponse } from 'src/interfaces/common'
import { ProgramVerification } from './types'
import UpdateFormModal from './UpdateFormModal'

export const useProgramVerificationDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
  }>({ show: false, programId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, programId: id })
    },
    visible: !!showId.show,
    programId: showId.programId,
    onClose,
  }
}

type ProgramVerificationDrawer = {
  visible: boolean
  onClose: () => void
  programId?: string | number | string[]
}

const ProgramVerificationDrawer = ({
  onClose,
  visible,
  programId,
}: ProgramVerificationDrawer) => {
  const { data: response, isLoading } = useOne<
    TCommonResponse<ProgramVerification>,
    TCommonError
  >({
    resource: `programs/${programId}`,
    dataProviderName: 'lms',
    id: 'verification',
    queryOptions: {
      enabled: !!programId && visible,
    },
  })
  const [form] = Form.useForm()
  const [isEditing, setEditing] = useState(false)

  const data = response?.data?.data

  return (
    <Drawer
      title="Data Verifikasi Program"
      visible={visible}
      onClose={onClose}
      destroyOnClose
      maskClosable={false}
      width="70%"
      extra={
        <Button
          icon={<Icons.EditOutlined />}
          onClick={() => {
            form.setFieldsValue(data)
            setEditing(true)
          }}
        >
          Ubah Data
        </Button>
      }
    >
      <Descriptions
        size="small"
        title="Informasi Verifikasi Program"
        bordered
        column={1}
      >
        <Descriptions.Item label="Kegiatan">
          {data?.kegiatan || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Angkatan">
          {data?.angkatan || '-'}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Table
        loading={isLoading}
        dataSource={data?.localPrograms}
        rowKey={'id'}
        pagination={{
          pageSize: 10,
        }}
        scroll={{ x: 500 }}
        style={{ width: '100%' }}
        tableLayout="fixed"
        title={() => <strong>Informasi Verifikasi Program Lokal</strong>}
        columns={[
          { title: 'ID', dataIndex: 'id', width: 100 },
          { title: 'Nama Program Lokal', dataIndex: 'name', width: 250 },
          { title: 'Unit Kerja', dataIndex: 'unitKerja', width: 200 },
          { title: 'Nomor Surat', dataIndex: 'nomorSurat', width: 200 },
          { title: 'Tanggal Terbit', dataIndex: 'tanggalTerbit', width: 200 },
          {
            title: 'Jenis Sertifikat',
            dataIndex: 'jenisSertifikat',
            width: 200,
          },
          { title: 'Peran', dataIndex: 'peran', width: 200 },
          {
            title: 'Penanda Tangan 1',
            dataIndex: 'penandaTangan1',
            width: 160,
          },
          {
            title: 'NIP Penanda Tangan 1',
            dataIndex: 'nipPenandaTangan1',
            width: 160,
          },
          {
            title: 'Penanda Tangan 2',
            dataIndex: 'penandaTangan2',
            width: 160,
          },
          {
            title: 'NIP Penanda Tangan 2',
            dataIndex: 'nipPenandaTangan2',
            width: 160,
          },
          { title: 'URL Sinde 1', dataIndex: 'urlSinde1', width: 250 },
          { title: 'URL Sinde 2', dataIndex: 'urlSinde2', width: 250 },
        ]}
      />

      <UpdateFormModal
        setVisible={setEditing}
        visible={isEditing}
        data={data}
        programId={programId}
      />
    </Drawer>
  )
}

export default ProgramVerificationDrawer
