import {
  AntdList,
  Button,
  Card,
  Divider,
  Drawer,
  Empty,
  Icons,
  Popconfirm,
  Select,
  Space,
  Table,
  useSelect,
  useTable,
} from '@pankod/refine-antd'
import { useInvalidate, useResource, useUpdate } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'
import dayjs from 'dayjs'

import { MappingWebinarProgramLocalResponse } from './types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const useWebinarProgramLocalConfigDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    availableDates: string[]
    webinarId: string | number
  }>({ show: false, webinarId: '', availableDates: [] })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, availableDates: string[]) => {
      setShowId({ show: true, webinarId: id, availableDates })
    },
    visible: !!showId.show,
    id: showId.webinarId,
    onClose,
    availableDates: showId.availableDates,
  }
}

const mapDateProgramLocal = (
  availableDates: string[],
  schedule?: readonly MappingWebinarProgramLocalResponse[],
) => {
  if (!schedule) return []

  return Array.from(
    schedule.reduce(
      (result, { dates, programLocal }) => {
        dates.forEach((date) => {
          const programLocals = result.get(date) || []
          result.set(date, [...programLocals, programLocal])
        })
        return result
      },
      new Map<string, { id: string; name: string }[]>(
        availableDates.map((date) => [date, []]),
      ),
    ),
    ([date, programLocals]) => ({ date, programLocals }),
  )
}

type WebinarProgramLocalConfigDrawerProps = {
  visible: boolean
  id: string | number
  onClose: () => void
  availableDates: string[]
}

const WebinarProgramLocalConfigDrawer = ({
  onClose,
  id,
  visible,
  availableDates,
}: WebinarProgramLocalConfigDrawerProps) => {
  const [newMapping, setNewMapping] = useState<
    {
      date: string
      programLocals: {
        id: string
        name: string
      }[]
    }[]
  >([])
  const [isEditing, setEditing] = useState(false)
  const { id: programId } = useResource()
  const { selectProps } = useSelect({
    resource: `programs/${programId}/local-programs`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: visible && !!programId,
    },
    optionLabel: 'name',
    optionValue: 'id',
    filters: [{ field: 'pageSize', operator: 'eq', value: -1 }],
    defaultValueQueryOptions: {
      enabled: false,
    },
  })
  const {
    tableProps: { dataSource },
  } = useTable<MappingWebinarProgramLocalResponse>({
    dataProviderName: 'lms',
    resource: `programs/${programId}/webinars/${id}/mapping-configs`,
    queryOptions: {
      enabled: !!programId && !!id && visible,
      onSuccess: (data) => {
        setNewMapping(mapDateProgramLocal(availableDates, data.data))
      },
    },
    hasPagination: false,
  })
  const invalidate = useInvalidate()
  const { mutateAsync: doUpdate, isLoading } = useUpdate()

  const mappedDataSource = mapDateProgramLocal(availableDates, dataSource)

  const resetMapping = () => {
    setNewMapping(mappedDataSource)
  }

  const handleClose = () => {
    setEditing(false)
    onClose()
  }

  const handleSubmit = () => {
    const mappedRequestData = Array.from(
      newMapping.reduce((result, { date, programLocals }) => {
        programLocals.forEach(({ id }) => {
          const dates = result.get(id) || []
          result.set(id, [...dates, date])
        })
        return result
      }, new Map<string, string[]>()),
      ([programLocalId, dates]) => ({ programLocalId, dates }),
    )
    return doUpdate(
      {
        id: 'mapping-configs',
        resource: `programs/${programId}/webinars/${id}`,
        values: { schedules: mappedRequestData },
        dataProviderName: 'lms',
        errorNotification: (err) =>
          showErrorNotification(
            err,
            'Terdapat gangguan saat menyimpan perubahan',
          ),
        successNotification: {
          message: 'Perubahan mapping webinar berhasil disimpan',
          type: 'success',
          description: 'Sukses',
        },
      },
      {
        onSuccess: () => {
          invalidate({
            invalidates: ['list'],
            dataProviderName: 'lms',
            resource: `programs/${programId}/webinars/${id}/mapping-configs`,
          })
          setEditing(false)
        },
      },
    )
  }

  const handleChange = (
    value: { label: string; value: string }[],
    date: string,
  ) => {
    setNewMapping((state) => {
      const filtered = state.filter(({ date: itemDate }) => itemDate !== date)
      const currentDate = {
        date,
        programLocals: value.map(({ label, value }) => ({
          id: value,
          name: label,
        })),
      }
      return [...filtered, currentDate].sort(
        (a, b) => +dayjs(a.date) - +dayjs(b.date),
      )
    })
  }

  const disableSubmit = newMapping.every(
    ({ programLocals }) => !programLocals.length,
  )

  return (
    <Drawer
      title={`Konfigurasi Mapping Webinar Program Lokal | Webinar ID ${id}`}
      visible={visible}
      onClose={handleClose}
      width={'70%'}
      maskClosable={false}
      extra={
        isEditing ? (
          <Space>
            <Popconfirm
              title="Apakah Anda yakin ingin menyimpan perubahan ini?"
              onConfirm={async () => handleSubmit()}
              okText="Simpan"
              cancelText="Kembali"
              cancelButtonProps={{ danger: true }}
              placement="bottomRight"
              zIndex={1000}
              disabled={disableSubmit}
            >
              <Button
                type="primary"
                icon={<Icons.SaveOutlined />}
                disabled={disableSubmit}
              >
                Simpan
              </Button>
            </Popconfirm>
            <Button
              onClick={() => {
                setEditing(false)
                resetMapping()
              }}
              danger
              disabled={isLoading}
            >
              Batal
            </Button>
          </Space>
        ) : (
          <Space>
            <Button
              onClick={() => setEditing(true)}
              icon={<Icons.ToolOutlined />}
            >
              Ubah Konfigurasi
            </Button>
          </Space>
        )
      }
    >
      <Table
        pagination={false}
        dataSource={isEditing ? newMapping : mappedDataSource}
        loading={isLoading}
        locale={{
          filterConfirm: (
            <>
              <Icons.FilterOutlined /> Filter
            </>
          ),
          filterSearchPlaceholder: 'Cari Program Lokal',
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Tidak ada data"
              className="!m-0"
            />
          ),
        }}
        columns={[
          {
            title: 'Tanggal Pelaksanaan',
            dataIndex: 'date',
            render: (value) => dayjs(value).format('DD MMMM YYYY'),
            width: 200,
          },
          {
            title: 'Program Lokal',
            dataIndex: 'programLocals',
            filterSearch: true,
            filters:
              selectProps.options?.map(({ label, value }) => ({
                text: label,
                value: value!,
              })) || [],
            onFilter: (value, record) =>
              record.programLocals.some(({ id }) => value === id),
            render: (
              value: { id: string; name: string }[],
              { date, programLocals },
            ) =>
              isEditing ? (
                <Select
                  options={selectProps.options}
                  onSearch={() => {}}
                  filterOption={(input, option) =>
                    (option!.label as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  maxTagCount={'responsive'}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px' }}>
                        <Button
                          type="text"
                          icon={<Icons.CheckSquareOutlined />}
                          onClick={() =>
                            handleChange(
                              selectProps.options as {
                                label: string
                                value: string
                              }[],
                              date,
                            )
                          }
                        >
                          Pilih Semua
                        </Button>
                        <Button
                          type="text"
                          icon={<Icons.CloseSquareOutlined />}
                          onClick={() => handleChange([], date)}
                          danger
                        >
                          Hapus Semua
                        </Button>
                      </Space>
                    </>
                  )}
                  onChange={(value) => handleChange(value, date)}
                  labelInValue
                  value={programLocals.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                  placeholder="Pilih program lokal"
                  mode="multiple"
                  className="w-full"
                />
              ) : (
                <AntdList
                  grid={{ gutter: 16, column: 4 }}
                  dataSource={value}
                  size="small"
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Tidak ada Program Lokal"
                        className="!p-0 !m-0"
                      />
                    ),
                  }}
                  renderItem={({ id, name }) => (
                    <AntdList.Item key={id} className="!px-0">
                      <Card size="small">{name}</Card>
                    </AntdList.Item>
                  )}
                />
              ),
          },
        ]}
      />
    </Drawer>
  )
}

export default WebinarProgramLocalConfigDrawer
