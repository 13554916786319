import {
  TableColumnType,
  TableColumnGroupType,
  Checkbox,
  Table,
  Input,
  Form,
  Icons,
  Tooltip,
} from '@pankod/refine-antd'
import { useState } from 'react'

import { LMSWebinarInstructor } from '../../types'
import useDebounce from '../../utils/useDebounce'

const HeaderTitle = ({ title }: { title: string }) => (
  <div className="w-full text-center">{title}</div>
)

type InstructorTableProps = {
  value?: LMSWebinarInstructor[]
  onChange?: (data: any) => void
  modules: string[]
  loading?: boolean
  formLoading?: boolean
  errors?: Record<string, string[]>
}
export const InstructorTable = ({
  value,
  modules,
  onChange,
  loading,
  formLoading,
  errors,
}: InstructorTableProps) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)
  const isDebouncing = query !== debouncedQuery

  const handleCheckboxClick = (
    module: string,
    record: LMSWebinarInstructor,
    checked: boolean,
  ) => {
    let eligibleModules = record.eligibleModules
    if (checked) {
      eligibleModules = eligibleModules.filter((value) => value !== module)
    } else {
      eligibleModules.push(module)
    }

    const currentRecordIndex =
      value?.findIndex(({ email }) => email === record.email) ?? -1

    if (currentRecordIndex < 0 || !value) return

    const newValue = [...value]
    newValue[currentRecordIndex] = { ...record, eligibleModules }

    onChange?.(newValue)
  }

  const columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      title: <HeaderTitle title="Nama Tim Teknis" />,
      key: 'name',
      dataIndex: 'name',
      fixed: 'left',
      width: 200,
    },
    {
      title: <HeaderTitle title="Instansi" />,
      key: 'instance',
      dataIndex: 'instance',
    },
    {
      title: <HeaderTitle title="Alamat Email" />,
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: <HeaderTitle title="Nomor WA" />,
      key: 'phone',
      dataIndex: 'phone',
    },

    {
      title: 'Eligible Module',
      children: modules.map((module) => ({
        title: module,
        key: module,
        dataIndex: 'eligibleModules',
        align: 'center',
        render: (eligibleModules: string[], record) => {
          const checked = eligibleModules.includes(module)
          const hasError = errors?.[record.email]?.includes(module)
          return (
            <Tooltip title={hasError ? 'Modul telah dipilih instruktur' : null}>
              <Checkbox
                checked={checked}
                onChange={() => handleCheckboxClick(module, record, checked)}
                disabled={formLoading || loading || hasError}
              />
            </Tooltip>
          )
        },
      })),
    },
  ]
  return (
    <>
      <Form.Item>
        <Input
          style={{ width: '30%' }}
          value={query}
          allowClear
          suffix={
            isDebouncing ? (
              <Icons.LoadingOutlined spin />
            ) : (
              <Icons.SearchOutlined />
            )
          }
          onChange={({ target: { value } }) => setQuery(value)}
          placeholder="Cari nama instruktur"
        />
      </Form.Item>
      <Table
        dataSource={value?.filter(({ name }) =>
          name.toLowerCase().includes(debouncedQuery.toLowerCase()),
        )}
        columns={columns}
        pagination={false}
        bordered
        rowKey="email"
        size="middle"
        loading={loading}
        scroll={{ y: 600, x: 800 }}
      />
    </>
  )
}
