import React from 'react'
import { useNode, Node } from '@craftjs/core'
import clsx from 'clsx'
import { Form, Input, InputNumber, Switch } from '@pankod/refine-antd'
import { TextInput as LibraryNumberInput } from '@instructure/ui-text-input'
import { Controller } from 'react-hook-form'
import { capitalize } from 'lodash'

import {
  NumberInputCustomProps,
  TNumberInput,
  WIDGET_NAME,
} from '@components/FormBuilder/schema'
import {
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
  getWidgetSettingsFields,
} from '@components/FormBuilder/utils'
import { useFormContext } from '@components/FormBuilder/formContext'
import ContentForm from '@components/ContentBank/ContentForm'

export const defaultProps: TNumberInput = {
  label: 'Number input label',
  name: 'numberInputName',
}

const PARSE_NUMBER_REGEX = new RegExp(/[^-0-9.]|(?<=\..*)\./, 'g')

const NumberInput = ({ label, name, helper, placeholder }: TNumberInput) => {
  const {
    connectors: { connect, drag },
  } = useNode()
  const { control } = useFormContext()
  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx('mb-3 last:mb-0', 'widget-component', 'text-default')}
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { name, onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <>
            <LibraryNumberInput
              renderLabel={
                <div
                  className="form-label ql-editor"
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              }
              value={value || value === 0 ? String(value) : ''}
              name={name}
              onChange={(e) => onChange(e.target.value)}
              onBlur={(e) => {
                const num = e.target.value.replace(PARSE_NUMBER_REGEX, '')
                onChange(num !== '' ? Number(num) : undefined)
                onBlur()
              }}
              placeholder={placeholder}
              type="tel"
              messages={[
                {
                  text: error ? capitalize(error?.message) : helper,
                  type: error ? 'error' : 'hint',
                },
              ]}
            />
          </>
        )}
      />
    </div>
  )
}

const NumberInputSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Name Key"
        name="name"
        validateStatus={errors['name'] && 'error'}
        help={errors['name']}
      >
        <Input
          autoComplete="off"
          value={props.name}
          onChange={(e) =>
            setProp(
              (props: TNumberInput) =>
                (props.name = e.target.value.replaceAll(' ', '')),
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        validateStatus={errors['label'] && 'error'}
        help={errors['label']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          value={props.text}
          onChange={(text) =>
            setProp((props: TNumberInput) => (props.label = text))
          }
        />
      </Form.Item>
      <Form.Item
        label="Required"
        name="isRequired"
        validateStatus={errors['isRequired'] && 'error'}
        help={errors['isRequired']}
      >
        <Switch
          checked={props.isRequired}
          onChange={(e) =>
            setProp((props: TNumberInput) => (props.isRequired = e))
          }
        />
      </Form.Item>
      <Form.Item
        label="Placeholder"
        name="placeholder"
        validateStatus={errors['placeholder'] && 'error'}
        help={errors['placeholder']}
      >
        <Input
          autoComplete="off"
          value={props.placeholder}
          onChange={(e) =>
            setProp(
              (props: TNumberInput) => (props.placeholder = e.target.value),
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Minimum Value"
        name="minValue"
        validateStatus={errors['minValue'] && 'error'}
        help={errors['minValue']}
      >
        <InputNumber
          autoComplete="off"
          value={props.minValue}
          precision={0}
          min={0}
          onChange={(e) =>
            setProp(
              (props: TNumberInput) =>
                (props.minValue = isNaN(e) ? undefined : Number(e)),
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Maximum Value"
        name="maxValue"
        validateStatus={errors['maxValue'] && 'error'}
        help={errors['maxValue'] || '0 is equal as no limitation'}
      >
        <InputNumber
          autoComplete="off"
          value={props.maxValue}
          precision={0}
          min={1}
          onChange={(e) =>
            setProp(
              (props: TNumberInput) =>
                (props.maxValue = isNaN(e) ? undefined : Number(e)),
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Helper Text"
        name="helper"
        validateStatus={errors['helper'] && 'error'}
        help={errors['helper']}
      >
        <Input
          autoComplete="off"
          value={props.helper}
          onChange={(e) =>
            setProp((props: TNumberInput) => (props.helper = e.target.value))
          }
        />
      </Form.Item>
    </Form>
  )
}

NumberInput.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: NumberInputCustomProps,
  related: {
    settings: NumberInputSettings,
  },
}

export { NumberInput }
