import {
  Button,
  Divider,
  Drawer,
  EditButton,
  Form,
  Icons,
  Input,
  Modal,
  notification,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  TextField,
  Upload,
  UploadFile,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useUpdate } from '@pankod/refine-core'
import { UploadChangeParam } from 'antd/lib/upload'

import { getGuruToken } from 'src/helpers/session'
const { CloudUploadOutlined, LoadingOutlined } = Icons
export type DrawerFormBahanAjarProps = {
  visible: boolean
  drawerProps?: any
  onClose: () => void
  programId: string
}

export const DrawerFormBahanAjar = (props: DrawerFormBahanAjarProps) => {
  const { visible, onClose, programId } = props
  const [formCriteria] = Form.useForm()
  const [BahanAjarTempData, setBahanAjarTempData] = React.useState<any>([])

  const [hasMutated, setHasMutated] = React.useState(false)

  const [isUploadModalShown, setIsUploadModalShown] = React.useState(false)
  const [isUploading, setIsUploading] = React.useState(false)

  const fileInput = React.useRef([])

  const [currentFileData, setFileData] = React.useState<any>(null)
  const [currentFormIndex, setCurrentFormIndex] = React.useState<number>(0)
  const [criteriaFormState, setCriteriaFormState] = React.useState({
    mode: 'create',
    visible: false,
    defaultData: null,
  })

  const { tableProps, tableQueryResult } = useTable({
    resource: `program-dasus/${programId}/bahan-ajar`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!visible,
      onSuccess(data) {
        setBahanAjarTempData(data.data)
      },
    },
  })

  const { mutateAsync: updateBahanAjar } = useUpdate()

  const handleEditCriteria = async (defaultData: any) => {
    setTimeout(() => {
      formCriteria.resetFields()
    }, 0)

    setCriteriaFormState({
      mode: 'edit',
      visible: true,
      defaultData,
    })
  }

  const handleCloseCriteriaForm = () => {
    formCriteria.resetFields()

    setCriteriaFormState({
      mode: 'create',
      visible: false,
      defaultData: null,
    })
  }

  const handleCreateCriteria = () => {
    setTimeout(() => {
      formCriteria.resetFields()
    }, 0)
    setCriteriaFormState({
      mode: 'create',
      visible: true,
      defaultData: null,
    })
  }

  const handleDeleteCriteria = async (title: string) => {
    // delete from BahanAjarTempData
    const newData = BahanAjarTempData.filter(
      (item: any) => item.title !== title,
    )

    await applyAndRefetch(newData)
  }

  const handleUpdateCriteria = async (data: any) => {
    // update from BahanAjarTempData
    const newData = BahanAjarTempData.map((item: any) => {
      if (item.title === data.title) {
        return data
      }
      return item
    })

    await applyAndRefetch(newData)
  }

  const handleAddCriteria = async (data: any) => {
    // add to BahanAjarTempData
    const newData = BahanAjarTempData.concat(data)

    setBahanAjarTempData(newData)

    await applyAndRefetch(newData)
  }

  const applyAndRefetch = async (data: any) => {
    await handleImmediateUpdateForm(data)
    handleCloseCriteriaForm()
  }

  const handleImmediateUpdateForm = (payload: any) => {
    delete payload.total
    updateBahanAjar({
      resource: `program-dasus/${programId}`,
      id: 'bahan-ajar',
      values: { bahanAjar: payload },
      dataProviderName: 'lms',
      successNotification: (e: any) => {
        return {
          message: 'Success',
          description: e.data.message,
          type: 'success',
        }
      },
      errorNotification: (errorRes: any) => {
        const responseErr = errorRes?.response?.data?.error?.message || ''

        return {
          message: responseErr,
          description: 'Failed',
          type: 'error',
        }
      },
    }).then(async () => {
      await tableQueryResult.refetch()
      setHasMutated(false)
    })
  }

  const handleDocUploadOnChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setIsUploading(true)
      return
    }

    if (info.file.status === 'done') {
      // TODO: DO SOMETHING HERE sent to BE
      const url = info.file.response?.data?.url

      setFileData({
        data: info.file,
        url,
        gcsLocation: info.file.response?.data?.gcsLocation,
      })

      setIsUploading(false)

      handleCloseFileUpload()
    }
    if (info.file.status === 'error') {
      setIsUploading(false)

      notification.open({
        message: 'Terjadi Kesalahan Ketika upload file',
        description: info.file.error.message,
        type: 'error',
        duration: 8,
      })
    }
  }

  const handleBeforeUpload = (file: UploadFile) => {
    const MAX_FILE_SIZE = 50 * 1024 * 1024 // 50MB
    if (file && file.size! > MAX_FILE_SIZE) {
      notification.open({
        message: 'Terjadi Kesalahan Ketika upload file',
        description: 'Ukuran file yang diupload melebihi batas maksimum 50MB',
        type: 'error',
        duration: 8,
      })
      return false
    }
    return true
  }

  const handleCloseFileUpload = () => {
    if (currentFileData?.url) {
      formCriteria.setFields([
        {
          name: ['documents', currentFormIndex, 'gcsLocation'],
          value: currentFileData?.gcsLocation,
        },
      ])

      formCriteria.setFields([
        {
          name: ['documents', currentFormIndex, 'url'],
          value: currentFileData?.url,
        },
      ])

      formCriteria.setFields([
        {
          name: ['documents', currentFormIndex, 'filename'],
          value: currentFileData?.data.name,
        },
      ])

      setFileData(null)
      setIsUploadModalShown(false)
    }
  }

  const currentDocumentList = Form.useWatch('documents', formCriteria)

  return (
    <Drawer
      visible={visible}
      closable={false}
      width="50%"
      className="relative"
      destroyOnClose
    >
      <div className="flex justify-end items-end">
        <Button
          size="small"
          icon={<Icons.CloseOutlined />}
          onClick={() => {
            if (hasMutated) {
              window.confirm(
                'Are you sure you want to close this form? Your changes will be lost',
              ) && onClose()
            } else {
              onClose()
            }
          }}
        />
      </div>
      <Tabs defaultActiveKey="criteria" destroyInactiveTabPane>
        <Tabs.TabPane tab="Bahan Ajar" key="criteria">
          <div>
            {criteriaFormState.visible ? (
              <Spin spinning={!!tableProps.loading}>
                <div className="flex justify-between items-center mt-2">
                  <h3>
                    {criteriaFormState.mode.toUpperCase()} Form Bahan Ajar
                  </h3>

                  <Button
                    onClick={handleCloseCriteriaForm}
                    icon={<Icons.CloseOutlined />}
                  />
                </div>

                <Modal
                  title={
                    <>
                      <div className="flex justify-between">
                        Upload Bahan Ajar
                        <Icons.CloseOutlined
                          onClick={() => setIsUploadModalShown(false)}
                        />
                      </div>
                    </>
                  }
                  visible={isUploadModalShown}
                  // onCancel={() => setIsUploadModalShown(false)}
                  className="upload-or-select-modal"
                  destroyOnClose
                  footer={null}
                  closable={false}
                  closeIcon={<Icons.CloseOutlined />}
                >
                  <Upload.Dragger
                    // accept={UPLOAD_FILE_CONSTRAINT.accept}
                    action={`${process.env.NEXT_PUBLIC_LMS_API_URL}/firestore-upload`}
                    beforeUpload={handleBeforeUpload}
                    // onDrop={handleOnDrop}
                    headers={{
                      Authorization: `Bearer ${getGuruToken()}`,
                    }}
                    name="file"
                    onChange={handleDocUploadOnChange}
                    maxCount={1}
                    showUploadList={false}
                    data={{ programId }}
                    disabled={isUploading}
                  >
                    {currentFileData?.url && (
                      // TODO: show after upload
                      <div className="upload-or-select-modal__preview-container">
                        <div className="upload-or-select-modal__preview  h-[4vw] mb-2 p-2">
                          <Icons.CheckCircleFilled
                            style={{ color: '#38a169' }}
                          />
                          <div style={{ marginTop: 8 }}>
                            {currentFileData?.data.name} has been uploaded!
                          </div>
                        </div>
                      </div>
                    )}

                    {!currentFileData?.url && (
                      <>
                        {isUploading ? (
                          <div>
                            <LoadingOutlined spin />
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                        ) : (
                          <div>
                            <p className="ant-upload-drag-icon">
                              <CloudUploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Klik atau seret berkas ke area ini untuk
                              mengunggah
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </Upload.Dragger>

                  {currentFileData?.url && (
                    // TODO: show after upload
                    <div className="upload-or-select-modal__preview-container mt-2">
                      <div className="p-2">
                        <Button
                          onClick={() => {
                            handleCloseFileUpload()
                          }}
                          className="mt-4"
                          block
                        >
                          apply file to form
                        </Button>
                      </div>
                    </div>
                  )}
                  <p className="text-xs text-gray-20 mt-4">
                    Max file size: 50MB
                  </p>
                </Modal>

                <div className="max-h-[65vh] overflow-y-auto p-2 mt-4">
                  <Form
                    form={formCriteria}
                    layout="horizontal"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 15 }}
                    size="small"
                    initialValues={criteriaFormState.defaultData || {}}
                    onFinish={(values: any) => {
                      if (criteriaFormState.mode === 'edit') {
                        handleUpdateCriteria(values)
                      } else {
                        handleAddCriteria(values)
                      }
                    }}
                    validateTrigger="onChange"
                  >
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: true, message: 'Title is required' }]}
                    >
                      <Input />
                    </Form.Item>

                    <div className="flex flex-col w-full p-2 border rounded-md mt-4">
                      <Form.List name={['documents']}>
                        {(fields, { add, remove }) => (
                          <>
                            <div className="flex justify-end mr-[18px]">
                              <Button
                                size="small"
                                onClick={() => {
                                  add()
                                }}
                              >
                                Tambah Dokumen
                              </Button>
                            </div>

                            <Divider />

                            <div className="overflow-y-auto max-h-[30vw]">
                              {fields.map((field, index) => {
                                return (
                                  <div
                                    key={`field-list-scoring-${index}`}
                                    className={'flex flex-col'}
                                  >
                                    <div className="flex flex-row w-full ">
                                      <Form.Item
                                        {...field}
                                        label="Value"
                                        className="w-full "
                                        name={[index, 'filename']}
                                        key={`${field.key}-text-${index}-value`}
                                        rules={[
                                          {
                                            required: true,
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>

                                      <Form.Item
                                        {...field}
                                        label="Value"
                                        className="w-full "
                                        name={[index, 'gcslocation']}
                                        key={`${field.key}-text-${index}-gcslocation`}
                                        hidden
                                      >
                                        <Input />
                                      </Form.Item>

                                      <Form.Item
                                        {...field}
                                        className="w-[150px] "
                                        name={[index, 'url']}
                                        key={`${field.key}-value-${index}`}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'file is required',
                                          },
                                        ]}
                                      >
                                        <input
                                          type="file"
                                          style={{ display: 'none' }}
                                          ref={(ref) => {
                                            // @ts-ignore
                                            fileInput.current[index] = ref
                                          }}
                                          onChange={(e) => {
                                            // @ts-ignore
                                            const filename =
                                              // @ts-ignore
                                              e?.target?.files[0].name

                                            formCriteria.setFields([
                                              {
                                                name: [
                                                  'documents',
                                                  index,
                                                  'filename',
                                                ],
                                                value: filename,
                                              },
                                            ])
                                          }}
                                        />

                                        <Button
                                          type="primary"
                                          size="small"
                                          onClick={() =>
                                            // @ts-ignore
                                            // fileInput.current[index].click()
                                            {
                                              setIsUploadModalShown(true)
                                              setCurrentFormIndex(index)
                                            }
                                          }
                                        >
                                          Upload File
                                        </Button>
                                      </Form.Item>

                                      {(currentDocumentList?.[index]?.url ||
                                        currentDocumentList?.[index]
                                          ?.gcsLocation) && (
                                        <div className="px-2 mb-2">
                                          <a
                                            href={
                                              currentDocumentList?.[index]
                                                ?.url ||
                                              currentDocumentList?.[index]
                                                ?.gcsLocation
                                            }
                                            target={'_blank'}
                                            rel="noreferrer"
                                          >
                                            <Tag color="blue">
                                              <Icons.FileDoneOutlined
                                                style={{
                                                  fontSize: '12px',
                                                  marginRight: '4px',
                                                  color: '#fff',
                                                }}
                                              />
                                            </Tag>
                                          </a>
                                        </div>
                                      )}
                                      <Button
                                        danger
                                        className="mr-4 w-full"
                                        onClick={() => remove(field.name)}
                                        icon={<Icons.DeleteOutlined />}
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </>
                        )}
                      </Form.List>
                    </div>

                    <div className="absolute bottom-[-40px] w-full">
                      <Button htmlType="submit" block>
                        Apply Updates
                      </Button>
                    </div>
                  </Form>
                </div>
              </Spin>
            ) : (
              <>
                <div className="flex flex-end justify-end mb-4">
                  <Button onClick={handleCreateCriteria}>
                    Tambah Bahan Ajar
                  </Button>
                </div>
                <Table
                  {...tableProps}
                  dataSource={BahanAjarTempData}
                  rowKey="id"
                  className="w-full"
                >
                  <Table.Column
                    dataIndex="title"
                    title="title"
                    render={(value) => <TextField value={value} />}
                  />
                  <Table.Column
                    title="Actions"
                    render={(_, record: any) => (
                      <Space>
                        <EditButton
                          size="small"
                          recordItemId={record.id}
                          onClick={() => {
                            handleEditCriteria(record)
                          }}
                        />
                        <Button
                          size="small"
                          danger
                          onClick={() => handleDeleteCriteria(record.title)}
                          icon={<Icons.DeleteOutlined />}
                        />
                      </Space>
                    )}
                  />
                </Table>
              </>
            )}
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}
