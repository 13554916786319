import { useRouter } from 'next/router'
import {
  Form,
  Button,
  Icons,
  Input,
  useForm,
  Descriptions,
  Select,
  TextField,
  Radio,
} from '@pankod/refine-antd'
import { useInvalidate, useOne, useResource } from '@pankod/refine-core'
import React from 'react'

import { TCommonError } from 'src/interfaces/common'
import { TDistrictData, TLMSParticipantDetails } from '../types'
import { useParticipantFilterContext } from 'src/hooks/context/useParticipantFilterOptionsContext'
import { kapabilitasMapper, kapabilitasTags } from './utils'

const REGION_KEY = ['provinsi', 'kabupaten', 'kecamatan']
const LABEL_KEY: Record<keyof TLMSParticipantDetails, string> = {
  email: 'Email',
  id: 'ID',
  jenjang: 'Jenjang',
  kabupaten: 'Kabupaten',
  kapabilitas: 'Kapabilitas',
  kecamatan: 'Kecamatan',
  kelasId: 'ID Kelas',
  kelasName: 'Nama Kelas',
  mataPelajaran: 'Mata Pelajaran',
  name: 'Nama',
  npsn: 'NPSN',
  nuptk: 'NUPTK',
  participantType: 'Tipe Peserta',
  programLocalId: 'ID Program Lokal',
  programLocalName: 'Nama Program Lokal',
  provinsi: 'Provinsi',
  ptk: 'PTK',
  sekolah: 'Sekolah',
  userId: 'User ID',
}

// TODO: integrate this to bussiness logic
export const ParticipantDetails = () => {
  const { resourceName } = useResource()
  const router = useRouter()
  const query = router.query
  const { id, participantEmail, mode, programId } = query
  const actualProgramId = programId || id
  const invalidate = useInvalidate()
  const {
    data: filterOptions,
    isLoading: filterOptionLoading,
    getCityOptions,
  } = useParticipantFilterContext()
  const {
    data: participantData,
    isLoading,
    // refetch,
  } = useOne<{
    data: TLMSParticipantDetails
  }>({
    resource: `programs/${actualProgramId}/participants`,
    dataProviderName: 'lms',
    id: `details?email=${participantEmail as string}`,
    // @ts-ignore
  })

  const { formProps, formLoading, onFinish, saveButtonProps } = useForm<
    { data: TLMSParticipantDetails },
    TCommonError,
    any
  >({
    resource: `programs/${actualProgramId}/participants/details`,
    dataProviderName: 'lms',
    action: 'edit',
    metaData: {
      httpMethod:
        participantData?.data?.data?.participantType === 'guru' && 'patch',
    },
    redirect: false,
    successNotification: (e: any) => {
      return {
        message: 'Success',
        type: 'success',
        description: e.data.message,
      }
    },
    errorNotification: (errorRes: any) => {
      const responseErr = errorRes?.response?.data?.error?.message || ''

      return {
        message: responseErr,
        description: 'Gagal edit partisipan',
        type: 'error',
      }
    },

    onMutationSuccess: () => {
      invalidate({
        resource: `programs/${actualProgramId}/participants`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
      invalidate({
        resource: `programs/${actualProgramId}/participants`,
        id: `details?email=${participantEmail as string}`,
        dataProviderName: 'lms',
        invalidates: ['detail'],
      })
      {
        resourceName === 'lms-program-local-management' &&
          invalidate({
            resource: `programs/${actualProgramId}/local-programs/${id}/participants`,
            id: `details?email=${participantEmail as string}`,
            dataProviderName: 'lms',
            invalidates: ['list'],
          })
      }
      router.replace({
        pathname: router.pathname,
        query: {
          ...query,
          mode: 'view',
        },
      })
    },
  })

  const [cityOptions, setCityOptions] = React.useState<any>(
    getCityOptions(participantData?.data?.data?.provinsi?.kode as string),
  )

  const [selectedCity, setSelectedCity] = React.useState<string | null>(
    participantData?.data?.data?.kabupaten?.kode || null,
  )

  const handleCloseDetails = () => {
    router.replace({
      pathname: router.pathname,
      query: {
        ...query,
        participantEmail: undefined,
      },
    })
  }

  const generateCityOptions = (provinceCode: string) => {
    setCityOptions(getCityOptions(provinceCode))

    formProps.form?.setFieldsValue({
      kode_kabupaten: undefined,
      kode_kecamatan: undefined,
    })

    setSelectedCity(null)
  }

  const { data: districtOptions } = useOne<TDistrictData>({
    resource: `programs/participants/filter-options`,
    id: `kecamatan?kodeKabupaten=${selectedCity}`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!selectedCity && query.mode === 'edit',
    },
  })

  const constructInitialValues = () => {
    const initialValues = {
      ...participantData?.data?.data,
      kapabilitas: Array.isArray(participantData?.data?.data?.kapabilitas)
        ? participantData?.data?.data?.kapabilitas?.[0] || null
        : participantData?.data?.data?.kapabilitas || null,
      kode_provinsi: participantData?.data?.data?.provinsi?.kode,
      kode_kabupaten: participantData?.data?.data?.kabupaten?.kode,
      kode_kecamatan: participantData?.data?.data?.kecamatan?.kode,
    }
    // remove region from initial values

    return initialValues
  }

  const handleOnFinish = async (values: any) => {
    const payload = JSON.parse(JSON.stringify(values))
    const guruFormPayload = {
      email: payload.email,
      kapabilitas: payload.kapabilitas,
    }

    if (participantData?.data.data.participantType === 'non-guru') {
      await onFinish(payload)
    } else {
      await onFinish(guruFormPayload)
    }
  }

  React.useEffect(() => {
    if (!isLoading && !filterOptionLoading) {
      setCityOptions(
        getCityOptions(participantData?.data?.data?.provinsi?.kode as string),
      )
      setSelectedCity(participantData?.data?.data?.kabupaten?.kode || null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, filterOptionLoading])

  if (isLoading || filterOptionLoading) {
    return (
      <div>
        <Icons.LoadingOutlined spin />
        <div style={{ marginTop: 8 }}>Loading..</div>
      </div>
    )
  }

  const isGuru = participantData?.data?.data.participantType === 'guru'

  return (
    <div>
      <div className="flex flex-row items-center mb-4">
        <div className="mr-4">
          <Icons.CloseOutlined onClick={handleCloseDetails} />
        </div>
        {(mode as string)?.toUpperCase()} Participant
      </div>
      {mode === 'view' && (
        <div className="h-[58.5vh] overflow-y-scroll">
          <Descriptions bordered>
            {Object.entries(participantData?.data?.data || []).map(
              ([key, value]) => {
                if (REGION_KEY.includes(key)) {
                  return (
                    <Descriptions.Item
                      key={key}
                      label={
                        LABEL_KEY[key as keyof TLMSParticipantDetails] || key
                      }
                      span={3}
                    >
                      {/** @ts-expect-error */}
                      {value?.name || (
                        <TextField value={'Kosong'} type="secondary" italic />
                      )}
                    </Descriptions.Item>
                  )
                }

                return (
                  <Descriptions.Item
                    key={key}
                    label={
                      LABEL_KEY[key as keyof TLMSParticipantDetails] || key
                    }
                    span={3}
                  >
                    {key === 'kapabilitas'
                      ? /** @ts-expect-error */
                        kapabilitasTags(value)
                      : value || (
                          <TextField value={'Kosong'} type="secondary" italic />
                        )}
                  </Descriptions.Item>
                )
              },
            )}
          </Descriptions>
        </div>
      )}
      {mode === 'edit' && (
        <Form
          {...formProps}
          onFinish={handleOnFinish}
          initialValues={constructInitialValues()}
          layout="vertical"
        >
          <div className="h-[70vh] overflow-y-scroll px-2">
            <Form.Item
              label="Nama"
              name="name"
              rules={[{ required: true, message: 'Required!' }]}
            >
              <Input disabled={isGuru} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Required!',
                },
              ]}
              hidden
            />

            <Form.Item label="PTK" name="ptk">
              <Input disabled={isGuru} />
            </Form.Item>

            <Form.Item label="NUPTK" name="nuptk">
              <Input disabled={isGuru} />
            </Form.Item>
            <Form.Item label="NPSN" name="npsn">
              <Input disabled={isGuru} />
            </Form.Item>

            <Form.Item label="Mata Pelajaran" name="mataPelajaran">
              <Input disabled={isGuru} />
            </Form.Item>

            <Form.Item
              name="jenjang"
              label="Jenjang"
              rules={[{ required: true, message: 'Required!' }]}
            >
              <Select disabled={isGuru}>
                {filterOptions?.data?.jenjang?.map((item: any) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Provinsi"
              name="kode_provinsi"
              rules={[{ required: true, message: 'Please input Provinsi!' }]}
            >
              <Select
                disabled={isGuru}
                onChange={(e) => generateCityOptions(e)}
                showSearch
                filterOption={(input, option) =>
                  ((option?.children ?? '') as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {filterOptions?.data?.regions?.map((item) => (
                  <Select.Option key={item?.kode} value={item?.kode}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="kode_kabupaten"
              label="Kabupaten"
              rules={[{ required: true, message: 'Please input kabupaten!' }]}
            >
              <Select
                disabled={isGuru}
                onChange={(e) => {
                  formProps.form?.setFieldsValue({
                    kode_kecamatan: undefined,
                  })
                  setSelectedCity(e)
                }}
                showSearch
                filterOption={(input, option) =>
                  ((option?.children ?? '') as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {cityOptions?.map((item: any) => (
                  <Select.Option key={item?.kode} value={item?.kode}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="kode_kecamatan"
              label="Kecamatan"
              rules={[{ required: true, message: 'Please input kecamatan!' }]}
            >
              <Select
                showSearch
                disabled={isGuru}
                filterOption={(input, option) =>
                  ((option?.children ?? '') as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {selectedCity &&
                  districtOptions?.data?.kecamatan.map((item) => (
                    <Select.Option key={item?.kode} value={item?.kode}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="kapabilitas"
              label="Kapabilitas"
              required
              rules={[
                {
                  validator: (_, value) => {
                    if (!value && value !== null) {
                      return Promise.reject('Required!')
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Radio.Group>
                {filterOptions?.data?.kapabilitas.map((item: any) => (
                  <Radio
                    key={item}
                    value={item}
                    style={{
                      marginLeft: '0px',
                      marginBottom: 8,
                      display: 'flex',
                    }}
                  >
                    {kapabilitasMapper(item)}
                  </Radio>
                ))}
                <Radio
                  key={'peserta'}
                  value={null}
                  style={{
                    marginLeft: '0px',
                    marginBottom: 8,
                    display: 'flex',
                  }}
                >
                  {kapabilitasMapper('peserta')}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="flex flex-row  mt-4">
            <Button
              {...saveButtonProps}
              loading={formLoading}
              type="primary"
              htmlType="submit"
              onClick={() => {}}
            >
              Simpan
            </Button>

            <Button
              className="ml-2"
              onClick={handleCloseDetails}
              type="default"
              htmlType="button"
            >
              Batal
            </Button>
          </div>
        </Form>
      )}
    </div>
  )
}
