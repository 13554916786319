import axios, { AxiosHeaders, AxiosInstance } from 'axios'
import { parseCookies } from 'nookies'
import queryString from 'query-string'
import {
  CrudFilters,
  CrudOperators,
  CrudSorting,
  DataProvider,
  HttpError,
} from '@pankod/refine-core'
import _ from 'lodash'

type TkvQueryProcessor = (params: {
  data: any
  query: any
}) => Promise<any> | any

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.errors?.[0]?.message,
      statusCode: error.response?.status,
    }

    return Promise.reject(customError)
  },
)

axiosInstance.interceptors.request.use((request) => {
  const { userSession } = parseCookies()
  const { guruToken } = JSON.parse(userSession)

  // Check if the header property exists
  if (request.headers) {
    // Set the Authorization header if it exists
    request.headers['Authorization'] = `Bearer ${guruToken}`
  } else {
    // Create the headers property if it does not exist
    request.headers = AxiosHeaders.concat(request.headers, {
      Authorization: `Bearer ${guruToken}`,
    })
  }

  return request
})

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case 'ne':
    case 'gte':
    case 'lte':
      return `_${operator}`
    case 'contains':
      return '_like'
    case 'eq':
    default:
      return ''
  }
}

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const sortBy: string[] = []

    sort.map((item) => {
      sortBy.push(`${item.order === 'asc' ? '' : '-'}${item.field}`)
    })

    return {
      sortBy,
    }
  }

  return
}

// CLIENT-SIDE KV QUERY PROCESSOR
const kvQueryProcessor: TkvQueryProcessor = ({ data, query }) => {
  const { sort, ...filters } = query

  const filteredData = data.filter((item: any) => {
    let isMatch = true

    Object.keys(filters).forEach((key) => {
      // if filter key not exist in item, return false
      const itemFilterTarget = item[key] || false
      if (itemFilterTarget !== filters[key]) {
        isMatch = false
      }
    })

    return isMatch
  })

  const sortReverse = sort && sort.includes('-')
  const sortQuery = sort && sort.replace('-', '')

  return sortQuery
    ? filteredData.sort((a: any, b: any) => {
        if (a[sortQuery] > b[sortQuery]) {
          if (sortReverse) return -1
          return 1
        }
        if (a[sortQuery] < b[sortQuery]) {
          if (sortReverse) return 1
          return -1
        }
        return 0
      })
    : filteredData
}

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string[] } = {}

  if (filters) {
    filters.map((filter) => {
      if (filter.operator !== 'or') {
        const { field, operator, value } = filter

        if (field === 'q') {
          queryFilters[field] = Array.isArray(value)
            ? _.flatten<string>(value)
            : value
          return
        }

        const mappedOperator = mapOperator(operator)
        queryFilters[`${field}${mappedOperator}`] = Array.isArray(value)
          ? _.flatten<string>(value)
          : value
      }
    })
  }
  return queryFilters
}

const JsonServer = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance,
): DataProvider => ({
  getList: async ({
    resource,
    hasPagination,
    pagination,
    filters,
    sort,
    metaData,
  }) => {
    let url = `${apiUrl}/${resource}`

    if (metaData?.isKv) {
      url = `${apiUrl}/kv`
    }

    // pagination
    const current = pagination?.current || 1
    const pageSize = pagination?.pageSize || 10

    const queryFilters = generateFilter(filters)

    const query: {
      page?: number
      pageSize?: number
      sort?: string
    } & Record<string, string | number | undefined> = {
      page: current,
      pageSize: pageSize,
    }

    const generatedSort = generateSort(sort)
    if (generatedSort) {
      const { sortBy } = generatedSort
      query[metaData?.querySortKey || 'sort'] = sortBy.join(',')
    }

    if (!hasPagination) {
      query.pageSize = undefined
      query.page = undefined
    }

    const queryKey = metaData?.queryKey

    const queryStr = queryString.stringify(
      {
        ...query,
        ...queryFilters,
        ...queryKey,
      },
      { arrayFormat: metaData?.queryArrayFormat || 'comma' },
    )

    const { data } = await httpClient.get(
      `${url}${queryStr !== '' ? `?${queryStr}` : ''}`,
    )

    // const total = +headers['x-total-count']
    if (metaData?.dataKey) {
      let responseData = data.data

      if (metaData?.isKv) {
        const { sort } = query

        responseData[metaData.dataKey] = kvQueryProcessor({
          data: responseData[metaData.dataKey],
          query: {
            ...queryFilters,
            sort,
          },
        })
      }

      return {
        data: responseData[metaData.dataKey],
        allData: responseData,
        /** TODO: Fix this kind of total of data (should be provided from BE) */
        total: responseData[metaData.dataKey].length,
      }
    }

    return {
      ...data,
      data: data.data,
      total: data.meta?.totalItems || data.data?.length || 0,
      meta: data.meta,
    }
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${queryString.stringify({ id: ids })}`,
    )

    return {
      data,
    }
  },

  create: async ({ resource, variables, metaData }) => {
    if (metaData?.isKv && metaData?.dataKey) {
      const url = `${apiUrl}/kv?${queryString.stringify(metaData?.queryKey)}`
      const { data } = await httpClient.post(url, variables)

      return {
        data,
      }
    }

    const url = `${apiUrl}/${resource}`

    const method: 'post' | 'put' = metaData?.httpMethod || 'post'

    const { data } = await httpClient[method](url, variables)

    return {
      data,
    }
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(`${apiUrl}/${resource}`, param)
        return data
      }),
    )

    return { data: response }
  },

  update: async ({ resource, id, variables, metaData }) => {
    if (metaData?.isKv && metaData?.dataKey) {
      const url = `${apiUrl}/kv?${queryString.stringify(metaData?.queryKey)}`
      const { data } = await httpClient.post(url, variables)
      return {
        data,
      }
    }

    const url = `${apiUrl}/${resource}/${id}`.replace(/^\/+|\/+$/g, '')
    const method: 'put' | 'patch' = metaData?.httpMethod || 'put'
    const { data } = await httpClient[method](url, variables)

    return {
      data,
    }
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.put(
          `${apiUrl}/${resource}/${id}`,
          variables,
        )
        return data
      }),
    )

    return { data: response }
  },

  getOne: async ({ resource, id, metaData }) => {
    if (metaData?.isKv && metaData?.dataKey) {
      const url = `${apiUrl}/kv?${queryString.stringify(metaData?.queryKey)}`
      const { data } = await httpClient.get(url)

      const value = data.data[metaData.dataKey]

      // for feature_flag KV
      if (typeof value === 'boolean') {
        return {
          data: data.data,
        }
      }

      const dataById = data.data[metaData.dataKey].find((d: any) => d.id === id)
      return {
        data: dataById,
        allData: data.data,
      }
    }

    const url = `${apiUrl}/${resource}/${id}`.replace(/\/+$/, '')

    const { data } = await httpClient.get(url)

    return {
      data,
    }
  },

  deleteOne: async ({ resource, id, variables, metaData }) => {
    const url = `${apiUrl}/${resource}/${id}`
      /** Trim trailing slash */
      .replace(/^\/+|\/+$/g, '')
    if (metaData?.isKv && metaData?.dataKey && metaData?.payload) {
      const url = `${apiUrl}/kv`
      const softDelete = metaData?.softDelete
      let payload = metaData.payload
      let metaDataValue = payload.value[metaData.dataKey]

      if (softDelete) {
        metaDataValue = metaDataValue.map((data: any) => {
          if (data.id === id) {
            return {
              ...data,
              deletedAt: new Date().toISOString(),
              softDelete: true,
            }
          }
          return data
        })
      } else {
        metaDataValue = metaDataValue.filter((data: any) => data.id !== id)
      }

      if (!!metaData?.deletedId) {
        payload = {
          ...payload,
          value: JSON.stringify({
            ...payload.value,
            [metaData.dataKey]: metaDataValue,
          }),
        }
      }

      const { data } = await httpClient.post(url, payload)
      return {
        data,
      }
    }

    const { data } = await httpClient.delete(url, {
      data: variables || metaData?.payload || {},
    })

    return {
      data,
    }
  },

  deleteMany: async ({ resource, ids, variables, metaData }) => {
    if (metaData?.isKv && metaData?.dataKey && metaData?.payload) {
      const url = `${apiUrl}/kv`
      const softDelete = metaData?.softDelete
      let payload = metaData.payload
      let metaDataValue = payload.value[metaData.dataKey]

      if (softDelete) {
        metaDataValue = metaDataValue.map((data: any) => {
          if (ids.includes(data.id)) {
            return {
              ...data,
              deletedAt: new Date().toISOString(),
              softDelete: true,
            }
          }
          return data
        })
      } else {
        metaDataValue = metaDataValue.filter(
          (data: any) => !ids.includes(data.id),
        )
      }

      payload = {
        ...payload,
        value: JSON.stringify({
          ...payload.value,
          [metaData.dataKey]: metaDataValue,
        }),
      }

      const { data } = await httpClient.post(url, payload)
      return {
        data,
      }
    }

    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(
          `${apiUrl}/${resource}/${id}`,
          { data: variables },
        )
        return data
      }),
    )
    return { data: response }
  },

  getApiUrl: () => {
    return apiUrl
  },

  custom: async ({
    url,
    method,
    filters,
    sort,
    payload,
    query,
    headers,
    metaData,
  }) => {
    let requestUrl = `${url}?`

    if (sort) {
      const generatedSort = generateSort(sort)
      if (generatedSort) {
        const { sortBy } = generatedSort
        const sortQuery = {
          [metaData?.querySortKey || 'sort']: sortBy.join(','),
        }
        requestUrl = `${requestUrl}${
          requestUrl.at(-1) === '?' ? '' : '&'
        }${queryString.stringify(sortQuery)}`
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters)
      requestUrl = `${requestUrl}${
        requestUrl.at(-1) === '?' ? '' : '&'
      }${queryString.stringify(filterQuery)}`
    }

    if (query) {
      requestUrl = `${requestUrl}${
        requestUrl.at(-1) === '?' ? '' : '&'
      }${queryString.stringify(query)}`
    }

    // if (headers) {
    //   httpClient.defaults.headers = {
    //     ...httpClient.defaults.headers,
    //     ...headers,
    //   }
    // }

    const applyCustomHeaders = () => ({
      ...httpClient.defaults.headers[method],
      ...headers,
      // @ts-expect-error
      ...(!headers?.['content-type'] &&
        ['put', 'post', 'patch'].includes(method) && {
          'content-type': 'application/json',
        }), // Set default `content-type` to application/json for mutation (non delete)
    })

    if (requestUrl.at(-1) === '?') {
      requestUrl = requestUrl.slice(0, requestUrl.length - 1)
    }

    let axiosResponse
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](requestUrl, payload, {
          headers: applyCustomHeaders(),
        })
        break
      case 'delete':
        axiosResponse = await httpClient.delete(requestUrl, {
          headers: applyCustomHeaders(),
        })
        break
      default:
        if (metaData?.customQueryFn) {
          axiosResponse = await metaData.customQueryFn({
            axiosInstance: httpClient,
            headers: applyCustomHeaders(),
            url: requestUrl,
          })
        } else {
          axiosResponse = await httpClient.get(requestUrl, {
            headers: applyCustomHeaders(),
          })
        }
        break
    }

    const { data } = axiosResponse

    return Promise.resolve({ data })
  },
})

export default JsonServer
