import {
  Modal,
  Form,
  Table,
  TableProps,
  message,
  Typography,
} from '@pankod/refine-antd'
import React, { useState } from 'react'

import { PesertaResponse } from '@resources/angkatan-ppg-management/types'

type ModalFilterCheckFormProps = {
  isVisible: boolean
  onClose: () => void
  isSubmitting?: boolean
  tableProps: any
  tableQueryResult: any
  columns?: any
  filterComponent?: React.ReactNode
  modalTitle: string
  maxSelection?: number
  onSubmit: any
  initialValues?: any
}
export const ModalFilterCheckForm = ({
  isVisible,
  onClose,
  isSubmitting = false,
  tableProps,
  tableQueryResult,
  columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ],
  filterComponent,
  modalTitle = 'ubah Aktor Matakuliah',
  maxSelection = 999,
  onSubmit,
  initialValues,
}: ModalFilterCheckFormProps) => {
  const [selectedRecord, setSelectedRecord] = useState<any>(initialValues)
  const [recordIds, setrecordIds] = useState<Set<React.Key>>(
    new Set(initialValues && initialValues.map((item: any) => item?.id)),
  )

  const rowSelection: TableProps<PesertaResponse>['rowSelection'] = {
    hideSelectAll: false,

    preserveSelectedRowKeys: true,
    onSelect: (data) => {
      if (recordIds.size >= maxSelection) {
        setrecordIds((values) => {
          if (values.has(data.id)) {
            values.delete(data.id)
            return new Set(values)
          }

          if (values.size >= maxSelection) {
            // delete first value
            values.delete(values.values().next().value)

            values.add(data.id)
          }

          return new Set(values)
        })

        return new Set([data.id])
      }

      if (recordIds.has(data.id)) {
        setrecordIds((values) => {
          values.delete(data.id)
          return new Set(values)
        })
        return
      }

      setrecordIds((values) => {
        values.add(data.id)
        return new Set(values)
      })
    },
    onChange(_selectedRowKeys, selectedRows, _info) {
      // remove first item in selectedRows
      if (selectedRows.length > maxSelection) {
        selectedRows.shift()
      }

      // console.log(selectedRows)

      setSelectedRecord(selectedRows)
    },
    selectedRowKeys: Array.from(recordIds),
    onSelectAll: (selected, _selectedRows, _changeRows) => {
      if (selected) {
        setrecordIds((values) => {
          const newValues = new Set(values)
          tableProps.dataSource.forEach((item: any) => {
            if (item?.id) {
              newValues.add(item.id)
            }
          })
          return newValues
        })
        return
      }
      setrecordIds((values) => {
        const newValues = new Set(values)
        _changeRows.forEach((item: any) => {
          if (item?.id) {
            newValues.delete(item.id)
          }
        })
        return newValues
      })
    },
    defaultSelectedRowKeys: Array.from(recordIds),
  }

  React.useEffect(() => {
    if (isVisible && initialValues) {
      setrecordIds((values) => {
        values.clear()
        return new Set(initialValues.map((item: any) => item?.id))
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      afterClose={() => {
        setrecordIds((values) => {
          values.clear()
          return new Set(values)
        })
      }}
      title={modalTitle}
      okText="Simpan"
      cancelText="Kembali"
      maskClosable={false}
      okButtonProps={{
        onClick: () => {
          // remove entry if selected record undefined
          const newSelectedRecord =
            selectedRecord &&
            selectedRecord.filter((item: any) => item !== undefined)
          onSubmit(newSelectedRecord)
        },
        loading: isSubmitting,
      }}
    >
      <div className="flex flex-row justify-end mb-4">{filterComponent}</div>

      <div className="flex flex-row mb-4">
        <Typography.Text strong className="ml-4">
          {recordIds.size} terpilih
        </Typography.Text>
      </div>

      <Form
        onFinishFailed={({ errorFields }) => {
          errorFields.forEach(({ errors }) => {
            message.error(errors[0])
          })
        }}
        initialValues={{
          recordIds: initialValues,
        }}
      >
        <Table
          {...tableProps}
          rowSelection={rowSelection}
          size="small"
          rowKey="id"
          columns={columns}
          loading={tableQueryResult.isFetching}
        />

        <Form.Item
          name="recordIds"
          hidden
          rules={[
            {
              validator(_, value) {
                if (!value || !value?.length)
                  return Promise.reject('Minimal 1 Data harus dipilih')
                return Promise.resolve()
              },
            },
          ]}
        >
          <select />
        </Form.Item>
      </Form>
    </Modal>
  )
}
