import React from 'react'
import dynamic from 'next/dynamic'
import RQ, { ReactQuillProps } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Button, Card, Icons } from '@pankod/refine-antd'
import clsx from 'clsx'

import { formats, handleAttachment } from './helper'
import QuillToolbar from './QuillToolbar'
import UploadOrSelectModal from '@components/UploadOrSelectModal'

type TForwardedReactQuillProps = { forwardedRef: any } & ReactQuillProps
const ReactQuill: React.ComponentType<TForwardedReactQuillProps> = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill')
    const { default: ImageCompress } = await import('quill-image-compress')

    const Link = RQ.Quill.import('formats/link')
    const PROTOCOL_WHITELIST = ['mailto', 'tel', 'http']
    class CustomLink extends Link {
      static sanitize(url: string) {
        const value = super.sanitize(url)
        if (value) {
          for (let i = 0; i < PROTOCOL_WHITELIST.length; i++)
            if (value.startsWith(PROTOCOL_WHITELIST[i])) return value

          return `https://${value}`
        }
        return value
      }
    }

    RQ.Quill.register(CustomLink)
    RQ.Quill.register('modules/imageCompress', ImageCompress)
    // eslint-disable-next-line react/display-name
    return ({ forwardedRef, ...props }) => <RQ ref={forwardedRef} {...props} />
  },
  {
    ssr: false,
  },
)

const { DeleteOutlined } = Icons

type TContentFormProps = {
  index: number | string
  onChange?: (value: string) => void
  onDeleteItem?: () => void
  readonly?: boolean
  showDeleteButton: boolean
  showIndentation?: boolean
  showList?: boolean
  showHeader?: boolean
  showLink?: boolean
  showTitle?: boolean
  showUploader?: boolean
  showImage?: boolean
  value?: string
  titleText?: string
  titleIndex?: string
  placeholder?: string
}

const ContentForm = ({
  index,
  onDeleteItem,
  onChange,
  readonly = false,
  showDeleteButton,
  showIndentation = true,
  showList = true,
  showHeader = false,
  showLink = true,
  showTitle = true,
  showUploader = false,
  showImage = false,
  titleText = 'Page',
  placeholder,
  titleIndex,
  value,
}: TContentFormProps) => {
  const quillRef = React.useRef<RQ>(null)
  const lastSelectionIndex = React.useRef(0)
  const [showUploadModal, setShowUploadModal] = React.useState(false)

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: `#toolbar-${index}`,
        handlers: {
          uploader: () => {
            setShowUploadModal(true)
            lastSelectionIndex.current = quillRef.current?.selection?.index || 0
          },
        },
      },
      imageCompress: {
        quality: 0.9,
        maxWidth: 700,
        maxHeight: 700,
        imageType: 'image/png', // default
        debug: process.env.NODE_ENV === 'development',
        suppressErrorLogging: false, // default
      },
    }),
    [index],
  )

  return (
    <Card
      title={
        showTitle
          ? `${titleText} ${titleIndex ? titleIndex : +index + 1}`
          : null
      }
      style={{ wordBreak: 'break-word' }}
      className={clsx(
        {
          'opacity-50 pointer-events-none select-none': readonly,
        },
        'content-form',
      )}
      extra={
        showDeleteButton && (
          <Button
            danger
            size="small"
            onClick={onDeleteItem}
            icon={<DeleteOutlined />}
          >
            Delete {titleText}
          </Button>
        )
      }
    >
      <QuillToolbar
        id={index}
        uploader={showUploader}
        link={showLink}
        indent={showIndentation}
        list={showList}
        header={showHeader}
        image={showImage}
      />
      <ReactQuill
        className="content-form-quill"
        forwardedRef={quillRef}
        value={value}
        defaultValue=""
        onChange={onChange}
        theme="snow"
        formats={formats}
        modules={modules}
        readOnly={readonly}
        placeholder={placeholder}
      />
      <UploadOrSelectModal
        isShown={showUploadModal}
        onCancel={() => {}}
        onOk={(fileUrl, fileType) => {
          handleAttachment({
            quillRef,
            fileUrl,
            fileType,
            selectionIndex: lastSelectionIndex.current,
          })
        }}
        setIsShown={setShowUploadModal}
      />
    </Card>
  )
}

export default ContentForm
