import 'dayjs/locale/id'
import dayjs from 'dayjs'
import { TextField } from '@pankod/refine-antd'

export const groupBySequence = (dates: string[]) =>
  dates
    .sort((a, b) => dayjs(a).diff(dayjs(b), 'd'))
    .reduce(
      (acc, date) => {
        const group = acc[acc.length - 1]
        if (
          dayjs(date).diff(dayjs(group[group.length - 1] || date), 'days') > 1
        ) {
          acc.push([date])
        } else {
          group.push(date)
        }
        return acc
      },
      [[]] as string[][],
    )

const DEFAULT_DATE_FORMAT = 'DD MMM YYYY'
export const renderGroupBySequence = (
  dateStringGroup: string[][],
  DATE_FORMAT: string = DEFAULT_DATE_FORMAT,
) => {
  return dateStringGroup.map((value, index) => {
    if (value.length > 1) {
      return (
        <TextField
          key={index}
          className="whitespace-nowrap last:mb-0"
          value={`${dayjs(value[0]).format(DATE_FORMAT)} - ${dayjs(
            value[value.length - 1],
          ).format(DATE_FORMAT)}`}
        />
      )
    }
    return (
      <p key={index} className="whitespace-nowrap last:mb-0">
        {dayjs(value[0]).format(DATE_FORMAT)}
      </p>
    )
  })
}

export const dateToStringFmt = (
  dateString?: string | number,
  targetFormat: string = 'DD MMM YYYY',
  defaultResult: string = '',
): string => {
  if (!dateString) return defaultResult
  return dayjs(dateString).format(targetFormat)
}
