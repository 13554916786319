import {
  AntdList,
  Button,
  Icons,
  Popconfirm,
  Space,
  Table,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useDelete } from '@pankod/refine-core'

import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import {
  JurnalPemantauanBelajarRequest,
  JurnalPemantauanBelajarResponse,
} from './types'
import { TableTitle } from '@components/TableTitle'
import { ModalForm } from './ModalForm'
import { useGetProgramDetail } from '@resources/lms-management/program/utils'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { LIST_FILTER } from './constant'

type JurnalPemantauanBelajarListProps = {
  programId: string | number
}
export const JurnalPemantauanBelajarList = ({
  programId,
}: JurnalPemantauanBelajarListProps) => {
  const { isFetching, isPPConfigFinalized: isFinalized } =
    useGetProgramDetail(programId)
  const { tableProps } = useTable<
    JurnalPemantauanBelajarResponse,
    TCommonError
  >({
    resource: `pp/${programId}/jurnal-pemantauan`,
    dataProviderName: 'lms',
    initialFilter: [
      {
        field: 'filter',
        operator: 'eq',
        value: LIST_FILTER.ASSIGNED,
      },
    ],
  })
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<
    JurnalPemantauanBelajarResponse,
    TLMSCommonError,
    JurnalPemantauanBelajarRequest
  >({
    action: 'create',
    resource: `pp/${programId}/jurnal-pemantauan`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Jurnal modul successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating jurnal modul',
      ),
  })
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<
    JurnalPemantauanBelajarResponse,
    TLMSCommonError,
    Omit<JurnalPemantauanBelajarRequest, 'moduleId'>
  >({
    action: 'edit',
    dataProviderName: 'lms',
    resource: `pp/${programId}/jurnal-pemantauan`,
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Jurnal modul successfully edited',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when editing jurnal modul',
      ),
  })
  const { mutateAsync: doDelete } = useDelete()
  return (
    <>
      <Table
        {...tableProps}
        loading={tableProps.loading && isFetching}
        rowKey="id"
        title={() => (
          <TableTitle
            title="Daftar Jurnal Pemantauan Belajar"
            extra={
              <Button
                icon={<Icons.PlusOutlined />}
                onClick={() => createShow()}
                disabled={isFinalized}
              >
                Tambah Jurnal
              </Button>
            }
          />
        )}
      >
        <Table.Column title="ID" dataIndex="id" />
        <Table.Column title="Nama Modul" dataIndex="title" width="40%" />
        <Table.Column
          title="Jurnal Form"
          dataIndex="forms"
          render={(values) => (
            <AntdList
              dataSource={values}
              rowKey={'id'}
              size="small"
              renderItem={(value: { id: string; title: string }) => (
                <AntdList.Item>
                  <TextField
                    value={value.title}
                    copyable={{ text: value.id, tooltips: 'Salin Form ID' }}
                    ellipsis={{ tooltip: value.title }}
                    style={{ width: 150 }}
                  />
                </AntdList.Item>
              )}
            />
          )}
          width={200}
        />
        <Table.Column<JurnalPemantauanBelajarResponse>
          render={(_, record) => (
            <Space>
              <Button
                icon={<Icons.EditOutlined />}
                onClick={() => editShow(record.id)}
                disabled={isFinalized}
              >
                Edit
              </Button>
              <Popconfirm
                title="Apakah Anda yakin ingin menghapus jurnal pemantauan belajar ini?"
                okButtonProps={{ danger: true }}
                okText="Hapus"
                cancelText="Kembali"
                disabled={isFinalized}
                onConfirm={async () =>
                  doDelete({
                    id: record.id,
                    resource: `pp/${programId}/jurnal-pemantauan`,
                    dataProviderName: 'lms',
                    invalidates: ['list'],
                    successNotification: {
                      message: 'Jurnal pemantauan belajar successfuly removed',
                      type: 'success',
                      description: 'Successful',
                    },
                    errorNotification: (error: any) =>
                      showErrorNotification(
                        error,
                        'There was a problem when removing jurnal pemantauan belajar',
                      ),
                  })
                }
              >
                <Button
                  danger
                  icon={<Icons.DeleteOutlined />}
                  disabled={isFinalized}
                >
                  Hapus
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
        programId={programId}
      />

      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
        programId={programId}
      />
    </>
  )
}
