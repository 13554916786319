import { Form, Modal, Select, TextField, useSelect } from '@pankod/refine-antd'
import { useCustom, useGetIdentity } from '@pankod/refine-core'
import React, { useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'
type ExportTimespendButtonProps = {
  programId?: string | number
  isOwner: boolean
  can?: boolean
}
const ExportTimespendButton = ({
  programId,
  can,
  isOwner,
}: ExportTimespendButtonProps) => {
  const { data, isSuccess } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const isAdminEntitas = LMSRole === 'admin-entitas'
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const programLocalId = Form.useWatch('programLocalId', form)
  const programModuleGroupId = Form.useWatch('programModuleGroupId', form)
  const exportType = Form.useWatch('exportType', form)
  const {
    selectProps: selectProgramModuleGroupProps,
    queryResult: queryProgramModuleGroupResult,
  } = useSelect({
    dataProviderName: 'lms',
    resource: `programs/${programId}/paket-modul`,
    queryOptions: { enabled: showModal },
    optionLabel: 'name',
    optionValue: 'id',
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })
  const {
    selectProps: selectProgramLocalProps,
    queryResult: queryProgramLocalResult,
  } = useSelect({
    dataProviderName: 'lms',
    resource: `${
      isAdminEntitas ? 'entities/' : ''
    }programs/${programId}/local-programs`,
    queryOptions: { enabled: showModal && isSuccess },
    optionLabel: 'name',
    optionValue: 'id',
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })
  const fetchConfig = {
    filters: [
      {
        field: 'programLocalId',
        operator: 'eq',
        value: programLocalId === -1 ? undefined : programLocalId,
      },
      {
        field: 'programModuleGroupId',
        operator: 'eq',
        value: programModuleGroupId,
      },
    ],
  } as any
  const { refetch: fetchAllTimespend, isFetching } = useCustom<string>({
    url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/timespends/${exportType}`,
    dataProviderName: 'lms',
    method: 'get',
    queryOptions: {
      enabled: false,
    },
    config: fetchConfig,
    successNotification: {
      message: 'Laporan timespend berhasil diunduh',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'Terdapat gangguan saat mengunduh laporan'),
  })
  return (
    <>
      {can && (
        <span onClick={() => setShowModal(true)}>Ekspor Laporan Timespend</span>
      )}
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        okButtonProps={{ loading: isFetching }}
        afterClose={() => {
          form.resetFields()
        }}
        onOk={async () => {
          await form.validateFields()
          const { data } = await fetchAllTimespend()
          const csv = data?.data
          if (!csv) return
          downloadTemplate(csv, `${exportType}Timespend_ProgramId_${programId}`)
          setShowModal(false)
        }}
        okText="Unduh"
        cancelText="Batal"
        title="Ekspor Laporan Timespend"
      >
        <Form
          form={form}
          layout="vertical"
          validateMessages={{ required: '${label} harus diisi.' }}
        >
          <Form.Item
            name="exportType"
            label="Cakupan Ekspor"
            required
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Pilih cakupan"
              options={[
                { label: 'Kelas (Semua)', value: 'export' },
                {
                  label: 'Kelompok Aktivitas',
                  value: 'export-kelompok-aktivitas',
                },
                { label: 'Aktivitas', value: 'export-aktivitas' },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="programLocalId"
            label="Program Lokal"
            required
            rules={[{ required: true }]}
          >
            <Select
              {...selectProgramLocalProps}
              options={[
                ...(!isOwner
                  ? []
                  : [
                      {
                        label: <TextField value="Semua Program Lokal" italic />,
                        value: -1,
                      },
                    ]),
                ...(selectProgramLocalProps?.options || []),
              ]}
              loading={queryProgramLocalResult.isFetching}
              onSearch={() => {}}
              placeholder="Pilih program lokal"
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase?.()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item name="programModuleGroupId" label="Paket Modul">
            <Select
              {...selectProgramModuleGroupProps}
              loading={queryProgramModuleGroupResult.isFetching}
              onSearch={() => {}}
              placeholder="Pilih paket modul"
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase?.()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ExportTimespendButton
