import { Icons, Space, Table, TextField } from '@pankod/refine-antd'
import React, { useState } from 'react'
import { useRouter } from 'next/router'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import ExpandedKategoriPenilaian from './ExpandedKategoriPenilaian'
import { PenilaianKinerjaCategoryResponse } from '@resources/lms-management/program/program-setup/blocks/penilaian-kinerja-category/types'
import { TCommonError } from 'src/interfaces/common'

const KategoriPenilaianKinerjaTable = () => {
  const router = useRouter()
  const programId = String(router.query?.programId)
  const { tableProps } = useTableWithMeta<
    PenilaianKinerjaCategoryResponse,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `programs/${programId}/penilaian-kinerja-categories`,
  })
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly React.Key[]>(
    [],
  )
  return (
    <Table
      {...tableProps}
      columns={[
        {
          dataIndex: 'name',
          title: 'Nama Kategori',
          fixed: 'left',
          render: (value) => (
            <Space className="group-hover:underline">
              <Icons.PartitionOutlined />
              <TextField value={value} />
            </Space>
          ),
        },
      ]}
      rowClassName="cursor-pointer group"
      rowKey={'id'}
      expandable={{
        expandedRowRender: ({ id }, _, __, expanded) => {
          return (
            <ExpandedKategoriPenilaian
              programId={programId}
              kategoriId={id}
              visible={expanded}
            />
          )
        },
        fixed: 'left',
        expandedRowKeys,
        onExpandedRowsChange(expandedKeys) {
          setExpandedRowKeys(expandedKeys)
        },
        expandRowByClick: true,
        showExpandColumn: false,
      }}
    />
  )
}

export default KategoriPenilaianKinerjaTable
