import {
  Button,
  Descriptions,
  Icons,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tag,
  useModalForm,
} from '@pankod/refine-antd'
import {
  useCustomMutation,
  // useDelete,
  useInvalidate,
  useOne,
} from '@pankod/refine-core'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'

import { SemesterResponse } from '@resources/angkatan-ppg-management/types'
import { SemesterUpdateModalForm } from './SemesterUpdateModalForm'
import { TCommonError } from 'src/interfaces/common'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { ModalShowRombelSyncProgress } from './ModalShowRombelSyncProgress'
// import { RombelMatkulPopover } from '../RombelMatkulPopover'

const parseDate = (dateStr?: string, format = 'DD MMMM YYYY') => {
  if (!dateStr) return '-'
  return dayjs(dateStr).format(format)
}

const getStatusElement = (status?: SemesterResponse['status']) => {
  if (!status) return '-'
  const ELEMENT: Record<SemesterResponse['status'], React.ReactNode> = {
    BERJALAN: (
      <Tag className="!inline-flex items-center" color="processing">
        BERJALAN
      </Tag>
    ),
    PERSIAPAN: (
      <Tag className="!inline-flex items-center" color="default">
        PERSIAPAN
      </Tag>
    ),
    SELESAI: (
      <Tag className="!inline-flex items-center" color="success">
        SELESAI
      </Tag>
    ),
  }
  return ELEMENT[status]
}

type SemesterTabPaneProps = {
  angkatanId: number
  lptkId: number
  semester: string
}
export const SemesterTabPane = ({
  angkatanId,
  lptkId,
  semester,
}: SemesterTabPaneProps) => {
  const { data: kvData } = useOne<any>({
    resource: 'kv?key=feature_flag',
    id: '',
    dataProviderName: 'microlearning',
  })
  const { enableRombelSyncRelatedDataMutation = true } =
    (kvData?.data?.data as any) || {}

  const [showSyncProgressModal, setShowSyncProgressModal] =
    React.useState<boolean>(false)

  const { data, isFetching } = useOne<SemesterResponse>({
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/semester`,
    dataProviderName: 'lms',
    id: semester,
    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when fetching Jadwal'),
  })
  // const { mutateAsync: doDelete, isLoading } = useDelete()

  const invalidate = useInvalidate()
  const doInvalidateSemester = useCallback(
    () =>
      invalidate({
        resource: `angkatan/${angkatanId}/lptk/${lptkId}/semester`,
        id: semester,
        dataProviderName: 'lms',
        invalidates: ['detail'],
      }),
    [angkatanId, invalidate, lptkId, semester],
  )

  const { modalProps, formProps, show, close } = useModalForm({
    action: 'edit',
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/semester`,
    dataProviderName: 'lms',
    warnWhenUnsavedChanges: true,
    redirect: false,
    autoResetForm: true,
    autoSubmitClose: true,
    successNotification: {
      message: 'Jadwal successfully updated',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was a problem when updating Jadwal'),
    invalidates: ['detail'],
  })
  const { mutateAsync: doFinalize, isLoading: finalizeLoading } =
    useCustomMutation<{}, TCommonError, void>()
  return (
    <>
      <Spin spinning={isFetching}>
        <ModalShowRombelSyncProgress
          modalProps={{
            visible: showSyncProgressModal,
            onCancel: () => setShowSyncProgressModal(false),
            onOk: () => setShowSyncProgressModal(false),
            cancelButtonProps: { style: { display: 'none' } },
          }}
          angkatanId={angkatanId}
          lptkId={lptkId}
          semester={semester}
        />
        <Descriptions
          bordered
          column={2}
          title="Informasi Semester"
          className="mb-6"
          extra={
            <Space>
              {enableRombelSyncRelatedDataMutation && (
                <Button
                  size="middle"
                  icon={<Icons.EditOutlined />}
                  onClick={() => show(semester)}
                >
                  Ubah Jadwal
                </Button>
              )}
            </Space>
          }
        >
          <Descriptions.Item label="Status" span={2}>
            <div className="flex justify-between">
              <div className="gap-2">
                {getStatusElement(data?.data.status)}
                <Button
                  type="link"
                  size="middle"
                  icon={<Icons.HistoryOutlined />}
                  onClick={() => setShowSyncProgressModal(true)}
                >
                  Riwayat Update LMS
                </Button>
              </div>

              {enableRombelSyncRelatedDataMutation && (
                <Popconfirm
                  title={
                    <>
                      <div>
                        Apakah anda ingin mengupdate data pengkelasan ke LMS?
                      </div>
                    </>
                  }
                  disabled={!data || !data?.data.canTriggerSisImport}
                  placement="topRight"
                  okText="Konfirmasi"
                  cancelText="Batal"
                  onConfirm={async () => {
                    return doFinalize({
                      method: 'post',
                      url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/angkatan/${angkatanId}/lptk/${lptkId}/semester/${semester}/sis-import/trigger`,
                      values: undefined,
                      errorNotification: (err) =>
                        showErrorNotification(
                          err,
                          'There was a problem when finalizing semester',
                        ),
                      successNotification: {
                        message: 'Data Change Request Submitted',
                        type: 'success',
                        description: 'Success',
                      },
                    }).then(doInvalidateSemester)
                  }}
                >
                  <Button
                    type="primary"
                    size="middle"
                    icon={<Icons.SyncOutlined />}
                    disabled={!data || !data?.data.canTriggerSisImport}
                    loading={finalizeLoading}
                  >
                    Update LMS
                  </Button>
                </Popconfirm>
              )}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Mulai Perkuliahan" span={2}>
            {parseDate(data?.data.jadwal.startDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Tgl. Mulai UTS">
            {parseDate(data?.data.jadwal.utsDate.startDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Tgl. Selesai UTS">
            {parseDate(data?.data.jadwal.utsDate.endDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Tgl. Mulai UAS">
            {parseDate(data?.data.jadwal.uasDate.startDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Tgl. Selesai UAS">
            {parseDate(data?.data.jadwal.uasDate.endDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Tgl. Mulai Penilaian Akhir">
            {parseDate(data?.data.jadwal.penilaianAkhirDate.startDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Tgl. Selesai Penilaian Akhir">
            {parseDate(data?.data.jadwal.penilaianAkhirDate.endDate)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title="Daftar Mata Kuliah" style={{ width: '100%' }}>
          <Descriptions.Item contentStyle={{ width: '100%' }}>
            <Table
              dataSource={data?.data.mataKuliah}
              style={{ width: '100%' }}
              scroll={{ x: 760 }}
              rowKey="id"
              size="small"
            >
              <Table.Column
                title="Nama Mata Kuliah"
                width={'50%'}
                dataIndex="name"
                fixed={'left'}
              />
              <Table.Column title="Jumlah Rombel" dataIndex="jumlahRombel" />
              {/* <Table.Column<SemesterMataKuliah>
                render={(_, record) => (
                  <Space>
                    <RombelMatkulPopover
                      angkatanId={angkatanId}
                      mataKuliahId={record.id}
                      semester={semester}
                      mataKuliahName={record.name}
                    >
                      <Button size="small" icon={<Icons.SettingOutlined />}>
                        Kelola
                      </Button>
                    </RombelMatkulPopover>
                    <PopDeleteConfirm
                      title="Apakah Anda yakin ingin menghapus mata kuliah ini?"
                      validateValue={record.name}
                      placeholder="Tulis ulang nama LPTK"
                      onConfirm={async () => {
                        return doDelete({
                          id: record.id,
                          resource: `angkatan/${angkatanId}/lptk/${lptkId}/semester/${semester}/mata-kuliah`,
                          dataProviderName: 'lms',
                          successNotification: {
                            message: 'Mata kuliah successfuly removed',
                            type: 'success',
                            description: 'Successful',
                          },
                          errorNotification: (err) =>
                            showErrorNotification(
                              err,

                              'There was a problem when removing Mata Kuliah'
                            ),
                        }).then(doInvalidateSemester)
                      }}
                      children={
                        <Button
                          danger
                          size="small"
                          icon={<Icons.DeleteOutlined />}
                        >
                          Hapus
                        </Button>
                      }
                      okText="Hapus"
                      cancelText="Kembali"
                      okButtonProps={{ danger: true, loading: isLoading }}
                    />
                  </Space>
                )}
              /> */}
            </Table>
          </Descriptions.Item>
        </Descriptions>
      </Spin>

      <SemesterUpdateModalForm
        formProps={formProps}
        modalProps={modalProps}
        onClose={close}
      />
    </>
  )
}
