import React, { useRef } from 'react'
import { Col, Create, Row } from '@pankod/refine-antd'
import Head from 'next/head'
import { Editor, Element, Frame } from '@craftjs/core'
import { useRouter } from 'next/router'
import queryString from 'query-string'

import { Container, ContentWrapper } from '@components/FormBuilder/widgets'
import FormInformation from '@components/FormBuilder/editor/FormInformation'
import { useCreatePage } from './hooks'
import { EditorRenderer, WidgetPanel } from '@components/FormBuilder/editor'
import resolver from '@components/FormBuilder/resolver'
import ContentAreaCard from '@components/FormBuilder/ContentAreaCard'
import SelectFormTypeModal, {
  useSelectFormTypeModal,
} from './blocks/SelectFormTypeModal'
import { useGetFormType } from '@components/FormBuilder/editor/toolboxWidgets/utils'

const CLCreate = () => {
  const router = useRouter()
  const { formType, questionsNo } = useGetFormType()
  const selectFormTypeProps = useSelectFormTypeModal(
    !formType || (formType === 'likert' && !questionsNo),
  )
  const contentWrapperRef = useRef<HTMLDivElement>(null)
  const { createForm, createLoading } = useCreatePage()
  return (
    <Create title="Create Form" footerButtons={<></>} isLoading={createLoading}>
      <Head>
        <title>LMS Admin | Form Generator</title>
      </Head>
      <Editor onRender={EditorRenderer} resolver={resolver}>
        <Row gutter={[24, 24]} wrap={false}>
          <Col flex="auto">
            <WidgetPanel contentWrapperRef={contentWrapperRef} />
          </Col>
          <Col flex="50%">
            <ContentAreaCard title="Content Area" ref={contentWrapperRef}>
              <Frame>
                <Element is={ContentWrapper} canvas>
                  <Element is={Container} canvas />
                </Element>
              </Frame>
            </ContentAreaCard>
          </Col>
          <Col flex="20%">
            <FormInformation
              onSaveClick={(props, _, onSavePageOptions) => {
                createForm(props, onSavePageOptions)
              }}
            />
          </Col>
        </Row>
      </Editor>
      <SelectFormTypeModal
        {...selectFormTypeProps}
        onSubmit={(type, numberOfQuestions) => {
          const urlSearch = queryString.stringify({
            formType: type,
            questionsNo: numberOfQuestions,
          })
          router.replace(`/form-generator/create?${urlSearch}`)
        }}
      />
    </Create>
  )
}

export default CLCreate
