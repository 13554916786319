import { Form, Space, Button, Icons, Checkbox } from '@pankod/refine-antd'
import { useTableReturnType, LogicalFilter } from '@pankod/refine-core'
import React, { useEffect } from 'react'

type CheckboxProps = {
  onClose: () => void
  dataIndex: string
  setFilters: useTableReturnType['setFilters']
  label: string
  currentFilters: LogicalFilter[]
  visible: boolean
  options: { label: string | React.ReactNode; value: string | string[] }[]
}

const CheckboxFilter = ({
  currentFilters,
  dataIndex,
  label,
  onClose,
  setFilters,
  visible,
  options,
}: CheckboxProps) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      const value = currentFilters.find(
        ({ field }) => field === dataIndex,
      )?.value
      if (!value) {
        form.resetFields()
        return
      }
      form.setFields([{ name: dataIndex, value }])
    }
  }, [currentFilters, dataIndex, form, visible])

  const clearFilters = () => {
    setFilters((prevFilters) => [
      ...(prevFilters as LogicalFilter[]).filter(
        ({ field }) => field !== dataIndex && field !== 'page',
      ),
      { field: 'page', operator: 'eq', value: 1 },
    ])
    onClose()
  }

  return (
    <div className="p-2 grid grid-cols-1">
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const value = values[dataIndex]
          if (!value) {
            clearFilters()
            return
          }

          setFilters([
            { field: dataIndex, operator: 'eq', value },
            { field: 'page', operator: 'eq', value: 1 },
          ])
          onClose()
        }}
      >
        <Form.Item
          name={dataIndex}
          label={label}
          getValueProps={(value = []) => {
            return value.map((v: string | string[]) =>
              Array.isArray(v) ? v.join('|') : v,
            )
          }}
          getValueFromEvent={(value) => {
            return value.map((v: string) => v.split('|'))
          }}
        >
          <Checkbox.Group>
            <div className="grid grid-cols-1 gap-1">
              {options.map(({ label, value }, i) => (
                <Checkbox
                  key={i}
                  value={Array.isArray(value) ? value.join('|') : value}
                  className="!m-0"
                >
                  {label}
                </Checkbox>
              ))}
            </div>
          </Checkbox.Group>
        </Form.Item>
      </Form>
      <Space className="justify-self-end">
        <Button
          onClick={form.submit}
          icon={<Icons.FilterOutlined />}
          type="primary"
          size="small"
        >
          Filter
        </Button>
        <Button
          onClick={() => {
            form.resetFields()
            clearFilters()
          }}
          size="small"
          danger
        >
          Clear
        </Button>
      </Space>
    </div>
  )
}

export default CheckboxFilter
