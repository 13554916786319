import { NextRouter } from 'next/router'

export const trackTabChange = (
  router: NextRouter,
  queryValue: string,
  queryKey: string = 'activeTab',
) => {
  router.replace(
    {
      pathname: router.pathname,
      query: {
        ...router.query,
        [queryKey]: queryValue,
      },
    },
    undefined,
    { shallow: true },
  )
}
