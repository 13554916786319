import { Button, Modal, Popconfirm, Space } from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useCreate, useInvalidate } from '@pankod/refine-core'
import { useRouter } from 'next/router'

import InstructorSchedule from '@resources/lms-management/class-group/blocks/InstructorScheduleModal/InstructorSchedule'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const defaultValue = {
  webinar: '',
  webinarKelasGroup: '',
}

type ScheduleId = { webinar: string; webinarKelasGroup: string }
export const useSelectScheduleModal = () => {
  const [id, setId] = useState<ScheduleId>(defaultValue)

  const show = useCallback((data: ScheduleId) => setId(data), [])

  const onClose = useCallback(() => setId(defaultValue), [])

  return {
    show,
    onClose,
    id,
    visible: !!id.webinar && !!id.webinarKelasGroup,
  }
}

type SelectScheduleModalProps = {
  visible: boolean
  onClose: () => void
  id: ScheduleId
}
const SelectScheduleModal = ({
  visible,
  onClose,
  id,
}: SelectScheduleModalProps) => {
  const router = useRouter()
  const { programId } = router.query
  const [session, setSession] = useState<{
    date: string
    sessionId: string
  }>({ date: '', sessionId: '' })
  const invalidate = useInvalidate()
  const { mutateAsync, isLoading } = useCreate()
  const handleClose = () => {
    setSession({ date: '', sessionId: '' })
    onClose()
  }
  const handleSave = async () => {
    return mutateAsync(
      {
        resource: `instructor/programs/${programId}/webinars/${id.webinar}/schedules`,
        values: {
          webinarKelasGroupId: id.webinarKelasGroup,
          date: session.date,
          sessionId: session.sessionId,
        },
        dataProviderName: 'lms',
        successNotification: {
          message: 'Jadwal webinar telah berhasil dipilih',
          type: 'success',
          description: 'Sukses',
        },
        errorNotification: (err) =>
          showErrorNotification(
            err,
            'Terdapat gangguan saat menyimpan pilihan jadwal webinar',
          ),
      },
      {
        onSuccess: () => {
          invalidate({
            dataProviderName: 'lms',
            resource: `instructor/programs/${programId}/webinars/${id.webinar}/class-group`,
            invalidates: ['list'],
          })
          invalidate({
            dataProviderName: 'lms',
            resource: `instructor/webinars-schedule`,
            invalidates: ['list'],
          })
          handleClose()
        },
      },
    )
  }
  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      title="Pilih Jadwal"
      width={'80%'}
      maskClosable={false}
      footer={
        <Space>
          <Button onClick={handleClose}>Batal</Button>
          <Popconfirm
            title="Apakah Anda yakin ingin memilih jadwal ini?"
            placement="topRight"
            okText="Ya"
            cancelText="Tidak"
            onConfirm={handleSave}
            disabled={!session.sessionId}
          >
            <Button
              disabled={!session.sessionId}
              loading={isLoading}
              type="primary"
            >
              Simpan
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      <InstructorSchedule
        enabled={!!id.webinar && !!id.webinarKelasGroup}
        resource={`instructor/programs/${programId}/webinars/${id.webinar}/schedules/${id.webinarKelasGroup}/available-sessions-options`}
        onSelected={setSession}
        selected={session}
      />
    </Modal>
  )
}

export default SelectScheduleModal
