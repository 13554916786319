import { Canvas, loadSVGFromString, FabricImage, FabricObject } from 'fabric'
import { nanoid } from 'nanoid'
import { message, RcFile, Upload, UploadProps } from '@pankod/refine-antd'
import { util } from 'fabric'

import {
  CANVAS_CONFIG,
  CONTROL_CONFIG,
  QR_CODE_CERTIFICATE_EXAMPLE_BASE64,
  QR_CODE_CERTIFICATE_KEY,
} from '../constants'
import { fixTspanPosSVGObjImport } from './canvas'

type CommonAddImage = {
  canvas: Canvas | null
  customVariableKey?: string
  shouldGroupSVG?: boolean
  shouldFocus?: boolean
}

type AddImage = (
  props:
    | ({
        file: RcFile
        base64String?: never
        position?: never
      } & CommonAddImage)
    | ({
        file?: never
        base64String: string
        position?: {
          top: number
          left: number
          scale: number
        }
      } & CommonAddImage),
) => string | null

export const addQRCode = (canvas: Canvas | null) => {
  const WIDTH = 300
  const SCALE = 0.3
  addImage({
    base64String: QR_CODE_CERTIFICATE_EXAMPLE_BASE64,
    canvas: canvas,
    customVariableKey: QR_CODE_CERTIFICATE_KEY,
    position: {
      left: CANVAS_CONFIG.width - WIDTH * SCALE,
      top: 0,
      scale: SCALE,
    },
  })
}

export const addImage: AddImage = (props) => {
  const {
    file,
    canvas,
    base64String,
    customVariableKey,
    shouldGroupSVG,
    shouldFocus = true,
    position,
  } = props
  canvas?.discardActiveObject()
  const customId = nanoid()
  if (base64String) {
    const isQRCode = customVariableKey === QR_CODE_CERTIFICATE_KEY
    FabricImage.fromURL(base64String, undefined, {
      customId,
      snapAngle: CONTROL_CONFIG.snapAngle,
      snapThreshold: CONTROL_CONFIG.snapThreshold,
      customVariableKey,
      customObjectType: 'Image',
      top: position?.top || 0,
      left: position?.left || 0,
      scaleX: position?.scale || 1,
      scaleY: position?.scale || 1,
      lockRotation: !!isQRCode,
    }).then((output) => {
      if (isQRCode)
        output.setControlsVisibility({
          mt: false,
          ml: false,
          mb: false,
          mr: false,
        })
      canvas?.add(output)
      if (shouldFocus) canvas?.setActiveObject(output)
    })
    return customId
  }

  if (!file) return null
  // if file is svg then load it as svg string
  if (file.type === 'image/svg+xml') {
    const reader = new FileReader()
    reader.onloadend = () => {
      loadSVGFromString(reader.result as string, (element, fabricObject) => {
        const variableKey = element.getAttribute('data-variablekey')
        const objectType = element.getAttribute('data-objecttype')
        const objectWidth = element.getAttribute('data-objectwidth')
        const objectAlign = element.getAttribute('data-objectalign')
        const objectLeft = element.getAttribute('data-objectleft')
        const objectTop = element.getAttribute('data-objecttop')
        if (variableKey) {
          fabricObject.customVariableKey = variableKey
        }
        if (objectWidth) {
          fabricObject.width = +objectWidth
          fabricObject.customObjectWidth = objectWidth
        }
        if (objectAlign) {
          fabricObject.customObjectAlign = objectAlign
        }
        if (objectLeft) {
          fabricObject.left = +objectLeft
        }
        if (objectTop) {
          fabricObject.top = +objectTop
        }
        fabricObject.customObjectType = objectType || undefined
        return fabricObject
      }).then((output) => {
        if (shouldGroupSVG) {
          const group = util.groupSVGElements(
            output.objects as FabricObject[],
            output.options,
          )
          canvas?.add(group)
          return
        }
        fixTspanPosSVGObjImport({
          output,
          canvas,
        })
      })
    }
    reader.readAsText(file)
  } else {
    const reader = new FileReader()
    reader.onloadend = () => {
      FabricImage.fromURL(reader.result as string, undefined, {
        customId,
        snapAngle: CONTROL_CONFIG.snapAngle,
        snapThreshold: CONTROL_CONFIG.snapThreshold,
        customVariableKey,
        customObjectType: 'Image',
      }).then((output) => {
        canvas?.add(output)
        canvas?.setActiveObject(output)
      })
    }
    reader.readAsDataURL(file)
  }
  return customId
}

export const generateUploadPropsFn = (props: {
  onLoaded: (result: string | ArrayBuffer | null) => void
  onError?: (type: 'TYPE_INVALID' | 'SIZE_INVALID', message: string) => void
  onRemove?: () => void
}): UploadProps => {
  const MAX_SIZE_IN_MB = CANVAS_CONFIG.imageSizeLimitInMB
  const ALLOWED_TYPE = CANVAS_CONFIG.imageAllowedType
  return {
    beforeUpload: (file) => {
      const isFileSizeValid = file.size / 1024 / 1024 < MAX_SIZE_IN_MB
      const isFileTypeValid = ALLOWED_TYPE.includes(file.type)

      if (!isFileSizeValid) {
        const messageText = 'Ukuran file harus < ' + MAX_SIZE_IN_MB + 'MB'
        message.error(messageText + '!')
        props.onError?.('SIZE_INVALID', messageText + '.')
        return Upload.LIST_IGNORE
      }

      if (!isFileTypeValid) {
        const messageText = 'Tipe file tidak sesuai'
        message.error(messageText + '!')
        props.onError?.('TYPE_INVALID', messageText + '.')
        return Upload.LIST_IGNORE
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        props.onLoaded(reader.result)
      }
      reader.readAsDataURL(file)
      return false
    },
    multiple: false,
    listType: 'picture',
    maxCount: 1,
    onRemove: () => props.onRemove?.(),
  }
}
