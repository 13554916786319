import { Drawer } from '@pankod/refine-antd'
import React from 'react'

import { GradeRangeForm } from '@resources/lms-management/program/sections/blocks/GradeRangeForm'

export type DrawerEditGradeProps = {
  selectedId?: number | null
  open: boolean
  onDrawerClose?: () => void
  onSubmitted?: () => void
  programName?: string
}

export const DrawerEditGrade = (props: DrawerEditGradeProps) => {
  const { selectedId, open, onDrawerClose, programName, onSubmitted } = props
  const [programId, setProgramId] = React.useState<number | null | undefined>(
    selectedId,
  )
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  const openDrawer = () => {
    setIsDrawerOpen(true)
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false)
    onDrawerClose && onDrawerClose()
  }

  const onFormSubmitted = () => {
    closeDrawer()
    onSubmitted?.()
  }

  React.useEffect(() => {
    if (open) {
      setProgramId(selectedId)
      openDrawer()
    }
  }, [open, selectedId])

  return (
    <Drawer
      title={`Ubah Predikat Dan Rentang Nilai | ${programName}`}
      width={'70%'}
      visible={isDrawerOpen}
      onClose={closeDrawer}
      destroyOnClose
    >
      <GradeRangeForm programId={programId} onSubmitted={onFormSubmitted} />
    </Drawer>
  )
}
