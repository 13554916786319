import { Skeleton } from '@pankod/refine-antd'
import clsx from 'clsx'
import React from 'react'

const SkeletonLoading = () => {
  return (
    <>
      <div className="px-3 py-4 mb-3 bg-surface-default last:mb-0">
        <Skeleton active />
      </div>
      <div className="px-3 py-4 mb-3 bg-surface-default last:mb-0 grid gap-2">
        <Skeleton active />
        <div
          className={clsx(
            'widget-component',
            'rounded border border-subdued py-3 pr-4',
            'grid grid-cols-[1fr_64px] gap-4',
          )}
        >
          <Skeleton active paragraph={{ rows: 2 }} />
          <Skeleton.Image
            active
            className="!w-16 !h-16 !self-start !justify-self-center !aspect-square !object-cover !rounded"
          />
        </div>
        <div
          className={clsx(
            'widget-component',
            'rounded border border-subdued py-3 pr-4',
            'grid grid-cols-[1fr_64px] gap-4 !mb-0',
          )}
        >
          <Skeleton active paragraph={{ rows: 2 }} />
          <Skeleton.Image
            active
            className="!w-16 !h-16 !self-start !justify-self-center !aspect-square !object-cover !rounded"
          />
        </div>
      </div>
    </>
  )
}

export default SkeletonLoading
