import { useOne } from '@pankod/refine-core'
import { useState } from 'react'

import {
  TFilterOptions,
  TRegionIDNameData,
} from '@components/DataTableParticipants/types'

export const useFilterOptionsHook = (): {} => {
  const [filterOptions, setFilterOptions] = useState<{ data: TFilterOptions }>()

  const { data, isLoading, refetch } = useOne<TFilterOptions>({
    resource: `programs/participants`,
    id: 'filter-options',
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !filterOptions,
    },
    // @ts-ignore because refine-core is doesn't have on success yet
    successNotification: (data) => {
      setFilterOptions(data as { data: TFilterOptions })
    },
  })

  const getCityOptions = (provinceId: string | null) => {
    const cityOptions =
      filterOptions?.data?.regions?.filter(
        (province: TRegionIDNameData) => province?.kode === provinceId,
      ) || []
    return cityOptions[0]?.kabupaten || []
  }

  return {
    data,
    isLoading,
    refetch,
    getCityOptions,
  }
}
