import { Icons, Show, Tabs } from '@pankod/refine-antd'
import React from 'react'
import { useCan, useNavigation } from '@pankod/refine-core'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { NestedBreadcrumb } from '../components/NestedBreadcrumb'
import { trackTabChange } from 'src/helpers/url'
import TabNilaiAkhir from '../program/sections/TabFinalScore'
import TabLaporanPresensi from '../program/sections/laporan-presensi/TabLaporanPresensi'
import TabLaporanPenilaianKinerja from '../program/sections/laporan-penilaian-kinerja/TabLaporanPenilaianKinerja'
import { useProgramStore } from '../program/store'

const ReportsAndFinalScore = () => {
  const router = useRouter()
  const programId = String(router.query?.programId)
  const { activeTab = null } = router.query || {}
  const [activeKey, setActiveKey] = React.useState(
    (activeTab as string) || 'nilaiAkhir',
  )
  const { listUrl, goBack, showUrl } = useNavigation()

  const isOwner = useProgramStore(({ isOwner }) => isOwner)

  const { data: canAccessPresensiReport } = useCan({
    action: 'show',
    resource: 'lms-presensi-report',
  })

  const { data: canAccessPenilaianKinerjaReport } = useCan({
    action: 'show',
    resource: 'penilaian-kinerja-report',
  })
  return (
    <Show
      breadcrumb={
        <NestedBreadcrumb
          hideOriginal
          injectedItems={[
            {
              label: 'LMS Management',
              icon: <Icons.SolutionOutlined />,
            },
            {
              label: 'Program',
              href: listUrl('lms-program-management'),
            },
            {
              label: programId,
              href: showUrl('lms-program-management', programId),
            },
            {
              label: `Laporan dan Nilai Akhir`,
            },
          ]}
        />
      }
      headerButtons={() => null}
      title={
        <div className="flex gap-4 items-center">
          <Icons.ArrowLeftOutlined
            className="ant-page-header-back-button"
            style={{ fontSize: '16px' }}
            onClick={goBack}
          />
          <div>Laporan dan Nilai Akhir</div>
        </div>
      }
    >
      <Head>
        <title>LMS Admin | Laporan dan Nilai Akhir</title>
      </Head>

      <Tabs
        defaultActiveKey="nilaiAkhir"
        type="card"
        activeKey={activeKey}
        destroyInactiveTabPane
        onChange={(activeKey) => {
          setActiveKey(activeKey)
          trackTabChange(router, activeKey)
        }}
      >
        <Tabs.TabPane tab="Nilai Akhir" key="nilaiAkhir">
          <TabNilaiAkhir programId={programId} />
        </Tabs.TabPane>
        {canAccessPresensiReport?.can && (
          <Tabs.TabPane tab="Laporan Presensi" key="presensiReport">
            <TabLaporanPresensi />
          </Tabs.TabPane>
        )}
        {(canAccessPenilaianKinerjaReport?.can || isOwner) && (
          <Tabs.TabPane
            tab="Laporan Penilaian Kinerja"
            key="penilaianKinerjaReport"
          >
            <TabLaporanPenilaianKinerja />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Show>
  )
}

export default ReportsAndFinalScore
