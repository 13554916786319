import {
  Button as AntdButton,
  Button,
  DeleteButton,
  EditButton,
  Icons,
  Input,
  List,
  Space,
  Table,
  Tag,
  TextField,
  message,
  useTable,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  GetListResponse,
  LogicalFilter,
  CrudFilters,
} from '@pankod/refine-core'
import Head from 'next/head'
import React, { useState } from 'react'

import Filter, { TFilterForm } from '@components/ContentFilter'
import { TFormGeneratorDetail } from './interface'
import PagePreviewDrawer from '@components/FormBuilder/PagePreviewDrawer'
import { TCommonError } from 'src/interfaces/common'

const FormList: React.FC<
  IResourceComponentsProps<GetListResponse<TFormGeneratorDetail>>
> = ({ options }) => {
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null)
  const { tableProps, searchFormProps, filters } = useTable<
    TFormGeneratorDetail,
    TCommonError,
    TFilterForm<TFormGeneratorDetail>
  >({
    resource: 'forms',
    onSearch: (params) => {
      const filters: CrudFilters = []
      // @ts-expect-error
      const { title } = params
      filters.push(
        {
          field: 'title',
          operator: 'eq',
          value: title,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        },
      )
      return filters
    },
  })

  const handleParseToJson = (value: string) => {
    try {
      return JSON.parse(value)
    } catch {
      return {}
    }
  }

  return (
    <List title="LTI Form List" createButtonProps={{ children: 'Create Form' }}>
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>

      <Filter
        layout="inline"
        formProps={searchFormProps}
        filters={filters as LogicalFilter[]}
        items={[
          {
            label: 'Form Title',
            name: 'title',
            component: (
              <Input
                placeholder="Filter by name"
                autoComplete="off"
                allowClear
              />
            ),
          },
        ]}
      />
      <Table
        {...tableProps}
        dataSource={(tableProps.dataSource || []).map((value) => {
          return {
            ...value,
            metaForm: handleParseToJson(value.metaForm),
          }
        })}
        loading={tableProps.loading}
        rowKey="id"
      >
        <Table.Column<TFormGeneratorDetail>
          dataIndex="id"
          title="ID"
          render={(value, record) => (
            <Space size="large">
              <TextField value={value} />
              <AntdButton
                size="small"
                icon={<Icons.CopyFilled />}
                type="dashed"
                onClick={async () => {
                  try {
                    await navigator?.clipboard.writeText(record.id)
                    message.info(`ID ${record.id} is copied to clipboard!`)
                  } catch {
                    message.error('Can not copy ID')
                  }
                }}
              >
                Copy
              </AntdButton>
            </Space>
          )}
        />
        <Table.Column
          dataIndex={['metaForm', 'title']}
          title="Title"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['metaForm', 'excerpt']}
          title="Description"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['formType']}
          title="Form Type"
          render={(value) =>
            value === 'LIKERT' ? (
              <Tag color="processing">LIKERT</Tag>
            ) : value === 'QUANTITATIVE' ? (
              <Tag color="processing">QUANTITATIVE</Tag>
            ) : (
              <Tag>GENERAL</Tag>
            )
          }
        />
        <Table.Column<TFormGeneratorDetail>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space direction="vertical">
                <AntdButton
                  size="small"
                  onClick={() => setSelectedPageId(record.id)}
                  icon={<Icons.EyeFilled />}
                >
                  Preview
                </AntdButton>
                <EditButton size="small" block recordItemId={record.id} />
                <DeleteButton
                  size="small"
                  block
                  recordItemId={record.id}
                  resourceNameOrRouteName="forms"
                  successNotification={() => ({
                    message: 'Form successfully deleted',
                    type: 'success',
                    description: 'Successful',
                  })}
                />
              </Space>
            )
          }}
        />
      </Table>
      <PagePreviewDrawer
        selectedId={selectedPageId}
        setSelectedId={setSelectedPageId}
        extra={<Button onClick={() => setSelectedPageId(null)}>Close</Button>}
      />
    </List>
  )
}

export default FormList
