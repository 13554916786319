import { Tag, Icons, TextField } from '@pankod/refine-antd'
import clsx from 'clsx'
import React from 'react'
import Highlighter from 'react-highlight-words'

type SelectOptionItemProps = {
  disabled?: boolean
  filter: string
  id: string
  label: string
  type: string
}
const SelectOptionItem = ({
  filter,
  id,
  label,
  type,
  disabled,
}: SelectOptionItemProps) => {
  return (
    <div
      className={'flex justify-between align-middle items-center pr-4 gap-2'}
    >
      <div className="grid grid-cols-[105px_1fr] gap-1">
        <div className="flex">
          <Tag
            className={clsx(
              disabled && '!opacity-40',
              '!text-ellipsis !max-w-[100px] !overflow-hidden',
            )}
            icon={<Icons.NumberOutlined />}
          >
            <Highlighter
              searchWords={[filter]}
              textToHighlight={id}
              highlightClassName="text-orange-40"
              highlightStyle={{ padding: 0 }}
              className="font-mono"
            />
          </Tag>
        </div>
        <div className="overflow-auto">
          <Highlighter
            searchWords={[filter]}
            textToHighlight={label}
            highlightClassName="text-orange-40"
            highlightStyle={{ padding: 0 }}
          />
        </div>
      </div>
      <TextField
        className={clsx(disabled && '!opacity-40', 'mr-2')}
        value={type}
        type="secondary"
        italic
      />
    </div>
  )
}

export default SelectOptionItem
