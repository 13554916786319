import {
  Button,
  Descriptions,
  Icons,
  Table,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'

import PesertaRequestDrawer, {
  useRequestPesertaDrawer,
} from './PesertaRequestDrawer'

type PesertaTableList = {
  programId?: string | string[] | number
}
const PesertaTableList = (props: PesertaTableList) => {
  const { tableProps } = useTable({
    dataProviderName: 'lms',
    resource: `programs/${props.programId}/participant-consolidation/requests`,
    queryOptions: {
      enabled: !!props.programId,
    },
  })
  const { show, ...requestPesertaDrawerProps } = useRequestPesertaDrawer()
  return (
    <>
      <Descriptions
        title={
          <>
            Daftar Jumlah <i>Request</i> Perubahan Data Peserta per Program
            Lokal
          </>
        }
      >
        <Descriptions.Item span={1}>
          <Table
            {...tableProps}
            className="w-full"
            rowKey={'programLocalId'}
            columns={[
              {
                title: 'ID',
                dataIndex: 'programLocalId',
                width: 100,
              },
              {
                title: 'Nama Program Lokal',
                dataIndex: 'programLocalName',
                width: 250,
              },
              {
                title: 'Jumlah Permintaan',
                dataIndex: 'total',
                width: 170,
              },
              {
                title: 'Aksi',
                render: (_, record) => (
                  <Button
                    icon={<Icons.UnorderedListOutlined />}
                    onClick={() => {
                      show({
                        programId: props.programId!,
                        programLocalId: record.programLocalId,
                        programLocalName: record.programLocalName,
                      })
                    }}
                  >
                    Daftar Permintaan
                  </Button>
                ),
              },
            ]}
          />
        </Descriptions.Item>
      </Descriptions>
      <PesertaRequestDrawer {...requestPesertaDrawerProps} />
    </>
  )
}

export default PesertaTableList
