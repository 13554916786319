import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Icons,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import dayjs from 'dayjs'
import locale from 'antd/lib/locale/id_ID'
import { useCreate, useDelete, useUpdate } from '@pankod/refine-core'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { FormSelectComponent } from '../FormSelectComponent'

export const useDasusFormPengkiDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type DasusFormPengkiDrawerProps = {
  id: number | string
  name: string
  onClose: () => void
  visible: boolean
}

const MAP_TYPE_TO_LABEL = {
  DASUS_FORM_TYPE_UNSPECIFIED: 'Unspecified',
  DASUS_FORM_TYPE_PENILAIAN_KINERJA: 'Penilaian Kinerja',
  DASUS_FORM_TYPE_UMPAN_BALIK: 'Umpan Balik',
}

const DasusPengkiDrawer = ({
  id,
  name,
  onClose,
  visible,
}: DasusFormPengkiDrawerProps) => {
  const [formMode, setFormMode] = useState<null | 'create' | 'edit'>(null)
  const [formDefaultValue, setFormDefaultValue] = React.useState<any>({})
  const { tableProps, tableQueryResult } = useTableWithMeta({
    resource: `program-dasus/${id}/penilaian-kinerja`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: visible && !!id,
    },
  })

  const { mutate: doSubmit } = useCreate()
  const { mutate: doUpdate } = useUpdate()
  const { mutate: doDelete } = useDelete()

  const handleEditRecord = (record: any) => {
    setFormDefaultValue(record)
    setFormMode('edit')
  }

  const handleSubmitForm = (values: any) => {
    if (formMode === 'create') {
      doSubmit(
        {
          resource: `program-dasus/${id}/penilaian-kinerja`,
          dataProviderName: 'lms',
          values,
          successNotification: {
            message: 'Penilaian kinerja berhasil ditambahkan',
            type: 'success',
            description: 'Sukses',
          },
          errorNotification: (e: any) =>
            showErrorNotification(
              e,
              'Terdapat gangguan saat membuat form penilaian kinerja',
            ),
        },
        {
          onSuccess: () => {
            setFormDefaultValue(null)

            setTimeout(() => {
              setFormMode(null)
              formDasusPengki.resetFields()
            }, 20)

            tableQueryResult.refetch()
          },
        },
      )
    } else {
      doUpdate(
        {
          resource: `program-dasus/${id}/penilaian-kinerja`,
          id: formDefaultValue.id,
          dataProviderName: 'lms',
          values,
          successNotification: {
            message: 'Penilaian kinerja berhasil diubah',
            type: 'success',
            description: 'Sukses',
          },
          errorNotification: (e: any) =>
            showErrorNotification(
              e,
              'Terdapat gangguan saat update form penilaian kinerja',
            ),
        },
        {
          onSuccess: () => {
            setFormDefaultValue(null)

            setTimeout(() => {
              setFormMode(null)
              formDasusPengki.resetFields()
            }, 20)

            tableQueryResult.refetch()
          },
        },
      )
    }
  }

  const columns = [
    { title: 'Nama Penilaian Kinerja', dataIndex: 'name', key: 'name' },

    {
      title: 'Display name',
      dataIndex: ['forms', 'displayName'],
      key: '_displayName',
      render: (_: any, record: any) => (
        <div className="flex flex-col">
          {record.forms.map((form: any, index: number) => (
            <>
              <Tooltip
                key={form.displayName}
                placement="topLeft"
                title={form.displayName}
              >
                <p
                  key={form.displayName}
                  className="line-clamp-1 max-w-[150px]"
                >
                  {form.displayName}
                </p>
              </Tooltip>
              {index < record.forms.length - 1 && <Divider />}
            </>
          ))}
        </div>
      ),
    },

    {
      title: 'Nama Form',
      dataIndex: ['forms', 'formName'],
      key: '_formName',
      render: (_: any, record: any) => (
        <div className="flex flex-col">
          {record.forms.map((form: any, index: number) => (
            <>
              <Tooltip
                key={form.form.title}
                placement="topLeft"
                title={form.form.title}
              >
                <p className="line-clamp-1 max-w-[150px]">{form.form.title}</p>
              </Tooltip>
              {index < record.forms.length - 1 && <Divider />}
            </>
          ))}
        </div>
      ),
    },

    {
      title: 'Tipe Form',
      dataIndex: ['forms', 'formType'],
      key: '_formType',
      render: (_: any, record: any) => (
        <div className="flex flex-col">
          {record.forms.map((form: any, index: number) => (
            <>
              <p
                key={form.formType}
                className="line-clamp-1 max-w-[130px] truncate "
              >
                <Tag>
                  {
                    // @ts-expect-error
                    MAP_TYPE_TO_LABEL[
                      form?.formType || 'DASUS_FORM_TYPE_UNSPECIFIED'
                    ] || 'Unspecified'
                  }
                </Tag>
              </p>
              {index < record.forms.length - 1 && <Divider />}
            </>
          ))}
        </div>
      ),
    },

    {
      title: 'Periode',
      key: 'periode',
      render: (_: any, record: any) => (
        <div>
          {dayjs(record.startDate).format('YYYY-MM-DD')} -{' '}
          {dayjs(record.endDate).format('YYYY-MM-DD')}
        </div>
      ),
    },
    {
      key: '_action',
      render: (_: any, record: any) => (
        <div className="flex flex-col gap-2">
          <Button
            block
            size="small"
            icon={<Icons.EditOutlined />}
            onClick={() => handleEditRecord(record)}
          >
            Ubah
          </Button>
          <Popconfirm
            title="Apakah Anda yakin ingin menghapus penilaian kinerja ini?"
            okText="Hapus"
            cancelText="Batal"
            okButtonProps={{ danger: true }}
            placement="topRight"
            onConfirm={async () =>
              doDelete(
                {
                  id: record.id,
                  resource: `program-dasus/${id}/penilaian-kinerja`,
                  dataProviderName: 'lms',
                  successNotification: {
                    message: 'penilaian kinerja berhasil dihapus',
                    type: 'success',
                    description: 'Sukses',
                  },
                  errorNotification: (error: any) =>
                    showErrorNotification(
                      error,
                      'There was a problem when removing penilaian kinerja category',
                    ),
                },
                {
                  onSuccess: () => tableQueryResult.refetch(),
                },
              )
            }
          >
            <Button block size="small" danger icon={<Icons.DeleteOutlined />}>
              Hapus
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ]

  const [formDasusPengki] = Form.useForm()

  return (
    <Drawer
      title={`Daftar Penilaian Kinerja Dasus | ${name}`}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      extra={
        <Space>
          {!formMode && (
            <Button
              type="primary"
              icon={<Icons.PlusOutlined />}
              onClick={() => {
                setFormDefaultValue(null)

                setTimeout(() => {
                  setFormMode('create')
                  formDasusPengki.resetFields()
                }, 20)
              }}
            >
              Tambah Penilaian Kinerja
            </Button>
          )}
        </Space>
      }
    >
      {!formMode ? (
        <Table {...tableProps} bordered columns={columns} rowKey="id" />
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <Button
              type="text"
              onClick={() => {
                formDasusPengki.resetFields()
                setFormMode(null)
              }}
              icon={<Icons.ArrowLeftOutlined />}
              className="mr-4"
            />
            <h3>
              {formMode === 'create' ? 'Tambah' : 'Ubah'} Penilaian Kinerja
            </h3>
          </div>
          <div className="p-4">
            <Form
              form={formDasusPengki}
              layout="vertical"
              onFinish={handleSubmitForm}
              initialValues={formDefaultValue}
            >
              <Form.Item
                name="name"
                label="Nama Penilaian Kinerja"
                rules={[
                  {
                    required: true,
                    message: 'Nama Penilaian Kinerja harus diisi',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div className="flex flex-row gap-4">
                <Form.Item
                  name="startDate"
                  label="Tanggal Mulai"
                  rules={[
                    {
                      required: true,
                      message: 'Tanggal Mulai harus diisi',
                    },
                  ]}
                  getValueProps={(date: Date) => ({
                    value: date ? dayjs(date) : null,
                  })}
                  getValueFromEvent={(date: Date) => {
                    return date?.toISOString()
                  }}
                >
                  <DatePicker className="w-[30vw]" locale={locale.DatePicker} />
                </Form.Item>

                <Form.Item
                  name="endDate"
                  label="Tanggal Selesai"
                  rules={[
                    {
                      required: true,
                      message: 'Tanggal Selesai harus diisi',
                    },
                  ]}
                  getValueProps={(date: Date) => ({
                    value: date ? dayjs(date) : null,
                  })}
                  getValueFromEvent={(date: Date) => {
                    return date?.toISOString()
                  }}
                >
                  <DatePicker className="w-[30vw]" locale={locale.DatePicker} />
                </Form.Item>
              </div>

              <Form.List name="forms">
                {(fields, { add, remove }) => (
                  <>
                    <div className="flex justify-end mr-[18px]">
                      <Button
                        size="small"
                        onClick={() => {
                          add()
                        }}
                      >
                        Tambah Form
                      </Button>
                    </div>
                    <Divider />
                    {fields.map((field) => {
                      return (
                        <div
                          key={field.key}
                          className="flex flex-row items-center w-full gap-4"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, 'displayName']}
                            label="Display Name"
                            className="w-[50vw]"
                            rules={[
                              {
                                required: true,
                                message: 'Display Name harus diisi',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'form']}
                            label="Form LTI"
                            className="min-w-[200px]"
                            rules={[
                              {
                                required: true,
                                message: 'Form Name harus diisi',
                              },
                            ]}
                          >
                            <FormSelectComponent />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, 'formType']}
                            label="Tipe"
                            className="w-[150px] min-w-[150px]"
                            rules={[
                              {
                                required: true,
                                message: 'Form Type harus diisi',
                              },
                            ]}
                          >
                            <Select
                              options={[
                                {
                                  label: '-',
                                  value: 'DASUS_FORM_TYPE_UNSPECIFIED',
                                },
                                {
                                  label: 'Penilaian Kinerja',
                                  value: 'DASUS_FORM_TYPE_PENILAIAN_KINERJA',
                                },
                                {
                                  label: 'Umpan Balik',
                                  value: 'DASUS_FORM_TYPE_UMPAN_BALIK',
                                },
                              ]}
                            />
                          </Form.Item>

                          <Button
                            size="small"
                            danger
                            icon={<Icons.DeleteOutlined />}
                            onClick={() => {
                              remove(field.name)
                            }}
                          />
                        </div>
                      )
                    })}
                  </>
                )}
              </Form.List>

              <Button htmlType="submit" block>
                Submit Configuration
              </Button>
            </Form>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default DasusPengkiDrawer
