import {
  AntdList,
  Button,
  Descriptions,
  Icons,
  ListButton,
  Show,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import {
  GetListResponse,
  IResourceComponentsProps,
  CrudFilters,
  useOne,
  useNavigation,
  HttpError,
  useList,
} from '@pankod/refine-core'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import { TIMEZONE_OPTIONS } from '../constants'
import {
  TimeZoneKey,
  InstructorWebinarClass,
  InstructorWebinarFasilitator,
} from '../types'
import { parseDate } from '../utils/dateParser'
import { TableInstructorScheduledWebinar } from './blocks/tableInstructorScheduledWebinar'
import { useErrorBoundary } from '@components/ErrorBoundary/hook'
import SelectScheduleModal, {
  useSelectScheduleModal,
} from './blocks/SelectScheduleModal'
import { TLMSProgramLocal } from '@resources/lms-management/program-lokal/types'

export const WebinarClassScheduleShow: React.FC<
  IResourceComponentsProps<GetListResponse<any>>
> = ({ options }) => {
  const { showBoundary } = useErrorBoundary()
  const { list } = useNavigation()
  const router = useRouter()
  const { id: webinarId, programId } = router.query
  const { data } = useList<Omit<TLMSProgramLocal, 'entity'>>({
    resource: `programs/${programId}/local-programs`,
  })

  const { show, ...scheduleModalProps } = useSelectScheduleModal()
  const { tableProps } = useTable<InstructorWebinarClass>({
    resource: `instructor/programs/${programId}/webinars/${webinarId}/class-group`, // or any webinar list filtered by eligibility
    queryOptions: {
      enabled: !!webinarId && !!programId,
    },
    dataProviderName: 'lms',
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { timezone } = params as { timezone: TimeZoneKey }

      filters.push({
        field: 'timezone',
        operator: 'eq',
        value: timezone,
      })
      return filters
    },
  })

  const handleNotFoundError = (error: HttpError) => {
    if (error.statusCode === 404) {
      showBoundary({
        status: 404,
        title: 'Webinar tidak ditemukan',
        backButtonText: 'Kembali ke Daftar Webinar',
        onBackClick() {
          list('webinar-schedule-management', 'replace')
        },
      })
    }
  }

  const { data: currentWebinarDetails, isFetching } = useOne({
    resource: `instructor/programs/${programId}/webinars`,
    id: webinarId as string,
    queryOptions: {
      enabled: !!webinarId && !!programId,
      onError: handleNotFoundError,
    },
  })

  const getFormattedDate = (dateStr: string) => {
    const formatDate = parseDate(dateStr)

    return `${formatDate.format('DD')} ${formatDate.format(
      'MMMM',
    )} ${formatDate.format('YYYY')}`
  }

  const webinarDateList = {
    chooseInstructor: {
      start: getFormattedDate(
        currentWebinarDetails?.data?.data.instructorSelectionDate.startDate,
      ),
      end: getFormattedDate(
        currentWebinarDetails?.data?.data.instructorSelectionDate.endDate,
      ),
    },
    execution: {
      start: getFormattedDate(
        currentWebinarDetails?.data?.data.executionDate[0],
      ),
      end: getFormattedDate(
        currentWebinarDetails?.data?.data.executionDate[
          currentWebinarDetails?.data?.data.executionDate.length - 1
        ],
      ),
    },
  }

  return (
    <Show
      title={currentWebinarDetails?.data?.data?.title || ''}
      headerButtons={() => (
        <ListButton resource="instructor/webinar">Daftar Webinar</ListButton>
      )}
      isLoading={isFetching}
    >
      <Head>
        <title>
          {currentWebinarDetails?.data?.data?.title || 'Webinar'} |{' '}
          {options?.label}
        </title>
      </Head>

      <Descriptions bordered className="!mb-4" layout="vertical">
        <Descriptions.Item label="Jadwal pemilihan instruktur">
          {webinarDateList.chooseInstructor.start} -{' '}
          {webinarDateList.chooseInstructor.end}
        </Descriptions.Item>
        <Descriptions.Item label="Jadwal pelaksanaan webinar">
          {webinarDateList.execution.start} - {webinarDateList.execution.end}
        </Descriptions.Item>
      </Descriptions>

      {TableInstructorScheduledWebinar && <TableInstructorScheduledWebinar />}

      <Table
        {...tableProps}
        dataSource={(tableProps?.dataSource || []).map((value) => ({
          ...value,
        }))}
        loading={tableProps.loading}
        rowKey="id"
        className="!mt-6"
        title={() => <h3>Jadwal yang dapat dipilih:</h3>}
        locale={{
          filterConfirm: (
            <>
              <Icons.FilterOutlined /> Filter
            </>
          ),
          filterSearchPlaceholder: 'Cari program lokal',
        }}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Button
                onClick={(e) => onExpand(record, e)}
                icon={<Icons.MinusCircleOutlined />}
                size="small"
              >
                Tutup Detail
              </Button>
            ) : (
              <Button
                onClick={(e) => onExpand(record, e)}
                icon={<Icons.PlusCircleOutlined />}
                size="small"
              >
                Lihat Detail
              </Button>
            ),
          expandedRowRender: (record) => (
            <Table
              dataSource={record.listKelas}
              rowKey="id"
              title={() => <TextField value="Daftar Kelas" strong />}
              pagination={false}
              size="small"
              scroll={{ x: 600 }}
              columns={[
                {
                  title: 'Nama',
                  dataIndex: 'name',
                  width: 300,
                },
                {
                  title: 'Fasilitator',
                  dataIndex: 'listFasilitator',
                  render: (value: InstructorWebinarFasilitator[]) => (
                    <AntdList
                      dataSource={value}
                      rowKey="userId"
                      size="small"
                      renderItem={(fasilitator) => (
                        <AntdList.Item>
                          <AntdList.Item.Meta
                            title={fasilitator.name}
                            description={
                              <div className="flex flex-wrap overflow-scroll">
                                <TextField
                                  value={
                                    <>
                                      <Icons.MailOutlined className="inline" />
                                      {fasilitator.email}
                                    </>
                                  }
                                  type="secondary"
                                  className="flex items-center gap-2"
                                />
                                {!!fasilitator.phone && (
                                  <TextField
                                    value={
                                      <>
                                        <Icons.PhoneOutlined className="inline" />
                                        {fasilitator.phone}
                                      </>
                                    }
                                    type="secondary"
                                    className="flex items-center gap-2"
                                  />
                                )}
                              </div>
                            }
                          />
                        </AntdList.Item>
                      )}
                    />
                  ),
                },
                {
                  title: 'Jumlah Peserta',
                  dataIndex: 'totalPeserta',
                  render: (value) => `${value} orang`,
                },
              ]}
            />
          ),
        }}
      >
        <Table.Column<InstructorWebinarClass>
          dataIndex={['name']}
          title="Kelompok Kelas"
          render={(value) => (
            <Space>
              <TextField value={value} />
            </Space>
          )}
        />
        <Table.Column
          dataIndex={['programLocal']}
          title="Program Lokal"
          key="programLocalId"
          filterMultiple={false}
          filters={data?.data?.map(({ id, name }) => ({
            text: name,
            value: id,
          }))}
          filterSearch={(value, record) => {
            // @ts-expect-error
            return record.text
              .toLowerCase()
              .includes((value as string).toLowerCase())
          }}
          render={(value) => <TextField value={value?.name} />}
        />
        <Table.Column
          dataIndex={['timezone']}
          title="Zona Waktu"
          filters={TIMEZONE_OPTIONS.map(({ label, value }) => ({
            text: label,
            value,
          }))}
          filterMultiple={false}
          render={(value) => <TextField value={value?.toUpperCase()} />}
        />
        <Table.Column
          dataIndex="listKelas"
          title="Total Peserta"
          render={(value: InstructorWebinarClass['listKelas']) => (
            <TextField
              value={
                value.reduce((result, { totalPeserta }) => {
                  return result + totalPeserta
                }, 0) + ' orang'
              }
            />
          )}
        />
        <Table.Column
          render={(_, record: InstructorWebinarClass) => (
            <Button
              disabled={record.status === 'NOT_AVAILABLE'}
              onClick={() => {
                show({
                  webinar: String(webinarId),
                  webinarKelasGroup: record.webinarKelasGroupId,
                })
              }}
            >
              Pilih
            </Button>
          )}
        />
      </Table>

      <SelectScheduleModal {...scheduleModalProps} />
    </Show>
  )
}
