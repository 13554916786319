import { AntdBreadcrumb, Icons } from '@pankod/refine-antd'
import {
  BreadcrumbsType,
  useBreadcrumb,
  useRouterContext,
} from '@pankod/refine-core'
import React from 'react'
export const NestedBreadcrumb = ({
  injectedItems,
  hideOriginal,
}: {
  injectedItems: BreadcrumbsType[]
  hideOriginal?: boolean
}) => {
  const { breadcrumbs } = useBreadcrumb()
  const { Link } = useRouterContext()
  return (
    <AntdBreadcrumb>
      <AntdBreadcrumb.Item>
        <Link to="/">
          <Icons.HomeOutlined />
        </Link>
      </AntdBreadcrumb.Item>
      {[...(!hideOriginal ? [breadcrumbs?.[0]] : []), ...injectedItems]
        .filter(Boolean)
        .map(({ label, href, icon }) => {
          return (
            <AntdBreadcrumb.Item key={label}>
              {icon}
              {href ? <Link to={href}>{label}</Link> : label}
            </AntdBreadcrumb.Item>
          )
        })}
    </AntdBreadcrumb>
  )
}
