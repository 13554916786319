import {
  Button,
  DatePicker,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Modal,
  Select,
  ShowButton,
  Space,
  Steps,
  Table,
  TextField,
  useModalForm,
  useSelect,
  useTable,
} from '@pankod/refine-antd'
import locale from 'antd/lib/locale/id_ID'
import {
  IResourceComponentsProps,
  GetListResponse,
  useDelete,
  CrudFilters,
  LogicalFilter,
  useGetIdentity,
  useCan,
  useInvalidate,
} from '@pankod/refine-core'
import dayjs, { Dayjs } from 'dayjs'
import Head from 'next/head'
import React from 'react'

import Filter, { TFilterForm } from '@components/ContentFilter'
import { TCommonError } from 'src/interfaces/common'
import { PopDeleteConfirm } from '../components/PopDeleteConfirm'
// import { TLMSBlueprint } from '../types'
import {
  PROGRAM_STATE,
  TLMSProgram,
  TLMSProgramDetail,
  TLMSProgramRequest,
} from './types'
import { getAvailableStatus, getProgressDot } from './utils'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const PROGRAM_RESOURCE = 'programs'
const PROGRAM_ENTITIES_RESOURCE = 'entities/programs/admin-entitas'

const STATUS_OPTIONS: { label: string; value: TLMSProgram['status'] }[] = [
  {
    label: 'Persiapan',
    value: PROGRAM_STATE.PREPARATION,
  },
  {
    label: 'Berjalan',
    value: PROGRAM_STATE.RUNNING,
  },
  {
    label: 'Selesai',
    value: PROGRAM_STATE.ENDED,
  },
]

export const LMSProgramManagementList: React.FC<
  IResourceComponentsProps<GetListResponse<TLMSProgram>>
> = ({ options }) => {
  const { data, isSuccess } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const invalidate = useInvalidate()
  const { tableProps, searchFormProps, filters } = useTable<
    TLMSProgram,
    TCommonError,
    TFilterForm<TLMSProgram>
  >({
    syncWithLocation: true,
    queryOptions: {
      enabled: isSuccess,
    },
    resource:
      LMSRole === 'admin-entitas'
        ? PROGRAM_ENTITIES_RESOURCE
        : PROGRAM_RESOURCE,
    dataProviderName: 'lms',
    initialSorter: [
      {
        field: 'endDate',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { status, name } = params
      filters.push(
        {
          field: 'status',
          operator: 'eq',
          value: status,
        },
        {
          field: 'name',
          operator: 'eq',
          value: name,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        },
      )
      return filters
    },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
    submit: createSubmit,
    // visible: isCreateVisible,
  } = useModalForm<TLMSProgram, TCommonError, TLMSProgramRequest>({
    action: 'create',
    resource: PROGRAM_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    submit: ({ periode, ...formValues }) => {
      const [startDate, enDate] = periode as unknown as [Dayjs, Dayjs]
      return {
        ...formValues,
        startDate: startDate.startOf('day').format('YYYY-MM-DD'),
        endDate: enDate.endOf('day').format('YYYY-MM-DD'),
      }
    },
    redirect: false,
    onMutationSuccess() {
      if (LMSRole === 'admin-entitas') {
        invalidate({
          resource: PROGRAM_ENTITIES_RESOURCE,
          dataProviderName: 'lms',
          invalidates: ['list'],
        })
      }
    },
    errorNotification: (error) =>
      showErrorNotification(error, 'Terdapat gangguan saat membuat program'),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    submit: editSubmit,
    // visible: isEditVisible,
  } = useModalForm<TLMSProgramDetail, TCommonError, TLMSProgramRequest>({
    action: 'edit',
    resource: PROGRAM_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    submit: ({ periode, ...formValues }) => {
      const [startDate, enDate] = periode as unknown as [Dayjs, Dayjs]
      return {
        blueprintId: formValues.blueprintId,
        name: formValues.name,
        status: formValues.status,
        startDate: startDate.startOf('day').format('YYYY-MM-DD'),
        endDate: enDate.endOf('day').format('YYYY-MM-DD'),
      }
    },
    onMutationSuccess() {
      if (LMSRole === 'admin-entitas') {
        invalidate({
          resource: PROGRAM_ENTITIES_RESOURCE,
          dataProviderName: 'lms',
          invalidates: ['list'],
        })
      }
    },
    successNotification: {
      message: 'Program berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(error, 'Terdapat gangguan saat mengubah program'),
    redirect: false,
  })

  const { selectProps } = useSelect({
    resource: 'entities',
    filters: [{ field: 'pageSize', operator: 'eq', value: -1 }],
    optionLabel: 'name',
    optionValue: 'id',
    queryOptions: {
      enabled: !!createModalProps.visible && LMSRole === 'admin-entitas',
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
  })

  // const {
  //   selectProps: blueprintSelectProps,
  //   queryResult: blueprintQueryResult,
  // } = useSelect<TLMSBlueprint, TCommonError>({
  //   resource: 'blueprints',
  //   dataProviderName: 'lms',
  //   optionLabel: 'label',
  //   optionValue: 'id',
  //   filters: [
  //     {
  //       field: 'pageSize',
  //       operator: 'eq',
  //       value: -1,
  //     },
  //   ],
  //   defaultValueQueryOptions: {
  //     enabled: false,
  //   },
  //   queryOptions: {
  //     enabled: isCreateVisible || isEditVisible,
  //   },
  // })

  const { mutateAsync } = useDelete()
  const { data: canEdit } = useCan({
    action: 'edit',
    resource: 'lms-program-management',
  })

  const { data: canDelete } = useCan({
    action: 'delete',
    resource: 'lms-program-management',
  })

  return (
    <>
      <List
        title="LMS Program Management"
        createButtonProps={{
          children: 'Buat Program Baru',
          onClick: () => {
            createShow()
          },
        }}
      >
        <Head>
          <title>LMS Admin | {options?.label}</title>
        </Head>

        <Filter
          layout="inline"
          formProps={searchFormProps}
          filters={filters as LogicalFilter[]}
          items={[
            {
              label: 'Program Status',
              name: 'status',
              component: (
                <Select
                  options={STATUS_OPTIONS}
                  placeholder="Filter by status"
                  allowClear
                />
              ),
            },
            {
              label: 'Program Name',
              name: 'name',
              component: (
                <Input
                  placeholder="Filter by name"
                  autoComplete="off"
                  allowClear
                />
              ),
            },
          ]}
        />

        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="slug"
            title="Slug"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title="Nama Program"
            sorter
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="startDate"
            title="Tanggal Mulai"
            sorter
            render={(value) => (
              <TextField value={dayjs(value).format('DD MMM YYYY')} />
            )}
          />
          <Table.Column
            dataIndex="endDate"
            title="Tanggal Selesai"
            sorter
            render={(value) => (
              <TextField value={dayjs(value).format('DD MMM YYYY')} />
            )}
          />
          <Table.Column
            dataIndex="status"
            title="Status"
            render={(value: TLMSProgram['status']) => {
              const status = getAvailableStatus(value)

              return (
                <Steps
                  direction="vertical"
                  size="small"
                  current={[
                    PROGRAM_STATE.PREPARATION,
                    PROGRAM_STATE.RUNNING,
                    PROGRAM_STATE.ENDED,
                  ].findIndex((v) => v === value)}
                  progressDot={getProgressDot}
                >
                  <Steps.Step
                    title={PROGRAM_STATE.PREPARATION}
                    className="capitalize"
                    status={status.status[0]}
                  />
                  <Steps.Step
                    title={PROGRAM_STATE.RUNNING}
                    className="capitalize"
                    status={status.status[1]}
                  />
                  <Steps.Step
                    title={PROGRAM_STATE.ENDED}
                    className="capitalize"
                    status={status.status[2]}
                  />
                </Steps>
              )
            }}
          />
          <Table.Column<TLMSProgramDetail>
            render={(_, record) => (
              <Space direction="vertical">
                <ShowButton block size="small" recordItemId={record.id}>
                  Lihat
                </ShowButton>
                <EditButton
                  size="small"
                  block
                  ignoreAccessControlProvider
                  recordItemId={record.id}
                  disabled={
                    record.status !== PROGRAM_STATE.PREPARATION ||
                    (!canEdit?.can && !record.programOwner)
                  }
                  onClick={() => {
                    editShow(record.id)
                  }}
                >
                  Ubah
                </EditButton>
                <PopDeleteConfirm
                  title={`Apakah Anda yakin ingin menghapus Program "${record.name}"?`}
                  placeholder="Tulis ulang nama Program"
                  onConfirm={() =>
                    mutateAsync(
                      {
                        id: record.id,
                        resource: PROGRAM_RESOURCE,
                        dataProviderName: 'lms',
                        successNotification: {
                          message: 'Program berhasil dihapus',
                          type: 'success',
                          description: 'Sukses',
                        },
                        errorNotification: (error) =>
                          showErrorNotification(
                            error,
                            'Terdapat gangguan saat menghapus program',
                          ),
                      },
                      {
                        onSettled: () => {
                          if (LMSRole === 'admin-entitas') {
                            invalidate({
                              resource: PROGRAM_ENTITIES_RESOURCE,
                              dataProviderName: 'lms',
                              invalidates: ['list'],
                            })
                          }
                        },
                      },
                    )
                  }
                  okButtonProps={{
                    danger: true,
                    type: 'default',
                  }}
                  okText="Delete"
                  validateValue={record.name}
                  errorMessage="Nama program tidak sama"
                  disabled={
                    record.status !== PROGRAM_STATE.PREPARATION ||
                    (!canDelete?.can && !record.programOwner)
                  }
                >
                  <Button
                    size="small"
                    danger
                    block
                    disabled={
                      record.status !== PROGRAM_STATE.PREPARATION ||
                      (!canDelete?.can && !record.programOwner)
                    }
                    icon={<Icons.DeleteOutlined />}
                  >
                    Hapus
                  </Button>
                </PopDeleteConfirm>
              </Space>
            )}
          />
        </Table>
      </List>

      <Modal
        {...editModalProps}
        title="Ubah Program"
        okButtonProps={{
          onClick: async () => {
            const data = await editSubmit()
            editFormProps.onFinish(data)
          },
        }}
      >
        <Form
          {...editFormProps}
          initialValues={(() => {
            const { startDate, endDate, ...rest } =
              editFormProps?.initialValues?.data || {}
            const periode = [dayjs(startDate), dayjs(endDate)]

            return {
              ...rest,
              periode,
              // blueprintId: blueprint?.id,
            }
          })()}
          layout="vertical"
          validateMessages={{ required: '${label} harus diisi.' }}
        >
          <Form.Item
            label="Nama Program"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Periode"
            name="periode"
            rules={[{ required: true }]}
          >
            <DatePicker.RangePicker />
          </Form.Item>
          {/* <Form.Item
            label="Blueprint ID"
            name="blueprintId"
            rules={[{ required: true }]}
          >
            <Select
              {...blueprintSelectProps}
              loading={blueprintQueryResult.isFetching}
              allowClear
              onSearch={() => {}}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item> */}
          <Form.Item name="status" hidden>
            <Input value={PROGRAM_STATE.PREPARATION} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        {...createModalProps}
        title="Buat Program Baru"
        okButtonProps={{
          onClick: async () => {
            const data = await createSubmit()
            createFormProps.onFinish(data)
          },
        }}
        onCancel={() => {
          createClose()
          createFormProps.form.resetFields()
        }}
        okText="Buat Program"
        cancelText="Batal"
      >
        <Form
          {...createFormProps}
          layout="vertical"
          validateMessages={{ required: '${label} harus diisi.' }}
        >
          <Form.Item
            label="Nama Program"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Periode"
            name="periode"
            rules={[{ required: true }]}
          >
            <DatePicker.RangePicker locale={locale.DatePicker} />
          </Form.Item>
          {LMSRole === 'admin-entitas' && (
            <Form.Item
              label="Entitas"
              name="entityId"
              rules={[{ required: true }]}
            >
              <Select
                {...selectProps}
                onSearch={() => {}}
                filterOption={(input, option) =>
                  (option!.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
          {/* <Form.Item
            label="Blueprint ID"
            name="blueprintId"
            rules={[{ required: true }]}
          >
            <Select
              {...blueprintSelectProps}
              loading={blueprintQueryResult.isFetching}
              allowClear
              onSearch={() => {}}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item> */}
          <Form.Item name="status" hidden>
            <Input value={PROGRAM_STATE.PREPARATION} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
