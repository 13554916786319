import { Button, Icons, Table, Typography, useTable } from '@pankod/refine-antd'
import React from 'react'
import { useRouter } from 'next/router'

import KelompokMuridDrawer, {
  useKelompokMuridDrawer,
} from './KelompokMuridDrawer'

const LaporanLokakarya = () => {
  const router = useRouter()
  const programId = String(router.query?.programId)
  const { tableProps } = useTable<{ id: string; name: string }>({
    dataProviderName: 'lms',
    resource: `programs/${programId}/classes`,
    queryOptions: {
      enabled: !!programId,
    },
  })
  const { show, ...kelompoMuridDrawerProps } = useKelompokMuridDrawer()
  return (
    <>
      <Typography.Title level={5} className="!mb-3">
        Daftar Kelas
      </Typography.Title>
      <Table
        {...tableProps}
        rowKey="id"
        bordered
        columns={[
          {
            title: 'Nama Kelas',
            dataIndex: 'name',
            width: '80%',
          },
          {
            title: 'Laporan Kelompok Murid',
            align: 'center',
            render: (_, record) => {
              return (
                <Button
                  icon={<Icons.UnorderedListOutlined />}
                  onClick={() => {
                    show(record.id, record.name)
                  }}
                >
                  Lihat Daftar Kelompok Murid
                </Button>
              )
            },
          },
        ]}
      />
      <KelompokMuridDrawer
        {...kelompoMuridDrawerProps}
        key={kelompoMuridDrawerProps.id}
      />
    </>
  )
}

export default LaporanLokakarya
