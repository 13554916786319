import { SerializedNodes } from '@craftjs/core'
import lz from 'lzutf8'

export const decompressContentSchema = (input: string) => {
  let contentSchema = ''
  try {
    console.warn('FORM:: Render compressed schema')
    contentSchema = JSON.parse(lz.decompress(lz.decodeBase64(input)))
  } catch (_) {
    contentSchema = JSON.parse(input)
  }

  return contentSchema
}

type CompressContentSchema = SerializedNodes & {
  [key: string]: { displayName: string; nodes: string[]; type?: any }
}
export const compressContentSchema = (
  input: CompressContentSchema,
  compressed = false,
) => {
  if (compressed) {
    return lz.encodeBase64(lz.compress(JSON.stringify(input)))
  }
  return JSON.stringify(input)
}
