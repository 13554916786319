export enum PROGRAM_STATE {
  PREPARATION = 'persiapan',
  RUNNING = 'berjalan',
  ENDED = 'selesai',
}

export enum FINALIZATION_CONFIG_STAGE {
  DRAFT = 'draft',
  FINALIZED = 'finalized',
}

export type TLMSProgram = {
  id: number
  slug: string
  // blueprintId: string
  endDate: string
  name: string
  startDate: string
  status: PROGRAM_STATE
  ppConfigStage: FINALIZATION_CONFIG_STAGE
  jurnalFasilitasiConfigStage: FINALIZATION_CONFIG_STAGE
  sisAccountId: string
}

export type TLMSProgramDetail = Omit<TLMSProgram, 'blueprintId'> & {
  blueprint: {
    id: string
    label: string
  }
  timeTrackerActive: boolean
  penilaianKinerjaConfigStage: FINALIZATION_CONFIG_STAGE
  umpanBalikConfigStage: FINALIZATION_CONFIG_STAGE
  penilaianWebinarConfigStage: FINALIZATION_CONFIG_STAGE
  periodePenilaianConfigStage: FINALIZATION_CONFIG_STAGE
  programOwner: boolean
  entityId: string
  useNewCertificate: boolean
  modifyProfileActive: boolean
}

export type TLMSProgramRequest = Omit<
  TLMSProgram,
  | 'id'
  | 'slug'
  | 'ppConfigStage'
  | 'jurnalFasilitasiConfigStage'
  | 'timeTrackerActive'
  | 'sisAccountId'
>

enum ImportStatus {
  Initializing = 'initializing',
  Created = 'created',
  Importing = 'importing',
  CleanupBatch = 'cleanup_batch',
  Imported = 'imported',
  ImportedWithMessages = 'imported_with_messages',
  Aborted = 'aborted',
  FailedWithMessages = 'failed_with_messages',
  Failed = 'failed',
  Restoring = 'restoring',
  PartiallyRestored = 'partially_restored',
  Restored = 'restored',
}

type CsvAttachment = {
  id: number
  uuid: string
  folderId: number | null
  displayName: string
  filename: string
  uploadStatus: string
  contentType: string
  url: string
  size: number
}

type ErrorAttachment = {
  id: number
  uuid: string
  folder_id: number | null
  display_name: string
  filename: string
  upload_status: string
  content_type: string
  url: string
  size: number
}

type ResultCounts = {
  accounts: number
  courses: number
  users: number
  enrollments: number
  errorCount: number
  warningCount: number
}

type Warning = {
  filename: string
  message: string
}

export type TProgramCanvasSyncData = {
  id: number
  localProgramId: number
  status: string
  progress: number
  importId: number
  importStatus: ImportStatus
  resultCounts: ResultCounts
  csvAttachments: CsvAttachment[]
  errorAttachments: ErrorAttachment
  errors: any[] // type unknown, as the data structure is not specified
  warnings: Warning[]
  createdAt: string
  updatedAt: string
  syncType: string
  actorId: string
  actorEmail: string
  userEmails: string[]
}

export type TProgramGrade = {
  label: string
  from: number
  to: number
  color: string
}

export type TProgramGradeData = {
  data: TProgramGrade[]
}

export type TimespendDurationResponse = {
  data: {
    async: number
    sync: number
  }
}

export type PresensiPesertaProgram = {
  userId: string
  nama: string
  statistik: {
    hadir: number
    terlambat: number
    alpha: number
    kosong: number
  }
}

export type PresensiStatistic = {
  id: string
  nama: string
  statistik: {
    hadir: number
    terlambat: number
    alpha: number
    kosong: number
  }
}

export type PresensiKelompokMuridStatistic = {
  id: string
  name: string
  daftarMurid: {
    userId: string
    name: string
    daftarLokakarya: {
      id: string
      name: string
      point: number
    }[]
  }[]
}

enum PRESENSI_STATE {
  PRESENSI_PESERTA_KOSONG = 'PRESENSI_PESERTA_KOSONG',
  PRESENSI_PESERTA_HADIR = 'PRESENSI_PESERTA_HADIR',
  PRESENSI_PESERTA_TERLAMBAT = 'PRESENSI_PESERTA_TERLAMBAT',
  PRESENSI_PESERTA_ALPHA = 'PRESENSI_PESERTA_ALPHA',
}

export type PresensiPesertaStatistic = {
  userId: string
  nama: string
  presensi: keyof typeof PRESENSI_STATE
}
