import {
  Button,
  Icons,
  useModalForm,
  Card,
  Divider,
  Input,
  Empty,
  Popconfirm,
} from '@pankod/refine-antd'
import {
  useList,
  useDelete,
  useUpdate,
  useInvalidate,
  useCreate,
  useOne,
} from '@pankod/refine-core'
import React from 'react'

import { KelompokMurid, TLMSClass, TLMSClassRequest } from '../types'
import { TCommonError } from 'src/interfaces/common'
import { PROGRAM_LOCAL_STATE } from '@resources/lms-management/program-lokal/types'
import { DrawerKelompokMurid } from './DrawerKelompokMurid'
import { ModalFormAssignMurid } from './ModalFormAssignMurid'
import { ModalFormCreateKelompokKelas } from './ModalFormCreateKelompokMurid'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type TClassTableListProps = {
  programId?: string
  programLocalId?: string
  hideTitle?: boolean
  programLocalStatus?: PROGRAM_LOCAL_STATE
  classId?: string
}
export const TabKelompokMurid = ({
  programId,
  programLocalId,

  classId,
}: TClassTableListProps) => {
  const KELOMPOK_MURID_RESOURCE = `local-programs/${programLocalId}/classes/${classId}/kelompok-murid`
  const invalidate = useInvalidate()
  const { data } = useList<KelompokMurid>({
    resource: KELOMPOK_MURID_RESOURCE,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId,
    },
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was an error when fetching Kelompok Murid',
      ),
  })

  const { mutateAsync: doDelete } = useDelete()
  const { mutateAsync } = useUpdate()
  const { mutateAsync: doAddDummy } = useCreate()

  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [studentDrawerQuery, setStudentDrawerQuery] = React.useState<string>('')
  const [editGroupDrawer, setEditGroupDrawer] = React.useState<{
    selectedGroupId: string | null
    open: boolean
  }>({
    selectedGroupId: null,
    open: false,
  })
  const [assignMode, setAssignMode] = React.useState<{
    type: string
    currentMemberCount?: number
  }>({ type: 'murid' })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<TLMSClass, TCommonError, TLMSClassRequest>({
    action: 'create',
    resource: KELOMPOK_MURID_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    onMutationSuccess: () => {
      invalidate({
        resource: KELOMPOK_MURID_RESOURCE,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    successNotification: {
      message: 'Kelompok Murid berhasil dibuat',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'Terdapat gangguan saat membuat Kelompok Kelas',
      ),
  })

  // invalidate cache when form is closed
  const {
    modalProps: assignMuridKelompokModalProps,
    formProps: assignMuridKelompokFormProps,
    show: assignModalShow,
    close: assignModalClose,
  } = useModalForm({
    action: 'create',
    resource: `${KELOMPOK_MURID_RESOURCE}/${editGroupDrawer.selectedGroupId}/${assignMode.type}`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Perubahan pada kelompok berhasil disimpan',
      type: 'success',
      description: 'Successful',
    },
    onMutationSuccess: () => {
      invalidate({
        resource: KELOMPOK_MURID_RESOURCE,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'Terdapat gangguan saat melakukan perubahan'),
  })

  const { data: dummyParticipantData } = useOne<{
    data: { isActive: boolean }
  }>({
    dataProviderName: 'lms',
    id: 'flag',
    resource: `programs/${programId}/dummy-participants`,
  })
  const dummyParticipantActive = dummyParticipantData?.data?.data?.isActive

  return (
    <>
      <div className="flex flex-row justify-between gap-4 items-center mb-4">
        <Input
          placeholder="Cari Kelompok Murid"
          prefix={<Icons.SearchOutlined />}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="max-w-xs"
        />

        <Button
          type="primary"
          onClick={() => {
            createShow()
          }}
        >
          Tambah Kelompok Murid
        </Button>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {data?.data
          .filter((item) => {
            if (searchQuery === '') {
              return item
            } else if (
              item.name.toLowerCase().includes(searchQuery.toLocaleLowerCase())
            ) {
              return item
            }
          })
          .map((item: any, index: number) => (
            <Card
              hoverable
              style={{
                borderRadius: 8,
              }}
              key={index}
              onClick={() => {
                setEditGroupDrawer({
                  selectedGroupId: item.id,
                  open: true,
                })
              }}
            >
              <div className="flex flex-row justify-between items-center my-0">
                <p className="font-bold my-0">{item.name}</p>

                <Popconfirm
                  title="Apakah Anda yakin ingin menghapus kelompok ini?"
                  okText="Hapus"
                  cancelText="Kembali"
                  okButtonProps={{ danger: true }}
                  onConfirm={async (e) => {
                    e?.stopPropagation()
                    return doDelete({
                      id: item.id,
                      resource: `local-programs/${programLocalId}/classes/${classId}/kelompok-murid`,
                      dataProviderName: 'lms',
                      successNotification: {
                        message: 'Kelompok Murid berhasil dihapus',
                        type: 'success',
                        description: 'Successful',
                      },
                      errorNotification: (err) =>
                        showErrorNotification(
                          err,
                          'Terdapat kendala saat menghapus Kelompok Murid',
                        ),
                    })
                  }}
                >
                  <Button
                    type="primary"
                    size="small"
                    danger
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <Icons.DeleteOutlined />
                  </Button>
                </Popconfirm>
              </div>

              <Divider />

              <p>
                Pengajar Praktik:{' '}
                <b>
                  {item.daftarPengajarPraktik &&
                  item.daftarPengajarPraktik.length > 0
                    ? 'Sudah di assign'
                    : 'Belum di assign'}
                </b>
              </p>

              <p>
                Jumlah Murid: <b>{item.jumlahMurid}</b>
              </p>

              <a>Lihat Detail Kelompok</a>
            </Card>
          ))}

        {/* if filtered data is empty return empty component */}
      </div>
      {data?.data.filter((item: any) => {
        if (searchQuery === '') {
          return item
        } else if (
          item.name.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        ) {
          return item
        }
      }).length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Tidak ada Kelompok Murid yang ditemukan"
        />
      )}

      <DrawerKelompokMurid
        selectedGroup={
          data?.data.find(({ id }) => editGroupDrawer.selectedGroupId === id) ||
          null
        }
        isVisible={editGroupDrawer.open}
        searchQuery={studentDrawerQuery}
        onSearchQueryChange={(value: string) => {
          setStudentDrawerQuery(value)
        }}
        onStudentDelete={(userId) => {
          return doDelete(
            {
              id: userId,
              resource: `local-programs/${programLocalId}/kelompok-murid/${editGroupDrawer.selectedGroupId}/user`,
              dataProviderName: 'lms',
              successNotification: {
                message: 'User berhasil dihapus dari kelompok',
                type: 'success',
                description: 'Successful',
              },
              errorNotification: (err) =>
                showErrorNotification(
                  err,
                  'Terdapat gangguan saat menghapus user',
                ),
            },
            {
              onSuccess: () => {
                invalidate({
                  resource: KELOMPOK_MURID_RESOURCE,
                  dataProviderName: 'lms',
                  invalidates: ['list'],
                })
              },
            },
          )
        }}
        onStudentAdd={(mode: string, currentMemberCount?: number) => {
          setAssignMode({ type: mode, currentMemberCount })
          assignModalShow()
        }}
        onClose={() => {
          setEditGroupDrawer({
            selectedGroupId: null,
            open: false,
          })
        }}
        onUpdateConfirm={async (newName: string) => {
          await mutateAsync(
            {
              resource: `local-programs/${programLocalId}/classes/${classId}/kelompok-murid`,
              id: editGroupDrawer.selectedGroupId!,
              values: {
                name: newName,
              },
              errorNotification: (err) =>
                showErrorNotification(
                  err,
                  'Terdapat gangguan saat melakukan perubahan',
                ),
              successNotification: () => {
                return {
                  type: 'success',
                  message: `Berhasil mengubah Nama Kelompok`,
                }
              },
            },
            {
              onSuccess: () => {
                invalidate({
                  resource: KELOMPOK_MURID_RESOURCE,
                  dataProviderName: 'lms',
                  invalidates: ['list'],
                })
              },
            },
          )
        }}
        enableAddDummy={!!dummyParticipantActive}
        onAddDummyParticipant={async ({ amount, classGroupId }) => {
          await doAddDummy(
            {
              resource: `programs/${programId}/dummy-participants`,
              values: {
                amount,
                classGroupId,
              },
              errorNotification: (err) =>
                showErrorNotification(
                  err,
                  'Terdapat gangguan saat menambah peserta dummy',
                ),
              successNotification: () => {
                return {
                  type: 'success',
                  message: `Berhasil menambahkan peserta dummy`,
                }
              },
            },
            {
              onSuccess: () => {
                invalidate({
                  resource: KELOMPOK_MURID_RESOURCE,
                  dataProviderName: 'lms',
                  invalidates: ['list'],
                })
              },
            },
          )
        }}
      />

      <ModalFormCreateKelompokKelas
        formProps={createFormProps}
        modalProps={createModalProps}
        onClose={() => {
          createFormProps.form?.resetFields()
          createClose()
        }}
      />

      <ModalFormAssignMurid
        formProps={assignMuridKelompokFormProps}
        modalProps={assignMuridKelompokModalProps}
        mode="create"
        onClose={() => {
          assignMuridKelompokFormProps.form?.resetFields()
          assignModalClose()
        }}
        localProgramId={programLocalId}
        classId={classId}
        assignMode={assignMode}
      />
    </>
  )
}
