import {
  Button,
  Descriptions,
  Divider,
  FormInstance,
  Icons,
  Space,
  Table,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useCan, useInvalidate, useOne, useResource } from '@pankod/refine-core'
import 'dayjs/locale/id'
import dayjs from 'dayjs'
dayjs.locale('id')

import { ModalForm } from './ModalForm'
import { LMSWebinar, LMSWebinarInstructor, LMSWebinarRequest } from '../types'
import { TCommonResponse, TLMSCommonError } from 'src/interfaces/common'
import { groupBySequence, renderGroupBySequence } from 'src/helpers/date'
import ShowWebinarButton from './ShowWebinarButton'
import InstructorModal from './instructor/InstructorModal'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import WebinarProgramLocalConfigDrawer, {
  useWebinarProgramLocalConfigDrawer,
} from './ProgramLocalConfiguration/WebinarProgramLocalConfigDrawer'
import PenilaianWebinarConfigDrawer, {
  usePenilaianWebinarConfigDrawer,
} from './PenilaianWebinar/PenilaianWebinarConfigDrawer'
import {
  FINALIZATION_CONFIG_STAGE,
  TLMSProgramDetail,
} from '@resources/lms-management/program/types'
import ExportReportButton from '@components/ExportReportButton'
import { useProgramStore } from '@resources/lms-management/program/store'

const DATE_FORMAT = 'DD MMM YYYY'

export const WebinarTableList = () => {
  const invalidate = useInvalidate()
  const { id: programId } = useResource({
    resourceNameOrRouteName: 'lms-program-management',
  })
  const { tableProps } = useTable({
    resource: `programs/${programId}/webinars`,
  })

  const { setProgram } = useProgramStore((store) => store)
  const { data } = useOne<TCommonResponse<TLMSProgramDetail>>({
    resource: 'programs',
    id: programId,
    dataProviderName: 'lms',
    queryOptions: {
      onSuccess: (data) => {
        setProgram({
          isOwner: Boolean(data.data.data.programOwner),
          entityId: data.data.data.entityId,
        })
      },
    },
  })
  const { show: showConfigDrawer, ...configDrawerProps } =
    useWebinarProgramLocalConfigDrawer()
  const { show: showPenilaianDrawer, ...penilaianDrawerProps } =
    usePenilaianWebinarConfigDrawer()
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<LMSWebinar, TLMSCommonError, LMSWebinarRequest>({
    action: 'create',
    resource: `programs/${programId}/webinars`,
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Webinar successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(error, 'There was a problem when creating webinar'),
  })
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<
    TCommonResponse<LMSWebinar>,
    TLMSCommonError,
    LMSWebinarRequest
  >({
    action: 'edit',
    resource: `programs/${programId}/webinars`,
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Webinar successfully edited',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(error, 'There was a problem when editing webinar'),
  })
  const {
    modalProps: instructorModalProps,
    formProps: instructorFormProps,
    show: instructorShow,
    close: instructorClose,
    onFinish,
    formLoading,
    mutationResult: instructorMutationResult,
  } = useModalForm<
    TCommonResponse<LMSWebinar>,
    TLMSCommonError,
    { instructors: LMSWebinarInstructor[] }
  >({
    action: 'create',
    resource: `programs/${programId}/instructors`,
    mutationMode: 'optimistic',
    redirect: false,
    successNotification: {
      message: 'Instructor list successfully updated',
      type: 'success',
      description: 'Successful',
    },
    invalidates: ['detail'],
    autoSubmitClose: false,
    onMutationError: () => {
      const form: FormInstance | undefined = instructorFormProps?.form
      form?.resetFields()
    },
    onMutationSuccess: () => {
      const form: FormInstance | undefined = instructorFormProps?.form
      form?.resetFields()
    },
    queryOptions: {
      enabled: true,
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when updating instructor list',
      ),
  })

  const { data: canExportFulfillmentReport } = useCan({
    action: 'field',
    resource: 'lms-webinar-management',
    params: {
      field: 'fulfillment-report',
    },
  })

  const isOwner = useProgramStore(({ isOwner }) => isOwner)

  return (
    <>
      <Descriptions
        title="Webinar"
        extra={
          <Space>
            <ExportReportButton
              can={canExportFulfillmentReport?.can || isOwner}
              url={`${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/webinars/laporan-keterisian/export`}
              fileName={`DataKeterisianWebinar_ProgramId_${programId}`}
              title="Ekspor Keterisian"
            />
            <Button
              icon={<Icons.TeamOutlined />}
              onClick={() => {
                invalidate({
                  resource: `programs/${programId}/instructors`,
                  invalidates: ['detail'],
                })
                instructorShow()
              }}
            >
              Daftar Instruktur
            </Button>
            <Divider type="vertical" />
            <Button
              onClick={() => {
                createShow()
              }}
              icon={<Icons.PlusOutlined />}
              type="primary"
            >
              Buat Webinar Baru
            </Button>
          </Space>
        }
      />

      <Table {...tableProps} rowKey="id" size="middle" scroll={{ x: 1100 }}>
        <Table.Column
          ellipsis
          dataIndex="id"
          title="ID"
          fixed="left"
          width={50}
        />
        <Table.Column
          dataIndex="title"
          title="Judul Webinar"
          fixed="left"
          width={150}
        />
        <Table.Column
          dataIndex="code"
          className="break-words"
          title="Kode Unik"
          width={80}
        />
        <Table.Column
          dataIndex="instructorSelectionDate"
          title="Tgl. Pemilihan Instruktur"
          render={(value) => (
            <TextField
              value={`${dayjs(value.startDate).format(DATE_FORMAT)} - ${dayjs(
                value.endDate,
              ).format(DATE_FORMAT)}`}
            />
          )}
          width={200}
        />
        <Table.Column
          dataIndex="executionDate"
          title="Tgl. Pelaksanaan Webinar"
          render={(value = []) => {
            const group = groupBySequence(value)
            return renderGroupBySequence(group)
          }}
          width={200}
        />
        <Table.Column<LMSWebinar>
          dataIndex={['statistic', 'assigned']}
          title="Terjadwal"
          render={(value, record) => `${value}/${record.statistic.total}`}
          width={120}
        />
        <Table.Column<LMSWebinar>
          dataIndex={['statistic', 'prepared']}
          title="Siap Melaksanakan"
          render={(value, record) => `${value}/${record.statistic.total}`}
          width={120}
        />
        <Table.Column<LMSWebinar>
          dataIndex={['statistic', 'reported']}
          title="Laporan Tersedia"
          render={(value, record) => `${value}/${record.statistic.total}`}
          width={120}
        />
        <Table.Column<LMSWebinar>
          fixed="right"
          width={155}
          render={(_, record) => (
            <Space direction="vertical">
              <ShowWebinarButton
                block
                size="small"
                recordItemId={record.id}
                programId={programId}
              >
                Lihat Webinar
              </ShowWebinarButton>
              <Button
                size="small"
                icon={<Icons.EditOutlined />}
                onClick={() => editShow(record.id)}
                block
              >
                Ubah Webinar
              </Button>
              <Button
                size="small"
                icon={<Icons.ApartmentOutlined />}
                onClick={() =>
                  showConfigDrawer(record.id, record.executionDate)
                }
                block
              >
                Kelola Mapping
              </Button>
              <Button
                size="small"
                icon={<Icons.FormOutlined />}
                onClick={() => {
                  showPenilaianDrawer(record.id)
                }}
                block
              >
                Kelola Penilaian
              </Button>
            </Space>
          )}
        />
      </Table>

      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={() => {
          createFormProps.form?.resetFields()
          createClose()
        }}
        programId={programId}
      />

      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={() => {
          editFormProps.form?.resetFields()
          editClose()
        }}
        programId={programId}
      />

      <InstructorModal
        formProps={instructorFormProps}
        modalProps={instructorModalProps}
        programId={programId}
        mutationResult={instructorMutationResult}
        onClose={() => {
          instructorFormProps?.form?.resetFields()
          instructorClose()
        }}
        onFinish={onFinish}
        formLoading={formLoading}
      />

      <WebinarProgramLocalConfigDrawer {...configDrawerProps} />
      <PenilaianWebinarConfigDrawer
        {...penilaianDrawerProps}
        isFinalized={
          data?.data?.data?.penilaianWebinarConfigStage ===
          FINALIZATION_CONFIG_STAGE.FINALIZED
        }
      />
    </>
  )
}
