import { HttpError } from '@pankod/refine-core'
import React from 'react'
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Icons,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  useDrawerForm,
} from '@pankod/refine-antd'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import locale from 'antd/lib/locale/id_ID'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Jakarta')

import { BuktiKaryaConfig } from '../types'
import { FormSelectComponent } from './FormSelectComponent'
import { isHtmlTagEmpty } from 'src/helpers/validator'
import ContentForm from '@components/ContentBank/ContentForm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const MAP_ACTUAL_BK_CATEGORY = [
  {
    value: '9',
    label: 'Artikel',
  },
  {
    value: '4',
    label: 'Bahan Ajar',
  },
  {
    value: '10',
    label: 'Dokumen Teknis',
  },
  {
    value: '6',
    label: 'Kepemimpinan Sekolah',
  },
  {
    value: '7',
    label: 'Praktik Baik',
  },
  {
    value: '1',
    label: 'Praktik Pembelajaran',
  },
  {
    value: '8',
    label: 'RPP/Modul Ajar',
  },
  {
    value: '5',
    label: 'Lainnya',
  },
]

type DrawerBuktiKaryaConfigProps = {
  show: boolean
  setShow: (show: boolean) => void
  programId?: string | string[] | number
}

const DrawerBuktiKaryaConfig = ({
  show,
  setShow,
  programId,
}: DrawerBuktiKaryaConfigProps) => {
  const { formProps, drawerProps, saveButtonProps } = useDrawerForm<
    {},
    HttpError,
    BuktiKaryaConfig
  >({
    action: 'edit',
    dataProviderName: 'lms',
    id: 'bukti-karya',
    resource: `programs/${programId}`,
    queryOptions: {
      enabled: show,
    },
    redirect: false,
    onMutationSuccess: () => {
      formProps?.form.resetFields()
      setShow(false)
    },
    successNotification: {
      message: 'Konfigurasi Bukti Karya berhasil disimpan',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (e) =>
      showErrorNotification(
        e,
        'Terdapat gangguan saat menyimpan konfigurasi bukti karya',
      ),
  })
  return (
    <Drawer
      {...drawerProps}
      visible={show}
      onClose={() => {
        formProps?.form.resetFields()
        setShow(false)
      }}
      destroyOnClose
      maskClosable={false}
      extra={
        <Space>
          <Button
            {...saveButtonProps}
            icon={<Icons.SaveOutlined />}
            type="primary"
          >
            Simpan
          </Button>
        </Space>
      }
      title="Pengaturan Bukti Karya"
      width="50%"
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues?.data,
          periode: [
            formProps?.initialValues?.data?.startDate,
            formProps?.initialValues?.data?.endDate,
          ],
          categories: formProps?.initialValues?.data?.categories.length
            ? formProps?.initialValues?.data?.categories
            : MAP_ACTUAL_BK_CATEGORY.map(({ label, value }) => ({
                id: value,
                name: label,
              })),
        }}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ form, periode, categories, ...rest }) => {
          formProps.onFinish?.({
            ...rest,
            formId: form!.id,
            startDate: dayjs(periode![0]).format('YYYY-MM-DD'),
            endDate: dayjs(periode![1]).format('YYYY-MM-DD'),
            categories:
              categories?.map(({ id }: { id: string; name: string }) => ({
                id,
                name:
                  MAP_ACTUAL_BK_CATEGORY.find(({ value }) => id === value)
                    ?.label || '',
              })) || [],
          })
        }}
      >
        <Form.Item name="title" label="Judul" rules={[{ required: true }]}>
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Deskripsi"
          required
          rules={[
            {
              validator: async (_, value) => {
                if (value === '' || !value || isHtmlTagEmpty(value))
                  return Promise.reject('Page content must be filled')
              },
            },
          ]}
        >
          <ContentForm
            index="description"
            showTitle={false}
            showDeleteButton={false}
            showIndentation={false}
            showHeader
            showImage
          />
        </Form.Item>
        <Form.Item
          label="Periode Bukti Karya"
          name="periode"
          rules={[
            { required: true },
            {
              validator(_, value) {
                if (!value || !value[0] || !value[1]) {
                  return Promise.reject(new Error('${label} harus diisi.'))
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(dates) => ({
            value: dates
              ? [
                  dates[0] ? dayjs(dates[0]) : undefined,
                  dates[1] ? dayjs(dates[1]) : undefined,
                ]
              : [],
          })}
          getValueFromEvent={(date) => {
            if (!date) return [null, null]
            const [startDate, endDate]: [Dayjs, Dayjs] = date
            return [startDate.tz().toISOString(), endDate.tz().toISOString()]
          }}
        >
          <DatePicker.RangePicker
            format="DD MMM YYYY"
            locale={locale.DatePicker}
            allowEmpty={[false, false]}
            showTime={false}
          />
        </Form.Item>
        <Form.Item
          label="Form Penilaian"
          name="form"
          rules={[{ required: true }]}
        >
          <FormSelectComponent
            defaultFormType={'LIKERT'}
            formTypeOptions={[
              {
                label: 'Likert',
                value: 'LIKERT',
              },
              {
                label: 'Quantitative',
                value: 'QUANTITATIVE',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="minimumScore"
          label="Nilai Minimal Kelulusan"
          rules={[{ required: true }]}
        >
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item
          name="requiredForCertificate"
          label="Wajib untuk menerbitkan sertifikat"
          rules={[{ required: true }]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={true}>Ya</Radio.Button>
            <Radio.Button value={false}>Tidak</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Kategori Aktif"
          name="categories"
          rules={[{ required: true, message: 'Kategori aktif harus diisi.' }]}
          getValueProps={(values) => {
            return {
              value:
                values?.map(({ id }: { id: string; name: string }) => ({
                  value: id,
                  label: MAP_ACTUAL_BK_CATEGORY.find(
                    ({ value }) => id === value,
                  )?.label,
                })) || [],
            }
          }}
          getValueFromEvent={(values) => {
            return values.map(
              ({ label, value }: { label: string; value: number }) => ({
                id: value,
                name: label,
              }),
            )
          }}
        >
          <Select
            options={MAP_ACTUAL_BK_CATEGORY}
            labelInValue
            mode="multiple"
            allowClear
          />
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default DrawerBuktiKaryaConfig
