import {
  Table,
  TextField,
  useTable,
  Button,
  Form,
  // Input,
  useModalForm,
  useSelect,
  Select,
  Tooltip,
  Tag,
  Dropdown,
  Card,
  Modal,
  DatePicker,
  Input,
  Icons,
  Descriptions,
} from '@pankod/refine-antd'
import {
  CrudFilters,
  useCustomMutation,
  useGetIdentity,
  useInvalidate,
  useOne,
} from '@pankod/refine-core'
import { useState } from 'react'
import dayjs from 'dayjs'

import ModalParseCSV from '@components/ModalParseCSV'
import {
  showErrorNotification,
  useGetLptkId,
} from '@resources/angkatan-ppg-management/utils'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'
import { CmsPpgLptkStudentData } from '@apis/model/types'
import {
  getMatrikulasiStatus,
  getRegisteredMatrikulasiStatus,
} from '@resources/basic-ppg-management/utils/matrikulasi_status_map'
type MahasiswaListProps = {
  lptkId?: string | number
  isMasterData?: boolean
  enableFilter?: boolean
}

export const MahasiswaList = (props: MahasiswaListProps) => {
  const [lptkId] = useGetLptkId()
  const { data } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const invalidate = useInvalidate()
  const currentLPTKId = props.lptkId || lptkId
  const [formPreviewImgUrl, setFormPreviewImgUrl] = useState<string>('')

  const { mutateAsync: doSubmit } = useCustomMutation()
  const [selectedMahasiswa, setSelectedMahasiswa] = useState<
    CmsPpgLptkStudentData & {
      resignDate?: string
      resignCategory?: string
      resignReason?: string
      resignFileUrl?: string
    }
  >()

  const [modalMahasiswaDetailOpen, setModalMahasiswaDetailOpen] =
    useState(false)

  const { mutate } = useCustomMutation()

  const { isMasterData = false } = props
  const [currentTableLptkId, setCurrentTableLptkId] = useState(currentLPTKId)

  const { tableProps, searchFormProps, setFilters } = useTable({
    syncWithLocation: true,
    resource: `lptk/${currentTableLptkId}/student`,
    dataProviderName: 'lms',
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { lptkId: lptkIdFilter, batchYear } = params || {}
      setCurrentTableLptkId(lptkIdFilter || currentLPTKId)
      // filters.push({
      //   field: 'studentName',
      //   operator: 'eq',
      //   value: name,
      // })

      // filters.push({
      //   field: 'lptkId',
      //   operator: 'eq',
      //   value: lptkIdFilter || currentLPTKId,
      // })

      filters.push({
        field: 'angkatanId',
        operator: 'eq',
        value: batchYear,
      })
      return filters
    },
  })

  const { selectProps: AngkatanSelectProps } = useSelect({
    resource: `angkatan`,
    dataProviderName: 'lms',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
    queryOptions: {
      enabled: isMasterData,
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
  })

  const {
    modalProps: bulkCreateModalProps,
    formProps: bulkCreateFormProps,
    submit: bulkCreateSubmit,
    show: bulkCreateShow,
    close: bulkCreateClose,
  } = useModalForm({
    action: 'create',
    resource: `lptk/${currentLPTKId}/peserta/sekolah`,
    submit: (formValue) => {
      return {
        data: formValue.data,
      }
    },
    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${currentTableLptkId}/student`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },

    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was an error when adding peserta sekolah',
      ),

    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  const handleDownloadData = async () => {
    await mutate({
      // @ts-ignore hack to mutate get
      method: 'get',
      url: `${
        process.env.LMS_API_URL
      }/peserta/download?lptkId=${currentLPTKId}&angkatanId=${searchFormProps.form?.getFieldValue(
        'batchYear',
      )}&'`,
      params: {
        lptkId: currentLPTKId,
        angkatanId: searchFormProps.form?.getFieldValue('batchYear'),
      },

      dataProviderName: 'lms',
      successNotification(data: any) {
        data && downloadTemplate(data?.data as string)

        return {
          message: 'Memulai Download Data',
          description: 'Memulai proses download',
          type: 'success',
        }
      },
    })
  }

  const { data: kvData } = useOne<any>({
    resource: 'kv?key=lms_ppg',
    id: '',
    dataProviderName: 'microlearning',
  })
  const { undurDiriOptions = [] } = (kvData?.data?.data as any) || {}

  const MAHASISWA_STATUS_MAP: any = {
    TERDAFTAR: 'Terdaftar',
    TIDAK_LAPOR_DIRI: 'Tidak Lapor Diri',
    MENGUNDURKAN_DIRI: 'Mengundurkan Diri',
    EMPTY: 'Belum Diketahui',
  }

  const csvHeaders = ['pesertaId', 'angkatanId', 'npsn', 'namaSekolah']

  const userCanAddStatus = ['super-admin', 'admin-pusat'].includes(LMSRole)

  if (userCanAddStatus) {
    // @ts-ignore
    csvHeaders.push({
      name: 'status',
      optional: true,
    })
  }

  const {
    modalProps: forfeitFormModalProps,
    formProps: forfeitFormProps,
    submit: forfeitFormSubmit,
    show: forfeitFormShow,
    close: forfeitFormClose,
  } = useModalForm({
    action: 'edit',
    resource: `lptk/${currentLPTKId}/student/${selectedMahasiswa?.id}/undur-diri`,
    submit: (formValue) => {
      return formValue
    },
    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${currentTableLptkId}/student`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },

    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  const formImage = Form.useWatch('forfeitFileUrl', forfeitFormProps?.form)

  const handleUploadImage = (e: any, key: string) => {
    const file = e.target.files?.[0]
    forfeitFormProps?.form?.setFieldsValue({
      [key]: file,
    })

    setFormPreviewImgUrl('/img/static/pdf-icon.png')
  }

  const handleFormSubmit = async (
    isCancellation = false,
    pesertaId: any = null,
  ) => {
    await forfeitFormProps.form.validateFields()
    const data = forfeitFormProps.form.getFieldsValue()
    const formData = new FormData()

    formData.append(
      'peserta_id',
      (selectedMahasiswa?.id as string) || pesertaId,
    )
    formData.append('lptk_id', currentLPTKId as string)
    if (!isCancellation) {
      formData.append('reason', data.forfeitReason)
      formData.append(
        'resign_date',
        dayjs(data.forfeitDate).format('YYYY-MM-DD'),
      )
      formData.append('category', data.forfeitCategory)
      formData.append('attachment', data.forfeitFileUrl)
    }

    return doSubmit(
      {
        url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/peserta/resign`,
        method: 'post',
        values: formData,
        config: {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
        successNotification: () => {
          return {
            message: 'Data Peserta Berhasil di update',
            type: 'success',
            description: 'Sukses',
          }
        },
        errorNotification: (error: any) =>
          showErrorNotification(
            error,
            'There was a problem when uploading payment proof',
          ),
      },
      {
        onSuccess: () => {
          forfeitFormClose()
          setFormPreviewImgUrl('')
          setSelectedMahasiswa(undefined)
          forfeitFormProps?.form?.resetFields()
          invalidate({
            resource: `lptk/${currentTableLptkId}/student`,
            dataProviderName: 'lms',
            invalidates: ['list'],
          })
        },
      },
    )
  }

  return (
    <div title={'List Mahasiswa'} className="flex flex-col">
      <div className="flex flex-row justify-between items-center mb-4">
        {isMasterData ? (
          <>
            <Form {...searchFormProps} layout="vertical">
              <div className="flex flex-row gap-4 items-end">
                <Form.Item
                  name="batchYear"
                  className="mr-8 w-[30vw]"
                  label="Angkatan"
                >
                  <Select
                    {...AngkatanSelectProps}
                    allowClear
                    placeholder="Pilih Angkatan"
                    style={{
                      width: '100%',
                    }}
                    onChange={(_e) => {
                      searchFormProps?.form?.setFieldsValue({
                        batchYear: _e,
                      })

                      return searchFormProps?.form?.submit()
                    }}
                  />
                </Form.Item>

                {props.enableFilter && (
                  <Form.Item name="email">
                    <Input
                      prefix={<Icons.SearchOutlined className="mr-4" />}
                      onPressEnter={(e) => {
                        setFilters([
                          {
                            field: 'email',
                            operator: 'eq',
                            // @ts-ignore
                            value: e.target.value,
                          },
                        ])
                      }}
                      placeholder="Cari nama atau email"
                      autoComplete="off"
                      allowClear
                    />
                  </Form.Item>
                )}
              </div>
            </Form>
          </>
        ) : (
          <Form layout="inline">
            {props.enableFilter && (
              <Form.Item name="email">
                <Input
                  prefix={<Icons.SearchOutlined className="mr-4" />}
                  onPressEnter={(e) => {
                    setFilters([
                      {
                        field: 'email',
                        operator: 'eq',
                        // @ts-ignore
                        value: e.target.value,
                      },
                    ])
                  }}
                  placeholder="Cari nama atau email"
                  autoComplete="off"
                  allowClear
                />
              </Form.Item>
            )}
          </Form>
        )}

        <div className="flex flex-row">
          {isMasterData && (
            <>
              <Tooltip title="Pilih Filter Angkatan terlebih dahulu untuk mendownload">
                <Button
                  className="mr-4"
                  disabled={!searchFormProps.form?.getFieldValue('batchYear')}
                  onClick={handleDownloadData}
                >
                  Download Data
                </Button>
              </Tooltip>

              <Button className="mr-4" onClick={bulkCreateShow}>
                Data Lokasi PPL
              </Button>
            </>
          )}
        </div>
      </div>
      <Table
        {...tableProps}
        rowKey="pesertaId"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        <Table.Column
          title="Status"
          dataIndex="status"
          render={(value, record: any) => (
            // TODO: add color coded status
            <Tag color={record.resignDate ? 'red' : 'blue'}>
              {record.resignDate
                ? 'MENGUNDURKAN DIRI'
                : MAHASISWA_STATUS_MAP[value || 'EMPTY']}
            </Tag>
          )}
        />

        <Table.Column
          dataIndex="name"
          title="Nama"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="email"
          title="Email"
          render={(value) => <TextField value={value} />}
        />
        {!isMasterData && (
          <Table.Column
            dataIndex="encodedId"
            title="encoded ID"
            render={(value) => <TextField value={value} />}
          />
        )}
        <Table.Column
          dataIndex="phone"
          title="No. Hp"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          width={250}
          dataIndex="studyField"
          title="Bidang Studi"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="npsn"
          title="NPSN"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="schoolName"
          title="Sekolah"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="batchName"
          title="Angkatan"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="isMatrikulasi"
          title="Pendaftaran Matrikulasi"
          render={(value) => (
            <Tag color={getRegisteredMatrikulasiStatus(value).color}>
              {getRegisteredMatrikulasiStatus(value).text}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex="matrikulasi"
          title="Status Matrikulasi"
          render={(value) => (
            <Tag color={getMatrikulasiStatus(value).color}>
              {getMatrikulasiStatus(value).text}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex="kategoriMatrikulasi"
          title="Kategori Matrikulasi"
          render={(value) => <Tag>{value}</Tag>}
        />
        <Table.Column
          dataIndex="lulusan"
          title="Lulusan"
          render={(value) => <Tag>{value}</Tag>}
        />

        <Table.Column
          dataIndex="action"
          title="Action"
          render={(_value, record: any) => (
            <Dropdown
              overlay={
                <Card bodyStyle={{ padding: 8, borderRadius: '20%' }}>
                  <div className="flex flex-col gap-2">
                    <Button
                      onClick={async () => {
                        setSelectedMahasiswa(record)
                        if (!record.resignDate) {
                          forfeitFormShow()
                        } else {
                          await forfeitFormSubmit()
                          forfeitFormProps && handleFormSubmit(true, record.id)
                        }
                      }}
                    >
                      {record.resignDate ? 'Batalkan Undur diri' : 'Undur diri'}
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedMahasiswa(record)
                        setModalMahasiswaDetailOpen(true)
                      }}
                    >
                      Profil Peserta
                    </Button>
                  </div>
                </Card>
              }
            >
              <Button>...</Button>
            </Dropdown>
          )}
        />
      </Table>

      <ModalParseCSV
        modalProps={bulkCreateModalProps}
        formProps={bulkCreateFormProps}
        onClose={bulkCreateClose}
        onSubmit={bulkCreateSubmit}
        validCSVHeaders={csvHeaders}
      />

      <Modal
        title="Detail Profil Mahasiswa"
        visible={modalMahasiswaDetailOpen}
        onCancel={() => setModalMahasiswaDetailOpen(false)}
        footer={null}
      >
        <Descriptions
          column={1}
          title="Informasi Mahasiswa"
          bordered
          className="mb-6"
        >
          {/* show in description for selectedMahasiswa data */}
          <Descriptions.Item label="Nama">
            {selectedMahasiswa?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {selectedMahasiswa?.email}
          </Descriptions.Item>
          <Descriptions.Item label="No. Hp">
            {selectedMahasiswa?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Bidang Studi">
            {selectedMahasiswa?.studyField}
          </Descriptions.Item>
          <Descriptions.Item label="NPSN">
            {selectedMahasiswa?.npsn}
          </Descriptions.Item>
          <Descriptions.Item label="Sekolah">
            {selectedMahasiswa?.schoolName}
          </Descriptions.Item>
          <Descriptions.Item label="Angkatan">
            {selectedMahasiswa?.batchName}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {MAHASISWA_STATUS_MAP[selectedMahasiswa?.status || 'EMPTY']}
          </Descriptions.Item>
        </Descriptions>

        {selectedMahasiswa?.resignDate && (
          <Descriptions
            column={1}
            title="Informasi Undur Diri"
            bordered
            className="mb-6"
          >
            <Descriptions.Item label="Tanggal Undur Diri">
              {selectedMahasiswa?.resignDate &&
                dayjs(selectedMahasiswa?.resignDate).format('DD-MM-YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label="Kategori Undur Diri">
              {selectedMahasiswa?.resignCategory}
            </Descriptions.Item>

            <Descriptions.Item label="Alasan Undur Diri">
              {selectedMahasiswa?.resignReason}
            </Descriptions.Item>
            <Descriptions.Item label="Bukti Undur Diri">
              <a
                href={selectedMahasiswa?.resignFileUrl}
                target="_blank"
                rel="noreferrer"
              >
                Lihat Bukti Undur Diri
              </a>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>

      <Modal
        {...forfeitFormModalProps}
        title="Mahasiswa Undur Diri"
        onCancel={() => {
          forfeitFormClose()
          // reset form
          forfeitFormProps?.form?.resetFields()
        }}
        {...(forfeitFormSubmit && {
          okButtonProps: {
            onClick: async () => {
              await forfeitFormSubmit()
              forfeitFormProps && handleFormSubmit()
            },
            disabled: !formImage,
          },
        })}
      >
        <Form
          {...forfeitFormProps}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Tanggal Undur Diri"
            name="forfeitDate"
            rules={[
              {
                required: selectedMahasiswa?.resignDate ? false : true,
                message: 'Tanggal Undur Diri tidak boleh kosong',
              },
            ]}
          >
            <DatePicker format={'DD-MM-YYYY'} />
          </Form.Item>

          <Form.Item
            label="Kategori Undur Diri"
            name="forfeitCategory"
            rules={[
              {
                required: selectedMahasiswa?.resignDate ? false : true,
                message: 'Kategori Undur Diri tidak boleh kosong',
              },
            ]}
          >
            <Select
              options={undurDiriOptions}
              placeholder="Pilih Kategori Undur Diri"
            />
          </Form.Item>

          <Form.Item
            label="Alasan Undur Diri"
            name="forfeitReason"
            rules={[
              {
                required: selectedMahasiswa?.resignDate ? false : true,
                message: 'Alasan Undur Diri tidak boleh kosong',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name={'forfeitFileUrl'}
            hidden
            rules={[
              {
                required: selectedMahasiswa?.resignDate ? false : true,
                message: 'Bukti Undur Diri tidak boleh kosong',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="grid grid-cols-6 gap-4 mb-4">
            <Input
              type="file"
              hidden
              id="forfeit-image-upload"
              onChange={(e) => handleUploadImage(e, 'forfeitFileUrl')}
              // pdf only
              accept=".pdf"
            />

            <div className="col-start-3">
              {formImage && (
                <div className="rounded border mb-4">
                  <img
                    alt="Bukti Undur Diri"
                    src={formPreviewImgUrl}
                    className="w-full aspect-square object-contain"
                  />
                </div>
              )}

              {formImage && <span className="mb-4">{formImage?.name}</span>}

              <Button
                className="mt-4"
                style={{ transition: 'all ease 0.3s' }}
                onClick={() => {
                  document &&
                    document.getElementById('forfeit-image-upload')?.click()
                }}
              >
                <div>
                  <Icons.PlusOutlined />
                  <div style={{ marginTop: 8 }}>Unggah Bukti Undur Diri</div>
                </div>
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
