import React from 'react'
import {
  GetListResponse,
  GetOneResponse,
  useDelete,
  useInvalidate,
} from '@pankod/refine-core'
import {
  Alert,
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Icons,
  InputNumber,
  Popconfirm,
  Radio,
  Typography,
  useForm,
  useModalForm,
} from '@pankod/refine-antd'

import { TLMSClass } from '../class/types'
import { TCommonResponse } from 'src/interfaces/common'
import { APIListMeta } from 'types/api'
import { LMSClassGroup } from './types'
import { ClassGroupModalForm } from './blocks/ClassGroupModal'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type AlertInfoProps = {
  plottedClass: number
  totalClass: number
}
const AlertInfo = ({ plottedClass, totalClass }: AlertInfoProps) =>
  plottedClass === totalClass ? (
    <Typography.Text>
      Seluruh kelas{' '}
      <strong>({`${plottedClass} dari total ${totalClass}`} kelas)</strong>{' '}
      telah dikelompokkan.
    </Typography.Text>
  ) : (
    <Typography.Text>
      Baru <strong>{`${plottedClass} dari total ${totalClass}`}</strong> kelas
      yang harus dikelompokkan.
    </Typography.Text>
  )

const LABEL_TEXT =
  'Anda harus mengelompokkan seluruh Kelas LMS ke dalam Kelompok Kelas Webinar'

type LMSClassGroupCreateProps = {
  vacantClassData?: GetListResponse<Pick<TLMSClass, 'id' | 'name'>>
  classGroupData?: GetOneResponse<
    TCommonResponse<
      LMSClassGroup[],
      APIListMeta & {
        finalized: boolean
      }
    >
  >
  totalClass: number
  programLocalId?: number | string | string[]
  loading: boolean
}
export const LMSClassGroupCreate = ({
  vacantClassData,
  classGroupData,
  totalClass,
  programLocalId,
  loading,
}: LMSClassGroupCreateProps) => {
  const invalidateClassGroup = useInvalidate()
  const invalidateVacantClass = useInvalidate()

  const invalidates = () => {
    invalidateClassGroup({
      resource: `local-programs/${programLocalId}`,
      id: 'class-group?pageSize=-1',
      invalidates: ['detail'],
    })
    invalidateVacantClass({
      resource: `local-programs/${programLocalId}/vacant-class`,
      invalidates: ['list'],
    })
  }

  const { formProps, formLoading } = useForm({
    action: 'create',
    resource: 'class-group/finalize',
    redirect: false,
    onMutationSuccess: () => {
      invalidates()
    },
    queryOptions: {
      enabled: false,
    },
    successNotification: {
      message: 'Kelompok kelas successfully finalized',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was a problem when finalizing kelompok kelas',
      ),
  })
  const { mutateAsync: deleteClassGroup, isLoading: deleteLoading } =
    useDelete()

  const doDelete = async (id: number) => {
    await deleteClassGroup({
      id,
      resource: 'class-group',
      successNotification: {
        message: 'Kelompok kelas successfully deleted',
        type: 'success',
        description: 'Successful',
      },
      errorNotification: (err) =>
        showErrorNotification(
          err,
          'There was a problem when deleting kelompok kelas',
        ),
    })
    invalidates()
  }

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm({
    action: 'create',
    resource: `local-programs/${programLocalId}/class-group`,
    warnWhenUnsavedChanges: true,
    redirect: false,
    autoResetForm: true,
    autoSubmitClose: true,
    invalidates: ['list'],
    onMutationSuccess: () => {
      invalidates()
    },
    successNotification: {
      message: 'Kelompok kelas successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was a problem when creating kelompok kelas',
      ),
  })

  const classGroups = classGroupData?.data.data || []
  const isVacantClassAvailable = vacantClassData && vacantClassData.total > 0
  const plottedClass = classGroups.reduce(
    (total, { totalClass }) => total + totalClass,
    0,
  )
  return (
    <>
      <Card
        title={
          <>
            <Typography.Title level={3}>
              Pengelompokan Kelas Webinar
            </Typography.Title>
            <Typography.Text type="secondary" title={LABEL_TEXT}>
              {LABEL_TEXT}
            </Typography.Text>
          </>
        }
        bordered={false}
        loading={loading}
      >
        {classGroups.map(({ id, name, totalClass }) => (
          <Card.Grid key={id} hoverable={false} className="!shadow-none">
            <Descriptions
              title={
                <Typography.Text ellipsis className="p-0 m-0 cla" title={name}>
                  {name}
                </Typography.Text>
              }
              bordered
              extra={
                <Popconfirm
                  title="Anda yakin ingin menghapus Kelompok Kelas Webinar ini?"
                  onConfirm={() => doDelete(id)}
                  okButtonProps={{
                    loading: deleteLoading,
                  }}
                  okText="Hapus"
                  cancelText="Batal"
                >
                  <Button
                    icon={<Icons.DeleteOutlined />}
                    danger
                    size="small"
                    disabled={formLoading}
                  />
                </Popconfirm>
              }
            >
              <Descriptions.Item label="Jumlah Kelas">
                {totalClass} Kelas
              </Descriptions.Item>
            </Descriptions>
          </Card.Grid>
        ))}
        {isVacantClassAvailable && (
          <Card.Grid
            key="new"
            className="text-center !shadow-none flex items-end"
          >
            <Button
              type="primary"
              ghost
              className="w-full !h-14"
              icon={<Icons.PlusOutlined />}
              size="large"
              onClick={() => createShow()}
            >
              Buat Kelompok Baru
            </Button>
          </Card.Grid>
        )}
      </Card>
      {!loading && (
        <>
          <Divider orientation="left">Finalisasi Kelompok Kelas</Divider>
          <Alert
            message={
              <AlertInfo plottedClass={plottedClass} totalClass={totalClass} />
            }
            showIcon
            type={!isVacantClassAvailable ? 'info' : 'warning'}
          />
          <Form
            {...formProps}
            layout="vertical"
            className="!mt-4"
            disabled={isVacantClassAvailable}
          >
            <Form.Item
              name="programLocalId"
              hidden
              initialValue={programLocalId}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="timezone"
              label="Zona Waktu"
              required
              rules={[{ required: true, message: 'Zona waktu harus dipilih.' }]}
            >
              <Radio.Group disabled={isVacantClassAvailable}>
                <Radio.Button value="wib">WIB</Radio.Button>
                <Radio.Button value="wita">WITA</Radio.Button>
                <Radio.Button value="wit">WIT</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<Icons.CheckOutlined />}
              loading={formLoading}
            >
              Finalisasi Kelompok Kelas
            </Button>
          </Form>
        </>
      )}

      <ClassGroupModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        onClose={createClose}
        vacantClasses={vacantClassData?.data || []}
      />
    </>
  )
}
