import {
  Button,
  Drawer,
  Icons,
  Space,
  Table,
  TextField,
  useModalForm,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useDelete } from '@pankod/refine-core'
import dayjs from 'dayjs'

import { PaketModulRequest, PaketModulResponse } from './types'
import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import { PopDeleteConfirm } from '@components/PopDeleteConfirm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import ModalForm from './ModalForm'

export const usePaketModulConfigDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
  }>({ show: false, programId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, programId: id })
    },
    visible: !!showId.show,
    id: showId.programId,
    onClose,
  }
}

type PaketModulDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
}

const PaketModulDrawer = ({ id, onClose, visible }: PaketModulDrawerProps) => {
  const { tableProps } = useTableWithMeta<PaketModulResponse, TCommonError>({
    resource: `programs/${id}/paket-modul`,
    dataProviderName: 'lms',
    queryOptions: { enabled: visible && !!id },
  })
  const { mutateAsync: doDelete } = useDelete()
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<{}, TLMSCommonError, PaketModulRequest>({
    action: 'create',
    resource: `programs/${id}/paket-modul`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Paket Modul berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan saat membuat paket modul',
      ),
  })
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<{}, TLMSCommonError, PaketModulRequest>({
    action: 'edit',
    resource: `programs/${id}/paket-modul`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Paket Modul berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan saat mengubah paket modul',
      ),
  })

  return (
    <Drawer
      title="Daftar Paket Modul"
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="50%"
      extra={
        <Button
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => {
            createShow()
          }}
        >
          Tambah Paket Modul
        </Button>
      }
    >
      <Table
        {...tableProps}
        rowKey={'id'}
        bordered
        tableLayout="fixed"
        scroll={{ x: 300 }}
        columns={[
          { title: 'ID', dataIndex: 'id', width: 80 },
          { title: 'Nama Paket Modul', dataIndex: 'name', width: 200 },
          {
            title: 'Jumlah Sesi Webinar Maksimal',
            dataIndex: 'maxWebinarSessions',
            width: 150,
          },
          {
            title: 'Periode Timespend',
            children: [
              {
                title: 'Mulai',
                dataIndex: 'startAt',
                width: 120,
                render: (value) =>
                  value ? (
                    dayjs(value).format('DD MMM YYYY')
                  ) : (
                    <TextField
                      type="secondary"
                      italic
                      value="Tidak ada tanggal"
                    />
                  ),
              },
              {
                title: 'Akhir',
                dataIndex: 'endAt',
                width: 120,
                render: (value) =>
                  value ? (
                    dayjs(value).format('DD MMM YYYY')
                  ) : (
                    <TextField
                      type="secondary"
                      italic
                      value="Tidak ada tanggal"
                    />
                  ),
              },
            ],
          },
          {
            title: 'Prioritas Urutan',
            dataIndex: 'sortPriority',
            width: 100,
          },
          {
            width: 125,
            fixed: 'right',
            render: (record) => (
              <Space direction="vertical">
                <Button
                  icon={<Icons.EditOutlined />}
                  block
                  onClick={() => editShow(record.id)}
                >
                  Ubah
                </Button>
                <PopDeleteConfirm
                  okText="Hapus"
                  cancelText="Batal"
                  okButtonProps={{ danger: true }}
                  title="Apakah Anda yakin ingin menghapus paket modul ini?"
                  cancelButtonProps={{
                    id: `cancel-delete-${record.id}`,
                  }}
                  onConfirm={async () =>
                    doDelete({
                      id: record.id,
                      resource: `programs/${id}/paket-modul`,
                      dataProviderName: 'lms',
                      invalidates: ['list'],
                      successNotification: {
                        message: 'Paket modul berhasil dihapus',
                        type: 'success',
                        description: 'Sukses',
                      },
                      errorNotification: (error: unknown) => {
                        // close the drawer
                        document
                          .getElementById(`cancel-delete-${record.id}`)
                          ?.click()

                        return showErrorNotification(
                          error,
                          'Terdapat gangguan saat menghapus paket modul',
                        )
                      },
                    })
                  }
                >
                  <Button block danger icon={<Icons.DeleteOutlined />}>
                    Hapus
                  </Button>
                </PopDeleteConfirm>
              </Space>
            ),
          },
        ]}
      />
      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />
      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
    </Drawer>
  )
}

export default PaketModulDrawer
