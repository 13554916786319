import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Canvas, TEvent, TPointerEvent } from 'fabric'
import { Collapse, Empty, Icons, Popover } from '@pankod/refine-antd'
import { isMacOs } from 'react-device-detect'
import { FabricObject } from 'fabric/dist/fabric'
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { LoaderPinwheel, Menu } from 'lucide-react'
import clsx from 'clsx'

import {
  CUSTOM_CERTIFICATE_TAB_CHANGE,
  CUSTOM_FABRIC_ORDER_EVENT,
  orderObjectEvent,
} from '../../utils/events'
import OrderNavigatorItem from './OrderNavigatorItem'

type OrderNavigatorProps = {
  loading?: boolean
  canvas: Canvas | null
  currentStack: {
    version: string
    objects: any[]
  }
}

export const OrderNavigator = (props: OrderNavigatorProps) => {
  const listContainerRef = useRef<HTMLDivElement>(null)
  const [canvasObjList, setCanvasObjList] = useState(
    props.canvas?.getObjects().toReversed() || [],
  )
  const [activeObjectCustomId, setActiveObjectCustomId] = useState<
    string | null
  >(null)

  const handleRefreshObjectList = useCallback(() => {
    if (!props.canvas) return
    setCanvasObjList(props.canvas.getObjects().toReversed())
  }, [props.canvas])

  const handleSelected = useCallback(
    (
      el: Partial<TEvent<TPointerEvent>> & {
        selected?: FabricObject[]
        deselected?: FabricObject[]
      },
    ) => {
      if ((el.selected?.length || 0) === 1) {
        const s = el.selected![0]
        handleRefreshObjectList()
        setActiveObjectCustomId(s.customId!)
        const itemElement = listContainerRef.current?.querySelector(
          '.certificate-template__order-navigator-item--selected',
        )
        itemElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        })
        return
      }
      setActiveObjectCustomId(null)
    },
    [handleRefreshObjectList],
  )

  useEffect(() => {
    document.addEventListener(
      CUSTOM_FABRIC_ORDER_EVENT,
      handleRefreshObjectList,
    )
    document.addEventListener(
      CUSTOM_CERTIFICATE_TAB_CHANGE,
      handleRefreshObjectList,
    )
    props.canvas?.on('selection:created', handleSelected)
    props.canvas?.on('selection:updated', handleSelected)
    props.canvas?.on('selection:cleared', handleSelected)
    props.canvas?.on('object:added', handleRefreshObjectList)
    props.canvas?.on('object:removed', handleRefreshObjectList)
    return () => {
      props.canvas?.off('selection:created', handleSelected)
      props.canvas?.off('selection:updated', handleSelected)
      props.canvas?.off('selection:cleared', handleSelected)
      props.canvas?.off('object:added', handleRefreshObjectList)
      props.canvas?.off('object:removed', handleRefreshObjectList)
      document.removeEventListener(
        CUSTOM_FABRIC_ORDER_EVENT,
        handleRefreshObjectList,
      )
      document.removeEventListener(
        CUSTOM_CERTIFICATE_TAB_CHANGE,
        handleRefreshObjectList,
      )
    }
  }, [handleRefreshObjectList, handleSelected, props.canvas])

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      const movedObject = canvasObjList.find(
        ({ customId }) => customId === active.id,
      )!
      const newIndex = canvasObjList.findIndex(
        ({ customId }) => customId === over?.id,
      )
      props.canvas?.moveObjectTo(
        movedObject,
        canvasObjList.length - newIndex - 1,
      )
      document.dispatchEvent(orderObjectEvent)
    }
  }

  return (
    <Collapse
      className={clsx(
        'absolute top-[570px] w-[250px] right-8 z-20 shadow !rounded select-none',
        'peer-[.canvas-grab]:*:*:pointer-events-none',
        'peer-[.canvas-grab]:!cursor-not-allowed',
        'peer-[.canvas-grab]:*:opacity-50',
      )}
      defaultActiveKey={'layer'}
    >
      <Collapse.Panel
        header="Daftar Komponen"
        key="layer"
        className="certificate-template__order-navigator-header"
        extra={
          <div className="flex justify-center items-center h-[22px]">
            <Popover
              title="Atur urutan secara vertikal"
              arrowPointAtCenter
              placement="topRight"
              content={
                <>
                  tahan dan tarik icon <Menu className="h-3 w-3" />, atau pilih
                  item lalu:
                  <ul className="list-disc p-0 ml-4">
                    <li>
                      <kbd className="shadow-sm rounded p-1">
                        {isMacOs ? 'cmd' : 'ctrl'}
                      </kbd>{' '}
                      + <kbd className="shadow-sm rounded p-1">&#8593;</kbd>{' '}
                      bawa ke depan/atas
                    </li>
                    <li>
                      <kbd className="shadow-sm rounded p-1">
                        {isMacOs ? 'cmd' : 'ctrl'}
                      </kbd>{' '}
                      + <kbd className="shadow-sm rounded p-1">&#8595;</kbd>{' '}
                      bawa ke belakang/bawah
                    </li>
                  </ul>
                </>
              }
            >
              <Icons.InfoCircleOutlined />
            </Popover>
          </div>
        }
      >
        <div className="order-navigator relative">
          <div
            ref={listContainerRef}
            className="flex flex-col h-[180px] overflow-y-auto"
          >
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToFirstScrollableAncestor]}
            >
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={
                  canvasObjList.map(({ customId }, i) => customId || i) || []
                }
              >
                {canvasObjList?.length ? (
                  <>
                    {canvasObjList.map((item, index) => (
                      <OrderNavigatorItem
                        activeObjectCustomId={activeObjectCustomId}
                        canvas={props.canvas}
                        customId={item.customId || index}
                        item={item}
                        key={item.customId || index}
                      />
                    ))}
                    {props.loading && (
                      <Empty
                        description={''}
                        className="absolute opacity-70 z-10 h-full w-full flex justify-center items-center -left-2 top-0 bottom-0 bg-white"
                        image={
                          <LoaderPinwheel className="w-20 h-20 animate-spin" />
                        }
                      />
                    )}
                  </>
                ) : (
                  <Empty
                    description={'Tidak ada komponen'}
                    className="!mt-16"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}
