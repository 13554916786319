import { CANVAS_PREVIEW_DOM_ID } from '../constants'
import { createCanvas } from './canvas'

export const exportObjectToSVG = async (canvasObject: {
  version: string
  objects: any[]
}) => {
  const canvas = createCanvas(CANVAS_PREVIEW_DOM_ID['canvas-front-page'])
  await canvas.loadFromJSON({ ...canvasObject })
  let index = 0
  const usedSystemVariables: string[] = []
  const result = canvas?.toSVG(undefined, (svg) => {
    if (!svg.startsWith('<g ')) return svg
    const object: {
      customObjectType: string
      customVariableKey?: string
      textAlign?: string
      width: number
      left: number
      top: number
    } = canvasObject.objects[index]
    const {
      customObjectType: type,
      customVariableKey: key,
      width,
      textAlign: align,
      left,
      top,
    } = object
    if (key !== undefined) {
      if (type === 'Image') {
        svg = svg.replace(/(xlink:href=")[^"]*(")/, `$1{{ ${key} }}$2`)
        svg = svg.replace('<image ', `<image data-variablekey="${key}" `)
      }
      if (type === 'Text') {
        svg = svg.replace(/(<tspan[^>]*>)(.*?)(<\/tspan>)/, `$1{{ ${key} }}$3`)
        svg = svg.replace('<text ', `<text data-variablekey="${key}" `)
      }

      if (!key.startsWith('attributes[')) {
        usedSystemVariables.push(key)
      }
    }

    if (['Text', 'Textbox'].includes(type)) {
      svg = svg.replace(
        '<text ',
        `<text data-objecttype="${type}" data-objectwidth="${width}" data-objectalign="${align}" data-objectleft="${left}" data-objecttop="${top}" `,
      )

      if (align) {
        if (type === 'Text') {
          const tspanXRegex = new RegExp(/<tspan x="([^"]*)"/, 'g')
          const matchTspanX = svg.match(tspanXRegex)

          /**
           * Adjust tspan based on text align/anchor
           * middle/center -- tspan x = 0 if align center
           * end/right -- tspan x = absolute value of the initial value
           */
          if (matchTspanX) {
            for (const tspanX of matchTspanX) {
              const xRegex = /x\s*=\s*"([^"]*)"/i
              const match = tspanX.match(xRegex)
              if (match && match[1]) {
                if (align === 'center') {
                  svg = svg.replaceAll(tspanX, `<tspan x="${0}"`)
                }
                if (align === 'right') {
                  svg = svg.replaceAll(
                    tspanX,
                    `<tspan x="${Math.abs(+match[1])}"`,
                  )
                }
              }
            }
          }
        }

        /** Find the style string so that we can overwrite the style */
        const styleRegex = new RegExp(/\sstyle="([^"]*)"/, 'g')
        const matchStyle = svg.match(styleRegex)
        if (matchStyle && matchStyle[1]) {
          let oldStyle = matchStyle[1]

          /**
           * Get the old text-anchor style, then remove it first
           * so that we can add the new text-anchor style later
           */
          const anchorRegex = new RegExp(/text-anchor:([^;]*).{1}/, 'g')
          const matchAnchor = oldStyle.match(anchorRegex)
          if (matchAnchor && matchAnchor[1]) {
            oldStyle = oldStyle.replace(matchAnchor[1], '')
          }

          /**
           * After removing the previous text-anchor style (if available)
           * add the new text-anchor style, default to left/start
           */
          const anchor = ((align: string): string => {
            const map: Record<string, string> = {
              left: 'start',
              center: 'middle',
              right: 'end',
            }
            return map[align] || 'start'
          })(align)
          let styleValue = oldStyle.replace(';"', `; text-anchor: ${anchor};"`)

          /**
           * Handle special case {{ grade }} variable has dynamic fill color
           * replace `fill:rgb(0, 0, 0)` with `fill:{{ gradeColor }}`
           */
          if (key === 'grade') {
            styleValue = styleValue.replace(
              'fill: rgb(0,0,0)',
              'fill: {{ gradeColor }}',
            )
          }

          svg = svg.replace(oldStyle, styleValue)
        }
      }

      /** Handle issue text-decoration not rendered, pass text-decoration to tspan */
      const textDecorationRegex = new RegExp(/\stext-decoration="([^"]*)"/, 'g')
      const matchTextDecoration = svg.match(textDecorationRegex)
      if (matchTextDecoration) {
        svg = svg.replaceAll('<tspan', `<tspan${matchTextDecoration}`)
      }
    }

    if (type === 'Image') {
      svg = svg.replace(
        '<image ',
        `<image data-objecttype="${type}" data-objectwidth="${width}" `,
      )
    }

    index++
    return svg
  })
  await canvas.dispose()
  return { svgString: result, usedSystemVariables }
}
