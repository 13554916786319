import {
  Button,
  Divider,
  Drawer,
  Form,
  Icons,
  Input,
  InputNumber,
  Select,
  Space,
  Table,
  Tag,
  TextField,
  useModalForm,
  useSelect,
  useTable,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useDelete, useUpdate } from '@pankod/refine-core'

import { ProgramModuleRequest, ProgramModuleResponse } from './types'
import { TCommonError } from 'src/interfaces/common'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { ModalForm } from './ModalForm'
import { PROGRAM_STATE } from '@resources/lms-management/program/types'

export const useModulDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type ModulDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  name: string
  programStatus?: PROGRAM_STATE
}
const ModulDrawer = ({
  id,
  onClose,
  visible,
  name,
  programStatus,
}: ModulDrawerProps) => {
  const [editingKey, setEditingKey] = useState<string | null>(null)
  const { tableProps } = useTable<ProgramModuleResponse, TCommonError>({
    resource: `programs/${id}/program-modules`,
    dataProviderName: 'lms',
    queryOptions: { enabled: visible && !!id },
  })
  const { selectProps: selectPaketModulProps } = useSelect({
    dataProviderName: 'lms',
    resource: `programs/${id}/paket-modul`,
    optionValue: 'id',
    optionLabel: 'name',
    queryOptions: {
      enabled: editingKey !== null,
      select({ data }) {
        return {
          data,
          total: data.length,
        }
      },
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: '-1',
      },
    ],
    defaultValueQueryOptions: {
      enabled: false,
    },
  })
  const { mutateAsync: doUpdate, isLoading: isUpdating } = useUpdate()
  const { mutateAsync: doDelete } = useDelete()
  const { modalProps, formProps, show, close } = useModalForm<
    ProgramModuleResponse,
    TCommonError,
    ProgramModuleRequest
  >({
    action: 'create',
    resource: `programs/${id}/program-modules`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Modul berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(error, 'There was a problem when creating modul'),
  })
  const [form] = Form.useForm()
  const shouldDisableAction = programStatus !== PROGRAM_STATE.PREPARATION
  return (
    <Drawer
      title={`Daftar Modul | ${name}`}
      visible={visible}
      onClose={() => {
        setEditingKey(null)
        onClose()
      }}
      destroyOnClose
      width="70%"
      extra={
        <Space>
          Status Program:
          <Tag
            className="uppercase"
            color={
              programStatus !== PROGRAM_STATE.PREPARATION
                ? 'processing'
                : 'default'
            }
          >
            {programStatus}
          </Tag>
          <Divider type="vertical" />
          <Button
            type="primary"
            disabled={shouldDisableAction}
            icon={<Icons.PlusOutlined />}
            onClick={show}
          >
            Tambah Modul
          </Button>
        </Space>
      }
    >
      <Form form={form}>
        <Table {...tableProps} rowKey="id">
          <Table.Column<ProgramModuleResponse>
            title="ID"
            dataIndex="id"
            width="80px"
          />
          <Table.Column<ProgramModuleResponse>
            title="Nama Modul"
            dataIndex="name"
            width="30%"
            onCell={({ id }) => ({
              className: id === editingKey ? 'align-top' : undefined,
            })}
            render={(value, record) =>
              editingKey === record.id ? (
                <Form.Item
                  name="name"
                  validateTrigger="onChange"
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                      message: `Nama harus diisi`,
                    },
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              ) : (
                <TextField value={value} />
              )
            }
          />
          <Table.Column<ProgramModuleResponse>
            title="Paket Modul"
            dataIndex="programModuleGroupId"
            width="30%"
            onCell={({ id }) => ({
              className: id === editingKey ? 'align-top' : undefined,
            })}
            render={(_, record) => {
              return editingKey === record.id ? (
                <Form.Item
                  name="programModuleGroupId"
                  initialValue={record.programModuleGroupId}
                  validateTrigger="onChange"
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                      message: `Paket modul harus diisi`,
                    },
                  ]}
                >
                  <Select
                    options={selectPaketModulProps.options}
                    onSearch={() => {}}
                    filterOption={(input, option) =>
                      (option!.label as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              ) : (
                <TextField value={record.programModuleGroupName} />
              )
            }}
          />
          <Table.Column<ProgramModuleResponse>
            title="Prioritas Urutan"
            dataIndex="sortPriority"
            width="30%"
            onCell={({ id }) => ({
              className: id === editingKey ? 'align-top' : undefined,
            })}
            render={(value, record) =>
              editingKey === record.id ? (
                <Form.Item
                  name="sortPriority"
                  initialValue={record.sortPriority}
                  validateTrigger="onChange"
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                      message: 'Prioritas Urutan harus diisi.',
                    },
                    {
                      validator: (_, value) => {
                        if (value <= 0)
                          return Promise.reject(
                            'Prioritas Urutan harus lebih dari 0.',
                          )
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <InputNumber
                    autoComplete="false"
                    addonBefore={<Icons.SortAscendingOutlined />}
                    min={1}
                    controls={false}
                    precision={0}
                  />
                </Form.Item>
              ) : (
                <TextField value={value} />
              )
            }
          />
          <Table.Column<ProgramModuleResponse>
            title="Aksi"
            dataIndex="actions"
            onCell={({ id }) => ({
              className: id === editingKey ? 'align-top' : undefined,
            })}
            render={(_, record) =>
              editingKey === record.id ? (
                <Space>
                  <Button
                    icon={<Icons.SaveOutlined />}
                    loading={isUpdating}
                    onClick={async () => {
                      await form.validateFields()
                      return doUpdate(
                        {
                          dataProviderName: 'lms',
                          resource: `programs/${id}/program-modules`,
                          id: record.id,
                          values: form.getFieldsValue(),
                          successNotification: {
                            message: 'Modul berhasil diubah',
                            type: 'success',
                            description: 'Sukses',
                          },
                          errorNotification: (err) =>
                            showErrorNotification(
                              err,
                              'There was a problem when updating Module',
                            ),
                        },
                        {
                          onSuccess: () => {
                            setEditingKey(null)
                          },
                        },
                      )
                    }}
                  >
                    Simpan
                  </Button>
                  <Button
                    icon={<Icons.CloseOutlined />}
                    onClick={() => setEditingKey(null)}
                    danger
                  >
                    Batal
                  </Button>
                </Space>
              ) : (
                <Space>
                  <Button
                    icon={<Icons.EditOutlined />}
                    onClick={() => {
                      form.setFieldsValue({ name: record.name })
                      setEditingKey(record.id)
                    }}
                    disabled={shouldDisableAction}
                  >
                    Ubah
                  </Button>
                  <PopDeleteConfirm
                    title="Apakah Anda yakin ingin menghapus modul ini?"
                    okText="Hapus"
                    cancelText="Batal"
                    onConfirm={async () =>
                      doDelete({
                        dataProviderName: 'lms',
                        resource: `programs/${id}/program-modules`,
                        id: record.id,
                        invalidates: ['list'],
                        successNotification: {
                          message: 'Modul berhasil dihapus',
                          type: 'success',
                          description: 'Sukses',
                        },
                        errorNotification: (err) =>
                          showErrorNotification(
                            err,
                            'There was a problem when removing Modul',
                          ),
                      })
                    }
                    disabled={shouldDisableAction}
                  >
                    <Button
                      danger
                      icon={<Icons.DeleteOutlined />}
                      disabled={shouldDisableAction}
                    >
                      Hapus
                    </Button>
                  </PopDeleteConfirm>
                </Space>
              )
            }
          />
        </Table>
      </Form>

      <ModalForm
        modalProps={modalProps}
        formProps={formProps}
        onClose={close}
        programId={String(id)}
      />
    </Drawer>
  )
}

export default ModulDrawer
