import {
  Form,
  Input,
  Table,
  TableColumnGroupType,
  TableColumnType,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters } from '@pankod/refine-core'
import React from 'react'

type InstructorTableProps = {
  selected?: { name: string; userId: string }
  onSelected?: (data: { name: string; userId: string }) => void
  resource: string
  enabled: boolean
  initialInstructorEmail?: string
}
const InstructorTable = ({
  selected = { name: '', userId: '' },
  onSelected,
  resource,
  enabled,
  initialInstructorEmail,
}: InstructorTableProps) => {
  const { tableProps, searchFormProps, tableQueryResult } = useTable({
    resource,
    dataProviderName: 'lms',
    queryOptions: {
      enabled,
      onSuccess: (data) => {
        const user = data.data.find(
          ({ email }) => email === initialInstructorEmail,
        )
        onSelected?.({ name: user?.name || '', userId: user?.userId || '' })
      },
    },
    onSearch(data: any) {
      const filters: CrudFilters = []
      filters.push(
        {
          field: 'query',
          operator: 'eq',
          value: data.query,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        },
      )
      return filters
    },
  })
  const handleRadioClick = (data: { name: string; userId: string }) => {
    onSelected?.(data)
  }
  const columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      title: 'Nama',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
  ]
  return (
    <>
      <Form {...searchFormProps}>
        <Form.Item name="query">
          <Input.Search
            style={{ width: '30%' }}
            allowClear
            autoComplete="off"
            loading={tableQueryResult.isFetching}
            onSearch={() => searchFormProps?.form?.submit()}
            placeholder="Cari nama atau email instruktur"
          />
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        columns={columns}
        rowKey="userId"
        rowSelection={{
          type: 'radio',
          onChange(_, record) {
            handleRadioClick({ name: record[0].name, userId: record[0].userId })
          },
          selectedRowKeys: [selected.userId],
          getCheckboxProps: (record) => ({
            name: record.userId,
          }),
          columnTitle: 'Pilih',
        }}
      />
    </>
  )
}

export default InstructorTable
