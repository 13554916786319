import { Canvas } from 'fabric'
import { useState, useRef } from 'react'

import { CanvasTabObject } from '../utils/canvas'

const useCanvasTabObject = () => {
  const canvas = useRef<Canvas | null>(null)
  const [activeTab, setActiveTab] = useState<number>(-1)
  const canvasTabObject = useRef<CanvasTabObject[]>([
    {
      id: 'canvas-front-page',
      canvasObj: {
        version: '6.3.0',
        objects: [],
      },
    },
    {
      id: 'canvas-back-page',
      canvasObj: {
        version: '6.3.0',
        objects: [],
      },
    },
  ])

  const updateTabObject = async () => {
    if (!canvas.current) return
    const object = await canvas.current.toObject([
      'customId',
      'customVariableKey',
      'customObjectType',
      'customObjectWidth',
    ])
    canvasTabObject.current[activeTab].canvasObj = object
  }

  return {
    canvas,
    activeTab,
    setActiveTab,
    canvasTabObject: canvasTabObject.current,
    updateTabObject,
  }
}

export default useCanvasTabObject
