import { useCreate } from '@pankod/refine-core'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const useCreateDasusOffline = (programId?: string | string[] | number) => {
  const router = useRouter()
  const { mutateAsync, isLoading } = useCreate()
  const doCreate = useCallback(() => {
    return mutateAsync(
      {
        resource: `pgp-dasus/programs/${programId}/sync`,
        values: {
          programId,
        },
        dataProviderName: 'lms',
        successNotification: {
          message: 'Program Dasus successfully created',
          type: 'success',
          description: 'Successful',
        },

        errorNotification: (err) =>
          showErrorNotification(
            err,
            'There was a problem when creating Program Dasus',
          ),
      },
      {
        onSuccess: () => {
          router.push(`/dasus-program-management/show/${programId}`)
        },
      },
    )
  }, [mutateAsync, programId, router])
  return {
    doCreate,
    isLoading,
  }
}

export default useCreateDasusOffline
