import {
  Button,
  Divider,
  Drawer,
  Icons,
  Popconfirm,
  Space,
  Table,
  Tag,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useDelete } from '@pankod/refine-core'

import { JurnalFasilitasiRequest, JurnalFasilitasiResponse } from './types'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import { LIST_FILTER } from './constant'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { ModalForm } from './ModalForm'
import { useGetProgramDetail } from '@resources/lms-management/program/utils'

export const useJurnalFasilitasiDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type JurnalFasilitasiDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  name: string
}
const JurnalFasilitasiDrawer = ({
  id,
  name,
  onClose,
  visible,
}: JurnalFasilitasiDrawerProps) => {
  const { mutateAsync: doDelete } = useDelete()
  const { isFetching, isJurnalFasilitasiFinalized: isFinalized } =
    useGetProgramDetail(id)
  const { tableProps } = useTable<JurnalFasilitasiResponse, TCommonError>({
    resource: `programs/${id}/jurnal-fasilitasi`,
    dataProviderName: 'lms',
    queryOptions: { enabled: visible && !!id },
    initialFilter: [
      {
        field: 'filter',
        operator: 'eq',
        value: LIST_FILTER.ASSIGNED,
      },
    ],
  })
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<
    JurnalFasilitasiResponse,
    TLMSCommonError,
    JurnalFasilitasiRequest
  >({
    action: 'create',
    resource: `programs/${id}/jurnal-fasilitasi`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Jurnal fasilitasi berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating jurnal fasilitasi',
      ),
  })
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<
    JurnalFasilitasiResponse,
    TLMSCommonError,
    Omit<JurnalFasilitasiRequest, 'moduleId'>
  >({
    action: 'edit',
    resource: `programs/${id}/jurnal-fasilitasi`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Jurnal fasilitasi berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when updating jurnal fasilitasi',
      ),
  })
  return (
    <>
      <Drawer
        title={`Daftar Jurnal Fasilitasi | ${name}`}
        visible={visible}
        onClose={onClose}
        destroyOnClose
        width="70%"
        extra={
          <Space>
            {typeof isFinalized !== 'undefined' ? (
              <>
                Status:{' '}
                <Tag color={isFinalized ? 'processing' : 'default'}>
                  {isFinalized ? 'FINALIZED' : 'DRAFT'}
                </Tag>
                <Divider type="vertical" />
              </>
            ) : null}
            <Button
              type="primary"
              icon={<Icons.PlusOutlined />}
              onClick={createShow}
              disabled={isFinalized}
            >
              Tambah Jurnal
            </Button>
          </Space>
        }
      >
        <Table
          {...tableProps}
          loading={tableProps.loading && isFetching}
          rowKey="id"
        >
          <Table.Column title="ID" dataIndex="id" />
          <Table.Column title="Nama Modul" dataIndex="title" width="40%" />
          <Table.Column
            title="Jurnal Form"
            dataIndex="form"
            render={(value) => (
              <TextField
                value={value.title}
                copyable={{ text: value.id, tooltips: 'Copy ID' }}
                ellipsis={{ tooltip: value.title }}
                style={{ width: 250 }}
              />
            )}
            width={200}
          />
          <Table.Column<JurnalFasilitasiResponse>
            render={(_, record) => (
              <Space>
                <Button
                  icon={<Icons.EditOutlined />}
                  onClick={() => editShow(record.id)}
                  disabled={isFinalized}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Apakah Anda yakin ingin menghapus jurnal fasilitasi ini?"
                  okButtonProps={{ danger: true }}
                  okText="Hapus"
                  cancelText="Kembali"
                  disabled={isFinalized}
                  onConfirm={async () =>
                    doDelete({
                      id: record.id,
                      resource: `programs/${id}/jurnal-fasilitasi`,
                      dataProviderName: 'lms',
                      invalidates: ['list'],
                      successNotification: {
                        message: 'Jurnal fasilitasi berhasil dihapus',
                        type: 'success',
                        description: 'Sukses',
                      },
                      errorNotification: (error: any) =>
                        showErrorNotification(
                          error,
                          'There was a problem when removing jurnal fasilitasi',
                        ),
                    })
                  }
                >
                  <Button
                    danger
                    icon={<Icons.DeleteOutlined />}
                    disabled={isFinalized}
                  >
                    Hapus
                  </Button>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
        <ModalForm
          formProps={createFormProps}
          modalProps={createModalProps}
          mode="create"
          onClose={createClose}
          programId={id}
        />
        <ModalForm
          formProps={editFormProps}
          modalProps={editModalProps}
          mode="edit"
          onClose={editClose}
          programId={id}
        />
      </Drawer>
    </>
  )
}

export default JurnalFasilitasiDrawer
