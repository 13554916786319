import {
  Button,
  Divider,
  Form,
  FormProps,
  Icons,
  Modal,
  ModalProps,
  Upload,
  Popconfirm,
} from '@pankod/refine-antd'
import { useList } from '@pankod/refine-core'
import React, { useState } from 'react'

import { InstructorTable } from './InstructorTable'
import { downloadTemplate, getUploadProps } from './utils'
import { ErrorResponseDetail } from 'src/interfaces/common'

type InstructorModalProps = {
  modalProps: ModalProps
  formProps: FormProps
  mutationResult: any
  onClose: () => void
  onFinish: (data: any) => void
  programId?: number | string
  formLoading?: boolean
}
const InstructorModal = ({
  formProps,
  modalProps,
  onClose,
  programId,
  onFinish,
  formLoading,
  mutationResult,
}: InstructorModalProps) => {
  const [modules, setModules] = useState<string[]>([])
  const { data: modulesData, isFetching } = useList<string>({
    resource: `programs/${programId}/modules`,
    queryOptions: {
      enabled: modalProps.visible,
      onSuccess(data) {
        setModules(data.data)
      },
    },
  })

  const instructorUnassignError =
    mutationResult?.error?.response?.data?.error?.details?.reduce?.(
      (result: Record<string, string[]>, current: ErrorResponseDetail) => {
        if (current.detail?.error_code !== '04170101' || !current.detail?.info)
          return result

        const info = JSON.parse(current.detail.info)
        const failedModule = JSON.parse(info.failedUnassignEligibleModules)

        return { ...result, [info.email]: failedModule }
      },
      {} as Record<string, string[]>,
    )

  const formData = Form.useWatch('data', formProps?.form)
  const initialData = formProps?.initialValues?.data

  const hasChanged = JSON.stringify(initialData) !== JSON.stringify(formData)

  const uploadProps = getUploadProps(({ data, modules }) => {
    setModules(modules)
    formProps.form?.setFieldsValue({ data })
  })

  const handleClose = () => {
    setModules(modulesData?.data || [])
    formProps.form?.resetFields()
    mutationResult?.reset()
    onClose()
  }

  return (
    <Modal
      {...modalProps}
      onCancel={handleClose}
      maskClosable={false}
      closable={false}
      title="Daftar Instruktur"
      cancelText="Kembali"
      width="80%"
      footer={[
        <Button
          key="template"
          icon={<Icons.DownloadOutlined />}
          type="link"
          onClick={() => downloadTemplate()}
          className="mr-2"
        >
          Unduh Template
        </Button>,
        <div key="upload" className="inline-block">
          <Upload {...uploadProps}>
            <Button icon={<Icons.UploadOutlined />}>Upload CSV</Button>
          </Upload>
        </div>,
        <Divider key="divider" type="vertical" />,
        <Popconfirm
          title="Apakah Anda yakin keluar tanpa menyimpan data?"
          key="back"
          onConfirm={handleClose}
          disabled={!hasChanged}
          okText="Ya"
          okButtonProps={{ style: { width: '50px' } }}
          cancelText="Tidak"
        >
          <Button onClick={hasChanged ? undefined : handleClose}>
            Kembali
          </Button>
        </Popconfirm>,
        <Button
          key="edit"
          type="primary"
          icon={<Icons.SaveOutlined />}
          disabled={!hasChanged}
          loading={formLoading || isFetching}
          onClick={() => formProps?.form?.submit()}
        >
          Simpan
        </Button>,
      ]}
    >
      <Form
        {...formProps}
        onFinish={(values) => {
          onFinish({ instructors: values.data })
        }}
      >
        <Form.Item name="data" noStyle>
          <InstructorTable
            errors={instructorUnassignError}
            modules={modules}
            formLoading={formLoading}
            loading={isFetching}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default InstructorModal
