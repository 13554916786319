import { Select, useSelect } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import React from 'react'

import { TFilterOptions } from '@components/DataTableParticipants/types'

// put this inside form.item
export const SelectBidangStudyOptions = ({
  visible = true,
  ...props
}: {
  visible?: boolean
  className?: string
  onChange?: (value: any) => void
  placeholder?: string
  allowClear?: boolean
}) => {
  // staging / lms_ppg_study_field.json
  const { selectProps } = useSelect({
    resource: 'kv?key=lms_ppg_study_field',
    dataProviderName: 'microlearning',
    optionLabel: 'label',
    optionValue: 'value',
    queryOptions: {
      enabled: !!visible,
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
  })

  return (
    <Select
      {...props}
      {...selectProps}
      onSearch={() => {}}
      filterOption={(input, option) =>
        (option!.label as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    />
  )
}

export const SelectSchoolOptions = (formInjectedProps: any) => {
  const { lptkId } = formInjectedProps

  const { data: schoolData } = useOne({
    resource: `lptk/${lptkId}`,
    id: `school?pageSize=-1`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!formInjectedProps.visible,
    },
  })

  const schoolOptions = schoolData?.data?.data.map((school: any) => ({
    label: school.name,
    value: school.id,
  }))

  return (
    <Select
      {...formInjectedProps}
      options={schoolOptions}
      onSearch={() => {}}
      filterOption={(input, option) =>
        (option!.label as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    />
  )
}

export const SelectProvinceOptions = (formInjectedProps: any) => {
  const { data: filterOptions } = useOne<TFilterOptions>({
    resource: `programs/participants`,
    id: `filter-options`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!formInjectedProps.visible,
    },
  })

  const regionData = filterOptions?.data?.regions

  const provinceOptions = regionData?.map((province) => ({
    label: province.name,
    value: province.kode,
  }))

  return (
    <>
      <Select
        {...formInjectedProps}
        options={provinceOptions}
        onSearch={() => {}}
        filterOption={(input, option) =>
          (option!.label as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      />
    </>
  )
}

export const SelectJenjangOptions = (formInjectedProps: any) => {
  const { data: filterOptions } = useOne<TFilterOptions>({
    resource: `programs/participants`,
    id: `filter-options`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!formInjectedProps.visible,
    },
  })

  const jenjangData = filterOptions?.data?.jenjang

  const provinceOptions = jenjangData?.map((jenjang) => ({
    label: jenjang,
    value: jenjang,
  }))

  return (
    <>
      <Select
        {...formInjectedProps}
        options={provinceOptions}
        onSearch={() => {}}
        filterOption={(input, option) =>
          (option!.label as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      />
    </>
  )
}

export const SelectCityOptions = (formInjectedProps: any) => {
  const { provinceId, initialValue } = formInjectedProps

  const { data: filterOptions } = useOne<TFilterOptions>({
    resource: `programs/participants`,
    id: `filter-options`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!formInjectedProps.visible,
    },
  })

  const regionData = filterOptions?.data?.regions

  const getCityOptions = (provinceId: string) => {
    const provinceData = regionData?.find(
      (province) => province.kode === provinceId,
    )
    const cityOptions = provinceData?.kabupaten?.map((city) => ({
      label: city.name,
      value: city.kode,
    }))
    return cityOptions
  }

  return (
    <Select
      {...formInjectedProps}
      options={getCityOptions(provinceId)}
      defaultValue={[
        {
          label: initialValue && initialValue.city.name,
          value: initialValue && initialValue.city.id,
        },
      ]}
      onSearch={() => {}}
      filterOption={(input, option) =>
        (option!.label as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    />
  )
}
