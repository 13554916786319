import {
  Button,
  Card,
  Form,
  FormProps,
  Icons,
  Input,
  Space,
} from '@pankod/refine-antd'
import { LogicalFilter } from '@pankod/refine-core'
import React from 'react'

export type TFilterForm<T = { keyword: string }> = T

const Filter: React.FC<{
  formProps: FormProps
  showClearAll?: boolean
  filters?: LogicalFilter[]
  layout?: 'vertical' | 'horizontal' | 'inline'
  items?: {
    label: string
    name: string
    placeholder?: string
    component?: React.ReactNode
  }[]
  size?: 'small'
}> = ({
  formProps,
  items,
  filters,
  showClearAll,
  layout = 'vertical',
  size,
}) => {
  const initialValues = filters?.reduce(
    (result, filter) => {
      result[filter.field] = filter.value
      return result
    },
    {} as Record<string, any>,
  )

  return (
    <Card className="!mb-5" size={size}>
      <Form layout={layout} {...formProps} initialValues={initialValues}>
        {(!items || items.length === 0) && (
          <Form.Item label="Keyword" name="keyword">
            <Input
              placeholder="Search by name, description, content.."
              autoComplete="off"
            />
          </Form.Item>
        )}
        {items &&
          items.length > 0 &&
          items.map(({ label, name, placeholder, component }) => (
            <Form.Item key={name} label={label} name={name}>
              {!!component ? (
                component
              ) : (
                <Input placeholder={placeholder} autoComplete="off" />
              )}
            </Form.Item>
          ))}
        <Form.Item noStyle>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              icon={<Icons.FilterOutlined />}
              size={size}
            >
              Filter
            </Button>
            {showClearAll && (
              <Button
                onClick={() => {
                  formProps.form?.resetFields()
                  formProps.form?.submit()
                }}
                size={size}
              >
                Clear
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default Filter
