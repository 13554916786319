import {
  Form,
  FormProps,
  Modal,
  ModalProps,
  Select,
  useModalForm,
  useSelect,
} from '@pankod/refine-antd'
import React from 'react'
import { useInvalidate } from '@pankod/refine-core'

import { AdminLPTKRequest, LPTKAdmin } from '../../types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type TModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  currentAdmins: string[]
  onClose: () => void
}
export const AdminLPTKModalForm = ({
  modalProps,
  formProps,
  onClose,
  currentAdmins,
}: TModalFormProps) => {
  const { selectProps, queryResult } = useSelect<LPTKAdmin>({
    resource: 'lptk/vacant-admin',
    optionLabel: 'email',
    optionValue: 'email',
    dataProviderName: 'lms',
    filters: [{ field: 'pageSize', operator: 'eq', value: -1 }],
    queryOptions: {
      enabled: modalProps.visible,
      select: (data) => {
        const filteredData = data.data.filter(
          ({ email }) => !currentAdmins.includes(email),
        )
        return {
          data: filteredData,
          total: filteredData.length,
        }
      },
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
  })

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title="Tambah Admin LPTK"
      okText="Simpan"
      cancelText="Kembali"
    >
      <Form<AdminLPTKRequest>
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        initialValues={(() => {
          return formProps?.initialValues?.data || {}
        })()}
      >
        <Form.Item
          label="Pilih Admin"
          name="email"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...selectProps}
            allowClear
            loading={queryResult.isFetching}
            mode="multiple"
            onSearch={() => {}}
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export const useAssignAdminLptkModalForm = ({
  lptkId,
}: {
  lptkId: number | string
}) => {
  const invalidate = useInvalidate()
  const { modalProps, formProps, show, close } = useModalForm({
    action: 'create',
    resource: `lptk/${lptkId}/assign-admin`,
    dataProviderName: 'lms',
    warnWhenUnsavedChanges: true,
    redirect: false,
    autoResetForm: true,
    autoSubmitClose: true,
    successNotification: {
      message: 'Admin LPTK successfully added',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when adding Admin LPTK'),
    onMutationSuccess: () => {
      invalidate({
        resource: 'lptk',
        id: lptkId,
        dataProviderName: 'lms',
        invalidates: ['detail'],
      })
    },
  })

  return {
    modalProps,
    formProps,
    show,
    onClose: close,
  }
}
