import {
  Button,
  Checkbox,
  Descriptions,
  Divider,
  Form,
  Icons,
  Input,
  message,
  notification,
  Select,
  Tabs,
  Tag,
  Upload,
  UploadFile,
  UploadProps,
  useForm,
} from '@pankod/refine-antd'
import React from 'react'
import { useRouter } from 'next/router'
import { UploadChangeParam } from 'antd/lib/upload'
import { useCreate, useInvalidate, useOne } from '@pankod/refine-core'

import { getGuruToken } from 'src/helpers/session'
import { TCommonError } from 'src/interfaces/common'
import { TDistrictData, TLMSParticipant } from '../types'
import { useParticipantFilterContext } from 'src/hooks/context/useParticipantFilterOptionsContext'
import { kapabilitasMapper } from './utils'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const { CloudUploadOutlined, LoadingOutlined } = Icons

type TCreateParticipantSectionProps = {
  onCancel: () => void
  customSubmitResources?: {
    resource: string
    onSuccess?: (data: any) => void
  }
  customExampleUrl?: string
  onSuccess?: () => void
  createResource?: string
}

type TValidationCodeEnum = {
  [K in number]: string
}

const VALIDATION_CODE_MAP: TValidationCodeEnum = {
  1: 'OK',
  2: 'EXIST_IN_PROGRAM',
  3: 'GURU_USER',
}

export const CreateParticipantSection = (
  props: TCreateParticipantSectionProps,
) => {
  const {
    onCancel,
    onSuccess,
    customExampleUrl = `${process.env.BASE_PATH}/api/sample/csv-file`,
    customSubmitResources,
    createResource,
  } = props
  const router = useRouter()
  const { id } = router.query
  const { mutateAsync } = useCreate()

  const UPLOAD_FILE_CONSTRAINT = {
    accept: '.csv',
    type: 'text/csv',
  }
  const [activeKey, setActiveKey] = React.useState('upload_csv')
  const [fileData, setFileData] = React.useState<{
    data: any
    url: string
  }>({
    data: null,
    url: '',
  })
  // TODO: adjust this type once the API is ready
  const [bulkUploadResponse, setBulkUploadResponse] = React.useState<any>(null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [emailCheckState, setEmailCheckState] = React.useState<any>({
    exist: null,
    data: null,
  })
  const [cityOptions, setCityOptions] = React.useState<any>([])
  const [selectedCity, setSelectedCity] = React.useState<any>(null)
  const invalidate = useInvalidate()

  const { data: filterOptions } = useParticipantFilterContext()

  const { formProps, onFinish } = useForm<
    { data: TLMSParticipant },
    TCommonError,
    any
  >({
    action: 'create',
    resource: createResource || `programs/${id}/participants/details`,
    dataProviderName: 'lms',
    redirect: false,
    onMutationSuccess: () => {
      !createResource &&
        invalidate({
          resource: `programs/${id}/participants`,
          dataProviderName: 'lms',
          invalidates: ['list'],
        })
      onCancel()
    },
    errorNotification: (err: any) => {
      const errResponse = err.response?.data?.error || ''

      return {
        message: errResponse.status,
        description: 'please check your input or try again later',
        type: 'error',
      }
    },
  })

  const { data: districtOptions } = useOne<TDistrictData>({
    resource: `programs/participants/filter-options`,
    id: `kecamatan?kodeKabupaten=${selectedCity}`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!selectedCity,
    },
  })

  const getEmailCode = (code: number) => {
    return VALIDATION_CODE_MAP[code] || 'UNKNOWN'
  }

  const handleOnDrop: UploadProps['onDrop'] = (e) => {
    const file = e.dataTransfer.files[0]
    if (file.type !== UPLOAD_FILE_CONSTRAINT.type) {
      message.error('You can only upload CSV file!')
      return false
    }
  }

  const handleBeforeUpload = (file: File) => {
    if (file.type !== UPLOAD_FILE_CONSTRAINT.type) {
      message.error('You can only upload CSV file!')
      return false
    }
  }

  const handleOnChange = (info: UploadChangeParam<UploadFile>) => {
    setBulkUploadResponse(null)
    if (info.file.status === 'uploading') {
      setIsUploading(true)
      return
    }
    if (info.file.status === 'done') {
      // TODO: DO SOMETHING HERE sent to BE
      const url = info.file.response?.data?.url
      setFileData({
        data: info.file,
        url,
      })
      mutateAsync(
        {
          resource:
            customSubmitResources?.resource ||
            `programs/${id}/bulk_participants`,
          dataProviderName: 'lms',
          values: {
            fileUrl: url,
          },
          successNotification: {
            message: 'Success Upload File!',
            type: 'success',
          },
          errorNotification: (err: any) =>
            showErrorNotification(
              err,
              'Terdapat kesalahan pada sistem, silahkan coba beberapa saat lagi',
            ),
        },
        {
          onSuccess: (data) => {
            setBulkUploadResponse({
              data,
              success: true,
            })

            onSuccess && onSuccess()
          },
          onError: () => {
            setBulkUploadResponse({
              success: false,
              data: null,
            })
          },
        },
      )

      setIsUploading(false)
    }
    if (info.file.status === 'error') {
      setIsUploading(false)
      const errorMessage =
        info.file?.response?.errors?.[0]?.message ||
        'Unknown error, please try again later.'

      notification.error({ message: errorMessage })
    }
  }

  const handleCheckEmailState = () => {
    const { form } = formProps
    const email = form?.getFieldValue('email')

    mutateAsync(
      {
        resource: `programs/${id}/participants/validate`,
        dataProviderName: 'lms',
        values: {
          email,
        },
        successNotification: (data: any) => {
          return {
            message:
              data?.data?.message === 'ok'
                ? 'Email is valid - please fill / update data if needed'
                : data?.data?.message,
            type: data?.data?.message === 'ok' ? 'success' : 'error',
          }
        },
        errorNotification: {
          message: `Participant Email doesn't exist! Please FIll the form`,
          type: 'error',
        },
      },
      {
        onSuccess: (data) => {
          const { code: responseCode = 0 } = data.data
          const codeMessage = getEmailCode(responseCode)

          setEmailCheckState((prev: any) => ({
            ...prev,
            exist:
              codeMessage === 'EXIST_IN_PROGRAM' || codeMessage === 'GURU_USER',
            data,
          }))

          if (codeMessage === 'OK' && data.data.data) {
            const { kabupaten, kecamatan, provinsi } = data.data.data
            const regionPayload = {
              kode_provinsi: provinsi.kode,
              kode_kabupaten: kabupaten.kode,
              kode_kecamatan: kecamatan.kode,
            }
            const cityOptions = filterOptions?.data?.regions?.filter(
              (province) => province.kode === provinsi.kode,
            )

            setCityOptions((cityOptions && cityOptions[0].kabupaten) || [])
            setSelectedCity(kabupaten.kode)

            formProps.form?.setFieldsValue({
              ...data.data.data,
              ...regionPayload,
            })
          }
        },
        onError: () => {
          setEmailCheckState((prev: any) => ({
            ...prev,
            exist: true,
          }))
        },
      },
    )
  }

  const generateCityOptions = (provinceCode: string) => {
    const cityOptions = filterOptions?.data?.regions?.filter(
      (province) => province.kode === provinceCode,
    )

    setCityOptions((cityOptions && cityOptions[0].kabupaten) || [])

    formProps.form?.setFieldsValue({
      kabupaten: undefined,
      kecamatan: undefined,
    })

    setSelectedCity(null)
  }

  return (
    <Tabs
      defaultActiveKey="detail"
      activeKey={activeKey}
      onChange={setActiveKey}
    >
      <Tabs.TabPane tab="Unggah CSV" key="upload_csv">
        <div>
          {/* sample csv download */}
          <div className="flex items-center mb-4">
            <span>Unduh contoh berkas CSV</span>
            <Button
              type="link"
              href={customExampleUrl}
              download="sample-csv-peserta.csv"
              className="!p-0 !pl-1"
            >
              di sini
            </Button>
          </div>

          <Upload.Dragger
            accept={UPLOAD_FILE_CONSTRAINT.accept}
            action={`${process.env.NEXT_PUBLIC_LMS_API_URL}/upload`}
            beforeUpload={handleBeforeUpload}
            onDrop={handleOnDrop}
            headers={{
              Authorization: `Bearer ${getGuruToken()}`,
            }}
            name="file"
            onChange={handleOnChange}
            maxCount={1}
            showUploadList={false}
            disabled={isUploading}
          >
            {fileData.url && (
              // TODO: show after upload
              <div className="upload-or-select-modal__preview-container overflow-y-scroll">
                <div className="upload-or-select-modal__preview  h-[20vw] ">
                  <Descriptions bordered>
                    <Descriptions.Item label="name" span={3}>
                      {fileData.data.name}
                    </Descriptions.Item>
                    {!!bulkUploadResponse && (
                      <Descriptions.Item label="status" span={3}>
                        {bulkUploadResponse?.success ? (
                          <Tag color="green">Success</Tag>
                        ) : (
                          <Tag color="red">Failed</Tag>
                        )}
                      </Descriptions.Item>
                    )}

                    {/* if failed print error + failed message */}
                    {bulkUploadResponse?.data?.data?.message && (
                      <Descriptions.Item label="message" span={3}>
                        {bulkUploadResponse?.data?.data?.message}
                      </Descriptions.Item>
                    )}

                    <Descriptions.Item label="" span={3}>
                      {bulkUploadResponse?.data?.data?.errors?.map(
                        (item: any) => {
                          {
                            return (
                              <>
                                <p>
                                  {item.message}
                                  {item.error?.message}
                                </p>
                                <p>
                                  {Array.isArray(item.email) &&
                                    item.email.map((email: any) => (
                                      <Tag
                                        color="red"
                                        key={email}
                                        style={{
                                          marginTop: 2,
                                        }}
                                      >
                                        {email}
                                      </Tag>
                                    ))}
                                  {typeof item.email === 'string' && (
                                    <Tag
                                      color="red"
                                      style={{
                                        marginTop: 2,
                                      }}
                                    >
                                      {item.email}
                                    </Tag>
                                  )}
                                </p>
                              </>
                            )
                          }
                        },
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            )}
            {!fileData.url && (
              <>
                {isUploading ? (
                  <div>
                    <LoadingOutlined spin />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                ) : (
                  <div>
                    <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Klik atau seret berkas ke area ini untuk mengunggah
                    </p>
                  </div>
                )}
              </>
            )}
          </Upload.Dragger>
          {fileData.url && (
            <Button
              className="mt-4"
              block
              onClick={() => {
                onCancel()
              }}
            >
              Tutup
            </Button>
          )}
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane className="invisible" key="manual">
        <Form {...formProps} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Email tidak valid.',
                type: 'email',
              },
            ]}
          >
            <div className="flex flex-row items-center">
              <Input />
              <Button className="ml-4" onClick={handleCheckEmailState}>
                Cek Email
              </Button>
            </div>
          </Form.Item>

          {emailCheckState.exist === false && (
            <>
              <div className="flex flex-row justify-between">
                <div className="w-full mr-2 ">
                  <Form.Item
                    name="name"
                    label="Nama"
                    rules={[
                      { required: true, message: 'Harap masukkan nama.' },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="kode_provinsi"
                    label="Provinsi"
                    rules={[
                      { required: true, message: 'Harap pilih provinsi.' },
                    ]}
                  >
                    <Select
                      onChange={(e) => generateCityOptions(e)}
                      showSearch
                      filterOption={(input, option) =>
                        ((option?.children ?? '') as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOptions?.data?.regions?.map((item) => (
                        <Select.Option key={item.kode} value={item.kode}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="kode_kabupaten"
                    label="Kabupaten"
                    rules={[
                      { required: true, message: 'Harap pilih kabupaten.' },
                    ]}
                  >
                    <Select
                      onChange={(e) => {
                        formProps.form?.setFieldsValue({
                          kode_kecamatan: undefined,
                        })
                        setSelectedCity(e)
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        ((option?.children ?? '') as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {cityOptions?.map((item: any) => (
                        <Select.Option key={item.kode} value={item.kode}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="kode_kecamatan"
                    label="Kecamatan"
                    rules={[
                      { required: true, message: 'Harap pilih kecamatan.' },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        ((option?.children ?? '') as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {selectedCity &&
                        districtOptions?.data?.kecamatan.map((item) => (
                          <Select.Option key={item.kode} value={item.kode}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name="kapabilitas" label="Kapabilitas">
                    <Checkbox.Group>
                      {filterOptions?.data?.kapabilitas.map((item: any) => (
                        <Checkbox
                          key={item}
                          value={item}
                          style={{
                            marginLeft: '0px',
                            marginBottom: 8,
                            display: 'flex',
                          }}
                        >
                          {kapabilitasMapper(item)}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
                <div className="w-full ">
                  {/* generate input type string for ptk nuptk npsn jenjang propinsi kabupaten kecamatan matapelajaran kapabilitas */}
                  <Form.Item name="ptk" label="PTK">
                    <Input />
                  </Form.Item>

                  <Form.Item name="nuptk" label="NUPTK">
                    <Input />
                  </Form.Item>

                  <Form.Item name="npsn" label="NPSN">
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="jenjang"
                    label="Jenjang"
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <Select>
                      {filterOptions?.data?.jenjang?.map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="mataPelajaran" label="Mata Pelajaran">
                    <Input />
                  </Form.Item>
                </div>
              </div>
              <Divider />
              <Form.Item>
                <Button type="primary" htmlType="submit" block className="mt-4">
                  Simpan
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Tabs.TabPane>
    </Tabs>
  )
}
