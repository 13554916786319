import React from 'react'
import {
  Modal,
  Icons,
  Form,
  Input,
  message,
  Button,
  Upload,
  notification,
} from '@pankod/refine-antd'
import { useCustomMutation, useInvalidate } from '@pankod/refine-core'

import { ProgramVerification } from './types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { getGuruToken } from 'src/helpers/session'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'

type UpdateFormModal = {
  visible: boolean
  setVisible: (visible: boolean) => void
  data?: ProgramVerification
  programId?: string | string[] | number
}

const UpdateFormModal = ({
  visible,
  setVisible,
  data,
  programId,
}: UpdateFormModal) => {
  const invalidate = useInvalidate()
  const { mutateAsync: doSubmit } = useCustomMutation()
  const [form] = Form.useForm()
  return (
    <Modal
      centered
      visible={visible}
      title="Ubah Informasi Verifikasi Program"
      onCancel={() => {
        form.resetFields()
        setVisible(false)
      }}
      okText="Simpan"
      okButtonProps={{ icon: <Icons.SaveOutlined /> }}
      cancelText="Kembali"
      destroyOnClose
      maskClosable={false}
      onOk={async () => {
        await form.validateFields()
        const values = form.getFieldsValue()
        const formData = new FormData()
        formData.append('kegiatan', values.kegiatan || '')
        formData.append('angkatan', values.angkatan || '')
        formData.append('programLocals', values.fileUpload?.file)
        return doSubmit(
          {
            url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/verification`,
            method: 'post',
            values: formData,
            config: {
              headers: {
                'content-type': 'multipart/form-data',
              },
            },
            successNotification: () => {
              return {
                message:
                  'Perubahan informasi verifikasi program berhasil disimpan',
                type: 'success',
                description: 'Sukses',
              }
            },
            errorNotification: (error: any) =>
              showErrorNotification(
                error,
                'Terdapat gangguan saat menyimpan perubahan',
              ),
          },
          {
            onSuccess: () => {
              invalidate({
                invalidates: ['detail'],
                dataProviderName: 'lms',
                resource: `programs/${programId}`,
                id: 'verification',
              })
            },
          },
        )
      }}
      width={700}
    >
      <Form
        form={form}
        size="small"
        initialValues={data}
        layout="horizontal"
        autoComplete="off"
      >
        <Form.Item label="Kegiatan" name={'kegiatan'}>
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item label="Angkatan" name={'angkatan'}>
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="fileUpload"
          label="Unggah Data Verifikasi Program Lokal"
          valuePropName="fileList"
          getValueProps={(value) => {
            return {
              fileList: value ? value.fileList : [],
            }
          }}
          required
          rules={[{ required: true, message: 'Berkas harus dipilih' }]}
        >
          <Upload
            beforeUpload={(file) => {
              {
                const isCsv = file.type === 'text/csv'
                if (!isCsv) {
                  message.error(`${file.name} bukan file .csv`)
                  form.setFields([
                    {
                      errors: ['Berkas harus .csv'],
                      name: 'fileUpload',
                      value: [],
                    },
                  ])
                  return isCsv || Upload.LIST_IGNORE
                }
                form.setFieldsValue({ fileUpload: file })
                return false
              }
            }}
            accept=".csv"
            multiple={false}
            maxCount={1}
          >
            <div className="flex gap-1">
              <Button type="default" icon={<Icons.FileAddOutlined />}>
                Pilih Berkas
              </Button>
              <Button
                type="link"
                onClick={async (e) => {
                  e.stopPropagation()
                  const response = await fetch(
                    process.env.NEXT_PUBLIC_LMS_API_URL +
                      `/programs/${programId}/verification-template`,
                    {
                      headers: {
                        Authorization: `Bearer ${getGuruToken()}`,
                      },
                    },
                  )
                  if (!response.ok) {
                    notification.error({
                      message: 'Error',
                      description: 'Terdapat gangguan saat mengunduh template',
                    })
                    return
                  }
                  const text = await response.text()
                  downloadTemplate(
                    text,
                    `verifikasi_program_${programId}`,
                    'template',
                  )
                }}
              >
                Unduh Template
              </Button>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdateFormModal
