import {
  Button,
  Drawer,
  Icons,
  Space,
  Table,
  Tag,
  TextField,
  useModalForm,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useDelete, useInvalidate } from '@pankod/refine-core'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { ActivityGroupRequest, ActivityGroupResponse } from './types'
import { PopDeleteConfirm } from '@components/PopDeleteConfirm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import ModalForm from './ModalForm'
import { TLMSCommonError } from 'src/interfaces/common'
import { HeaderTitle } from '@components/HeaderTableTitle'
import { PROGRAM_STATE } from '@resources/lms-management/program/types'

export const useActivityGroupDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programState?: PROGRAM_STATE
  }>({ show: false, programId: '', programState: undefined })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
      programState: undefined,
    }))
  }, [])

  return {
    show: (id: number | string, programState?: PROGRAM_STATE) => {
      setShowId({ show: true, programId: id, programState })
    },
    visible: !!showId.show,
    id: showId.programId,
    onClose,
    state: showId.programState,
  }
}

type ActivityGroupDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  state?: PROGRAM_STATE
}

const ActivityGroupDrawer = ({
  id,
  onClose,
  visible,
  state,
}: ActivityGroupDrawerProps) => {
  const invalidate = useInvalidate()
  const { tableProps } = useTableWithMeta<ActivityGroupResponse>({
    dataProviderName: 'lms',
    resource: `programs/${id}/activity-groups`,
    queryOptions: {
      enabled: visible,
    },
  })
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<{}, TLMSCommonError, ActivityGroupRequest>({
    action: 'create',
    resource: `programs/${id}/activity-groups`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Kelompok aktivitas berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan saat membuat Kelompok Aktivitas',
      ),
    onMutationError() {
      invalidate({
        invalidates: ['list'],
        dataProviderName: 'lms',
        resource: `programs/${id}/canvas-content`,
      })
      createFormProps?.form?.setFieldsValue({
        canvasContents: [],
      })
    },
  })
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<{}, TLMSCommonError, ActivityGroupRequest>({
    action: 'edit',
    resource: `programs/${id}/activity-groups`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Kelompok aktivitas berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan saat mengubah Kelompok Aktivitas',
      ),
    onMutationError() {
      invalidate({
        invalidates: ['list'],
        dataProviderName: 'lms',
        resource: `programs/${id}/canvas-content`,
      })
      editFormProps?.form?.setFieldsValue({
        canvasContents:
          editFormProps?.initialValues?.data?.canvasContents || [],
      })
    },
  })
  const { mutateAsync: doDelete } = useDelete()
  return (
    <Drawer
      title="Daftar Kelompok Aktivitas"
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      extra={
        <Button
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => {
            createShow()
          }}
        >
          Tambah Kelompok Aktivitas
        </Button>
      }
    >
      <Table
        {...tableProps}
        rowKey={'id'}
        scroll={{ x: 400 }}
        tableLayout="fixed"
        expandable={{
          columnWidth: 100,
          expandIcon: ({ expandable, expanded, onExpand, record }) =>
            expandable && (
              <Button
                icon={
                  expanded ? <Icons.CaretUpFilled /> : <Icons.CaretDownFilled />
                }
                size="small"
                onClick={(e) => onExpand(record, e)}
              />
            ),
          rowExpandable: ({ type }) => type === 'async',
          expandedRowRender: ({ canvasContents }) => {
            return (
              <Table
                pagination={false}
                size="small"
                title={() => (
                  <HeaderTitle title={<strong>Daftar Aktivitas</strong>} />
                )}
                dataSource={canvasContents}
                scroll={{ x: 300, y: 200 }}
                columns={[
                  {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 150,
                    render: (value) => <TextField value={value} code />,
                  },
                  {
                    title: 'Nama',
                    dataIndex: 'title',
                    ellipsis: true,
                  },
                  {
                    title: 'Durasi Maksimum',
                    dataIndex: 'maxDuration',
                    width: 150,
                    render: (value) =>
                      +value ? (
                        +value / 60 + ' Menit'
                      ) : (
                        <TextField value="Kosong" italic type="secondary" />
                      ),
                  },
                  {
                    title: 'Prioritas Urutan',
                    dataIndex: 'sortPriority',
                    width: 80,
                  },
                ]}
              />
            )
          },
        }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            width: 80,
            fixed: 'left',
          },
          {
            title: 'Nama Kelompok Aktivitas',
            dataIndex: 'name',
            width: 170,
            fixed: 'left',
          },
          {
            title: 'Nama Module',
            dataIndex: 'programModuleName',
            width: 170,
          },
          {
            title: 'Kategori',
            dataIndex: 'type',
            width: 120,
            render: (value: string) => {
              return value === 'async' ? (
                <Tag color="lime">Asinkronus</Tag>
              ) : (
                <Tag color="cyan">Sinkronus</Tag>
              )
            },
          },
          {
            title: 'Target Durasi',
            width: 130,
            dataIndex: 'duration',
            render: (value) => Math.round(value / 60) + ' menit',
          },
          {
            title: 'Prioritas Urutan',
            width: 80,
            dataIndex: 'sortPriority',
          },
          {
            fixed: 'right',
            width: 130,
            render: (_, record) => {
              return (
                <Space direction="vertical">
                  <Button
                    block
                    icon={<Icons.EditOutlined />}
                    onClick={() => editShow(record.id)}
                  >
                    Ubah
                  </Button>
                  <PopDeleteConfirm
                    title="Apakah Anda yakin ingin menghapus Kelompok Aktivitas ini?"
                    placement="topRight"
                    okButtonProps={{ danger: true }}
                    okText="Hapus"
                    cancelText="Batal"
                    onConfirm={async () => {
                      return doDelete({
                        dataProviderName: 'lms',
                        resource: `programs/${id}/activity-groups`,
                        id: record.id,
                        successNotification: {
                          description: 'Sukses',
                          message: 'Kelompok Aktivitas berhasil dihapus',
                          type: 'success',
                        },
                        errorNotification: (error) =>
                          showErrorNotification(
                            error,
                            'Terdapat gangguan saat menghapus Kelompok Aktivitas',
                          ),
                      })
                    }}
                  >
                    <Button block danger icon={<Icons.DeleteOutlined />}>
                      Hapus
                    </Button>
                  </PopDeleteConfirm>
                </Space>
              )
            },
          },
        ]}
      />
      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
        programId={String(id)}
      />
      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
        programId={String(id)}
        programState={state}
      />
    </Drawer>
  )
}

export default ActivityGroupDrawer
