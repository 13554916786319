import React from 'react'
import {
  List,
  // Select,
  useTable,
  Table,
  TextField,
  Space,
  Button,
  Popconfirm,
  Icons,
  useModalForm,
  Tag,
} from '@pankod/refine-antd'
import Head from 'next/head'
import {
  IResourceComponentsProps,
  GetListResponse,
  useInvalidate,
  useDelete,
} from '@pankod/refine-core'

import { ModalFormLPTKRoles } from './blocks/ModalFormLPTKRoles'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const LPTKRolesManagementList: React.FC<
  IResourceComponentsProps<GetListResponse<any>>
> = ({ options }) => {
  const [formMode, setFormMode] = React.useState<'create' | 'edit'>('create')
  const [selectedRecord, setSelectedRecord] = React.useState<any>(null)

  const { tableProps } = useTable({
    resource: 'lptk/additional-actors',
    dataProviderName: 'lms',
    syncWithLocation: true,
    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when fetching LPTK Roles'),
  })

  const invalidate = useInvalidate()
  const { mutateAsync: doDelete } = useDelete()

  const {
    modalProps: roleModalProps,
    formProps: roleFormProps,
    show: roleModalShow,
    close: roleModalClose,
  } = useModalForm({
    action: formMode,
    resource: `lptk/additional-actors${
      (formMode === 'edit' && `/${selectedRecord?.id}`) || ''
    }`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    onMutationSuccess: () => {
      invalidate({
        resource: 'lptk/additional-actors',
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    successNotification: {
      message: 'Kelas successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was a problem when assigning role'),
  })

  return (
    <List
      title="LPTK Role Management"
      createButtonProps={{
        children: 'Assign New LPTK User Role',
        onClick: () => {
          setFormMode('create')
          setSelectedRecord(null)
          roleModalShow()
        },
      }}
    >
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>

      <ModalFormLPTKRoles
        modalProps={roleModalProps}
        formProps={roleFormProps}
        mode={formMode}
        onClose={roleModalClose}
        initialValue={selectedRecord}
      />

      <Table {...tableProps} rowKey="email">
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="email"
          title="User Email"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="role"
          title="Role"
          render={(value) => (
            <Tag>
              <TextField value={value} />
            </Tag>
          )}
        />
        <Table.Column
          dataIndex="lptkId"
          title="LPTK ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          title="Actions"
          render={(_, record: any) => (
            <Space>
              <Popconfirm
                title="Are you sure want to delete this user?"
                onConfirm={() => {
                  return doDelete({
                    id: record.id,
                    resource: `lptk/additional-actors`,
                    dataProviderName: 'lms',
                    invalidates: ['list'],
                    successNotification: {
                      message: 'User successfuly removed',
                      type: 'success',
                      description: 'Successful',
                    },
                    errorNotification: (error) =>
                      showErrorNotification(
                        error,
                        'There was a problem when removing User',
                      ),
                  })
                }}
                okButtonProps={{
                  danger: true,
                  type: 'default',
                }}
                okText="Delete"
              >
                <Button size="small" danger icon={<Icons.DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
              <Button
                size="small"
                icon={<Icons.EditOutlined />}
                onClick={() => {
                  setFormMode('edit')
                  setSelectedRecord(record)
                  roleModalShow()
                }}
              >
                Edit Users
              </Button>
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
