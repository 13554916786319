import React from 'react'
import { useNode, Node, useEditor } from '@craftjs/core'
import clsx from 'clsx'
import { Form, Input, Switch } from '@pankod/refine-antd'

import {
  SelectCustomProps,
  TSelect,
  WIDGET_NAME,
} from '@components/FormBuilder/schema'
import {
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
  getWidgetSettingsFields,
} from '@components/FormBuilder/utils'
import Autocomplete from './Autocomplete'
import { useFormContext } from '@components/FormBuilder/formContext'
import MenuOptions, {
  defaultOptions,
  optionsParser,
} from '../utils/MenuOptions'
import { useGetFormType } from '@components/FormBuilder/editor/toolboxWidgets/utils'
import ContentForm from '@components/ContentBank/ContentForm'

export const defaultProps: TSelect = {
  label: 'Select label',
  name: 'selectName',
  options: defaultOptions,
  placeholder: 'Pilih..',
}

const Select = ({
  label,
  options,
  name,
  helper,
  placeholder = 'Pilih..',
  isRequired,
}: TSelect) => {
  const {
    connectors: { connect, drag },
  } = useNode()

  const {
    query: { getState },
  } = useEditor()

  const {
    options: { enabled },
  } = getState()

  const { control } = useFormContext()
  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx(
        'mb-5 last:mb-0',
        'widget-component',
        'widget-component--select',
        'text-default',
        'relative',
      )}
    >
      {enabled && (
        <div className="italic block absolute right-2 text-subdued">
          Drag Here
        </div>
      )}
      <Autocomplete
        control={control}
        label={label}
        name={name}
        options={optionsParser(options).map(({ label, value }) => ({
          label,
          value,
        }))}
        helper={helper}
        placeholder={placeholder}
        required={isRequired}
      />
    </div>
  )
}

const SelectSettings = () => {
  const { formType } = useGetFormType()
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Name Key"
        name="name"
        validateStatus={errors['name'] && 'error'}
        help={errors['name']}
      >
        <Input
          autoComplete="off"
          value={props.name}
          onChange={(e) =>
            setProp(
              (props: TSelect) =>
                (props.name = e.target.value.replaceAll(' ', '')),
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        validateStatus={errors['label'] && 'error'}
        help={errors['label']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          value={props.text}
          onChange={(text) => setProp((props: TSelect) => (props.label = text))}
        />
      </Form.Item>
      <Form.Item
        label="Options"
        name="options"
        validateStatus={errors['options'] && 'error'}
        help={errors['options']}
      >
        <MenuOptions
          onChange={(e) => setProp((props: TSelect) => (props.options = e))}
          numberOnly={formType === 'quantitative'}
        />
      </Form.Item>
      <Form.Item
        label="Required"
        name="isRequired"
        validateStatus={errors['isRequired'] && 'error'}
        help={errors['isRequired']}
      >
        <Switch
          checked={props.isRequired}
          onChange={(e) => setProp((props: TSelect) => (props.isRequired = e))}
        />
      </Form.Item>
      <Form.Item
        label="Placeholder"
        name="placeholder"
        validateStatus={errors['placeholder'] && 'error'}
        help={errors['placeholder']}
      >
        <Input
          autoComplete="off"
          value={props.placeholder}
          onChange={(e) =>
            setProp((props: TSelect) => (props.placeholder = e.target.value))
          }
        />
      </Form.Item>
      <Form.Item
        label="Helper Text"
        name="helper"
        validateStatus={errors['helper'] && 'error'}
        help={errors['helper']}
      >
        <Input
          autoComplete="off"
          value={props.helper}
          onChange={(e) =>
            setProp((props: TSelect) => (props.helper = e.target.value))
          }
        />
      </Form.Item>
    </Form>
  )
}

Select.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: SelectCustomProps,
  related: {
    settings: SelectSettings,
  },
}

export { Select }
