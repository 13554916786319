import {
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
  useSelect,
} from '@pankod/refine-antd'
import React from 'react'

import { TLMSEntity } from '@resources/lms-management/entity/types'
import { TCommonError } from 'src/interfaces/common'
import { TLMSProgramLocalRequest } from '../types'
import { useProgramStore } from '@resources/lms-management/program/store'

type TModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  programName?: string
  onClose: () => void
}
export const ModalForm = ({
  modalProps,
  formProps,
  mode,
  programName,
  onClose,
}: TModalFormProps) => {
  const hasEntity = useProgramStore(({ hasEntity }) => hasEntity)
  const { selectProps: entitySelectProps } = useSelect<
    TLMSEntity,
    TCommonError
  >({
    resource: 'entities',
    dataProviderName: 'lms',
    optionLabel: 'name',
    optionValue: 'id',
    queryOptions: { enabled: modalProps.visible && !hasEntity },
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={
        mode === 'create' ? 'Buat Program Lokal Baru' : 'Ubah Program Lokal'
      }
      okText={mode === 'create' ? 'Buat Program Lokal' : 'Simpan'}
      cancelText="Batal"
    >
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        initialValues={(() => {
          const { entity, ...rest } = formProps?.initialValues?.data || {}
          return {
            ...rest,
            entityId: entity?.id,
          }
        })()}
      >
        {!hasEntity && (
          <Form.Item
            label="Entitas"
            name="entityId"
            required
            rules={[{ required: true }]}
            getValueFromEvent={(v) => {
              const name = formProps.form?.getFieldValue('name')
              if (!name || name === '') {
                const entityName = entitySelectProps.options?.find(
                  ({ value }) => value === v,
                )?.label
                formProps.form?.setFieldsValue({
                  name: `${entityName}_${programName?.toUpperCase()}`
                    .toLocaleUpperCase()
                    .replaceAll(' ', '_'),
                })
              }
              return v
            }}
          >
            <Select
              {...entitySelectProps}
              allowClear
              onSearch={() => {}}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}
        <Form.Item<TLMSProgramLocalRequest>
          label="Nama Program Lokal"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        {mode === 'edit' && (
          <Form.Item<TLMSProgramLocalRequest> name="status" hidden>
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
