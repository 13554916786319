import { Drawer } from '@pankod/refine-antd'
import { useResource } from '@pankod/refine-core'
import { useRouter } from 'next/router'
import React from 'react'

import { ClassGroup } from '@resources/lms-management/class-group/ClassGroup'

type DrawerClassGroup = {
  visible: boolean
  onClose: () => void
}

const DrawerClassGroup = ({ onClose, visible }: DrawerClassGroup) => {
  const { id: programLocalId } = useResource()
  const router = useRouter()
  const programId = router.query.programId
  return (
    <Drawer
      title="Daftar Kelompok Kelas Webinar"
      visible={visible}
      onClose={onClose}
      width={'85%'}
      destroyOnClose
      maskClosable={false}
    >
      <ClassGroup programId={programId} programLocalId={programLocalId} />
    </Drawer>
  )
}

export default DrawerClassGroup
