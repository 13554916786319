import { useModalForm } from '@pankod/refine-antd'
import React from 'react'
import { useInvalidate, useResource } from '@pankod/refine-core'

import ModalParseCSV from '@components/ModalParseCSV'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type CertificateParticipantFlagUploadButton = {
  children?: React.ReactNode
  disabled?: boolean
}

const CertificateParticipantFlagUploadButton = ({
  children,
  disabled,
}: CertificateParticipantFlagUploadButton) => {
  const { id: programId } = useResource({
    resourceNameOrRouteName: 'lms-program-management',
  })
  const invalidate = useInvalidate()

  const {
    modalProps: modalPropsPeserta,
    formProps: formPropsPeserta,
    close: closePeserta,
    submit: submitPeserta,
    show: showPeserta,
  } = useModalForm({
    action: 'create',
    resource: `programs/${programId}/participants/certificate-flags`,
    submit: (formValue) => {
      const flagSertifikat = formValue.data
      return {
        flagSertifikat,
      }
    },
    successNotification: {
      message: 'Perubahan flag berhasil disimpan',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'Tedapat gangguan saat menyimpan CSV'),
    onMutationSuccess: () => {
      invalidate({
        resource: `programs/${programId}/participants`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  return (
    <>
      <span
        onClick={() => {
          if (disabled) return
          showPeserta()
        }}
      >
        {children}
      </span>
      <ModalParseCSV
        modalProps={modalPropsPeserta}
        formProps={formPropsPeserta}
        onClose={() => {
          closePeserta()
        }}
        onSubmit={submitPeserta}
        validCSVHeaders={['userId', 'status']}
        presetValidate={[
          {
            name: 'status',
            entries: ['true', 'false'],
          },
        ]}
        title="Flag Sertifikat Partisipan"
      />
    </>
  )
}

export default CertificateParticipantFlagUploadButton
