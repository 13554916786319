import {
  Drawer,
  Descriptions,
  Divider,
  Button,
  Icons,
  Input,
  Empty,
  Form,
  Popconfirm,
  Space,
  InputNumber,
} from '@pankod/refine-antd'

import {
  ClassMuridParticipants,
  ClassParticipants,
  KelompokMurid,
} from '../types'
import { MAX_KELOMPOK_MURID_MEMBER_COUNT } from '../config'
import ModalFormMigrateKelompokMurid, {
  useModalMigrateKelompokMurid,
} from './ModalFormMigrateKelompokMurid'

//** TODO: Revisit wether should remove this flag or not */
const SHOULD_SHOW_PINDAH_BUTTON = false

type DrawerKelompokMuridProps = {
  onClose: () => void
  isVisible: boolean
  selectedGroup: KelompokMurid | null
  searchQuery: string
  onSearchQueryChange: (value: string) => void
  onStudentDelete: (userId: string) => void
  onStudentAdd: (mode: string, currentMemberCount?: number) => void
  onUpdateConfirm: (newName: string, formInstance?: any) => Promise<void>
  onAddDummyParticipant: (data: {
    amount: number
    classGroupId: string
  }) => Promise<void>
  enableAddDummy: boolean
}

export const DrawerKelompokMurid = ({
  onClose,
  isVisible,
  selectedGroup,
  searchQuery,
  onSearchQueryChange,
  onUpdateConfirm,
  onStudentDelete,
  onStudentAdd,
  onAddDummyParticipant,
  enableAddDummy,
}: DrawerKelompokMuridProps) => {
  const [form] = Form.useForm()
  const currentMuridCount = selectedGroup?.daftarMurid.length || 0
  const { show, ...modalMigrateParam } = useModalMigrateKelompokMurid()
  return (
    <Drawer
      title="Detail Kelompok Murid"
      width={'60%'}
      visible={isVisible}
      onClose={onClose}
      style={{
        zIndex: 100,
      }}
      extra={
        <Space>
          {enableAddDummy && (
            <Popconfirm
              icon={null}
              placement="bottomRight"
              className="mb-2"
              zIndex={1000}
              title={
                <Form
                  form={form}
                  key={selectedGroup?.id}
                  layout="vertical"
                  initialValues={{
                    name: selectedGroup?.name,
                  }}
                >
                  <Form.Item
                    label={`Jumlah peserta dummy:`}
                    className="!mb-0"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: 'Jumlah peserta dummy harus diisi',
                      },
                    ]}
                    initialValue={1}
                  >
                    <InputNumber autoComplete="off" min={1} />
                  </Form.Item>
                </Form>
              }
              onVisibleChange={(visible) => {
                if (visible) form.resetFields()
              }}
              onConfirm={async () => {
                await form.validateFields()
                await onAddDummyParticipant({
                  amount: form.getFieldValue('amount'),
                  classGroupId: selectedGroup?.id!,
                })
              }}
              okText="Tambah"
              cancelText="Batal"
            >
              <Button icon={<Icons.RobotOutlined />} type="dashed">
                Tambah Peserta Dummy
              </Button>
            </Popconfirm>
          )}
          <Popconfirm
            icon={null}
            placement="bottomRight"
            className="mb-2"
            zIndex={1000}
            title={
              <Form
                form={form}
                key={selectedGroup?.id}
                layout="vertical"
                initialValues={{
                  name: selectedGroup?.name,
                }}
              >
                <Form.Item
                  label={`Nama kelompok:`}
                  className="!mb-0"
                  name="name"
                  rules={[
                    { required: true, message: 'Nama kelompok harus diisi' },
                  ]}
                >
                  <Input placeholder={`Tulis nama baru`} autoComplete="off" />
                </Form.Item>
              </Form>
            }
            onVisibleChange={(visible) => {
              if (visible) form.resetFields()
            }}
            onConfirm={async () => {
              await form.validateFields()
              await onUpdateConfirm(form.getFieldValue('name'))
            }}
            okText="Simpan"
            cancelText="Batal"
          >
            <Button icon={<Icons.EditOutlined />}>Ubah Nama Kelompok</Button>
          </Popconfirm>
        </Space>
      }
    >
      <Descriptions
        bordered
        column={1}
        size="small"
        title={
          <div className="flex flex-row justify-between items-center">
            {selectedGroup?.name}
          </div>
        }
      >
        <Descriptions.Item label="Jumlah Murid">
          {selectedGroup?.jumlahMurid}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        bordered
        column={1}
        size="small"
        title={'Pengajar Praktik'}
        className="mt-4"
      >
        {/* empty */}
        {selectedGroup?.daftarPengajarPraktik?.length === 0 && (
          <Descriptions.Item>
            <Empty description="Belum ada pengajar praktik yang di assign">
              <Button
                type="primary"
                onClick={() => {
                  onStudentAdd('pengajar-praktik')
                }}
              >
                <Icons.PlusOutlined />
                Assign Pengajar Praktik
              </Button>
            </Empty>
          </Descriptions.Item>
        )}

        {selectedGroup?.daftarPengajarPraktik?.map((pp) => (
          <Descriptions.Item label={pp.name} key={pp.userId}>
            <div className="flex flex-row justify-between items-center">
              <div>
                <p className="mt-2 mb-2">{pp.email}</p>
                {pp.phone && (
                  <>
                    <Divider style={{ margin: 0 }} />
                    <p className="mt-2 mb-0">{pp.phone}</p>
                  </>
                )}
              </div>
              <Popconfirm
                title="Apakah Anda yakin ingin menghapus pengajar praktik ini?"
                onConfirm={() => {
                  onStudentDelete(pp.userId)
                }}
                okButtonProps={{
                  danger: true,
                }}
                okText="Hapus"
                cancelText="Batal"
                placement="topRight"
              >
                <Button danger icon={<Icons.DeleteOutlined />} size="small">
                  Hapus
                </Button>
              </Popconfirm>
            </div>
          </Descriptions.Item>
        ))}
      </Descriptions>

      <Descriptions
        bordered
        column={1}
        size="small"
        title={'Daftar Murid'}
        className="mt-4"
      >
        <Descriptions.Item
          label={
            <Input
              placeholder="Cari Murid"
              prefix={<Icons.SearchOutlined />}
              onChange={(e) => onSearchQueryChange(e.target.value)}
            />
          }
        >
          <div className="flex justify-end">
            <Button
              type="primary"
              onClick={() => {
                onStudentAdd('murid', currentMuridCount)
              }}
              disabled={currentMuridCount >= MAX_KELOMPOK_MURID_MEMBER_COUNT}
            >
              <Icons.PlusOutlined />
              Tambah Murid
            </Button>
          </div>
        </Descriptions.Item>

        {selectedGroup?.daftarMurid
          .filter(
            (item: ClassParticipants) =>
              item.name
                .toLowerCase()
                .includes(searchQuery.toLocaleLowerCase()) ||
              item.email
                .toLowerCase()
                .includes(searchQuery.toLocaleLowerCase()) ||
              item.phone
                .toLowerCase()
                .includes(searchQuery.toLocaleLowerCase()),
          )
          .sort((a, b) => a.name.localeCompare(b.name))

          .map((item: ClassMuridParticipants) => (
            <Descriptions.Item label={item.name} key="item.userId">
              <div className="flex flex-row justify-between items-center gap-2">
                <div>
                  <p className="mt-0 mb-2">{item.email}</p>
                  {item.phone && (
                    <>
                      <Divider style={{ margin: 0 }} />
                      <p className="mt-2 mb-0">{item.phone}</p>
                    </>
                  )}
                </div>
                <div className="flex flex-col gap-2 ">
                  <Popconfirm
                    title="Apakah Anda yakin ingin menghapus murid ini?"
                    onConfirm={() => {
                      onStudentDelete(item.userId)
                    }}
                    okButtonProps={{
                      danger: true,
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                    placement="topRight"
                  >
                    <Button
                      danger
                      icon={<Icons.DeleteOutlined />}
                      size="small"
                      block
                    >
                      Hapus
                    </Button>
                  </Popconfirm>
                  {!item.classGroupValid && SHOULD_SHOW_PINDAH_BUTTON && (
                    <Button
                      type="ghost"
                      icon={<Icons.UserSwitchOutlined />}
                      size="small"
                      block
                      onClick={() =>
                        show({
                          localProgramId: item.localProgramId,
                          classId: item.classId,
                          email: item.email,
                          userId: item.userId,
                          classGroupId: selectedGroup.id,
                        })
                      }
                    >
                      Pindah
                    </Button>
                  )}
                </div>
              </div>
            </Descriptions.Item>
          ))}
      </Descriptions>
      <ModalFormMigrateKelompokMurid {...modalMigrateParam} />
    </Drawer>
  )
}
