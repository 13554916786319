import React from 'react'

import { TFilterOptions } from '@components/DataTableParticipants/types'
import { useFilterOptionsHook } from './useFilterOptionsHook'

type TFilterOptionsContext = {
  data: { data: TFilterOptions }
  isLoading: boolean
  refetch: () => void
  getCityOptions: (provinceId: string) => void
}

export const ParticipantFilterDetaultValue: TFilterOptionsContext = {
  data: {
    data: {
      jenjang: [],
      kapabilitas: [],
      regions: [],
    },
  },
  isLoading: true,
  refetch: () => null,
  getCityOptions: () => null,
}

export const WithParticipantFilter = React.createContext(
  ParticipantFilterDetaultValue,
)

WithParticipantFilter.displayName = 'ParticipantFilterContext'

export function useParticipantFilterContext() {
  const context = React.useContext(WithParticipantFilter)
  if (context === undefined) {
    throw new Error(
      'ParticipantFilterContext must be used within a WithParticipantFilter.Provider',
    )
  }
  return context
}

export function WithParticipantFilterContext(props: any) {
  const participantFilter = useFilterOptionsHook()

  return <WithParticipantFilter.Provider value={participantFilter} {...props} />
}
