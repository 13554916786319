import { Table, TableProps, TextField, Tooltip } from '@pankod/refine-antd'
import React from 'react'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { TCommonError } from 'src/interfaces/common'
import { ReportKategoriPenilaianKinerjaResponse } from '../types'
import { kapabilitasTags } from '@components/DataTableParticipants/blocks/utils'

type ExpandedKategoriPenilaianProps = {
  visible: boolean
  kategoriId: string
  programId: string | string[]
}

const ExpandedKategoriPenilaian = ({
  kategoriId,
  programId,
  visible,
}: ExpandedKategoriPenilaianProps) => {
  const { tableProps } = useTableWithMeta<
    ReportKategoriPenilaianKinerjaResponse,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `programs/${programId}/penilaian-kinerja-categories/${kategoriId}/reports`,
    queryOptions: {
      enabled: visible,
      select(data) {
        data.data.forEach(({ penilaianKinerjas }) => {
          penilaianKinerjas.sort((a, b) => +a.id - +b.id)
        })
        return data
      },
    },
  })

  const penilaianKinerjaCols =
    (): TableProps<ReportKategoriPenilaianKinerjaResponse>['columns'] => {
      tableProps
      return (
        tableProps.dataSource?.[0].penilaianKinerjas.map(
          ({ name, weight }, i) => ({
            title: (
              <Tooltip title={name} placement="topLeft">
                <div className="flex flex-col overflow-hidden">
                  <TextField value={name} ellipsis />
                  <TextField
                    type="secondary"
                    value={`(${String(weight.toFixed(2))}%)`}
                  />
                </div>
              </Tooltip>
            ),
            ellipsis: { showTitle: false },
            width: 220,
            render: (_, __, index) =>
              String(
                (
                  tableProps.dataSource?.[index].penilaianKinerjas[i].score || 0
                ).toFixed(2),
              ),
          }),
        ) || []
      )
    }

  return (
    <div>
      <Table
        dataSource={tableProps.dataSource}
        pagination={tableProps.pagination}
        loading={tableProps.loading}
        size="small"
        bordered
        rowKey={({ email, kelompokMuridId, programLocalId, kelasId, role }) =>
          `${email}|${kelompokMuridId}|${programLocalId}|${kelasId}|${role}`
        }
        className="w-full"
        scroll={{ x: 500 }}
        columns={[
          {
            title: 'Nama Sasaran',
            dataIndex: 'nama',
            fixed: 'left',
            ellipsis: { showTitle: false },
            width: 200,
            render: (value) => (
              <Tooltip placement="topLeft" title={value}>
                {value}
              </Tooltip>
            ),
          },
          {
            title: 'Email Sasaran',
            dataIndex: 'email',
            ellipsis: { showTitle: false },
            width: 200,
            render: (value) => (
              <Tooltip placement="topLeft" title={value}>
                {value}
              </Tooltip>
            ),
          },
          {
            title: 'Nama Program Lokal',
            dataIndex: 'programLocalName',
            ellipsis: { showTitle: false },
            width: 180,
            render: (value) => (
              <Tooltip placement="topLeft" title={value}>
                {value}
              </Tooltip>
            ),
          },
          {
            title: 'Nama Kelas',
            dataIndex: 'kelasName',
            ellipsis: { showTitle: false },
            width: 180,
            render: (value) => (
              <Tooltip placement="topLeft" title={value}>
                {value}
              </Tooltip>
            ),
          },
          {
            title: 'Nama Kelompok Murid',
            dataIndex: 'kelompokMuridName',
            ellipsis: { showTitle: false },
            width: 180,
            render: (value) =>
              value ? (
                <Tooltip placement="topLeft" title={value}>
                  {value}
                </Tooltip>
              ) : (
                <TextField
                  className="block text-center"
                  value={'-'}
                  type="secondary"
                />
              ),
          },
          {
            title: 'Role Sasaran',
            dataIndex: 'role',
            width: 125,
            render: (value) => kapabilitasTags(value),
          },
          ...penilaianKinerjaCols()!,
          {
            title: 'Total Skor',
            dataIndex: 'totalScore',
            width: 100,
            render: (value) => String(value.toFixed(2)),
          },
        ]}
      />
    </div>
  )
}

export default ExpandedKategoriPenilaian
