import React, { useState } from 'react'
import { TextField, Icons, Button, Table } from '@pankod/refine-antd'
import { CrudFilters } from '@pankod/refine-core'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { Participant } from '../type'
import Filter, { TFilterForm } from '@components/ContentFilter'
import { kapabilitasMapper } from '@components/DataTableParticipants/blocks/utils'
import { TCommonError } from 'src/interfaces/common'
import ParticipantDetailModal from '../components/ParticipantDetailModal'
import ProgramLocalFilter from '../components/ProgramLocalFilter'

const ROLE_OPTIONS = [
  {
    text: 'Asisten',
    value: 'asisten',
  },
  {
    text: 'Fasilitator',
    value: 'instruktur',
  },
  {
    text: 'Peserta',
    value: 'peserta',
  },
  {
    text: 'Admin Kelas',
    value: 'admin_kelas',
  },
]

type ParticipantTableList = {
  programId?: string | string[]
}

const ParticipantTableList = ({ programId }: ParticipantTableList) => {
  const { tableProps, searchFormProps } = useTableWithMeta<
    Participant,
    TCommonError,
    TFilterForm
  >({
    resource: `programs/${programId}/participant-consolidation`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId,
    },
    onSearch: (params: Record<string, string>) => {
      const filters: CrudFilters = []
      const { keywords } = params
      filters.push(
        {
          field: 'keywords',
          operator: 'eq',
          value: keywords,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        },
      )
      return filters
    },
    syncWithLocation: true,
  })
  const [showFilterProgramLocal, setShowFilterProgramLocal] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
  return (
    <>
      <div className="p-6">
        <Filter
          formProps={searchFormProps}
          size="small"
          items={[
            {
              label: 'Cari Peserta',
              name: 'keywords',
              placeholder: 'Ketik user ID, email, atau nama',
            },
          ]}
        />
        <Table
          {...tableProps}
          tableLayout="fixed"
          rowKey={'userId'}
          columns={[
            {
              title: 'User ID',
              dataIndex: 'userId',
              width: 100,
              render: (value) => (
                <TextField
                  value={value}
                  title={value}
                  ellipsis
                  className="w-[100px]"
                />
              ),
            },
            {
              title: 'Email',
              dataIndex: 'email',
              ellipsis: true,
              width: 200,
              render: (value) => (
                <TextField
                  value={value}
                  title={value}
                  ellipsis
                  className="w-[200px]"
                />
              ),
            },
            {
              title: 'Nama',
              dataIndex: 'name',
              ellipsis: true,
              width: 150,
              render: (value) => (
                <TextField
                  value={value}
                  title={value}
                  ellipsis
                  className="w-[150px]"
                />
              ),
            },
            {
              title: 'Program Lokal',
              dataIndex: 'programLocalName',
              ellipsis: true,
              width: 150,
              render: (value) => (
                <TextField
                  value={value}
                  ellipsis
                  title={value}
                  className="w-[150px]"
                />
              ),
              filters: [],
              onFilterDropdownVisibleChange(visible) {
                setShowFilterProgramLocal(visible)
              },
              filterDropdownVisible: showFilterProgramLocal,
              filterDropdown: (props) => (
                <ProgramLocalFilter
                  {...props}
                  show={showFilterProgramLocal}
                  programId={programId}
                />
              ),
            },
            {
              title: 'Kapabilitas',
              dataIndex: 'kapabilitas',
              filters: ROLE_OPTIONS,
              filterMultiple: false,
              ellipsis: true,
              render: (value) => (
                <TextField
                  value={kapabilitasMapper(value)}
                  ellipsis
                  title={kapabilitasMapper(value)}
                  className="w-[100px]"
                />
              ),
            },
            {
              title: 'Status Kelengkapan',
              dataIndex: 'emptyDataCount',
              ellipsis: true,
              align: 'center',
              render: (value) =>
                value > 0 ? (
                  <TextField value={`${value} kolom kosong`} type="warning" />
                ) : (
                  <Icons.CheckCircleFilled style={{ color: '#52c41a' }} />
                ),
              fixed: 'right',
            },
            {
              render: (_, record) => (
                <Button
                  icon={<Icons.EyeOutlined />}
                  onClick={() => setSelectedUserId(record.userId)}
                >
                  Lihat
                </Button>
              ),
              width: 200,
              fixed: 'right',
            },
          ]}
        />
      </div>
      <ParticipantDetailModal
        onClose={() => setSelectedUserId(null)}
        userId={selectedUserId}
        programId={programId}
      />
    </>
  )
}

export default ParticipantTableList
