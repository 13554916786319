import {
  Tabs,
  useSimpleList,
  Button,
  Table,
  Icons,
  Space,
  List,
  Descriptions,
  useModalForm,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useDelete,
  useNavigation,
  useOne,
} from '@pankod/refine-core'
import Head from 'next/head'
import React, { useState } from 'react'
import { useRouter } from 'next/router'

import { HeaderTitle } from '@components/HeaderTableTitle'
import { AngkatanTabList } from '../block/AngkatanTabList'
import { AngkatanLPTKListResponse, RombelResponse } from '../types'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import { showErrorNotification, useGetLptkId } from '../utils'
import { useErrorBoundary } from '@components/ErrorBoundary/hook'
import { ShowRombelButton } from '../block/ShowRombelButton'
import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { TCommonError } from 'src/interfaces/common'
import { RombelModalForm } from './blocks/RombelModalForm'

export const RombelList: React.FC<IResourceComponentsProps> = ({ options }) => {
  const { data: kvData } = useOne<any>({
    resource: 'kv?key=feature_flag',
    id: '',
    dataProviderName: 'microlearning',
  })
  const { enableRombelSyncRelatedDataMutation = true } =
    (kvData?.data?.data as any) || {}

  const [activeTab, setActiveTab] = useState('UTAMA')
  const { showBoundary } = useErrorBoundary()

  const router = useRouter()
  const { angkatanId } = router.query

  const [lptkId] = useGetLptkId()

  const { list } = useNavigation()

  const { listProps, queryResult: listQueryResult } =
    useSimpleList<AngkatanLPTKListResponse>({
      resource: `lptk/${lptkId}/angkatan`,
      queryOptions: {
        enabled: !!lptkId,
      },
      permanentFilter: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
    })

  const { tableProps, tableQueryResult } = useTableWithMeta<
    AngkatanLPTKListResponse,
    TCommonError,
    unknown,
    {
      pesertaTanpaRombel: number
      totalPeserta: number
    }
  >({
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/rombel`,
    dataProviderName: 'lms',
    permanentFilter: [
      {
        field: 'type',
        operator: 'eq',
        value: activeTab,
      },
    ],
    queryOptions: {
      enabled: !!lptkId && !!angkatanId,
      onError(err) {
        showBoundary({
          status: err.statusCode,
          onBackClick: () => list('angkatan-ppg-management'),
        })
      },
    },
    syncWithLocation: true,
  })

  const { mutateAsync: doDelete } = useDelete()
  const ROMBEL_RESOURCE = `angkatan/${angkatanId}/lptk/${lptkId}/rombel`

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm({
    action: 'create',
    resource: ROMBEL_RESOURCE,
    dataProviderName: 'lms',
    warnWhenUnsavedChanges: true,
    redirect: false,
    autoResetForm: true,
    autoSubmitClose: true,
    invalidates: ['list'],
    successNotification: {
      message: 'Rombel successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was a problem when creating Rombel'),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<RombelResponse, TCommonError>({
    action: 'edit',
    resource: ROMBEL_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Rombel successfully updated',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was a problem when updating Rombel'),
    metaData: {
      httpMethod: 'patch',
    },
  })

  if (!angkatanId || !lptkId) {
    showBoundary({
      status: 404,
      onBackClick: () => list('angkatan-ppg-management'),
      backButtonText: 'Kembali ke Angkatan',
      title: 'Halaman tidak ditemukan',
    })
    return null
  }

  const columns = [
    {
      title: <HeaderTitle align="left" title="Nama Rombel" />,
      key: 'name',
      dataIndex: 'name',
      width: '40%',
    },
    {
      title: <HeaderTitle align="left" title="Jumlah Peserta" />,
      key: 'jumlahPeserta',
      dataIndex: 'jumlahPeserta',
      width: '20%',
    },
    {
      key: 'action',
      width: '40%',
      render: (_: any, record: any) => (
        <Space>
          <ShowRombelButton
            size="small"
            angkatanId={angkatanId as string}
            rombelId={record.id}
            icon={<Icons.EyeOutlined />}
          >
            Lihat
          </ShowRombelButton>
          {enableRombelSyncRelatedDataMutation && (
            <>
              <Button
                icon={<Icons.EditOutlined />}
                size="small"
                onClick={() => editShow(record.id)}
              >
                Edit
              </Button>
              <PopDeleteConfirm
                title="Apakah Anda yakin ingin menghapus Rombel ini?"
                validateValue={record.name}
                placeholder="Tulis ulang nama Rombel"
                placement="topRight"
                onConfirm={() => {
                  return doDelete({
                    id: record.id,
                    resource: ROMBEL_RESOURCE,
                    dataProviderName: 'lms',
                    invalidates: ['list'],
                    successNotification: {
                      message: 'Rombel successfuly removed',
                      type: 'success',
                      description: 'Successful',
                    },
                    errorNotification: (err) =>
                      showErrorNotification(
                        err,
                        'There was a problem when removing Rombel',
                      ),
                  })
                }}
                children={
                  <Button danger icon={<Icons.DeleteOutlined />} size="small">
                    Hapus
                  </Button>
                }
                okText="Hapus"
                cancelText="Kembali"
                okButtonProps={{ danger: true }}
              />
            </>
          )}
        </Space>
      ),
    },
  ]

  const metaData = tableQueryResult.data?.meta

  return (
    <>
      <List
        title="Daftar Rombel"
        headerButtons={
          enableRombelSyncRelatedDataMutation && (
            <Button icon={<Icons.PlusOutlined />} onClick={() => createShow()}>
              Tambah Rombel
            </Button>
          )
        }
      >
        <AngkatanTabList
          lptkId={lptkId}
          angkatanId={angkatanId as string}
          isLoading={listQueryResult.isFetching}
          listProps={listProps}
        >
          <Head>
            <title>LMS Admin | {options?.label} </title>
          </Head>

          <Descriptions
            bordered
            title="Informasi Rombel"
            className="mb-6"
            layout="vertical"
          >
            <Descriptions.Item label="Peserta Tanpa Rombel">
              {metaData?.pesertaTanpaRombel || 0}
            </Descriptions.Item>
            <Descriptions.Item label="Total Peserta">
              {metaData?.totalPeserta || 0}
            </Descriptions.Item>
          </Descriptions>

          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <Tabs.TabPane key="UTAMA" tab="Rombel Utama">
              <Table
                {...tableProps}
                style={{ width: '100%' }}
                scroll={{ x: 500 }}
                columns={columns}
                rowKey="id"
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="PPL" tab="Rombel PPL">
              <Table {...tableProps} columns={columns} rowKey="id" />
            </Tabs.TabPane>
            <Tabs.TabPane key="SEMINAR" tab="Rombel Seminar">
              <Table {...tableProps} columns={columns} rowKey="id" />
            </Tabs.TabPane>
            <Tabs.TabPane key="KEPEMIMPINAN" tab="Rombel Kepemimpinan">
              <Table {...tableProps} columns={columns} rowKey="id" />
            </Tabs.TabPane>
          </Tabs>
        </AngkatanTabList>
      </List>

      <RombelModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />

      <RombelModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
    </>
  )
}
