import {
  Slider,
  Input,
  InputNumber,
  Button,
  Table,
  Icons,
  Space,
  Alert,
  Popconfirm,
  SliderSingleProps,
  Tooltip,
  Form,
  Select,
  FormInstance,
} from '@pankod/refine-antd'
import { useCreate } from '@pankod/refine-core'
import React, { CSSProperties, useEffect, useState } from 'react'
import clsx from 'clsx'

import { TProgramGrade } from '../../types'
import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { TCommonError } from 'src/interfaces/common'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const DEFAULT_GRADE_CONFIG = [
  {
    label: 'Amat Baik',
    from: 90,
    to: 100,
    color: '#000000',
  },
  {
    label: 'Baik',
    from: 80,
    to: 89,
    color: '#000000',
  },
  {
    label: 'Cukup',
    from: 70,
    to: 79,
    color: '#000000',
  },
  {
    label: 'Tidak Lulus',
    from: 0,
    to: 69,
    color: '#000000',
  },
]

const DEFAULT_PASSING_GRADE_INDEX = 2

export type GradeRangeFormProps = {
  programId: string | number | null | undefined
  onSubmitted?: () => void
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'number' | 'text' | 'color'
  record: TProgramGrade
  index: number
  children: React.ReactNode
  form: FormInstance
  onModified: (value: string | number, index: number, name: string) => void
}

const EditableCell: React.FC<EditableCellProps> = ({
  dataIndex,
  title,
  inputType,
  index,
  children,
  record,
  form,
  onModified,
  ...restProps
}) => {
  const inputNode = (() => {
    switch (inputType) {
      case 'color':
        return <input type="color" className="w-full" />
      case 'number':
        return (
          <InputNumber
            disabled={
              (record.from === 0 && dataIndex === 'from') || dataIndex === 'to'
            }
            step={1}
            precision={0}
            max={
              dataIndex === 'from'
                ? (() => {
                    const currentToValue = form.getFieldValue([index, 'to'])
                    return isNaN(currentToValue) ? 100 : currentToValue
                  })()
                : undefined
            }
            min={
              dataIndex === 'from'
                ? (() => {
                    const nextFromValue = form.getFieldValue([
                      index + 1,
                      'from',
                    ])
                    return record.from === 0 && dataIndex === 'from'
                      ? 0
                      : nextFromValue + 1
                  })()
                : undefined
            }
          />
        )
      default:
        return <Input autoComplete="off" />
    }
  })()

  const getValueFromEvent = (() => {
    switch (inputType) {
      case 'color':
        return (e: React.ChangeEvent<HTMLInputElement>) => {
          const currentValue = form.getFieldValue([index])
          const value = e.target.value
          form.setFieldsValue({
            [index]: { ...currentValue, color: value },
          })
          onModified(value, index, dataIndex)
          return value
        }
      case 'number':
        return (value: string | number) => {
          const nextGradeValue = form.getFieldValue([index + 1])

          form.setFieldsValue({
            [index + 1]: { ...nextGradeValue, to: +value - 1 },
          })
          onModified(value, index, dataIndex)
          return value
        }
      case 'text':
        return (e: React.ChangeEvent<HTMLInputElement>) => {
          const currentValue = form.getFieldValue([index])
          const value = e.target.value
          form.setFieldsValue({
            [index]: { ...currentValue, label: value },
          })
          onModified(value, index, dataIndex)
          return value
        }
      default:
        return () => {}
    }
  })()
  return (
    <td {...restProps}>
      {title ? (
        <Form.Item
          name={[index, dataIndex]}
          style={{ margin: 0 }}
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
              message: `${title} tidak boleh kosong.`,
            },
            {
              validator(_, value) {
                const values = form.getFieldsValue()
                const hasDupe = Object.values<TProgramGrade>(values).some(
                  (v, i) => v.label === value && index !== i,
                )
                if (hasDupe) {
                  return Promise.reject('Label tidak boleh sama.')
                }
                return Promise.resolve()
              },
            },
            // ...(dataIndex === 'from'
            //   ? [
            //       {
            //         validator(_: any, value: string | number) {
            //           const isFromEqualTo = record.to === value
            //           if (isFromEqualTo) {
            //             return Promise.reject(
            //               'Nilai dari dan hingga tidak boleh sama.'
            //             )
            //           }
            //           return Promise.resolve()
            //         },
            //       },
            //     ]
            //   : []),
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export const GradeRangeForm = (props: GradeRangeFormProps) => {
  const [form] = Form.useForm()
  const [grades, setGrades] = useState<TProgramGrade[]>([])
  const [passingGradeIndex, setPassingGradeIndex] = useState(2)
  const { programId, onSubmitted } = props
  const { mutateAsync: updateGrade, isLoading: updateGradeLoading } =
    useCreate()
  const gradeList: Record<number, TProgramGrade> = Form.useWatch([], form)

  const { tableQueryResult } = useTableWithMeta<
    TProgramGrade,
    TCommonError,
    unknown,
    { passingGradeLabel: string }
  >({
    resource: `programs/${programId}/grades`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId,
    },
  })

  useEffect(() => {
    const initialValue = {
      grade: DEFAULT_GRADE_CONFIG,
      passingGradeLabel: DEFAULT_PASSING_GRADE_INDEX,
    }

    if (tableQueryResult?.data?.data.length) {
      initialValue.grade = tableQueryResult.data.data
      initialValue.passingGradeLabel = tableQueryResult.data.data.findIndex(
        ({ label }) => label === tableQueryResult.data!.meta.passingGradeLabel,
      )
    }

    setGrades(initialValue.grade)
    setPassingGradeIndex(initialValue.passingGradeLabel)
    form.setFieldsValue(initialValue.grade)
    form.setFieldsValue({
      passingGrade: initialValue.passingGradeLabel,
    })
  }, [
    form,
    tableQueryResult.data,
    tableQueryResult.data?.data,
    tableQueryResult.data?.meta.passingGradeLabel,
  ])

  const markStyle: Record<string, CSSProperties> = {
    notPassed: {
      color: '#ff5500',
      cursor: 'default',
    },
    passed: {
      color: '#87d068',
      cursor: 'default',
    },
  }

  const getPassingGradeValue = (params: {
    data?: TProgramGrade[]
    passingGradeIndex: number
  }): number => {
    const { data, passingGradeIndex } = params
    if (!data || data.length === 0) return 0
    return (
      data.find(({ label }) => label === grades[passingGradeIndex]?.label)
        ?.from ?? data[data.length - 1].from
    )
  }

  const handleSubmit = async () => {
    await form.validateFields()
    const values = Object.values<TProgramGrade>(form.getFieldsValue())?.filter(
      (value) => typeof value !== 'number',
    )
    const payload = {
      data: values,
      passingGradeLabel: values[passingGradeIndex].label,
    }
    await updateGrade({
      resource: `programs/${programId}/grades`,
      values: payload,
      dataProviderName: 'lms',
      successNotification: () => {
        return {
          message: 'Predikat dan Range Nilai berhasil disimpan',
          type: 'success',
          description: 'Sukses',
        }
      },
      errorNotification: (error) =>
        showErrorNotification(
          error,
          'Terdapat gangguan saat menyimpan perubahan',
        ),
    })
    onSubmitted?.()
  }

  const handleAddGrade = (index: number) => {
    const values = Object.values<TProgramGrade>(form.getFieldsValue())?.filter(
      (value) => typeof value !== 'number',
    )
    const prev = values[index - 1]
    const newValues = values.toSpliced(index, 0, {
      label: `Predikat Baru`,
      from: prev.from,
      to: prev.from,
      color: '#000000',
    })
    newValues[index - 1] = { ...prev, from: prev.from + 1 }
    form.setFieldsValue(newValues)
    setGrades(newValues)
    const passingGrade = newValues.findIndex(
      ({ label }) => label === values[passingGradeIndex]?.label,
    )
    form.setFieldsValue({ passingGrade })
    setPassingGradeIndex(passingGrade)
  }

  const handleDeleteGrade = (index: number) => {
    const values = Object.values<TProgramGrade>(form.getFieldsValue())?.filter(
      (value) => typeof value !== 'number',
    )
    const prev = values[index - 1]
    const newValues = values.toSpliced(index, 1)
    newValues[index - 1] = { ...prev, from: newValues[index].to + 1 }

    form.setFieldsValue(newValues)
    setGrades(newValues)

    if (passingGradeIndex > index) {
      const newPassingGradeIndex = passingGradeIndex - 1
      form.setFieldsValue({ passingGrade: newPassingGradeIndex })
      setPassingGradeIndex(newPassingGradeIndex)
    }

    if (passingGradeIndex === index) {
      form.setFieldsValue({ passingGrade: -1 })
      setPassingGradeIndex(-1)
    }
  }

  const generateMarks = (
    data?: TProgramGrade[],
  ): SliderSingleProps['marks'] => {
    if (!data) return {}
    const passingGradeValue = getPassingGradeValue({ data, passingGradeIndex })
    const result = data?.reduce(
      (result, current, index) => {
        return {
          ...result,
          [current.from]: {
            label: (
              <Tooltip
                title={
                  <div className="flex flex-col justify-center items-center">
                    <p className="m-0">{current.label}</p>
                    <p className="m-0">
                      {current.from} &mdash; {current.to}
                    </p>
                  </div>
                }
                placement="bottom"
              >
                <div
                  className={clsx(
                    current.label === grades[passingGradeIndex]?.label &&
                      'text-warning font-bold',
                    'max-w-[70px] max-h-[50px] overflow-hidden text-ellipsis whitespace-nowrap',
                  )}
                >
                  {current.label}
                </div>
              </Tooltip>
            ),
            style: {
              ...(current.from < passingGradeValue
                ? markStyle['notPassed']
                : markStyle['passed']),
              transform: `translate(-50%, ${index % 2 === 0 ? '-40px' : '0'}) `,
            } as CSSProperties,
          },
        }
      },
      {
        100: {
          label: '100',
          style: { fontWeight: 700, color: '#87d068', pointerEvents: 'none' },
        },
      },
    )
    return result
  }

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      type: 'text',
    },
    {
      title: 'Warna',
      dataIndex: 'color',
      type: 'color',
      width: 100,
    },
    {
      title: 'Dari',
      dataIndex: 'from',
      type: 'number',
    },
    {
      title: 'Hingga',
      dataIndex: 'to',
      type: 'number',
    },
  ]

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record: TProgramGrade, index?: number) => ({
        record,
        inputType: col.type,
        dataIndex: col.dataIndex,
        title: col.title,
        index,
        form,
        onModified: (
          value: string | number,
          index: number,
          name: keyof TProgramGrade,
        ) => {
          setGrades((state) => {
            const newState = [...state]
            // @ts-expect-error
            newState[index][name] = value
            return newState
          })
        },
      }),
    }
  })

  return (
    <>
      {tableQueryResult.isSuccess && !tableQueryResult.data?.data?.length && (
        <Alert
          type="warning"
          description={
            <>
              Data di bawah merupakan konfigurasi default yang belum diterapkan.
              Harap sesuaikan konfigurasi di bawah lalu pilih{' '}
              <strong>Simpan</strong> untuk menerapkan konfigurasi.
            </>
          }
          showIcon
          className="!mb-7 animate-[1s_pulse_5]"
          message="Predikat dan Rentang Nilai belum dikonfigurasi"
        />
      )}
      <div className="px-8 pt-6 my-4 h-[100px]">
        <Slider
          range
          tooltipVisible={false}
          handleStyle={[
            { borderColor: '#ff5500', background: '#ff5500' },
            { background: 'var(--color-warning)', borderColor: '#87d068' },
            { borderColor: '#87d068', background: '#87d068' },
          ]}
          marks={generateMarks(grades)}
          value={[
            0,
            getPassingGradeValue({
              data: grades,
              passingGradeIndex,
            }),
            // @ts-expect-error
            100,
          ]}
          min={0}
          max={100}
          className="!cursor-default"
          trackStyle={[
            {
              backgroundColor: '#ff5500',
            },
            {
              backgroundColor: '#87d068',
            },
          ]}
          dots={false}
        />
      </div>
      <Form form={form} layout="vertical">
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowKey={undefined}
          scroll={{ y: 500 }}
          dataSource={grades}
          pagination={false}
          footer={() => (
            <div className="flex flex-col gap-2">
              <Form.Item
                label={
                  <strong>
                    Pilih Batas Predikat Lulus
                    <Tooltip title="Batas nilai minimal dimana nilai peserta masih dianggap lulus (inklusif)">
                      <Icons.InfoCircleOutlined className="ml-2" />
                    </Tooltip>
                  </strong>
                }
                name="passingGrade"
                getValueFromEvent={(value) => {
                  setPassingGradeIndex(+value)
                  return +value
                }}
                getValueProps={(value) => {
                  if (value === -1) return { value: undefined }
                  return { value }
                }}
                required
                rules={[
                  {
                    required: true,
                    message: 'Batas predikat lulus harus diisi.',
                  },
                  {
                    validator(_, value) {
                      if (value === -1) {
                        return Promise.reject(
                          'Batas predikat lulus harus diisi.',
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Select
                  options={Object.values(gradeList || {})
                    ?.filter((v) => !!v?.label)
                    ?.map?.(({ label }, index) => ({
                      label,
                      value: index,
                    }))}
                />
              </Form.Item>
              <Button
                icon={<Icons.SaveOutlined />}
                type="primary"
                loading={updateGradeLoading}
                onClick={async () => handleSubmit()}
              >
                Simpan
              </Button>
            </div>
          )}
          bordered
          columns={[
            ...mergedColumns,
            {
              width: 260,
              render: (value, record, index) => {
                return (
                  <Space direction="vertical" className="w-full">
                    {value.to !== 100 && (
                      <Button
                        block
                        icon={<Icons.PlusOutlined />}
                        onClick={() => handleAddGrade(index)}
                        disabled={
                          grades[index - 1]?.to === grades[index - 1]?.from
                        }
                      >
                        Tambah Rentang Di Atas
                      </Button>
                    )}
                    <Popconfirm
                      title="Anda yakin ingin menghapus predikat dan rentang nilai ini?"
                      okText="Hapus"
                      okButtonProps={{ danger: true }}
                      cancelText="Batal"
                      placement="topRight"
                      onConfirm={() => handleDeleteGrade(index)}
                      disabled={record.from === 0 || record.to === 100}
                    >
                      <Button
                        disabled={record.from === 0 || record.to === 100}
                        block
                        danger
                        icon={<Icons.DeleteOutlined />}
                      >
                        Hapus
                      </Button>
                    </Popconfirm>
                  </Space>
                )
              },
            },
          ]}
        />
      </Form>
    </>
  )
}
