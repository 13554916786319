import { message } from '@pankod/refine-antd'
import axios from 'axios'
import { parseCookies } from 'nookies'
import { useState, useEffect, useCallback, useRef } from 'react'
import { useRouter } from 'next/router'

interface Option {
  value?: string | null | number
  label: string | React.ReactNode
  children?: Option[]
  isLeaf?: boolean
  loading?: boolean
}

export const useCascaderLoadData = <T extends Option>({
  initialOptions = [],
  resourceUrl,
  optionLabelKey = 'name',
  optionValueKey = 'id',
}: {
  resourceUrl: (id?: string | null | number) => string
  initialOptions?: T[]
  optionLabelKey?: string
  optionValueKey?: string
}) => {
  const [options, setOptions] = useState<T[]>(initialOptions)
  const [fetchedId, setFetchedId] = useState(new Set<string>())
  const { userSession } = parseCookies()
  const { guruToken } = JSON.parse(userSession)

  const getLocalPrograms = async (id?: string | null | number) => {
    if (fetchedId.has(String(id))) return null

    try {
      const response = await axios.get(resourceUrl(id), {
        headers: {
          Authorization: `Bearer ${guruToken}`,
        },
      })
      setFetchedId((state) => {
        state.add(String(id))
        return state
      })
      return response
    } catch {
      message.error('Terdapat gangguan saat mendapatkan daftar item')
      return null
    }
  }

  return {
    loadData: async (selectedOptions: Option[]) => {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true

      const response = await getLocalPrograms(targetOption.value)
      if (!response) {
        targetOption.loading = false
        return
      }

      const childrenOptions =
        response?.data?.data?.map((item: any) => ({
          label: item[optionLabelKey],
          value: item[optionValueKey],
        })) || []

      targetOption.loading = false
      targetOption.children = childrenOptions
      setOptions([...options])
    },
    options,
    setOptions,
  }
}

export const useWarnIfUnsavedChanges = (unsaved: boolean) => {
  const router = useRouter()
  const alreadyConfirmToLeave = useRef(false)
  const handleAnchorClick = (e: MouseEvent) => {
    if (e.button !== 0) return // only handle left-clicks
    const targetUrl = (e.currentTarget as HTMLAnchorElement).href
    const currentUrl = window.location.href
    if (targetUrl !== currentUrl && window.onbeforeunload) {
      // @ts-ignore
      const res = window.onbeforeunload()
      if (!res) e.preventDefault()
    }
  }

  const addAnchorListeners = useCallback(() => {
    const anchorElements =
      document.querySelectorAll<HTMLAnchorElement>('a[href]')
    anchorElements.forEach((anchor) =>
      anchor.addEventListener('click', handleAnchorClick),
    )
  }, [])

  useEffect(() => {
    const mutationObserver = new MutationObserver(addAnchorListeners)
    mutationObserver.observe(document.body, { childList: true, subtree: true })
    addAnchorListeners()

    return () => {
      mutationObserver.disconnect()
      const anchorElements =
        document.querySelectorAll<HTMLAnchorElement>('a[href]')
      anchorElements.forEach((anchor) =>
        anchor.removeEventListener('click', handleAnchorClick),
      )
    }
  }, [addAnchorListeners])

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      e?.preventDefault?.()
      e.returnValue = '' // required for Chrome
    }

    const handlePopState = (e: PopStateEvent) => {
      if (unsaved && !alreadyConfirmToLeave.current) {
        const confirmLeave = window.confirm(
          'You have unsaved changes. Are you sure you want to leave?',
        )
        if (!confirmLeave) {
          e?.preventDefault?.()
          window.history.pushState(null, '', window.location.href)
          router.events.emit('routeChangeError')
        } else {
          alreadyConfirmToLeave.current = true
        }
      }
    }

    const handleRouteChangeError = () => {
      throw Promise.reject('Cancel redirect')
    }

    if (unsaved) {
      router.events.on('beforeHistoryChange', handlePopState)
      window.addEventListener('beforeunload', beforeUnloadHandler)
      // window.addEventListener('popstate', handlePopState)
      router.events.on('routeChangeError', handleRouteChangeError)
    } else {
      router.events.off('beforeHistoryChange', handlePopState)
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      // window.removeEventListener('popstate', handlePopState)
      router.events.off('routeChangeError', handleRouteChangeError)
    }

    return () => {
      router.events.off('beforeHistoryChange', handlePopState)
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      // window.removeEventListener('popstate', handlePopState)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [router.events, unsaved])

  useEffect(() => {
    const originalPush = router.push

    router.push = async (url: string) => {
      if (unsaved && !alreadyConfirmToLeave.current) {
        const confirmLeave = window.confirm(
          'You have unsaved changes. Are you sure you want to leave?',
        )
        if (confirmLeave) {
          alreadyConfirmToLeave.current = true
          return await originalPush(url)
        }
        throw Promise.reject(false)
      } else {
        return await originalPush(url)
      }
    }

    return () => {
      router.push = originalPush
    }
  }, [router, unsaved])
}
