import {
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Empty,
  Form,
  Icons,
  Input,
  InputNumber,
  Modal,
  RcFile,
  Space,
  TextField,
  Upload,
  UploadFile,
  message,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useCustomMutation, useDelete } from '@pankod/refine-core'
import dayjs from 'dayjs'
import 'dayjs/locale/id'
import locale from 'antd/lib/locale/id_ID'

const DEFAULT_PAYMENT_AMOUNT =
  +process.env.NEXT_PUBLIC_DEFAULT_WEBINAR_PAYMENT_AMOUNT

import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import separateThousand from 'utils/separateThousand'
import { PDF_MIME_LIST } from 'src/constant'

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const useWebinarPaymentProofModal = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    id: string | undefined
    webinarId: string | number
    name: string
  }>({
    id: '',
    webinarId: '',
    name: '',
    show: false,
  })

  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: ({
      id,
      name,
      webinarId,
    }: {
      id: string | undefined
      webinarId: string | number
      name: string
    }) => {
      setShowId({ id, name, show: true, webinarId })
    },
    visible: !!showId.show,
    id: showId.id,
    name: showId.name,
    onClose,
    webinarId: showId.webinarId,
  }
}

type UploadPaymentProofProps = {
  visible: boolean
  name: string
  localProgramId?: string | number
  webinarId: string | number
  id: string | undefined
  url?: string | null
  date?: string | null
  amount?: number | string | null
  notes?: string | null
  onSuccess?: (id: string | number) => void
  onClose?: () => void
}

const WebinarPaymentProofModal = ({
  visible,
  url,
  name,
  id,
  onSuccess,
  onClose,
  amount,
  date,
  localProgramId,
  webinarId,
  notes,
}: UploadPaymentProofProps) => {
  const [form] = Form.useForm()
  const { mutateAsync: doDelete } = useDelete()
  const { mutateAsync: doSave } = useCustomMutation()
  const [isEditing, setEditing] = useState(false)
  const [preview, setPreview] = useState<string | null>(null)
  const [file, setFile] = useState<UploadFile>()
  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      PDF_MIME_LIST.includes(file.type)
    if (!isJpgOrPng) {
      message.error('Hanya dapat mengunggah berkas PDF atau JPG/PNG')
    }
    const isFileSizeAcccepted = file.size / 1024 / 1024 < 16
    if (!isFileSizeAcccepted) {
      message.error('Ukuran maksimal gambar 16MB')
    }
    setFile(file)
    form.setFieldsValue({ paymentProof: file })
    setPreview(await getBase64(file))
    return false
  }

  const handleClose = () => {
    setEditing(false)
    setPreview(null)
    setFile(undefined)
    onClose?.()
  }

  const handleSave = async () => {
    await form.validateFields()
    const data = form.getFieldsValue()
    const formData = new FormData()
    formData.append('paymentProof', file as RcFile)
    formData.append('paymentDate', dayjs(data.paymentDate).format('YYYY-MM-DD'))
    formData.append('amount', data.amount || '')
    formData.append('notes', data.notes || '')
    return doSave(
      {
        url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/local-programs/${localProgramId}/webinars/${webinarId}/class-group/${id}/payment-proof`,
        method: 'post',
        dataProviderName: 'lms',
        values: formData,
        config: {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
        successNotification: {
          message: 'Bukti pembayaran berhasil diunggah',
          type: 'success',
          description: 'Sukses',
        },
        errorNotification: (error: any) =>
          showErrorNotification(
            error,
            'There was a problem when uploading payment proof',
          ),
      },
      {
        onSuccess: () => {
          handleClose()
          onSuccess?.(id!)
        },
      },
    )
  }
  return (
    <Modal
      visible={visible}
      title="Bukti Pembayaran"
      onCancel={handleClose}
      footer={
        <Space>
          <Button
            onClick={
              isEditing
                ? () => {
                    setEditing(false)
                    setPreview(null)
                    setFile(undefined)
                    form.resetFields()
                  }
                : handleClose
            }
          >
            {isEditing ? 'Batal' : 'Kembali'}
          </Button>
          <Divider type="vertical" />
          {url && (
            <PopDeleteConfirm
              title="Apakah Anda yakin ingin menghapus bukti pembayaran dan mengubah status menjadi belum dibayar?"
              okText="Hapus"
              okButtonProps={{ danger: true }}
              cancelText="Batal"
              onConfirm={() =>
                doDelete(
                  {
                    id: `${id}/payment-proof`,
                    resource: `local-programs/${localProgramId}/webinars/${webinarId}/class-group`,
                    dataProviderName: 'lms',
                    invalidates: ['list'],
                    successNotification: {
                      message: 'Bukti pembayaran berhasil dihapus',
                      type: 'success',
                      description: 'Sukses',
                    },
                    errorNotification: (err) =>
                      showErrorNotification(
                        err,
                        'There was a problem when deleting bukti pembayaran',
                      ),
                  },
                  {
                    onSuccess: () => {
                      handleClose()
                      onSuccess?.(id!)
                    },
                  },
                )
              }
            >
              <Button danger>Hapus</Button>
            </PopDeleteConfirm>
          )}

          {!url && !isEditing && (
            <Button
              type="primary"
              onClick={async () => {
                form.setFieldsValue({
                  paymentProof: url,
                  paymentDate: date ? dayjs(date) : undefined,
                  amount,
                  notes,
                })
                setEditing(true)
              }}
            >
              Tambah Bukti Pembayaran
            </Button>
          )}

          {isEditing && (
            <Button type="primary" onClick={handleSave}>
              Simpan
            </Button>
          )}
        </Space>
      }
    >
      <Form form={form}>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Nama Fasilitator">{name}</Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ verticalAlign: 'top' }}
            label={
              <>
                Tanggal Pembayaran
                {isEditing && <TextField type="danger" value="*" />}
              </>
            }
          >
            {isEditing ? (
              <Form.Item
                name="paymentDate"
                required
                rules={[
                  {
                    required: true,
                    message: 'Tanggal harus diisi',
                  },
                ]}
                style={{ marginBottom: 0 }}
                initialValue={dayjs()}
              >
                <DatePicker
                  locale={locale.DatePicker}
                  format={'DD MMM YYYY'}
                  showToday={false}
                  allowClear={false}
                  style={{ width: 160 }}
                />
              </Form.Item>
            ) : date ? (
              dayjs(date).format('DD MMM YYYY')
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ verticalAlign: 'top' }}
            label={
              <>
                Jumlah Pembayaran
                {isEditing && <TextField type="danger" value="*" />}
              </>
            }
          >
            {isEditing ? (
              <Form.Item
                name="amount"
                required
                rules={[
                  { required: true, message: 'Jumlah harus diisi' },
                  {
                    validator(_, value) {
                      if (+value <= 0) {
                        return Promise.reject(
                          'Tidak boleh kurang atau sama dengan 0',
                        )
                      }

                      return Promise.resolve()
                    },
                  },
                ]}
                style={{ marginBottom: 0 }}
                initialValue={DEFAULT_PAYMENT_AMOUNT}
              >
                <InputNumber
                  prefix="Rp"
                  controls={false}
                  stringMode
                  style={{ width: 160 }}
                  formatter={separateThousand}
                  parser={(value) => value!.replace(/(\.*)/g, '')}
                />
              </Form.Item>
            ) : amount ? (
              `Rp${separateThousand(amount)}`
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ verticalAlign: 'top' }}
            label="Catatan"
          >
            {isEditing ? (
              <Form.Item name="notes" style={{ marginBottom: 0 }}>
                <Input.TextArea rows={2} style={{ resize: 'none' }} />
              </Form.Item>
            ) : (
              notes || '-'
            )}
          </Descriptions.Item>
        </Descriptions>
        <div className="text-center">
          {url ? (
            <Button
              className="mt-5"
              icon={<Icons.DownloadOutlined />}
              href={url}
              target="_blank"
            >
              Unduh Bukti Pembayaran
            </Button>
          ) : preview ? null : (
            <Empty
              description="Tidak ada bukti pembayaran"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className="!mb-0"
            />
          )}
          {isEditing && (
            <Form.Item
              name="paymentProof"
              rules={[{ required: true, message: 'Berkas harus diisi' }]}
            >
              {preview && (
                <TextField
                  value="Bukti bayar sudah dipilih"
                  className="w-full block mt-4"
                />
              )}
              <Upload
                accept=".jpg, .jpeg, .png, .pdf"
                beforeUpload={beforeUpload}
                multiple={false}
                showUploadList={false}
                key="webinarPaymentProofModalUpload"
              >
                <Button className="mt-4">
                  {preview ? 'Ganti' : 'Unggah'} Bukti Bayar
                </Button>
              </Upload>
            </Form.Item>
          )}
        </div>
      </Form>
    </Modal>
  )
}

export default WebinarPaymentProofModal
