import { Button, Icons } from '@pankod/refine-antd'
import React from 'react'

import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'

type DownloadTemplateButton = {
  filename: string
  columns: string[]
}

const DownloadTemplateButton = (props: DownloadTemplateButton) => {
  const handleDownloadClick = () => {
    downloadTemplate(
      `${props.columns.join(',')}\n${props.columns
        .map((_, i) => '_____'.replace(/_/g, String.fromCharCode(97 + i)))
        .join(',')}`,
      props.filename,
      'template_participant',
    )
  }

  return (
    <Button
      type="text"
      icon={<Icons.DownloadOutlined />}
      onClick={handleDownloadClick}
    >
      Unduh Template
    </Button>
  )
}

export default DownloadTemplateButton
