import { useCreate, useNavigation, useUpdate } from '@pankod/refine-core'

import { TFormInformationOnSaveClickParam } from '@components/FormBuilder/editor/FormInformation'
import { ErrorFormGenerator, TFormGeneratorRequest } from './interface'
import { ErrorCode } from 'src/interfaces/error'

const handleErrors = (
  error: ErrorFormGenerator,
  onError?: (
    widgetError: { widgetNode: string; reason: string }[],
    paramsError: { name: string; reason: string }[],
  ) => void,
) => {
  if (error.statusCode === 400) {
    const errors = error.response?.data.errors || []
    const widgetError: { widgetNode: string; reason: string }[] = []
    const paramsError: { name: string; reason: string }[] = []
    for (let error of errors) {
      if (error.code === ErrorCode.WIDGET) {
        widgetError.push({
          reason: error.message,
          widgetNode: error.detail.widgetNode,
        })
        continue
      }

      if (error.code === ErrorCode.FORM_FIELD) {
        paramsError.push({
          name: error.detail.field,
          reason: error.message,
        })
      }
    }
    onError?.(widgetError, paramsError)
  }
}

export type UseSavePageOptions = {
  onError?: (
    widgetError: { widgetNode: string; reason: string }[],
    paramsError: { name: string; reason: string }[],
  ) => void
}
export const useCreatePage = () => {
  const { list } = useNavigation()
  const { mutateAsync: doCreate, isLoading: createLoading } = useCreate<
    {},
    ErrorFormGenerator,
    TFormGeneratorRequest
  >()

  const createForm = async (
    {
      pageData,
      contentSchema,
      validationSchema,
      formType,
    }: TFormInformationOnSaveClickParam,
    params?: UseSavePageOptions,
  ) => {
    const metaFormPayload: any = {
      excerpt: pageData.excerpt,
      title: pageData.title,
      maxScore:
        (pageData as any).totalMaxLikertValue ||
        (pageData as any).quantitativeTotalMax,
      formType: formType && formType.toUpperCase(),
      totalQuestions:
        (pageData as any).totalLikertQuestion ||
        (pageData as any).totalQuantitativeQuestion,
    }

    if (formType === 'likert') {
      metaFormPayload.likertOptions = (pageData as any).likertOptions
    }

    return doCreate(
      {
        resource: 'forms',
        values: {
          contentSchema,
          validationSchema,
          formType: formType && formType.toUpperCase(),
          metaForm: JSON.stringify(metaFormPayload),
        },
        successNotification: () => ({
          description: 'Successful',
          type: 'success',
          message: 'Successfully created Form',
        }),
        errorNotification: (error?) => {
          if ((error as ErrorFormGenerator)?.statusCode === 500) {
            return {
              message: 'Please try again later',
              type: 'error',
              description: 'Error Creating Data',
            }
          }

          return {
            message: 'Please check your data again before submitting',
            type: 'error',
            description: 'Data Invalid',
          }
        },
      },
      {
        onSuccess: () => {
          list('form-generator')
        },
        onError: (error) => handleErrors(error, params?.onError),
      },
    )
  }

  return {
    createForm,
    createLoading,
  }
}

export const useUpdatePage = () => {
  const { list } = useNavigation()
  const { mutateAsync: doUpdate, isLoading: updateLoading } = useUpdate<
    {},
    ErrorFormGenerator,
    TFormGeneratorRequest
  >()

  const updateForm = async (
    {
      pageData: { id, ...pageData },
      contentSchema,
      formType,
      validationSchema,
    }: TFormInformationOnSaveClickParam,
    params?: UseSavePageOptions,
  ) => {
    const metaFormPayload = {
      excerpt: pageData.excerpt,
      title: pageData.title,
      maxScore:
        (pageData as any).totalMaxLikertValue ||
        (pageData as any).quantitativeTotalMax,
      formType: formType && formType.toUpperCase(),
      totalQuestions:
        (pageData as any).totalLikertQuestion ||
        (pageData as any).totalQuantitativeQuestion,

      likertOptions: (pageData as any).likertOptions,
    }

    if (formType === 'likert') {
      metaFormPayload.likertOptions = (pageData as any).likertOptions
    }

    return doUpdate(
      {
        resource: 'forms',
        id,
        values: {
          contentSchema,
          validationSchema,
          metaForm: JSON.stringify(metaFormPayload),
        },
        successNotification: () => ({
          description: 'Successful',
          type: 'success',
          message: 'Successfully updated Form',
        }),
        errorNotification: (error?) => {
          if ((error as ErrorFormGenerator)?.statusCode === 500) {
            return {
              message: 'Please try again later',
              type: 'error',
              description: 'Error Updating Data',
            }
          }

          return {
            message: 'Please check your data again before submitting',
            type: 'error',
            description: 'Data Invalid',
          }
        },
      },
      {
        onSuccess: () => {
          list('form-generator')
        },
        onError: (error) => handleErrors(error, params?.onError),
      },
    )
  }

  return {
    updateForm,
    updateLoading,
  }
}
