import { Button, Drawer, Icons, Space, Table, Tabs } from '@pankod/refine-antd'
import {
  useCreate,
  useDelete,
  useInvalidate,
  useResource,
} from '@pankod/refine-core'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { kapabilitasTags } from '@components/DataTableParticipants/blocks/utils'
import useTableWithMeta from 'src/hooks/useTableWithMeta'
import ModalAssignUnallocatedParticipant from './ModalAssignUnallocatedParticipant'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { PopDeleteConfirm } from '@components/PopDeleteConfirm'

type DrawerUnallocatedResourceProps = {
  visible: boolean
  onClose: () => void
}

const DrawerUnallocatedResource = ({
  onClose,
  visible,
}: DrawerUnallocatedResourceProps) => {
  const { id: localProgramId } = useResource()
  const router = useRouter()
  const programId = router.query.programId
  const [assignParticipantModal, setAssignParticipantModal] = useState<
    | undefined
    | {
        userId: string
        email: string
        name: string
        kapabilitas: string
      }
  >(undefined)
  const [activeKey, setActiveKey] = useState('kelas')
  const { tableProps, setFilters } = useTableWithMeta({
    dataProviderName: 'lms',
    resource: `programs/${programId}/local-programs/${localProgramId}/unallocated-resources/${activeKey}`,
    queryOptions: {
      enabled: visible,
    },
  })
  const { mutateAsync: unassignParticipant } = useDelete()
  const invalidate = useInvalidate()
  const { mutateAsync: createParticipant } = useCreate()
  return (
    <Drawer
      title="Daftar Kelas & Peserta Belum Teralokasi"
      visible={visible}
      onClose={onClose}
      width={'70%'}
      destroyOnClose
      maskClosable={false}
      afterVisibleChange={(visible) => {
        if (!visible) {
          setActiveKey('kelas')
          setFilters([{ field: 'page', operator: 'eq', value: '1' }])
        }
      }}
    >
      <Tabs
        activeKey={activeKey}
        onChange={(activeKey) => {
          setActiveKey(activeKey)
          setFilters([{ field: 'page', operator: 'eq', value: '1' }])
        }}
      >
        <Tabs.TabPane
          key="kelas"
          tab="Daftar Kelas tanpa Fasilitator/Pengajar Praktik (Asisten)"
        >
          <Table
            {...tableProps}
            rowKey={'id'}
            columns={[
              { title: 'ID', dataIndex: 'id', width: 100 },
              { title: 'Nama Kelas', dataIndex: 'name', width: 320 },
              {
                title: 'Aksi',
                render(_, record) {
                  return (
                    <Button
                      icon={<Icons.EyeOutlined />}
                      onClick={() => {
                        router.push(
                          `/lms-class-management/show/${record.id}?programId=${programId}&programLocalId=${localProgramId}`,
                        )
                      }}
                      size="small"
                    >
                      Lihat Kelas
                    </Button>
                  )
                },
              },
            ]}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="actors" tab="Daftar Peserta belum masuk Kelas">
          <Table
            {...tableProps}
            rowKey={'userId'}
            scroll={{ x: 500 }}
            tableLayout="fixed"
            columns={[
              { title: 'User ID', dataIndex: 'userId', width: 150 },
              { title: 'Email Peserta', dataIndex: 'email', width: 200 },
              { title: 'Nama Peserta', dataIndex: 'name', width: 300 },
              {
                width: 150,
                title: 'Kapabilitas',
                dataIndex: 'kapabilitas',
                render: (value) => {
                  return kapabilitasTags(value)
                },
              },
              {
                width: 170,
                fixed: 'right',
                title: 'Aksi',
                render(_, record) {
                  return (
                    <Space direction="vertical">
                      <Button
                        icon={<Icons.ExportOutlined />}
                        block
                        onClick={() => {
                          setAssignParticipantModal({
                            email: record.email,
                            name: record.name,
                            userId: record.userId,
                            kapabilitas: record.kapabilitas,
                          })
                        }}
                        size="small"
                      >
                        <i className="mx-1 ml-2">Assign</i> ke Kelas
                      </Button>
                      <PopDeleteConfirm
                        title={
                          <>
                            Apakah Anda yakin ingin melepas (<i>unassign</i>)
                            peserta dari program lokal ini?
                          </>
                        }
                        okText={<i>Unassign</i>}
                        cancelText="Batal"
                        okButtonProps={{ danger: true }}
                        placement="bottomRight"
                        onConfirm={async () => {
                          await unassignParticipant(
                            {
                              resource: `programs/${programId}/local-programs/${localProgramId}/participants`,
                              dataProviderName: 'lms',
                              metaData: {
                                payload: {
                                  userId: record.userId,
                                },
                              },
                              id: '',
                              successNotification: () => {
                                return {
                                  message: 'Peserta berhasil diunassign',
                                  description: 'Sukses',
                                  type: 'success',
                                }
                              },
                              errorNotification: (error) =>
                                showErrorNotification(
                                  error,
                                  'Terdapat gangguan saat melakukan unassign peserta',
                                ),
                            },
                            {
                              onSettled: () => {
                                invalidate({
                                  invalidates: ['list'],
                                  dataProviderName: 'lms',
                                  resource: `programs/${programId}/local-programs/${localProgramId}/unallocated-resources/${activeKey}`,
                                })
                              },
                            },
                          )
                        }}
                      >
                        <Button
                          size="small"
                          danger
                          icon={<Icons.UserDeleteOutlined />}
                          block
                        >
                          <i className="mx-1 ml-2">Unassign</i> Peserta
                        </Button>
                      </PopDeleteConfirm>
                    </Space>
                  )
                },
              },
            ]}
          />
        </Tabs.TabPane>
      </Tabs>
      <ModalAssignUnallocatedParticipant
        visible={!!assignParticipantModal}
        selectedUser={assignParticipantModal}
        onClose={() => {
          setAssignParticipantModal(undefined)
        }}
        onSubmit={async (data) => {
          await createParticipant(
            {
              resource: `programs/${programId}/local-programs/${localProgramId}/classes/${data.kelasId}/participants`,
              dataProviderName: 'lms',
              values: {
                users: [
                  {
                    email: data.email,
                    name: data.name,
                    userId: data.userId,
                  },
                ],
              },
              successNotification: () => {
                return {
                  message: 'Peserta berhasil ditambahkan ke kelas',
                  description: 'Sukses',
                  type: 'success',
                }
              },
              errorNotification: (e) =>
                showErrorNotification(
                  e,
                  'Terdapat gangguan saat menambahkan peserta ke kelas',
                ),
            },
            {
              onSettled: () => {
                setAssignParticipantModal(undefined)
                invalidate({
                  invalidates: ['list'],
                  dataProviderName: 'lms',
                  resource: `programs/${programId}/local-programs/${localProgramId}/unallocated-resources/${activeKey}`,
                })
              },
            },
          )
        }}
      />
    </Drawer>
  )
}

export default DrawerUnallocatedResource
