import {
  Button,
  Divider,
  Icons,
  Modal,
  Popconfirm,
  Space,
  Table,
  TextField,
} from '@pankod/refine-antd'
import { useCustomMutation, useOne } from '@pankod/refine-core'
import React from 'react'
import { AxiosError } from 'axios'

import { ParticipantDataSource } from '@resources/lms-management/participant-management/type'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type ApprovalModal = {
  programId?: string | string[] | number
  selectedId: string
  onClose: () => void
  onSubmitSuccess?: () => void
  onSubmitError?: (error: AxiosError) => void
}

const ApprovalModal = ({
  onClose,
  selectedId,
  programId,
  onSubmitSuccess,
  onSubmitError,
}: ApprovalModal) => {
  const { mutateAsync } = useCustomMutation()
  const { data } = useOne<{
    data: {
      id: string
      attributes: {
        label: string
        before: string
        after: string
        source: ParticipantDataSource
      }[]
    }
  }>({
    dataProviderName: 'lms',
    id: selectedId,
    resource: `programs/${programId}/participant-consolidation/requests`,
    queryOptions: {
      enabled: !!selectedId && !!programId,
    },
  })
  const handleSubmit = async (status: 'APPROVED' | 'REJECTED') => {
    await mutateAsync(
      {
        method: 'patch',
        url: `${process.env.LMS_API_URL}/programs/${programId}/participant-consolidation/requests/${selectedId}/status`,
        values: {
          status,
        },
        successNotification: {
          message: `Pengajuan perubahan data telah berhasil ${status === 'APPROVED' ? 'disetujui' : 'ditolak'}`,
          type: 'success',
          description: 'Sukses',
        },
        errorNotification(error) {
          return showErrorNotification(
            error,
            'Terdapat gangguan saat memberikan persetujuan/penolakan perubahan data',
          )
        },
      },
      {
        onSuccess: () => {
          onSubmitSuccess?.()
          onClose()
        },
        onError: (error) => {
          onSubmitError?.(error as unknown as AxiosError)
        },
      },
    )
  }
  return (
    <Modal
      title="Detail Pengajuan Perubahan Data"
      visible={!!selectedId}
      maskClosable={false}
      onCancel={() => {
        onClose()
      }}
      destroyOnClose
      width={700}
      footer={
        <Space>
          <Button
            onClick={() => {
              onClose()
            }}
          >
            Kembali
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Apakah Anda yakin ingin menolak perubahan data ini?"
            cancelText="Batal"
            okText="Tolak"
            okButtonProps={{ danger: true }}
            onConfirm={async () => {
              await handleSubmit('REJECTED')
            }}
          >
            <Button danger icon={<Icons.CloseCircleOutlined />}>
              Tolak
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Apakah Anda yakin ingin menyetujui perubahan data ini?"
            cancelText="Batal"
            okText="Setujui"
            onConfirm={async () => {
              await handleSubmit('APPROVED')
            }}
          >
            <Button type="primary" icon={<Icons.CheckCircleFilled />}>
              Setujui
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      <Table
        size="small"
        bordered
        dataSource={data?.data.data.attributes}
        pagination={false}
        columns={[
          {
            dataIndex: 'label',
            render: (value) => <TextField value={value} strong />,
          },
          { title: 'Sebelum Perubahan', dataIndex: 'before' },
          { title: 'Diubah Menjadi', dataIndex: 'after' },
        ]}
      />
    </Modal>
  )
}

export default ApprovalModal
