import {
  Button,
  Drawer,
  Form,
  Icons,
  Table,
  Tabs,
  useForm,
} from '@pankod/refine-antd'
import React from 'react'
import { useList } from '@pankod/refine-core'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
export type DrawerManagePesertaKelasDasusProps = {
  visible: boolean
  drawerProps?: any
  onClose: () => void
  programId: string | number
  programLocalId: string | number
  programLocalClassId: string | number
  className: string
}

export const DrawerManagePesertaKelasDasus = (
  props: DrawerManagePesertaKelasDasusProps,
) => {
  const {
    visible,
    onClose,
    programId,
    className,
    programLocalId,
    programLocalClassId,
  } = props

  // list

  //api.staging.belajar.id/guru/lms-be/cms/programs/503/local-programs/630/classes/13284/participants?page=1&pageSize=10
  const { data: participantClassList } = useList({
    resource: `programs/${programId}/local-programs/${programLocalId}/classes/${programLocalClassId}/participants?pageSize=-1&roleKelas=peserta`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!visible,
    },
  })

  const { formProps } = useForm({
    action: 'edit',
    resource: `pgp-dasus/programs/${programId}/local-programs/${programLocalId}/classes/${programLocalClassId}/participants`,
    dataProviderName: 'lms',
    redirect: false,
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'Terjadi Kesalahan melakukan update gelombang',
      ),
    queryOptions: {
      enabled: visible,
    },
  })

  const selectedRowKeys = Form.useWatch('participants', formProps?.form)

  return (
    <Drawer
      visible={visible}
      closable={false}
      onClose={onClose}
      width="50%"
      className="relative"
      destroyOnClose
      {...props.drawerProps}
    >
      <div className="flex justify-end items-end">
        <Button
          size="small"
          icon={<Icons.CloseOutlined />}
          onClick={() => {
            window.confirm(
              'Are you sure you want to close this form? Your changes will be lost',
            ) && onClose()
          }}
        />
      </div>
      <Tabs defaultActiveKey="rubrik" destroyInactiveTabPane>
        <Tabs.TabPane tab="Peserta Kelas" key="rubrik">
          <h3 className="mb-8">List Peserta Kelas: {className}</h3>

          <Form {...formProps} layout="vertical">
            <Form.Item
              name="participants"
              rules={[{ required: true }]}
              hidden
            ></Form.Item>
            <Table
              scroll={{ y: 500 }}
              dataSource={participantClassList?.data}
              rowKey="email"
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedRowKeys || [],
                preserveSelectedRowKeys: true,
                onChange: (selectedRowKeys) => {
                  formProps?.form?.setFieldsValue({
                    participants: selectedRowKeys,
                  })
                },
              }}
            >
              <Table.Column title="Email" dataIndex="email" key="email" />
              <Table.Column title="Name" dataIndex="name" key="name" />
              <Table.Column
                title="Provinsi"
                dataIndex="provinsi"
                key="provinsi"
              />
              <Table.Column title="Jenjang" dataIndex="jenjang" key="jenjang" />
            </Table>

            <Button
              type="primary"
              htmlType="submit"
              className="mt-8"
              block
              size="large"
            >
              Update Peserta Kelas
            </Button>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}
