import { Drawer } from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'

import { WebinarTableList } from '@resources/lms-management/webinar/blocks/TableList'

export const useWebinarConfigDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
  }>({ show: false, programId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, programId: id })
    },
    visible: !!showId.show,
    programId: showId.programId,
    onClose,
  }
}

type WebinarDrawerProps = {
  visible: boolean
  onClose: () => void
  programId: string | number
}

const WebinarDrawer = ({ onClose, visible }: WebinarDrawerProps) => {
  return (
    <Drawer
      title="Daftar Webinar"
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      maskClosable={false}
    >
      <WebinarTableList />
    </Drawer>
  )
}

export default WebinarDrawer
