import { Popover } from '@pankod/refine-antd'
import clsx from 'clsx'
import React from 'react'

const ImageRenderPreview = (props: {
  value?: string
  className?: string
  align?: 'left' | 'center'
  previewSize?: { height: number; width: number }
}) => {
  return (
    <div className={clsx('h-6 relative', props.className)}>
      {props.value && props.value !== '' && (
        <Popover
          title={<div className="text-center">Pratinjau Gambar</div>}
          content={
            <div
              className="relative"
              style={{
                height: props.previewSize?.height || 300,
                width: props.previewSize?.width || 300,
              }}
            >
              <img
                src={props.value}
                className="absolute top-0 left-0 w-full h-full object-contain"
                alt="gambar ilustrasi besar"
              />
            </div>
          }
          placement="left"
        >
          <img
            src={props.value}
            className={clsx(
              'absolute top-0 left-0 aspect-auto h-full object-cover max-w-full',
              props.align === 'center' ? 'object-center' : 'object-left',
            )}
            alt="gambar ilustrasi"
          />
        </Popover>
      )}
    </div>
  )
}

export default ImageRenderPreview
