import { Form, Modal, notification, Radio } from '@pankod/refine-antd'
import React, { useState } from 'react'

import { getGuruToken } from 'src/helpers/session'

const ExportKeterisianButton = (props: {
  programId?: string | string[] | number
}) => {
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  return (
    <>
      <span onClick={() => setShowModal(true)}>Ekspor Laporan Keterisian</span>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        afterClose={() => {
          form.resetFields()
        }}
        onOk={async () => {
          await form.validateFields()
          const type = form.getFieldValue('type')
          const response = await fetch(
            process.env.NEXT_PUBLIC_LMS_API_URL +
              `/programs/${props.programId}/laporan-keterisian/export` +
              `?type=${type}`,
            {
              headers: {
                Authorization: `Bearer ${getGuruToken()}`,
              },
            },
          )
          if (!response.ok) {
            notification.error({
              message: 'Error',
              description: 'Terdapat gangguan saat mengunduh laporan',
            })
            return
          }
          try {
            const data = await response.json()
            const csvResponse = await fetch(data.exportUrl)
            if (!csvResponse.ok) {
              throw new Error('File not found')
            }
            const a = document.createElement('a')
            a.setAttribute('href', data.exportUrl)
            a.setAttribute('target', '_blank')
            a.setAttribute(
              'download',
              `laporan_${type}_program_${props.programId}.csv`,
            )
            a.click()
            setShowModal(false)
            notification.success({
              message: 'Sukses',
              description: 'Laporan berhasil diunduh',
            })
          } catch (e) {
            notification.error({
              message: 'Error',
              description: 'Laporan tidak ditemukan',
            })
          }
        }}
        okText="Unduh"
        cancelText="Kembali"
        title="Ekspor Laporan Keterisian"
      >
        <Form
          form={form}
          layout="vertical"
          validateMessages={{ required: '${label} harus diisi.' }}
        >
          <Form.Item
            name="type"
            label="Jenis Laporan"
            required
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={'KETERISIAN_TYPE_LOKA_KARYA'}>Loka Karya</Radio>
              <Radio value={'KETERISIAN_TYPE_PENDAMPINGAN_INDIVIDU'}>
                Pendampingan Individu
              </Radio>
              <Radio value={'KETERISIAN_TYPE_PRESENSI'}>Presensi</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ExportKeterisianButton
