import sanitize from 'sanitize-html'

/** Resource https://github.com/benwinding/quill-image-compress/blob/master/src/downscaleImage.ts */

// Take an image URL, downscale it to the given width, and return a new image URL.
export async function downscaleImage({
  dataUrl,
  ignoreImageTypes,
  imageQuality,
  imageType,
  keepImageTypes,
  maxHeight,
  maxWidth,
}: {
  dataUrl: string
  maxWidth?: number
  maxHeight?: number
  imageType?: string
  keepImageTypes?: string[]
  ignoreImageTypes?: string[]
  imageQuality?: number
}) {
  // Input image values
  const inputImageType = dataUrl.split(';')[0].split(':')[1]

  // Provide default values
  imageType = imageType || 'image/jpeg'
  imageQuality = imageQuality || 0.7

  // Create a temporary image so that we can compute the height of the downscaled image.
  const image = new Image()
  image.src = dataUrl
  await new Promise<void>((resolve) => {
    image.onload = () => {
      resolve()
    }
  })
  const [newWidth, newHeight] = getDimensions(
    image.width,
    image.height,
    maxWidth,
    maxHeight,
  )

  // Create a temporary canvas to draw the downscaled image on.
  const canvas = document.createElement('canvas')
  canvas.width = newWidth
  canvas.height = newHeight

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

  // If the type is an jpeg, draw a white background first.
  if (imageType === 'image/jpeg') {
    ctx.fillStyle = '#FFFFFF'
    ctx.fillRect(0, 0, image.width, image.height)
  }

  // If the type is included in the ignore list, return the original
  if (ignoreImageTypes?.includes(inputImageType)) {
    return dataUrl
  }

  // If the type is included in keep type list, fix the image type
  if (keepImageTypes?.includes(inputImageType)) {
    imageType = inputImageType
  }

  // Draw the downscaled image on the canvas and return the new data URL.
  ctx.drawImage(image, 0, 0, newWidth, newHeight)
  const newDataUrl = canvas.toDataURL(imageType, imageQuality)

  return newDataUrl
}

function getDimensions(
  inputWidth: number,
  inputHeight: number,
  maxWidth?: number,
  maxHeight?: number,
): [number, number] {
  if (
    maxWidth &&
    maxHeight &&
    inputWidth <= maxWidth &&
    inputHeight <= maxHeight
  ) {
    return [inputWidth, inputHeight]
  }
  if (maxWidth && inputWidth > maxWidth) {
    const newWidth = maxWidth
    const newHeight = Math.floor((inputHeight / inputWidth) * newWidth)

    if (maxHeight && newHeight > maxHeight) {
      const newHeight = maxHeight
      const newWidth = Math.floor((inputWidth / inputHeight) * newHeight)
      return [newWidth, newHeight]
    } else {
      return [newWidth, newHeight]
    }
  }
  if (maxHeight && inputHeight > maxHeight) {
    const newHeight = maxHeight
    const newWidth = Math.floor((inputWidth / inputHeight) * newHeight)
    return [newWidth, newHeight]
  }
  return [inputHeight, inputWidth]
}

export const file2b64 = async (file: Blob) => {
  const fileReader = new FileReader()

  const promise = new Promise<string>((resolve, reject) => {
    fileReader.addEventListener(
      'load',
      () => {
        const base64ImageSrc = fileReader.result?.toString()
        if (!base64ImageSrc) {
          reject('Could not convert file to base64')
        } else {
          resolve(base64ImageSrc)
        }
      },
      false,
    )
  })
  fileReader.readAsDataURL(file)
  return promise
}

export const sanitizeHtml = (html: string) =>
  sanitize(html, {
    allowedTags: sanitize.defaults.allowedTags.concat(['img', 'del', 'a']),
    allowedAttributes: {
      a: ['href', 'rel', 'target'],
      img: ['src'],
    },
    allowedSchemesByTag: {
      img: ['data'],
    },
    exclusiveFilter: (frame) =>
      frame.tag === 'p' && !frame.text.trim() && !frame.mediaChildren.length,
  })
