import { DrawerProps, ModalProps } from '@pankod/refine-antd'
import {
  GetOneResponse,
  useCustomMutation,
  useInvalidate,
  useOne,
} from '@pankod/refine-core'
import { useState } from 'react'
import prependHttp from 'prepend-http'

import { TCommonResponse } from 'src/interfaces/common'
import { ProgramLocalWebinarClass } from './types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export type UseWebinarDrawerParam = {
  resource: string
  enabled: boolean
  width?: string
}
export type UseWebinarDrawerReturn<T = any> = {
  open: (id: number | string) => void
  isFetching: boolean
  data?: GetOneResponse<TCommonResponse<T>>['data']['data']
  drawerProps: DrawerProps
}
export const useWebinarDrawer = <T>({
  enabled,
  resource,
  width = '75%',
}: UseWebinarDrawerParam): UseWebinarDrawerReturn<T> => {
  const [id, setid] = useState<null | number | string>(null)
  const { data, isFetching } = useOne<TCommonResponse<T>>({
    resource,
    id: id ?? -1,
    queryOptions: {
      enabled: enabled && !!id,
    },
    dataProviderName: 'lms',
  })
  const open = (id: number | string) => {
    setid(id)
  }
  const close = () => {
    setid(null)
  }
  const realData = data?.data?.data
  const drawerProps: DrawerProps = {
    visible: id !== null,
    onClose: close,
    width,
  }

  return {
    open,
    isFetching,
    data: realData,
    drawerProps,
  }
}

export type UseUrlDataModalParam = {
  webinarData?: ProgramLocalWebinarClass
}
export type UseUrlDataModalReturn = {
  open: (id: number | string) => void
  isLoading: boolean
  data?: {
    webinarUrl: string | null
    inquiryUrl: string | null
    reportUrl: string | null
  }
  modalProps: ModalProps
  onSubmit: (data: UseUrlDataModalReturn['data']) => void
}
export const useUrlDataModal = ({
  webinarData,
}: UseUrlDataModalParam): UseUrlDataModalReturn => {
  let data: UseUrlDataModalReturn['data'] = undefined

  const [id, setid] = useState<null | number | string>(null)
  const { mutateAsync, isLoading } = useCustomMutation()
  const invalidate = useInvalidate()

  const open = (id: number | string) => {
    setid(id)
  }

  const close = () => {
    setid(null)
  }

  const handleUpdateUrl = (data: UseUrlDataModalReturn['data']) => {
    if (!data || !webinarData) return
    return mutateAsync({
      method: 'put',
      url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/webinar/${webinarData.webinar.id}/schedule`,
      values: {
        webinarId: webinarData.webinar.id,
        classGroupId: id,
        date: webinarData.classGroups.find(
          ({ id: classGroupId }) => classGroupId === id,
        )?.executionDate,
        webinarUrl: data.webinarUrl ? prependHttp(data.webinarUrl) : null,
        inquiryUrl: data.inquiryUrl ? prependHttp(data.inquiryUrl) : null,
        reportUrl: data.reportUrl ? prependHttp(data.reportUrl) : null,
      },
      successNotification: {
        message: 'URL link successfully updated',
        type: 'success',
        description: 'Successful',
      },
      errorNotification: (err) =>
        showErrorNotification(err, 'Failed to update URL link'),
    }).then(() => {
      invalidate({
        resource: `local-programs/${webinarData.programLocal.id}/webinars`,
        id: webinarData.webinar.id,
        dataProviderName: 'lms',
        invalidates: ['detail'],
      })
      close()
    })
  }

  const modalProps: ModalProps = {
    visible: id !== null,
    onCancel: close,
  }

  if (webinarData) {
    const classGroup = webinarData.classGroups.find(({ id: cId }) => cId === id)
    if (classGroup) {
      data = {
        inquiryUrl: classGroup.inquiryUrl || null,
        reportUrl: classGroup.reportUrl || null,
        webinarUrl: classGroup.webinarUrl || null,
      }
    }
  }

  return {
    modalProps,
    open,
    isLoading,
    data,
    onSubmit: handleUpdateUrl,
  }
}
