import { Tabs } from '@pankod/refine-antd'
import React from 'react'

import PesertaTableList from './PesertaTableList'
import NonPesertaTableList from './NonPesertaTableList'

type DataAlterationApprovalTab = {
  programId?: string | string[] | number
}

const DataAlterationApprovalTab = (props: DataAlterationApprovalTab) => {
  return (
    <Tabs tabPosition="left" destroyInactiveTabPane>
      <Tabs.TabPane tab="Peserta" key="peserta">
        <PesertaTableList programId={props.programId} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Non Peserta" key="non-peserta">
        <NonPesertaTableList programId={props.programId} />
      </Tabs.TabPane>
    </Tabs>
  )
}

export default DataAlterationApprovalTab
