import { Editor, Frame } from '@craftjs/core'
import {
  Drawer,
  DrawerProps,
  Input,
  Spin,
  notification,
} from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import React, { useEffect, useContext, useMemo } from 'react'
import { Button, ButtonProps } from '@instructure/ui-buttons'
import _ from 'lodash'

import { TFormGeneratorDetail } from '@resources/form-generator/interface'
import resolver from './resolver'
import SkeletonLoading from './SkeletonLoading'
import ContentAreaCard from './ContentAreaCard'
import { ctx } from './formContext'
import { TCommonResponse } from 'src/interfaces/common'
import { decompressContentSchema } from 'utils/contentSchemaCompressor'

type TPagePreviewDrawerCommon = {
  initialValue?: Object
  loading?: boolean
  submitButtonProps?: ButtonProps
  backButtonProps?: ButtonProps
  draftButtonProps?: ButtonProps
  drawerProps?: DrawerProps
  onSubmit?: (data: any, rules: any) => Promise<unknown>
  onDraft?: (data: any) => Promise<unknown>
  title?: string
  extra?: React.ReactNode
}

type TPagePreviewPropsWithShowHide = {
  selectedId?: string
  show: boolean
  setShow: (show: boolean) => void
  onSubmit?: (data: any) => Promise<unknown>
}
type TPagePreviewDrawerPropsWithNullId = {
  selectedId?: string | null
  setSelectedId: (id: string | null) => void
}
type TPagePreviewDrawerProps =
  | TPagePreviewPropsWithShowHide
  | TPagePreviewDrawerPropsWithNullId
const PagePreviewDrawer = (
  props: TPagePreviewDrawerProps & TPagePreviewDrawerCommon,
) => {
  const selectedId = props.selectedId
  let setSelectedId = (_id: string | null) => {}
  if ('setSelectedId' in props) {
    setSelectedId = props.setSelectedId
  }
  let visible = !!selectedId
  if ('show' in props) {
    visible = visible && props.show
  }
  const { data, isFetching } = useOne<TCommonResponse<TFormGeneratorDetail>>({
    resource: 'forms',
    id: selectedId!,
    queryOptions: {
      enabled: !!selectedId,
    },
  })

  const formData = data?.data

  const schema = useMemo(() => {
    if (!formData?.data.contentSchema) return undefined

    return decompressContentSchema(formData.data.contentSchema)
  }, [formData?.data?.contentSchema])

  const onSubmit = (data: any) => {
    notification.info({
      message: 'Form value valid!',
      description: (
        <Input.TextArea
          readOnly
          autoSize={{ minRows: 3, maxRows: 6 }}
          value={JSON.stringify(data, null, 2)}
        />
      ),
      placement: 'bottomRight',
    })
  }

  const { handleSubmit, setValidationSchema, clearErrors, reset, setValue } =
    useContext(ctx)

  const parseValidationSchema = (
    schema?: string | object,
  ): string | undefined => {
    if (!schema || typeof schema === 'string') return schema
    return JSON.stringify(schema)
  }

  /** Clean up form value */
  useEffect(() => {
    return reset?.()
  }, [reset])

  useEffect(() => {
    reset?.(props.initialValue || {})
    setValidationSchema?.(
      parseValidationSchema(formData?.data?.validationSchema),
    )
  }, [
    formData?.data?.validationSchema,
    props.initialValue,
    reset,
    setValidationSchema,
  ])

  return (
    <Drawer
      visible={visible}
      afterVisibleChange={(visible) => {
        if (!visible) {
          reset?.()
          if ('setShow' in props) {
            props.setShow(false)
            return
          }
          setSelectedId(null)
          clearErrors?.()
        }
      }}
      closable={false}
      width="425px"
      className="relative"
      destroyOnClose
      {...props.drawerProps}
    >
      <Editor resolver={resolver} enabled={false}>
        <ContentAreaCard
          title={props.title || `Form LTI | ID: ${selectedId}`}
          extra={props.extra}
        >
          {props.loading && (
            <div
              className={
                'w-full h-full absolute l-0 r-0 b-0 t-0 z-10 bg-white opacity-60 flex justify-center items-center'
              }
            >
              <Spin size="large" />
            </div>
          )}
          <form
            onSubmit={handleSubmit?.(
              async ({ isDraft, ...data }) => {
                if (isDraft) {
                  await props?.onDraft?.(data)
                } else {
                  await (props?.onSubmit?.(
                    data,
                    formData?.data?.validationSchema,
                  ) || onSubmit(data))
                }
                reset?.(data)
              },
              (errors) => {
                notification.warning({
                  description:
                    'Terdapat item yang belum diisi, silakan cek kembali form sebelum disubmit.',
                  message: 'Form belum lengkap',
                })
                const [firstKey] = _.toPairs(errors)[0]
                const element = document.querySelector(
                  `input[name='${firstKey}']`,
                )
                element?.scrollIntoView()
              },
            )}
          >
            {!schema || isFetching ? <SkeletonLoading /> : null}
            {schema && !isFetching ? (
              <div className="max-h-[calc(100vh_-_175px)] overflow-auto">
                <Frame key={selectedId} data={schema} />
              </div>
            ) : null}
            <div className="bg-white p-3 border-t-4 border-t-black flex gap-2 justify-end">
              {props.backButtonProps && (
                <div className="mr-auto">
                  <Button {...props.backButtonProps} />{' '}
                </div>
              )}

              {props.draftButtonProps && (
                <Button
                  {...props.draftButtonProps}
                  type="submit"
                  onClick={() => {
                    setValidationSchema?.(undefined)
                    setValue?.('isDraft', true)
                  }}
                />
              )}
              <Button
                type="submit"
                children="Validate"
                {...props.submitButtonProps}
                onClick={() => {
                  setValidationSchema?.(
                    parseValidationSchema(formData?.data?.validationSchema),
                  )
                  setValue?.('isDraft', false)
                }}
              />
            </div>
          </form>
        </ContentAreaCard>
      </Editor>
    </Drawer>
  )
}

export default PagePreviewDrawer
