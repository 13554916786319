import { Button, Form, Icons, Popover, Select } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import React, { useState } from 'react'

import { TFilterOptions } from '@components/DataTableParticipants/types'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'
import { getGuruToken } from 'src/helpers/session'

const DownloadRegionCodeButton = () => {
  const [downloading, setDownloading] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const [form] = Form.useForm()
  const { data: filterOptions, isFetching } = useOne<TFilterOptions>({
    resource: `programs/participants`,
    id: `filter-options`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: showPopover,
      select(data) {
        data.data.regions = data.data.regions.sort((a, b) =>
          a.kode.localeCompare(b.kode),
        )
        return data
      },
    },
  })
  const region = Form.useWatch('region', form)
  const kab_kotaList =
    filterOptions?.data?.regions?.find(({ kode }) => kode == region)
      ?.kabupaten || []
  return (
    <Popover
      visible={showPopover}
      onVisibleChange={setShowPopover}
      trigger="click"
      content={
        <div className="w-[200px]">
          <Form
            form={form}
            layout="vertical"
            onFinish={async (data) => {
              try {
                setDownloading(true)
                const kodeWilayah = data.kabupaten_kota || data.region
                const response = await fetch(
                  process.env.NEXT_PUBLIC_LMS_API_URL +
                    `/programs/participants/filter-options/export?` +
                    new URLSearchParams({ kodeWilayah }),
                  {
                    headers: {
                      Authorization: `Bearer ${getGuruToken()}`,
                    },
                  },
                )
                const text = await response.text()
                downloadTemplate(text, `kode_wilayah_${kodeWilayah}`, 'daftar')
                setShowPopover(false)
              } catch (e) {
              } finally {
                setDownloading(false)
              }
            }}
          >
            <Form.Item
              name="region"
              label="Provinsi"
              getValueFromEvent={(value) => {
                form.resetFields(['kabupaten_kota'])
                return value
              }}
              required
              rules={[
                {
                  required: true,
                  message: 'Provinsi harus dipilih',
                },
              ]}
            >
              <Select
                options={filterOptions?.data?.regions?.map(
                  ({ kode, name }) => ({
                    label: name,
                    value: kode,
                  }),
                )}
                loading={isFetching}
                placeholder="Pilih Provinsi"
                showSearch
                onSearch={() => {}}
                filterOption={(input, option) =>
                  (option!.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              name="kabupaten_kota"
              label="Kabupaten/Kota"
              dependencies={['region']}
            >
              <Select
                options={kab_kotaList
                  ?.sort((a, b) => a.kode.localeCompare(b.kode))
                  ?.map(({ kode, name }) => ({
                    label: name,
                    value: kode,
                  }))}
                disabled={!region}
                loading={isFetching}
                placeholder="Pilih Kabupaten/Kota"
                showSearch
                onSearch={() => {}}
                filterOption={(input, option) =>
                  (option!.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              icon={<Icons.DownloadOutlined />}
              loading={isFetching || downloading}
            >
              Unduh Kode Wilayah
            </Button>
          </Form>
        </div>
      }
      title="Pilih Wilayah"
    >
      <Button
        icon={<Icons.DownloadOutlined />}
        onClick={() => {
          form.resetFields()
          setShowPopover((show) => !show)
        }}
      >
        Kode Wilayah
      </Button>
    </Popover>
  )
}

export default DownloadRegionCodeButton
