import {
  DatePicker,
  Form,
  FormProps,
  Icons,
  Input,
  InputNumber,
  Modal,
  ModalProps,
} from '@pankod/refine-antd'
import React from 'react'
import 'dayjs/locale/id'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import locale from 'antd/lib/locale/id_ID'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Jakarta')

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
}
const ModalForm = ({
  formProps,
  modalProps,
  mode,
  onClose,
}: ModalFormProps) => {
  const isEditMode = mode === 'edit'

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={isEditMode ? 'Edit Paket Modul' : 'Buat Paket Modul'}
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => {
        formProps.form?.resetFields()
      }}
      okButtonProps={{
        ...modalProps.okButtonProps,
        icon: <Icons.SaveOutlined />,
      }}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues?.data,
          timespend_period: [
            formProps?.initialValues?.data?.startAt,
            formProps?.initialValues?.data?.endAt,
          ],
        }}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ timespend_period, ...data }) => {
          formProps.onFinish?.({
            ...data,
            startAt: timespend_period[0],
            endAt: timespend_period[1],
          })
        }}
      >
        <Form.Item
          label="Nama Paket Modul"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Jumlah Sesi Webinar Maksimal"
          name="maxWebinarSessions"
          required
          rules={[{ required: true }]}
        >
          <InputNumber min={0} autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Periode Timespend"
          name="timespend_period"
          rules={[
            { required: true },
            {
              validator(_, value) {
                if (!value || !value[0] || !value[1]) {
                  return Promise.reject(
                    new Error('Periode Timespend harus diisi.'),
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(dates) => ({
            value: dates
              ? [
                  dates[0] ? dayjs(dates[0]) : undefined,
                  dates[1] ? dayjs(dates[1]) : undefined,
                ]
              : [],
          })}
          getValueFromEvent={(date) => {
            if (!date) return [null, null]
            const [startDate, endDate]: [Dayjs, Dayjs] = date
            return [startDate.tz().toISOString(), endDate.tz().toISOString()]
          }}
        >
          <DatePicker.RangePicker
            format="DD MMM YYYY HH:mm"
            locale={locale.DatePicker}
            allowEmpty={[false, false]}
            showTime={{
              defaultValue: [dayjs().startOf('day'), dayjs().endOf('day')],
            }}
          />
        </Form.Item>
        <Form.Item
          label="Prioritas Urutan"
          name="sortPriority"
          required
          rules={[
            {
              required: true,
              message: 'Prioritas Urutan harus diisi.',
            },
            {
              validator: (_, value) => {
                if (value <= 0)
                  return Promise.reject('Prioritas Urutan harus lebih dari 0.')
                return Promise.resolve()
              },
            },
          ]}
        >
          <InputNumber
            autoComplete="false"
            addonBefore={<Icons.SortAscendingOutlined />}
            min={1}
            controls={false}
            precision={0}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalForm
