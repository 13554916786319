import { ITreeMenu } from '@pankod/refine-core'
import { useEffect, useState } from 'react'

const mapIncludedMenu = (menuItems: ITreeMenu[]) => {
  const includedMenus = menuItems.map(({ options, children, key }) => {
    let includes =
      options?.include?.map((value: string) => ({ [value]: key })) || []
    if (children) {
      includes = [...includes, ...mapIncludedMenu(children)]
    }
    return includes
  })
  return includedMenus.flatMap((value) => value)
}

const generateSelectedKey = (
  menuItems: ITreeMenu[],
): Record<string, string> => {
  let result = {}
  mapIncludedMenu(menuItems).forEach((value) => {
    result = { ...result, ...value }
  })
  return result
}

export const useGetSelectedKey = (menuItems: ITreeMenu[]) => {
  const [selectedKeys, setSelectedKeys] = useState<Record<string, string>>({})
  useEffect(() => {
    setSelectedKeys(generateSelectedKey(menuItems))
  }, [menuItems])

  return (selectedKey: string) => selectedKeys[selectedKey] || selectedKey
}
