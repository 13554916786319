import axios, { AxiosResponse } from 'axios'

import { APIResponse } from 'types/api'
import { UserSession } from 'types/user'

interface IFetchGuruToken {
  tokenId: string
}

const baseAuthUrl = process.env.BASE_AUTH_URL

export async function fetchGuruToken({ tokenId }: IFetchGuruToken) {
  try {
    const path = '/teachers/v1alpha2/login'
    const {
      data: { data: responseData },
    } = (await axios({
      url: `${baseAuthUrl}${path}`,
      method: 'POST',
      data: {
        grant_type: 'GOOGLE',
        token: tokenId,
      },
    })) as AxiosResponse<APIResponse<UserSession>>

    return {
      user: responseData,
    }
  } catch (e: any) {
    let error = 'FetchGuruTokenError'

    const { message, response } = e
    if (message === 'Network Error') {
      error = 'FetchGuruTokenErrorNetwork'
    } else if ([404, 401].includes((response as any)?.data?.code)) {
      error = 'FetchGuruTokenErrorNotRegistered'
    }
    return {
      error,
    }
  }
}

export async function fetchLMSRole({ guruToken }: { guruToken: string }) {
  try {
    const path = '/admins/me'
    const { data } = (await axios({
      url: `${process.env.LMS_API_URL}${path}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${guruToken}`,
      },
    })) as AxiosResponse<{
      data: { role: string; email: string; lptkId: number }
    }>

    return {
      LMSRole: data?.data.role || null,
      lptkId: data?.data.lptkId || null,
    }
  } catch (e: any) {
    const { message, response } = e
    let error = 'FetchLMSRoleError'

    if (message === 'Network Error') {
      error = 'FetchLMSRoleErrorNetwork'
    } else if ([404, 401].includes((response as any)?.data?.code)) {
      error = 'FetchLMSRoleErrorNotRegistered'
    }

    return {
      error,
      LMSRole: null,
      lptkId: null,
    }
  }
}

// export async function refreshGoogleToken(token: any) {
//   try {
//     const url =
//       'https://oauth2.googleapis.com/token?' +
//       new URLSearchParams({
//         client_id: process.env.GOOGLE_ID!,
//         client_secret: process.env.GOOGLE_SECRET!,
//         grant_type: 'refresh_token',
//         refresh_token: token.refreshToken,
//       })

//     const response = await fetch(url, {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//       method: 'POST',
//     })

//     const refreshedTokens = await response.json()

//     if (!response.ok) {
//       throw refreshedTokens
//     }

//     return {
//       ...token,
//       idToken: refreshedTokens.id_token,
//       refreshToken: refreshedTokens.refresh_token ?? token.refreshToken, // Fall back to old refresh token
//       tokenExpires: Date.now() + Number(refreshedTokens.expires_in) * 1000,
//     }
//   } catch (error) {
//     return {
//       ...token,
//       error: 'RefreshAccessTokenError',
//     }
//   }
// }
