import {
  Button,
  Form,
  Icons,
  Modal,
  ModalProps,
  Upload,
  Popconfirm,
  Input,
  FormProps,
} from '@pankod/refine-antd'
import React, { useState } from 'react'

import { parseCSVToObject } from 'utils/uploadCSVParser'
import { downloadTemplate, generateTemplate } from './generateCSVTemplate'

type csvHeaderConfig = {
  name: string
  optional?: boolean
}

type PresetValidateRules = {
  name: string
  result: boolean
}

type ModalParseProps = {
  modalProps: ModalProps
  formProps: FormProps
  onClose: () => void
  onSubmit?: () => Promise<any>
  onFinish?: (data: any) => void
  validCSVHeaders: Array<string | csvHeaderConfig>
  maxEntries?: number

  formLoading?: boolean
  title?: string
  dataIndex?: string
  presetValidate?: {
    name: string
    mapEntry?: Map<string, string>
    entries?: string[]
    optional?: boolean
    rules?: (value: any) => PresetValidateRules
  }[]
  okButtonText?: string
}
const ModalParse = ({
  formProps,
  modalProps,
  onClose,
  onFinish,
  onSubmit,
  formLoading,
  validCSVHeaders,
  title,
  dataIndex,
  presetValidate,
  okButtonText = 'Simpan',
  maxEntries,
}: ModalParseProps) => {
  const [errorMessages, setErrorMessages] = useState<any>({})

  const formData = Form.useWatch('data', formProps?.form)
  const initialData = formProps?.initialValues?.data

  const hasChanged = JSON.stringify(initialData) !== JSON.stringify(formData)

  const uploadProps = parseCSVToObject(({ data }) => {
    formProps.form?.setFieldsValue({ data })

    if (maxEntries && data.length > maxEntries) {
      setErrorMessages({
        maxEntries: `Jumlah maksimal data yang dapat diupload adalah ${maxEntries}`,
      })
      return
    }

    const isValid = validateFormData(data)
    setErrorMessages(isValid)
  })

  const handleClose = () => {
    setErrorMessages({})
    formProps.form?.resetFields()
    onClose && onClose()
  }

  // validate formData if hasChanded based on validCSVHeaders
  const validateFormData = (data: any) => {
    const errors: any = {}
    validCSVHeaders.forEach((header) => {
      const validHeader = typeof header === 'object' ? header.name : header

      const isHeaderValid = data.some((item: any) => {
        if (typeof header === 'object' && header.optional) return true

        return item.hasOwnProperty(header)
      })
      if (!isHeaderValid) {
        errors[validHeader] = `Tidak terdapat header "${header}" pada file CSV`
      }

      data.some((item: any) => {
        const isValid = validatePreset(validHeader, item[validHeader])

        if (!isValid) {
          errors[validHeader] =
            `Data "${item[validHeader]}" pada header "${validHeader}" tidak valid`
        }

        return !isValid
      })
    })
    return errors
  }

  const validatePreset = (header: string, value: any) => {
    if (!presetValidate) return true

    const preset = presetValidate.find((item) => item.name === header)

    if (!preset) return true

    if (preset.optional && !value) return true

    const { mapEntry, entries, rules } = preset

    if (entries && value)
      return entries.some(
        (entry) => entry.toLowerCase() === value.toLowerCase(),
      )

    if (mapEntry) return mapEntry.has(value)

    if (rules) return rules(value).result
  }

  const csvSample = generateTemplate(validCSVHeaders)

  return (
    <Modal
      {...modalProps}
      onCancel={handleClose}
      maskClosable={false}
      closable={false}
      title={`Bulk Tambah Data${title ? ` ${title}` : ''}`}
      cancelText="Kembali"
      {...(onSubmit && {
        okButtonProps: {
          onClick: async () => {
            const data = await onSubmit()
            formProps && formProps?.onFinish?.(data)
          },
        },
      })}
      footer={[
        <Popconfirm
          title="Apakah Anda yakin keluar tanpa menyimpan data?"
          key="back"
          onConfirm={handleClose}
          disabled={!hasChanged}
          okText="Ya"
          okButtonProps={{ style: { width: '50px' } }}
          cancelText="Tidak"
        >
          <Button onClick={hasChanged ? undefined : handleClose}>
            Kembali
          </Button>
        </Popconfirm>,
        <Button
          key="edit"
          type="primary"
          icon={<Icons.SaveOutlined />}
          disabled={!hasChanged || Object.keys(errorMessages).length > 0}
          loading={formLoading}
          onClick={async () => {
            if (onFinish) return onFinish(formData)

            const data = onSubmit && (await onSubmit())

            formProps && formProps?.onFinish?.(data)
          }}
        >
          {okButtonText}
        </Button>,
      ]}
    >
      <div className="flex flex-col w-full items-center">
        {!hasChanged && (
          <>
            <div className="my-8 items-center flex-col text-center w-full overflow-x-auto">
              <p>Contoh Format CSV / Table yang diterima:</p>
              {/* generate table based on validCSVHeaders */}
              <table className="table-auto w-full border-collapse border border-gray-400">
                <thead>
                  <tr>
                    {validCSVHeaders.map((header) => (
                      <th
                        className="border border-gray-400 px-4 py-2"
                        key={
                          typeof header === 'object'
                            ? `${header.name} ${
                                header.optional && '(optional)'
                              }`
                            : header
                        }
                      >
                        {typeof header === 'object'
                          ? `${header.name} ${header.optional && '(optional)'}`
                          : header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {validCSVHeaders.map((header) => (
                      <td
                        className="border border-gray-400 px-4 py-2"
                        key={typeof header === 'object' ? header.name : header}
                      >
                        "{typeof header === 'object' ? header.name : header}"
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="text-xs text-surface-subdued">
              Silakan upload file CSV untuk memulai
            </p>
            <Button
              type="link"
              size="small"
              icon={<Icons.DownloadOutlined />}
              onClick={() => {
                downloadTemplate(csvSample, title)
              }}
            >
              Unduh Template CSV
            </Button>
          </>
        )}

        {hasChanged && (
          // generate table with fixed header based on formData
          <div className="w-full overflow-x-auto max-h-[400px]">
            <table className="min-w-full divide-y divide-gray-200 max-h-[400px]">
              <thead className="sticky top-0 backdrop-blur-sm">
                <tr>
                  {Object.keys(formData[0]).map((key) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      key={key}
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {formData.map((row: any, index: number) => (
                  <tr key={index}>
                    {Object.values(row).map((value, index) => (
                      <td className="px-6 py-4 whitespace-nowrap" key={index}>
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {Object.keys(errorMessages).length > 0 ? (
          //  return list of error messages
          <div className="w-full overflow-x-auto mt-8 text-left ">
            {/* use generic list */}
            <ul className="list-disc list-inside text-red-500">
              {Object.keys(errorMessages).map((key) => (
                <li key={key}>{errorMessages[key]}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="w-full overflow-x-auto mt-4 text-center ">
            <p className="text-green-500">
              {hasChanged && 'Format CSV Data valid'}
            </p>
          </div>
        )}

        <Upload {...uploadProps}>
          <Button
            icon={<Icons.UploadOutlined />}
            className="mt-4"
            type={
              hasChanged && Object.keys(errorMessages).length === 0
                ? 'default'
                : 'primary'
            }
          >
            {hasChanged ? 'Upload Ulang' : 'Upload'} CSV
          </Button>
        </Upload>
      </div>

      {presetValidate &&
        presetValidate.map((preset) => (
          <div
            className="my-8 items-center flex-col w-full"
            key={`preset-table-${preset.name}`}
          >
            <p>
              value Kolom <b>{preset.name}</b> yang diterima:
            </p>

            <pre
              style={{
                whiteSpace: 'break-spaces',
              }}
            >
              <p>{preset.entries && preset.entries.join(', ')}</p>
              <p>
                {preset.mapEntry &&
                  Array.from(preset.mapEntry.keys()).join(', ')}
              </p>
              <p>{preset.rules && preset.rules('').name}</p>
            </pre>
          </div>
        ))}

      <Form
        {...formProps}
        onFinish={({ data }) => {
          const payload = dataIndex ? { [dataIndex]: data } : data

          formProps.onFinish?.(payload)
        }}
        {...(onFinish && {
          onFinish: (values) => {
            onFinish(values)
          },
        })}
      >
        <Form.Item name="data" noStyle hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalParse
