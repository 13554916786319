import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  FormProps,
  message,
  Badge,
  InputNumber,
} from '@pankod/refine-antd'
import React, { useEffect } from 'react'
import { useEditor } from '@craftjs/core'
import Sticky from 'react-stickynode'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { useGenerateWidgetData } from '../hooks'
import { UseSavePageOptions } from '@resources/form-generator/hooks'
import { TFormGeneratorDetail } from '@resources/form-generator/interface'
import { useGetFormType } from './toolboxWidgets/utils'
import { useFormContext } from '../formContext'
import QuantitativeTotalMax from './QuantitativeTotalMax'

export type TFormInformationOnSaveClickParam = {
  pageData: {
    id: string
    title: string
    excerpt: string
  }
  formType?: string // 'NONE' | 'GENERAL' | 'LIKERT' | 'QUANTITATIVE'
  contentSchema: string
  validationSchema: TFormGeneratorDetail['validationSchema']
}

type TFormInformation = {
  formInitialValues?: FormProps['initialValues']
  onSaveClick?: (
    { pageData, contentSchema }: TFormInformationOnSaveClickParam,
    shouldRedirect: boolean,
    onError: UseSavePageOptions,
  ) => void
  stickyTop?: number | string
}
const FormInformation = ({
  formInitialValues,
  onSaveClick,
  stickyTop = 88,
}: TFormInformation) => {
  const { query } = useRouter()
  const action = query.action
  const { formType, questionsNo } = useGetFormType()
  const { formInformationInstance } = useFormContext()
  const form = formInformationInstance
  const { actions } = useEditor()
  const generateWidgetData = useGenerateWidgetData()

  useEffect(() => {
    if (formInitialValues) {
      form?.setFieldsValue(formInitialValues)
    } else {
      form?.resetFields()
    }
  }, [form, formInitialValues])

  const handleErrorRequest = (
    widgetError: { widgetNode: string; reason: string }[],
    paramsError: { name: string; reason: string }[],
  ) => {
    widgetError.forEach(({ widgetNode, reason }) => {
      message.error(`${reason} (Component ID ${widgetNode})`)
      actions.setCustom(widgetNode, (custom) => {
        custom.errors = [...custom.errors, { label: 'id', message: reason }]
      })
    })
    const paramsErrorParsed = paramsError.map(({ name, reason }) => ({
      name,
      errors: [reason],
    }))
    form?.setFields(paramsErrorParsed)
  }

  const handleSaveData = async () => {
    if (!form) return
    await form.validateFields()
    const { contentSchema, validationSchema, likertInfo } =
      await generateWidgetData()

    const pageData = form.getFieldsValue()

    onSaveClick?.(
      {
        contentSchema,
        pageData: {
          ...pageData,
          ...(formType === 'likert' && likertInfo),
        },
        formType: formType === 'general' || !formType ? 'GENERAL' : formType,
        validationSchema: JSON.stringify(validationSchema),
      },
      true,
      {
        onError: handleErrorRequest,
      },
    )
  }

  const isLikert =
    action === 'create'
      ? formType === 'likert' && questionsNo
      : !!formInitialValues?.likertOptions

  const isQuantitatiave =
    action === 'create'
      ? formType === 'quantitative'
      : formInitialValues?.formType === 'QUANTITATIVE'

  return (
    <Sticky top={stickyTop}>
      <Row align="top" gutter={[24, 24]}>
        <Col span={24}>
          <Form
            form={form}
            initialValues={formInitialValues}
            layout="vertical"
            validateMessages={{ required: '${label} must be filled.' }}
          >
            <Badge.Ribbon
              text={formType}
              className={clsx(
                'uppercase',
                formType === 'general' && 'invisible',
              )}
            >
              <Card title="Form Detail" size="small">
                <Row gutter={16}>
                  <Form.Item hidden name="id">
                    <Input />
                  </Form.Item>
                  <Col flex="auto">
                    <Form.Item
                      name="title"
                      label="Form Title"
                      rules={[{ required: true }]}
                    >
                      <Input autoComplete="off" maxLength={255} showCount />
                    </Form.Item>
                    <Form.Item
                      name="excerpt"
                      label="Description"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        autoComplete="off"
                        maxLength={255}
                        showCount
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Badge.Ribbon>
            {isLikert && (
              <Card
                title="Likert Answer Option Label"
                size="small"
                className="!mt-3"
              >
                <Form.List
                  name="likertOptions"
                  initialValue={
                    action === 'create'
                      ? new Array(questionsNo).fill('')
                      : undefined
                  }
                  rules={[
                    {
                      validator: async (_, answerLabels) => {
                        if (
                          answerLabels.some((label: string) => label === '')
                        ) {
                          return Promise.reject(
                            new Error('All label must be filled'),
                          )
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  {(fields, _, { errors }) => {
                    return (
                      <>
                        <div className="max-h-[300px] overflow-auto mb-2">
                          {fields.map((field, index) => (
                            <Form.Item
                              {...field}
                              label={`Answer label ${index + 1}`}
                              key={field.key}
                              required
                            >
                              <Input autoComplete="off" />
                            </Form.Item>
                          ))}
                        </div>
                        <Form.ErrorList errors={errors} />
                      </>
                    )
                  }}
                </Form.List>
              </Card>
            )}
            {isQuantitatiave && (
              <Card
                title="Quantitative Information"
                size="small"
                className="!mt-3"
              >
                <Form.Item name="quantitativeTotalMax" hidden>
                  <InputNumber />
                </Form.Item>
                <QuantitativeTotalMax
                  onTotalChange={(value) => {
                    form?.setFieldsValue({ quantitativeTotalMax: value })
                  }}
                />
              </Card>
            )}
          </Form>
        </Col>
        <Col span={24}>
          <Button type="primary" size="large" onClick={handleSaveData} block>
            Save Form
          </Button>
        </Col>
      </Row>
    </Sticky>
  )
}

export default FormInformation
