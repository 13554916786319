import { Descriptions, Show, Table } from '@pankod/refine-antd'
import React from 'react'
import { useRouter } from 'next/router'
import {
  useResource,
  useOne,
  IResourceComponentsProps,
  useNavigation,
} from '@pankod/refine-core'
import dayjs from 'dayjs'
import Head from 'next/head'

import { LMSWebinarProgramLocal, WebinarProgramLocalStatistic } from './types'
import { TCommonError, TCommonResponse } from 'src/interfaces/common'
import { groupBySequence, renderGroupBySequence } from 'src/helpers/date'
import { NestedBreadcrumb } from '../components/NestedBreadcrumb'

export const LMSWebinarManagementShow: React.FC<IResourceComponentsProps> = ({
  options,
}) => {
  const router = useRouter()
  const programId = router.query.programId as string
  const { id } = useResource({
    resourceNameOrRouteName: 'lms-webinar-management',
  })
  const { listUrl, showUrl } = useNavigation()

  const { data, isLoading } = useOne<
    TCommonResponse<LMSWebinarProgramLocal>,
    TCommonError
  >({
    resource: `programs/${programId}/webinars`,
    dataProviderName: 'lms',
    id,
    queryOptions: {
      enabled: !!programId,
    },
  })
  const webinarData = data?.data?.data
  return (
    <Show
      title="Webinar Detail"
      isLoading={isLoading}
      canDelete={false}
      headerButtons={() => null}
      breadcrumb={
        <NestedBreadcrumb
          injectedItems={[
            {
              label: 'Program',
              href: listUrl('lms-program-management'),
            },
            {
              label: 'Webinar',
              href:
                showUrl('lms-program-management', programId) +
                `?activeTab=webinar`,
            },
            {
              label: 'Show',
            },
          ]}
        />
      }
    >
      <Head>
        <title>LMS Admin | {options?.label} </title>
      </Head>
      <Descriptions
        bordered
        column={1}
        className="mt-4 mb-6"
        labelStyle={{ fontWeight: 600 }}
      >
        <Descriptions.Item label="ID">{webinarData?.id}</Descriptions.Item>
        <Descriptions.Item label="Judul">
          {webinarData?.title}
        </Descriptions.Item>
        <Descriptions.Item label="Tanggal Pemilihan Instruktur">
          <p className="whitespace-nowrap mb-0">{`${dayjs(
            webinarData?.instructorSelectionDate.startDate,
          ).format('DD MMMM YYYY')} - ${dayjs(
            webinarData?.instructorSelectionDate.endDate,
          ).format('DD MMMM YYYY')}`}</p>
        </Descriptions.Item>
        <Descriptions.Item label="Tanggal Pelaksanaan">
          {renderGroupBySequence(
            groupBySequence(webinarData?.executionDate || []),
            'DD MMMM YYYY',
          )}
        </Descriptions.Item>
      </Descriptions>

      <Table
        dataSource={webinarData?.programLocal}
        rowKey="id"
        size="middle"
        pagination={false}
      >
        <Table.Column dataIndex="name" title="Program Lokal" />
        <Table.Column<WebinarProgramLocalStatistic>
          dataIndex={['statistic', 'assigned']}
          title="Terjadwal"
          render={(value, record) => `${value}/${record.statistic.total}`}
        />
        <Table.Column<WebinarProgramLocalStatistic>
          dataIndex={['statistic', 'prepared']}
          title="Siap Melaksanakan"
          render={(value, record) => `${value}/${record.statistic.total}`}
        />
        <Table.Column<WebinarProgramLocalStatistic>
          dataIndex={['statistic', 'reported']}
          title="Laporan Tersedia"
          render={(value, record) => `${value}/${record.statistic.total}`}
        />
      </Table>
    </Show>
  )
}
