import { Drawer } from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'

import RequestTableList from './RequestTableList'

export const useRequestPesertaDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number | string[]
    programLocalId: string | number | string[]
    programLocalName: string
  }>({ show: false, programId: '', programLocalId: '', programLocalName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: ({
      programId,
      programLocalId,
      programLocalName,
    }: {
      programId: number | string | string[]
      programLocalId: number | string | string[]
      programLocalName: string
    }) => {
      setShowId({ show: true, programId, programLocalId, programLocalName })
    },
    visible: !!showId.show,
    programId: showId.programId,
    programLocalId: showId.programLocalId,
    programLocalName: showId.programLocalName,
    onClose,
  }
}

type PesertaRequestDrawer = {
  visible: boolean
  programId: number | string | string[]
  programLocalId: number | string | string[]
  programLocalName: string
  onClose: () => void
}
const PesertaRequestDrawer = ({
  onClose,
  programId,
  programLocalId,
  programLocalName,
  visible,
}: PesertaRequestDrawer) => {
  return (
    <Drawer
      title={
        <>
          Daftar <i>Request</i> Perubahaan Data Peserta | {programLocalName}
        </>
      }
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      maskClosable={false}
    >
      <RequestTableList
        type="peserta"
        programId={programId}
        programLocalId={programLocalId}
      />
    </Drawer>
  )
}

export default PesertaRequestDrawer
