import { CreateHandlerOptions, useEditor } from '@craftjs/core'
import {
  Card,
  Typography,
  Icons,
  Space,
  Tooltip,
  Collapse,
} from '@pankod/refine-antd'
import React from 'react'

import { WIDGET_NAME } from '../schema'
import { generateWidgetButtons, useGetFormType } from './toolboxWidgets/utils'
import generalWidgets from './toolboxWidgets/generalWidgets'
import formWidgets from './toolboxWidgets/formWidgets'

const getDisabledWidgets = (formType: string) => {
  switch (formType) {
    case 'likert':
      return formWidgets
        .map(({ name }) => name)
        .filter((name) => name !== WIDGET_NAME.LikertQuestion)
    case 'quantitative':
      return [
        WIDGET_NAME.TextInput,
        WIDGET_NAME.CheckboxGroup,
        WIDGET_NAME.DatePicker,
        WIDGET_NAME.LikertQuestion,
        WIDGET_NAME.RichTextEditor,
        WIDGET_NAME.TextArea,
      ]
    default:
      return [WIDGET_NAME.LikertQuestion]
  }
}

type TToolbox = {
  isPreview: boolean
}
const Toolbox = ({ isPreview }: TToolbox) => {
  const { formType } = useGetFormType()
  const { actions, connectors, disableNonRootWidget } = useEditor(
    (_, query) => {
      const nodes = query.getNodes()
      const contentWrapper = Object.values(nodes).find(
        ({ data }) => data.name === WIDGET_NAME.ContentWrapper,
      )
      const hasContainer = Object.values(nodes).some(
        ({ data }) => data.name === WIDGET_NAME.Container,
      )

      const isContentWrapperEmpty = contentWrapper?.data.nodes.length === 0
      const disableNonRootWidget = isContentWrapperEmpty || !hasContainer

      return {
        disableNonRootWidget,
      }
    },
  )

  const createCallback: CreateHandlerOptions = {
    onCreate: (nodeTree) => {
      actions.selectNode(nodeTree.rootNodeId)
    },
  }
  const disabledWidgets = getDisabledWidgets(formType)

  const buttonGenerators = generateWidgetButtons({
    connectors,
    createCallback,
    disableNonRootWidget,
    disabledWidgets,
    isPreview,
  })

  const generalWidgetButtons = buttonGenerators(generalWidgets)
  const formWidgetButtons = buttonGenerators(formWidgets)
  // const internalWidgetButtons = buttonGenerators(internalWidgets)
  // const externalWidgetButtons = buttonGenerators(externalWidgets)

  return (
    <Card
      size="small"
      title={
        <Space className="mb-[1px]">
          <Typography.Text>Widget Components</Typography.Text>
          <Tooltip title="Drag components below to the Content Area">
            <Icons.QuestionCircleOutlined className="cursor-help" />
          </Tooltip>
        </Space>
      }
    >
      <Collapse
        defaultActiveKey={['general', 'form']}
        className="max-h-[600px] overflow-auto"
      >
        <Collapse.Panel header="General" key="general">
          <div className="grid gap-2 grid-cols-3">{generalWidgetButtons}</div>
        </Collapse.Panel>
        <Collapse.Panel header="Form" key="form">
          <div className="grid gap-2 grid-cols-3">{formWidgetButtons}</div>
        </Collapse.Panel>
      </Collapse>
    </Card>
  )
}

export default Toolbox
