import { Popconfirm, Button, Icons } from '@pankod/refine-antd'
import React from 'react'
import {
  useCan,
  useDelete,
  useInvalidate,
  useResource,
} from '@pankod/refine-core'
import { useRouter } from 'next/router'

import { InstructorWebinarSchedule } from '../types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type PopConfirmCancelWebinarProps = {
  wkgId: InstructorWebinarSchedule['webinarKelasGroupId']
}
const PopConfirmCancelWebinar = ({ wkgId }: PopConfirmCancelWebinarProps) => {
  const router = useRouter()
  const { id } = useResource()
  const programId = router.query.programId
  const invalidate = useInvalidate()
  const { mutateAsync: doDelete } = useDelete()
  const { data: canCancelSchedule } = useCan({
    action: 'field',
    resource: 'webinar-schedule-list',
    params: { field: 'cancel' },
  })

  if (!canCancelSchedule?.can) return null

  return (
    <Popconfirm
      title="Apakah Anda yakin ingin membatalkan sesi ini?"
      okText="Ya"
      okButtonProps={{ danger: true }}
      cancelText="Tidak"
      onConfirm={async () => {
        return doDelete(
          {
            id: wkgId,
            dataProviderName: 'lms',
            resource: `instructor/webinars-schedule`,
            errorNotification: (err) =>
              showErrorNotification(
                err,
                'Terdapat gangguan saat membatalkan sesi webinar',
              ),
            successNotification: {
              type: 'success',
              message: 'Berhasil membatalkan sesi webinar',
              description: 'Sukses',
            },
          },
          {
            onSettled: () => {
              invalidate({
                dataProviderName: 'lms',
                invalidates: ['list'],
                resource: `instructor/programs/${programId}/webinars/${id}/class-group`,
              })
            },
          },
        )
      }}
    >
      <Button block danger icon={<Icons.CloseOutlined />}>
        Batalkan
      </Button>
    </Popconfirm>
  )
}

export default PopConfirmCancelWebinar
