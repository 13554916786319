import {
  Button,
  Card,
  Empty,
  Icons,
  List,
  Select,
  Table,
  TextField,
  useSelect,
} from '@pankod/refine-antd'
import {
  GetListResponse,
  IResourceComponentsProps,
  useNavigation,
} from '@pankod/refine-core'
import Head from 'next/head'
import { useState } from 'react'

import { parseDate } from '../utils/dateParser'
import { groupBySequence, renderGroupBySequence } from 'src/helpers/date'
import useTableWithMeta from 'src/hooks/useTableWithMeta'

export const WebinarScheduleManagementList: React.FC<
  IResourceComponentsProps<GetListResponse<any>>
> = ({ options }) => {
  const { showUrl, push } = useNavigation()
  const [programId, setProgramId] = useState<string | null | number>(null)

  const {
    tableProps: tableWebinarProps,
    tableQueryResult,
    setFilters,
  } = useTableWithMeta({
    resource: `instructor/programs/${programId}/webinars`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId,
    },
  })

  const { selectProps } = useSelect({
    resource: 'instructor/programs',
    dataProviderName: 'lms',
    optionLabel: 'nama',
    optionValue: 'id',
  })

  return (
    <List title="Pilih Webinar">
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>
      <p className="mb-3">
        Anda dapat memilih webinar yang tersedia pada tabel berikut:
      </p>
      <Card className="!mb-5" size="small">
        <Select
          {...selectProps}
          className="w-full"
          placeholder="Pilih program.."
          labelInValue
          onChange={({ value }) => {
            setFilters([{ field: 'page', operator: 'eq', value: 1 }])
            setProgramId(value)
          }}
          onSearch={() => {}}
          filterOption={(input, option) =>
            (option!.label! as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Card>
      <Table
        {...tableWebinarProps}
        loading={tableQueryResult.isFetching}
        rowKey="id"
        locale={{
          emptyText: !programId ? (
            <Empty
              description="Pilih Program terlebih dahulu"
              imageStyle={{ height: '40px' }}
              image={<Icons.SelectOutlined style={{ fontSize: '40px' }} />}
            />
          ) : (
            <Empty
              description="Tidak ada data"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
      >
        <Table.Column
          dataIndex={['title']}
          title="Judul Webinar"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['executionDate']}
          title="Jadwal Pelaksanaan"
          render={(value) => renderGroupBySequence(groupBySequence(value))}
        />
        <Table.Column
          dataIndex={['instructorSelectionDate']}
          title="Jadwal Pemilihan Instruktur"
          render={(value) => {
            const { startDate, endDate } = value || {}
            return (
              <p>
                {parseDate(startDate).format('DD MMM YYYY')} -{' '}
                {parseDate(endDate).format('DD MMM YYYY')}
              </p>
            )
          }}
        />
        <Table.Column
          render={(record) => (
            <Button
              icon={<Icons.EyeOutlined />}
              onClick={() => {
                push(
                  `${showUrl(
                    'webinar-schedule-management',
                    record.id,
                  )}?programId=${programId}`,
                )
              }}
            >
              Lihat
            </Button>
          )}
        />
      </Table>
    </List>
  )
}
