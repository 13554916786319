import { Modal, useTable, Table, Popconfirm, Button } from '@pankod/refine-antd'
import {
  useCustomMutation,
  useInvalidate,
  useResource,
} from '@pankod/refine-core'
import React, { useState } from 'react'
import { useRouter } from 'next/router'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const useModalMigrateKelompokMurid = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    classId: string
    localProgramId: string
    email: string
    userId: string
    classGroupId: string
  }>({
    show: false,
    localProgramId: '',
    classId: '',
    email: '',
    userId: '',
    classGroupId: '',
  })
  const onClose = () => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }
  return {
    show: (param: {
      localProgramId: string
      classId: string
      email: string
      userId: string
      classGroupId: string
    }) => {
      setShowId({ show: true, ...param })
    },
    visible: !!showId.show,
    localProgramId: showId.localProgramId,
    classId: showId.classId,
    email: showId.email,
    userId: showId.userId,
    classGroupId: showId.classGroupId,
    onClose,
  }
}

type TModalFormProps = {
  visible: boolean
  email: string
  localProgramId: string
  classId: string
  userId: string
  classGroupId: string
  onClose: () => void
}
const ModalFormMigrateKelompokMurid = ({
  onClose,
  email,
  classId,
  localProgramId,
  visible,
  userId,
  classGroupId,
}: TModalFormProps) => {
  const { id: currentClassId } = useResource()
  const router = useRouter()
  const currentLocalProgramId = router.query.programLocalId
  const { tableProps } = useTable({
    resource: `local-programs/${localProgramId}/classes/${classId}/kelompok-murid`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: visible,
    },
  })
  const { mutateAsync: doMigrate } = useCustomMutation()
  const invalidate = useInvalidate()
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={`Pindah Kelompok Murid | ${email}`}
      footer={null}
    >
      <Table
        {...tableProps}
        pagination={false}
        rowKey="id"
        columns={[
          { title: 'ID', dataIndex: 'id' },
          { title: 'Nama Kelompok', dataIndex: 'name' },
          { title: 'Jumlah Murid', dataIndex: 'jumlahMurid' },
          {
            render: (_, record) => (
              <Popconfirm
                okText="Pindahkan"
                cancelText="Batal"
                title="Apakah Anda yakin ingin memindahkan murid ke kelompok ini?"
                onConfirm={async () =>
                  doMigrate(
                    {
                      method: 'post',
                      url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/local-programs/${currentLocalProgramId}/classes/${currentClassId}/kelompok-murid/${classGroupId}/murid/migrate`,
                      values: {
                        userId,
                        destinationKelompokMuridId: record.id,
                      },
                      errorNotification: (err) =>
                        showErrorNotification(
                          err,
                          'There was a problem when migrating into new kelompok murid',
                        ),
                      successNotification: {
                        message: 'Murid berhasil dipindahkan',
                        type: 'success',
                        description: 'Sukses',
                      },
                    },
                    {
                      onSettled: () => {
                        invalidate({
                          invalidates: ['list'],
                          resource: `local-programs/${currentLocalProgramId}/classes/${currentClassId}/kelompok-murid`,
                          dataProviderName: 'lms',
                        })
                      },
                      onSuccess: () => {
                        onClose()
                      },
                    },
                  )
                }
              >
                <Button type="primary">Pilih</Button>
              </Popconfirm>
            ),
          },
        ]}
      />
    </Modal>
  )
}

export default ModalFormMigrateKelompokMurid
