import { Form, Space, Button, Icons, Radio } from '@pankod/refine-antd'
import { useTableReturnType, LogicalFilter } from '@pankod/refine-core'
import React, { useEffect } from 'react'

type RadioProps = {
  onClose: () => void
  dataIndex: string
  setFilters: useTableReturnType['setFilters']
  label: string
  currentFilters: LogicalFilter[]
  visible: boolean
  options: { label: string | React.ReactNode; value: string }[]
}

const RadioFilter = ({
  currentFilters,
  dataIndex,
  label,
  onClose,
  options,
  setFilters,
  visible,
}: RadioProps) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      const value = currentFilters.find(
        ({ field }) => field === dataIndex,
      )?.value
      if (!value) {
        form.resetFields()
        return
      }
      form.setFields([{ name: dataIndex, value }])
    }
  }, [currentFilters, dataIndex, form, visible])

  const clearFilters = () => {
    setFilters((prevFilters) => [
      ...(prevFilters as LogicalFilter[]).filter(
        ({ field }) => field !== dataIndex && field !== 'page',
      ),
      { field: 'page', operator: 'eq', value: 1 },
    ])
    onClose()
  }

  return (
    <div className="p-2 grid grid-cols-1">
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const value = values[dataIndex]
          if (!value) {
            clearFilters()
            return
          }

          setFilters([
            { field: dataIndex, operator: 'eq', value },
            { field: 'page', operator: 'eq', value: 1 },
          ])
          onClose()
        }}
      >
        <Form.Item name={dataIndex} label={label}>
          <Radio.Group>
            <Space direction="vertical">
              {options.map(({ label, value }, i) => (
                <Radio value={value} key={i}>
                  {label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
      <Space className="justify-self-end">
        <Button
          onClick={form.submit}
          icon={<Icons.FilterOutlined />}
          type="primary"
          size="small"
        >
          Filter
        </Button>
        <Button
          onClick={() => {
            form.resetFields()
            clearFilters()
          }}
          size="small"
          danger
        >
          Clear
        </Button>
      </Space>
    </div>
  )
}

export default RadioFilter
