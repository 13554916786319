import React, { useContext, useState } from 'react'
import {
  FieldValues,
  useForm,
  UseFormHandleSubmit,
  UseFormReturn,
  UseFormClearErrors,
  UseFormSetValue,
} from 'react-hook-form'
import { Form, FormInstance } from '@pankod/refine-antd'

import { ajvI18nResolver } from './validatorResolver'

type TFormContext = {
  control?: UseFormReturn['control']
  handleSubmit?: UseFormHandleSubmit<FieldValues>
  setValidationSchema?: (schema?: string) => void
  clearErrors?: UseFormClearErrors<FieldValues>
  reset?: UseFormReturn['reset']
  formInformationInstance?: FormInstance
  setValue?: UseFormSetValue<FieldValues>
}

export const ctx = React.createContext<TFormContext>({})

const FormContextProvider: React.FC<{ children?: React.ReactNode }> = (
  props,
) => {
  const [formInformationInstance] = Form.useForm()
  const [validationSchema, setValidationSchema] = useState<string | undefined>()
  const { control, handleSubmit, clearErrors, reset, setValue } = useForm({
    resolver: validationSchema
      ? ajvI18nResolver(JSON.parse(validationSchema))
      : undefined,
    reValidateMode: 'onBlur',
  })

  return (
    <ctx.Provider
      value={{
        control,
        setValidationSchema,
        handleSubmit,
        clearErrors,
        reset,
        formInformationInstance,
        setValue,
      }}
    >
      {props.children}
    </ctx.Provider>
  )
}

const useFormContext = () => {
  const { control, formInformationInstance } = useContext(ctx)
  return {
    control,
    formInformationInstance,
  }
}

export { FormContextProvider, useFormContext }
