import {
  Button,
  Form,
  FormInstance,
  Icons,
  Input,
  InputNumber,
  Table,
  Typography,
} from '@pankod/refine-antd'
import React, { useEffect, useRef, useContext, useState } from 'react'

type Option = {
  label: string
  value: string
  key?: any
}

export const defaultOptions: Option[] = [
  {
    label: 'Option 1',
    value: '1',
  },
  {
    label: 'Option 2',
    value: '2',
  },
  {
    label: 'Option 3',
    value: '3',
  },
]

export const optionsParser: (options?: Option[] | string) => Option[] = (
  options,
) => {
  if (!options) return []

  if (typeof options === 'string')
    return options.split(',').map((value) => ({ label: value, value }))

  return options
}

const EditableContext = React.createContext<FormInstance | null>(null)
const EditableRow = ({ index: _, ...props }: any) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  rowIndex,
  disabled,
  numberOnly,
  ...restProps
}: any) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<any>(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus({ cursor: 'all' })
    }
  }, [editing])
  const toggleEdit = () => {
    setEditing(!editing)
    form?.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }
  const save = async () => {
    try {
      const values = await form?.validateFields()
      toggleEdit()
      handleSave(
        {
          ...record,
          ...values,
        },
        rowIndex,
      )
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Save failed:', errInfo)
    }
  }
  let childNode = children
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {dataIndex === 'value' && numberOnly ? (
          <InputNumber
            controls={false}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            stringMode
            disabled={disabled}
            precision={0}
          />
        ) : (
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 4 }}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            disabled={disabled}
          />
        )}
      </Form.Item>
    ) : (
      <div
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }
  return <td {...restProps}>{childNode}</td>
}

type MenuOptionsProps = {
  value?: Option[] | string
  onChange?: (value: Option[]) => void
  disabled?: boolean
  numberOnly?: boolean
}
const MenuOptions = ({
  value: origin,
  onChange,
  disabled,
  numberOnly = false,
}: MenuOptionsProps) => {
  const value =
    typeof origin === 'string'
      ? origin.split(',').map((v) => ({ label: v, value: v }))
      : origin
  const [count, setCount] = useState(defaultOptions.length + 1)

  const handleDelete = (val: string) => {
    if (!value) return

    const newData = value?.filter((item: Option) => item.value !== val)
    onChange?.(newData)
  }
  const handleSave = (row: Option, rowIndex: number) => {
    if (!value) return
    const newData = [...value]
    const item = newData[rowIndex]
    newData.splice(rowIndex, 1, {
      ...item,
      ...row,
    })

    onChange?.(newData)
  }

  const handleAdd = () => {
    if (!value) return

    const newData = {
      key: count,
      label: `Option ${count}`,
      value: `${count}`,
    }
    onChange?.([...value, newData])
    setCount(count + 1)
  }

  const defaultColumns = [
    {
      title: 'Label',
      dataIndex: 'label',
      editable: true,
      width: '60%',
      render: (value: any) => <Input disabled={disabled} value={value} />,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      editable: true,
      render: (value: any) =>
        numberOnly ? (
          <InputNumber
            controls={false}
            disabled={disabled}
            value={value}
            stringMode
          />
        ) : (
          <Input disabled={disabled} value={value} />
        ),
    },
    {
      render: (_: unknown, record: Option) => (
        <Button
          icon={<Icons.DeleteOutlined />}
          danger
          disabled={disabled || (value?.length || 0) <= 1}
          onClick={() => handleDelete(record.value)}
        />
      ),
    },
  ]
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Option, rowIndex?: number) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        rowIndex,
        disabled,
        numberOnly,
      }),
    }
  })

  return (
    <>
      <Table
        size="small"
        dataSource={value}
        pagination={false}
        rowClassName={() => 'align-top'}
        footer={() => (
          <Button
            size="small"
            type="primary"
            onClick={() => handleAdd()}
            icon={<Icons.PlusOutlined />}
            disabled={disabled}
          >
            Add Item
          </Button>
        )}
        columns={columns}
        components={components}
      />
      <Typography.Text type="secondary">
        Press enter to save after updating the row value
      </Typography.Text>
    </>
  )
}

export default MenuOptions
