import {
  ModalProps,
  FormProps,
  Modal,
  Icons,
  Button,
  Form,
  Input,
  Select,
  Space,
  InputNumber,
} from '@pankod/refine-antd'
import React from 'react'

import { EvaluatorRoleLabel } from './types'
import { FormSelectComponent } from '@resources/lms-management/program/program-setup/blocks/FormSelectComponent'

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  close: () => void
}

const evaluatorRoleOptions = Object.entries(EvaluatorRoleLabel).map(
  ([value, label]) => ({ label, value }),
)

const ModalForm = ({ formProps, modalProps, mode, close }: ModalFormProps) => {
  const isEditMode = mode === 'edit'
  const evaluatorRoles = Form.useWatch('evaluatorRoles', formProps?.form)
  return (
    <Modal
      {...modalProps}
      onCancel={close}
      title={isEditMode ? 'Ubah Penilaian Webinar' : 'Buat Penilaian Webinar'}
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => {
        formProps.form?.resetFields()
        formProps.form?.setFields([{ name: 'evaluatorRoles', value: [] }])
      }}
      maskClosable={false}
      okButtonProps={{
        ...modalProps.okButtonProps,
        icon: <Icons.SaveOutlined />,
      }}
      footer={[
        <Button key="back" onClick={close}>
          Kembali
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={modalProps.okButtonProps?.loading}
          onClick={modalProps.okButtonProps?.onClick}
          icon={<Icons.SaveOutlined />}
        >
          Simpan
        </Button>,
      ]}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues?.data,
        }}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ formKuantitatif, formKualitatif, ...data }) => {
          formProps.onFinish?.({
            ...data,
            formKuantitatifId: formKuantitatif.id,
            formKualitatifId: formKualitatif?.id,
          })
        }}
      >
        <Form.Item
          label="Nama penilaian"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        {/* <Form.Item
          label="Aktor Pengisi"
          name="evaluatorRoles"
          required
          rules={[{ required: true }]}
        >
          <Select mode="multiple" options={evaluatorRoleOptions} />
        </Form.Item> */}
        <Form.Item label="Aktor penilai" required>
          <Form.List
            name="evaluatorRoles"
            rules={[
              {
                validator(_, value) {
                  if (!value?.length)
                    return Promise.reject('Aktor penilai tidak boleh kosong.')
                  return Promise.resolve()
                },
              },
              {
                validator(_, value) {
                  const totalWeight = value?.reduce?.(
                    (
                      total: number,
                      current?: { role: string; weight: number },
                    ) => total + (current?.weight || 0),
                    0,
                  )
                  if (totalWeight > 100)
                    return Promise.reject(
                      'Total bobot tidak boleh lebih dari 100.',
                    )
                  return Promise.resolve()
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map(({ key, name, ...rest }) => {
                    return (
                      <Space
                        key={key}
                        className="flex gap-2 w-full !items-start mb-2 last-of-type:mb-3"
                      >
                        <Form.Item
                          {...rest}
                          name={[name, 'role']}
                          rules={[
                            { required: true, message: 'Role harus diisi' },
                          ]}
                          help="Role aktor"
                        >
                          <Select
                            options={evaluatorRoleOptions.map(
                              ({ value, label }) => {
                                return {
                                  value,
                                  label,
                                  disabled: evaluatorRoles.some(
                                    (evaluatorRole?: {
                                      role: string
                                      weight: number
                                    }) => evaluatorRole?.role === value,
                                  ),
                                }
                              },
                            )}
                            showSearch={false}
                            placeholder={`Aktor penilai`}
                            className="!w-[220px]"
                          />
                        </Form.Item>
                        <Form.Item
                          {...rest}
                          name={[name, 'weight']}
                          initialValue={0}
                          help="Bobot"
                        >
                          <InputNumber
                            precision={2}
                            max={100}
                            min={0}
                            controls={false}
                            addonAfter="%"
                          />
                        </Form.Item>
                        <Button
                          icon={<Icons.MinusCircleOutlined />}
                          danger
                          type="dashed"
                          className="ml-2"
                          onClick={() => remove(name)}
                        />
                      </Space>
                    )
                  })}
                  <div className="w-full">
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<Icons.PlusOutlined />}
                      disabled={
                        evaluatorRoles?.length === evaluatorRoleOptions.length
                      }
                    >
                      Tambah aktor penilai
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </div>
                </>
              )
            }}
          </Form.List>
        </Form.Item>
        <div className="grid grid-cols-2 gap-3">
          <Form.Item
            label="Form Kuantitatif"
            name="formKuantitatif"
            required
            rules={[{ required: true }]}
          >
            <FormSelectComponent
              defaultFormType={'LIKERT'}
              formTypeOptions={[
                {
                  label: 'Likert',
                  value: 'LIKERT',
                },
                {
                  label: 'Quantitative',
                  value: 'QUANTITATIVE',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Bobot Kuantitatif"
            name="weight"
            required
            rules={[{ required: true }]}
            initialValue={0}
          >
            <InputNumber
              precision={2}
              max={100}
              min={0}
              controls={false}
              addonAfter="%"
            />
          </Form.Item>
        </div>
        <Form.Item label="Form Kualitatif" name="formKualitatif">
          <FormSelectComponent />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export { ModalForm }
