import {
  AntdList,
  Button,
  Icons,
  Popconfirm,
  Space,
  Table,
  Tag,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useDelete } from '@pankod/refine-core'

import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import { LokaKaryaRequest, LokaKaryaResponse } from './types'
import { TableTitle } from '@components/TableTitle'
import { ModalForm } from './ModalForm'
import { useGetProgramDetail } from '@resources/lms-management/program/utils'
import { Form } from '../../../types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type LokaKaryaListProps = {
  programId: string | number
}
export const LokaKaryaList = ({ programId }: LokaKaryaListProps) => {
  const { isFetching, isPPConfigFinalized: isFinalized } =
    useGetProgramDetail(programId)
  const { tableProps } = useTable<LokaKaryaResponse, TCommonError>({
    resource: `pp/${programId}/loka-karya`,
    dataProviderName: 'lms',
  })
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<LokaKaryaResponse, TLMSCommonError, LokaKaryaRequest>({
    action: 'create',
    resource: `pp/${programId}/loka-karya`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Loka Karya successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was a problem when creating Loka Karya',
      ),
  })
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<LokaKaryaResponse, TLMSCommonError, LokaKaryaRequest>({
    action: 'edit',
    dataProviderName: 'lms',
    resource: `pp/${programId}/loka-karya`,
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Loka Karya successfully edited',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when editing Loka Karya',
      ),
  })
  const { mutateAsync: doDelete } = useDelete()
  return (
    <>
      <Table
        {...tableProps}
        loading={tableProps.loading && isFetching}
        rowKey="id"
        scroll={{ x: 760 }}
        title={() => (
          <TableTitle
            title="Daftar Loka Karya"
            extra={
              <Button
                icon={<Icons.PlusOutlined />}
                onClick={() => createShow()}
                disabled={isFinalized}
              >
                Tambah Jurnal
              </Button>
            }
          />
        )}
      >
        <Table.Column title="ID" dataIndex="id" fixed="left" width={80} />
        <Table.Column
          title="Nama Loka Karya"
          dataIndex="title"
          width={220}
          fixed="left"
        />
        <Table.Column
          title="Form Kehadiran"
          dataIndex="kehadiranForm"
          render={(value) => (
            <TextField
              value={value.title}
              copyable={{ text: value.id, tooltips: 'Copy ID' }}
              ellipsis={{ tooltip: value.title }}
              style={{ width: 150 }}
            />
          )}
          width={150}
        />
        <Table.Column
          title="Form Keaktifan"
          dataIndex="keaktifanForm"
          render={(value) => (
            <TextField
              value={value.title}
              copyable={{ text: value.id, tooltips: 'Copy ID' }}
              ellipsis={{ tooltip: value.title }}
              style={{ width: 150 }}
            />
          )}
          width={150}
        />
        <Table.Column
          title="Form Catatan"
          dataIndex="catatanForm"
          render={(value) => (
            <TextField
              value={value.title}
              copyable={{ text: value.id, tooltips: 'Copy ID' }}
              ellipsis={{ tooltip: value.title }}
              style={{ width: 150 }}
            />
          )}
          width={150}
        />
        <Table.Column
          title="Form Penilaian Lain"
          dataIndex="penilaianForm"
          render={(value: Form[]) => (
            <AntdList
              dataSource={value}
              rowKey="id"
              size="small"
              renderItem={(item) => (
                <AntdList.Item>
                  <TextField
                    value={item.title}
                    copyable={{ text: item.id, tooltips: 'Copy ID' }}
                    ellipsis={{ tooltip: item.title }}
                    style={{ width: 150 }}
                  />
                </AntdList.Item>
              )}
            />
          )}
          width={150}
        />
        <Table.Column
          title="Form Upload Murid"
          dataIndex="studentUpload"
          render={({ isActive }: LokaKaryaResponse['studentUpload']) => (
            <Tag color={isActive ? 'success' : 'default'}>
              {isActive ? 'Aktif' : 'Nonaktif'}
            </Tag>
          )}
          width={150}
        />
        <Table.Column<LokaKaryaResponse>
          width={100}
          render={(_, record) => (
            <Space direction="vertical">
              <Button
                icon={<Icons.EditOutlined />}
                block
                size="small"
                disabled={isFinalized}
                onClick={() => editShow(record.id)}
              >
                Edit
              </Button>
              <Popconfirm
                title="Apakah Anda yakin ingin menghapus loka karya ini?"
                okButtonProps={{ danger: true }}
                okText="Hapus"
                cancelText="Kembali"
                disabled={isFinalized}
                onConfirm={async () =>
                  doDelete({
                    id: record.id,
                    resource: `pp/${programId}/loka-karya`,
                    dataProviderName: 'lms',
                    invalidates: ['list'],
                    successNotification: {
                      message: 'Loka karya successfuly removed',
                      type: 'success',
                      description: 'Successful',
                    },
                    errorNotification: (error: any) =>
                      showErrorNotification(
                        error,
                        'There was a problem when removing loka karya',
                      ),
                  })
                }
              >
                <Button
                  danger
                  icon={<Icons.DeleteOutlined />}
                  block
                  disabled={isFinalized}
                  size="small"
                >
                  Hapus
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />

      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
    </>
  )
}
