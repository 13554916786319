import {
  Button,
  Collapse,
  DatePicker,
  Divider,
  Form,
  FormProps,
  Icons,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Select,
  useSelect,
} from '@pankod/refine-antd'
import React from 'react'
import 'dayjs/locale/id'
import locale from 'antd/lib/locale/id_ID'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjs, { Dayjs } from 'dayjs'
import { useList } from '@pankod/refine-core'
dayjs.extend(isSameOrAfter)

import { LMSWebinar, LMSWebinarRequest, WebinarTimezone } from '../types'
import { ScheduleSession } from './form/ScheduleSession'

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
  programId: string
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
}

const DATE_NOW_STR = dayjs().format('YYYY-MM-DD')

const getNextAvailableDate = (
  selectedDates?: string[],
  unavailableDates?: string[],
) => {
  if (!selectedDates) return undefined
  selectedDates.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? -1 : 1))
  let date = dayjs(selectedDates[selectedDates.length - 1])
  while (
    selectedDates?.includes(date.format('YYYY-MM-DD')) ||
    unavailableDates?.includes(date.format('YYYY-MM-DD'))
  ) {
    date = date.add(1, 'day')
  }
  return date
}

const mapUnavailableDateByTimezone = (
  unavailableTimes: LMSWebinar['sessionScheduleTime'],
  tz: WebinarTimezone,
): [Dayjs, Dayjs][] => {
  const selectedTz = unavailableTimes?.[tz]?.filter(Boolean)
  return (
    selectedTz?.reduce(
      (dates, session) => {
        if (!session) return dates
        return [
          ...dates,
          [
            dayjs(DATE_NOW_STR + ' ' + session.startTime),
            dayjs(DATE_NOW_STR + ' ' + session.endTime),
          ],
        ]
      },
      [] as [Dayjs, Dayjs][],
    ) || []
  )
}

const getUnavailableDates = (
  executionDates?: string[],
  unavailableDates?: string[],
): string[] =>
  unavailableDates?.filter((value) => !executionDates?.includes(value)) || []

export const ModalForm = ({
  formProps,
  modalProps,
  mode,
  onClose,
  programId,
}: ModalFormProps) => {
  const initialValues: LMSWebinar | undefined = formProps.initialValues?.data
  const { data: webinarDates } = useList<string>({
    resource: `programs/${programId}/webinar-dates`,
    queryOptions: {
      enabled: modalProps.visible,
    },
  })
  const unavailableDates = getUnavailableDates(
    initialValues?.executionDate,
    webinarDates?.data,
  )
  const unavailableTimes: LMSWebinar['sessionScheduleTime'] = Form.useWatch(
    'sessionScheduleTime',
    formProps.form,
  )
  const { selectProps } = useSelect({
    resource: `programs/${programId}/paket-modul`,
    dataProviderName: 'lms',
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
    optionValue: 'id',
    optionLabel: 'name',
    defaultValueQueryOptions: {
      enabled: false,
    },
    queryOptions: {
      enabled: modalProps.visible && !!programId,
    },
  })

  const isEditMode = mode === 'edit'
  const instructorSelectionStartDate =
    initialValues?.instructorSelectionDate.startDate
  const hasInstructorSelectionStarted =
    isEditMode &&
    dayjs().isSameOrAfter(dayjs(instructorSelectionStartDate), 'date')

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={isEditMode ? 'Edit Webinar' : 'Buat Webinar'}
      cancelText="Batal"
      okText="Simpan"
      maskClosable={false}
      okButtonProps={{
        ...modalProps.okButtonProps,
        icon: <Icons.SaveOutlined />,
      }}
      bodyStyle={{
        maxHeight: '70vh',
        overflow: 'auto',
      }}
      footer={[
        <Button key="back" onClick={onClose}>
          Kembali
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={modalProps.okButtonProps?.loading}
          onClick={modalProps.okButtonProps?.onClick}
          icon={<Icons.SaveOutlined />}
        >
          Simpan
        </Button>,
      ]}
    >
      <Form
        {...formProps}
        initialValues={formProps?.initialValues?.data}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
      >
        <Form.Item
          label="Judul webinar"
          name="title"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" disabled={hasInstructorSelectionStarted} />
        </Form.Item>
        <Form.Item
          label="Kode unik"
          name="code"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" disabled={isEditMode} />
        </Form.Item>
        <Form.Item
          label="Tanggal pemilihan sesi bagi instruktur"
          name="instructorSelectionDate"
          required
          rules={[{ required: true }]}
          getValueProps={(
            value: LMSWebinarRequest['instructorSelectionDate'],
          ) => {
            return {
              value: value
                ? [dayjs(value.startDate), dayjs(value.endDate)]
                : [],
            }
          }}
          getValueFromEvent={([startDate, endDate]) => {
            return {
              startDate: startDate?.format('YYYY-MM-DD'),
              endDate: endDate?.format('YYYY-MM-DD'),
            }
          }}
        >
          <DatePicker.RangePicker
            picker="date"
            format="DD MMM YYYY"
            locale={locale.DatePicker}
            disabledDate={(date) => date.isBefore(dayjs().startOf('date'))}
            disabled={hasInstructorSelectionStarted ? [true, false] : false}
          />
        </Form.Item>
        <Form.Item<LMSWebinarRequest>
          label="Tanggal pelaksanaan"
          required
          rules={[{ required: true }]}
        >
          <Form.List
            name="executionDate"
            initialValue={isEditMode ? undefined : [null]}
            rules={[
              {
                validator: async (_, date) => {
                  if (!date || date.length < 1) {
                    return Promise.reject(
                      new Error('Minimal 1 tanggal pelaksanaan.'),
                    )
                  }
                  if (date.some((d?: Dayjs) => !d)) {
                    return Promise.reject(
                      new Error('Seluruh tanggal harus diisi.'),
                    )
                  }

                  return Promise.resolve()
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              const selectedDates: string[] =
                formProps.form?.getFieldValue('executionDate') || []
              return (
                <>
                  <div className="flex flex-wrap gap-4">
                    {fields.map((field) => (
                      <Form.Item
                        {...formItemLayout}
                        required
                        key={field.key}
                        noStyle
                      >
                        <div>
                          <Form.Item
                            {...field}
                            noStyle
                            getValueProps={(dateString?: string) => ({
                              value: dateString ? dayjs(dateString) : undefined,
                            })}
                            getValueFromEvent={(dateDayjs?: Dayjs) => {
                              if (!dateDayjs) return undefined
                              return dayjs(dateDayjs).format('YYYY-MM-DD')
                            }}
                          >
                            <DatePicker
                              disabled={hasInstructorSelectionStarted}
                              format="DD MMMM YYYY"
                              picker="date"
                              locale={locale.DatePicker}
                              allowClear={false}
                              onChange={() =>
                                formProps.form?.validateFields([
                                  'executionDate',
                                ])
                              }
                              disabledDate={(date) =>
                                date.isBefore(dayjs().startOf('date')) ||
                                selectedDates?.includes(
                                  date.format('YYYY-MM-DD'),
                                ) ||
                                unavailableDates.includes(
                                  date.format('YYYY-MM-DD'),
                                )
                              }
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <Button
                              icon={<Icons.MinusCircleOutlined />}
                              className="ml-1"
                              disabled={hasInstructorSelectionStarted}
                              danger
                              size="small"
                              type="text"
                              onClick={() => {
                                remove(field.name)
                              }}
                            />
                          ) : null}
                        </div>
                      </Form.Item>
                    ))}
                    <Form.Item noStyle>
                      <Button
                        type="dashed"
                        onClick={() => {
                          const executionDate =
                            formProps.form?.getFieldValue('executionDate')
                          const date = getNextAvailableDate(
                            executionDate,
                            unavailableDates,
                          )
                          add(date ? date.format('YYYY-MM-DD') : undefined)
                        }}
                        icon={<Icons.PlusOutlined />}
                        disabled={
                          !selectedDates.some(Boolean) ||
                          hasInstructorSelectionStarted
                        }
                      >
                        Tambah tanggal
                      </Button>
                    </Form.Item>
                  </div>
                  <Form.ErrorList errors={errors} />
                </>
              )
            }}
          </Form.List>
        </Form.Item>
        <Form.Item
          label="Maksimal sesi per instruktur"
          name="instructorMaxSession"
          required
          rules={[{ required: true }]}
        >
          <InputNumber min={1} disabled={hasInstructorSelectionStarted} />
        </Form.Item>
        <Form.Item
          label="Paket Modul"
          name="programModuleGroupId"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...selectProps}
            placeholder="Pilih paket modul"
            disabled={hasInstructorSelectionStarted}
            onSearch={() => {}}
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Divider dashed>Jadwal sesi pelaksanaan tiap wilayah</Divider>
        <Collapse defaultActiveKey={['wib', 'wita', 'wit']}>
          <Collapse.Panel
            header={
              <em>
                Jadwal sesi untuk kelas yang berada pada waktu{' '}
                <strong>Indonesia bagian barat (WIB)</strong>
              </em>
            }
            key="wib"
            id="wib"
          >
            <ScheduleSession
              timezone="wib"
              name="sessionScheduleTime"
              hasInstructorSelectionStarted={hasInstructorSelectionStarted}
              form={formProps?.form}
              mode={mode}
              unavailableTime={mapUnavailableDateByTimezone(
                unavailableTimes,
                'wib',
              )}
            />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <em>
                Jadwal sesi untuk kelas yang berada pada waktu{' '}
                <strong>Indonesia bagian tengah (WITA)</strong>
              </em>
            }
            key="wita"
            id="wita"
          >
            <ScheduleSession
              timezone="wita"
              name="sessionScheduleTime"
              hasInstructorSelectionStarted={hasInstructorSelectionStarted}
              form={formProps?.form}
              mode={mode}
              unavailableTime={mapUnavailableDateByTimezone(
                unavailableTimes,
                'wita',
              )}
            />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <em>
                Jadwal sesi untuk kelas yang berada pada waktu{' '}
                <strong>Indonesia bagian timur (WIT)</strong>
              </em>
            }
            key="wit"
            id="wit"
          >
            <ScheduleSession
              timezone="wit"
              name="sessionScheduleTime"
              hasInstructorSelectionStarted={hasInstructorSelectionStarted}
              form={formProps?.form}
              mode={mode}
              unavailableTime={mapUnavailableDateByTimezone(
                unavailableTimes,
                'wit',
              )}
            />
          </Collapse.Panel>
        </Collapse>
      </Form>
    </Modal>
  )
}
