import { Canvas, FabricObject, FabricText, Textbox } from 'fabric'
import React, { useEffect, useState } from 'react'
import { Empty, Tag, Typography } from '@pankod/refine-antd'
import clsx from 'clsx'

import { TextboxConfig } from '../utils/textbox'
import { COMPONENT_LABEL, QR_CODE_CERTIFICATE_KEY } from '../constants'

type SelectedComponentConfig = {
  canvas: Canvas | null
}
const SelectedComponentConfig = ({ canvas }: SelectedComponentConfig) => {
  const [selectedObject, setSelectedObject] = useState<FabricObject | null>(
    null,
  )
  useEffect(() => {
    const handler = () => {
      const e = canvas?.getActiveObjects() || []
      if (e.length === 1) {
        setSelectedObject(e[0])
      } else {
        setSelectedObject(null)
      }
    }
    canvas?.on('selection:created', handler)
    canvas?.on('selection:updated', handler)
    canvas?.on('selection:cleared', handler)
    return () => {
      canvas?.off('selection:created', handler)
      canvas?.off('selection:updated', handler)
      canvas?.off('selection:cleared', handler)
    }
  }, [canvas])

  return (
    <div
      className={clsx(
        'absolute w-[220px] min-w-[180px] left-6 m-2 p-2 top-24 rounded certificate-template__selected-component-container shadow z-10 config-wrapper',
        !selectedObject && 'hidden',
      )}
      key={selectedObject?.customId}
    >
      {!selectedObject ? (
        <Empty
          description="Tidak ada element terpilih"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <>
          {canvas?.getActiveObject()?.customVariableKey && (
            <Tag className="absolute -top-6 left-0" color="warning">
              Komponen Variable
            </Tag>
          )}
          <Typography.Title level={4} className="capitalize">
            {COMPONENT_LABEL[selectedObject.type] || <em>Komponen Lain</em>}
            {selectedObject.customVariableKey === QR_CODE_CERTIFICATE_KEY && (
              <code className="!ml-2">QR Code</code>
            )}
          </Typography.Title>
          {(() => {
            if (selectedObject instanceof Textbox) {
              return <TextboxConfig canvas={canvas} />
            }
            if (selectedObject instanceof FabricText) {
              return <TextboxConfig canvas={canvas} />
            }

            return (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Tidak ada yang dapat dikonfigurasi"
                className="!text-subdued"
              />
            )
          })()}
        </>
      )}
    </div>
  )
}

export default SelectedComponentConfig
