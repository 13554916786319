import { Button, Upload, UploadProps, message } from '@pankod/refine-antd'
import { Canvas } from 'fabric'
import { TypeOutline, ImagePlus } from 'lucide-react'
import React from 'react'

import { CANVAS_CONFIG, COMPONENT_LABEL } from '../../constants'

type CommonComponents = {
  canvas: Canvas | null
  onAddTextbox: () => void
  onAddImage: (result: string | ArrayBuffer | null) => void
  onExportSVG: () => void
}

const CommonComponents = (props: CommonComponents) => {
  const MAX_SIZE_IN_MB = CANVAS_CONFIG.imageSizeLimitInMB
  const ALLOWED_TYPE = CANVAS_CONFIG.imageAllowedType
  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isFileSizeValid = file.size / 1024 / 1024 < MAX_SIZE_IN_MB
      const isFileTypeValid = ALLOWED_TYPE.includes(file.type)
      if (!isFileTypeValid) {
        message.error('Tipe file tidak sesuai!')
        return false
      }
      if (!isFileSizeValid) {
        message.error(`Ukuran file harus < ${MAX_SIZE_IN_MB}MB`)
        return false
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        props.onAddImage(reader.result)
      }
      reader.readAsDataURL(file)
      return false
    },
    itemRender: () => null,
  }
  return (
    <>
      <div className="grid gap-2 grid-flow-row">
        <Button
          onClick={() => props.onAddTextbox()}
          icon={<TypeOutline className="h-5 w-5" />}
          className="!flex gap-2 items-center justify-center"
          block
        >
          {COMPONENT_LABEL['textbox']}
        </Button>
        <Upload
          {...uploadProps}
          accept="image/png,image/jpeg,image/jpg,image/svg+xml"
          className="w-full grid grid-cols-[1fr_0]"
        >
          <Button
            className="!flex gap-2 items-center justify-center w-full"
            block
            icon={<ImagePlus className="h-5 w-5" />}
          >
            {COMPONENT_LABEL['image']}
          </Button>
        </Upload>
      </div>
    </>
  )
}

export default CommonComponents
