import {
  Button,
  Descriptions,
  Icons,
  Popconfirm,
  Tag,
} from '@pankod/refine-antd'
import React from 'react'

import { PROGRAM_LOCAL_STATE, TLMSProgramLocal } from '../types'

const ProgramLocalDetail = ({
  data,
  onUpdateStatus,
  canUpdateStatus,
  isUpdatingStatus,
}: {
  data?: TLMSProgramLocal
  onUpdateStatus: () => void
  canUpdateStatus: boolean
  isUpdatingStatus: boolean
}) => {
  const isPublished = data?.status === PROGRAM_LOCAL_STATE.PUBLISHED

  return (
    <>
      <Descriptions
        bordered
        size="small"
        column={1}
        className="mb-6"
        labelStyle={{ fontWeight: 600 }}
      >
        <Descriptions.Item label="ID">{data?.id}</Descriptions.Item>
        <Descriptions.Item label="Nama">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag
            className="uppercase"
            color={isPublished ? 'success' : 'default'}
          >
            {data?.status}
          </Tag>
          <Popconfirm
            placement="topRight"
            onConfirm={onUpdateStatus}
            disabled={!canUpdateStatus || isUpdatingStatus}
            title={
              <>
                Apakah Anda yakin ingin mengubah status program lokal menjadi{' '}
                <strong>{isPublished ? 'Draft' : 'Published'}</strong>?
              </>
            }
            okText="Lanjutkan"
            cancelText="Batal"
          >
            <Button
              size="small"
              icon={<Icons.DoubleRightOutlined />}
              className="ml-5"
              disabled={!canUpdateStatus || isUpdatingStatus}
              loading={isUpdatingStatus}
              type={isPublished ? 'default' : 'primary'}
            >
              Ubah status
            </Button>
          </Popconfirm>
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

export default ProgramLocalDetail
