import { PopconfirmProps, Form, Popconfirm, Input } from '@pankod/refine-antd'

type PopDeleteConfirmWithValidate = {
  validateValue: string
  placeholder?: string
  errorMessage?: string
  validatorMatcher?: (inputValue: string, targetValue: string) => boolean
} & PopconfirmProps

type PopDeleteConfirmWithoutValidate = {
  validatorMatcher?: never
  validateValue?: never
  placeholder?: never
  errorMessage?: never
} & PopconfirmProps

export const PopDeleteConfirm = ({
  title,
  children,
  validateValue,
  validatorMatcher = (inputValue, targetValue) => inputValue === targetValue,
  placeholder,
  errorMessage,
  ...props
}: PopDeleteConfirmWithValidate | PopDeleteConfirmWithoutValidate) => {
  const [form] = Form.useForm()
  const textValue = Form.useWatch('text', form)

  if (!validateValue)
    return (
      <Popconfirm title={title} {...props}>
        {children}
      </Popconfirm>
    )

  return (
    <Popconfirm
      title={
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            text: '',
          }}
        >
          <Form.Item
            label={title}
            className="!mb-0"
            name="text"
            rules={[
              {
                validator(_, value) {
                  if (!validatorMatcher(value, validateValue)) {
                    return Promise.reject(
                      errorMessage || 'Confirmation validation did not match',
                    )
                  }

                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input autoComplete="off" placeholder={placeholder} />
          </Form.Item>
        </Form>
      }
      {...props}
      okButtonProps={{
        ...props.okButtonProps,
        disabled: !validatorMatcher(textValue, validateValue),
      }}
      onVisibleChange={(visible) => {
        if (visible) form.resetFields()
      }}
    >
      {children}
    </Popconfirm>
  )
}
