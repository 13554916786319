import { Space, Checkbox, TextField, Modal } from '@pankod/refine-antd'
import { useCustomMutation, useResource } from '@pankod/refine-core'
import React, { useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { TCommonError } from 'src/interfaces/common'

const SyncClassesButton = ({
  children,
  disabled,
}: {
  children?: React.ReactNode
  disabled?: boolean
}) => {
  const { id: programId } = useResource({
    resourceNameOrRouteName: 'lms-program-management',
  })
  const [visible, setVisible] = useState(false)
  const [checked, setChecked] = useState(false)
  const { mutateAsync, isLoading } = useCustomMutation<
    {},
    TCommonError,
    { isSyncBlueprint: boolean }
  >()

  const doSync = async () => {
    return await mutateAsync(
      {
        dataProviderName: 'lms',
        method: 'post',
        url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/sync_classes`,
        values: {
          isSyncBlueprint: checked,
        },
        successNotification: {
          message: 'Kelas berhasil disinkronisasi',
          type: 'success',
          description: 'Sukses',
        },
        errorNotification: (err) =>
          showErrorNotification(
            err,
            'Terdapat gangguan ketika sinkronisasi kelas',
          ),
      },
      {
        onSuccess: () => {
          setVisible(false)
          setChecked(false)
        },
      },
    )
  }

  const renderConfirm = () => {
    return (
      <Space direction="vertical">
        <div>Apakah Anda yakin ingin melakukan sinkronisasi kelas?</div>
        <Checkbox
          checked={checked}
          disabled={isLoading}
          onChange={() => setChecked((v) => !v)}
        >
          <TextField value="Dengan Blueprint" />
        </Checkbox>
      </Space>
    )
  }

  return (
    <>
      <span
        onClick={() => {
          if (disabled) return
          setVisible(true)
        }}
      >
        {children}
      </span>
      <Modal
        visible={visible}
        onCancel={() => {
          setChecked(false)
          setVisible(false)
        }}
        title="Konfirmasi Sinkronisasi Kelas"
        okText="Sinkronisasi"
        cancelText="Kembali"
        onOk={doSync}
        okButtonProps={{
          loading: isLoading,
        }}
      >
        {renderConfirm()}
      </Modal>
    </>
  )
}

export default SyncClassesButton
