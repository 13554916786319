import {
  Button,
  Drawer,
  Icons,
  Popconfirm,
  Space,
  Table,
  TableColumnGroupType,
  TableColumnType,
  useModalForm,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useDelete } from '@pankod/refine-core'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import {
  PenilaianKinerjaCategoryRequest,
  PenilaianKinerjaCategoryResponse,
} from './types'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import {
  PenilaianKinerjaRequest,
  PenilaianKinerjaResponse,
} from '../penilaian-kinerja/types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import ModalForm from './ModalForm'

export const usePenilaianKinerjaCategoryDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type PenilaianKinerjaCategoryDrawerProps = {
  id: number | string
  name: string
  onClose: () => void
  visible: boolean
}

const PenilaianKinerjaCategoryDrawer = ({
  id,
  name,
  onClose,
  visible,
}: PenilaianKinerjaCategoryDrawerProps) => {
  const { tableProps } = useTableWithMeta<
    PenilaianKinerjaCategoryResponse,
    TCommonError
  >({
    resource: `programs/${id}/penilaian-kinerja-categories`,
    dataProviderName: 'lms',
    queryOptions: { enabled: visible && !!id },
  })
  const { mutateAsync: doDelete } = useDelete()

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<
    PenilaianKinerjaCategoryResponse,
    TLMSCommonError,
    PenilaianKinerjaCategoryRequest
  >({
    action: 'create',
    resource: `programs/${id}/penilaian-kinerja-categories`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Kategori penilaian kinerja berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating penilaian kinerja category',
      ),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<
    PenilaianKinerjaResponse,
    TLMSCommonError,
    PenilaianKinerjaRequest
  >({
    action: 'edit',
    resource: `programs/${id}/penilaian-kinerja-categories`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Kategori penilaian kinerja berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when updating penilaian kinerja category',
      ),
  })

  const columns: (
    | TableColumnType<PenilaianKinerjaCategoryResponse>
    | TableColumnGroupType<PenilaianKinerjaCategoryResponse>
  )[] = [
    { title: 'ID', dataIndex: 'id', key: '_id' },
    { title: 'Nama Kategori', dataIndex: 'name', key: '_name' },
    {
      key: '_action',
      render: (_, record) => (
        <Space>
          <Button
            block
            size="small"
            icon={<Icons.EditOutlined />}
            onClick={() => editShow(record.id)}
          >
            Ubah
          </Button>
          <Popconfirm
            title="Apakah Anda yakin ingin menghapus penilaian kinerja ini? Apabila anda menghapus kategori ini, maka semua afiliasi penilaian kinerja terhadap kategori ini juga akan dihapus."
            okText="Hapus"
            cancelText="Batal"
            okButtonProps={{ danger: true }}
            placement="topRight"
            onConfirm={async () =>
              doDelete({
                id: record.id,
                resource: `programs/${id}/penilaian-kinerja-categories`,
                dataProviderName: 'lms',
                invalidates: ['list'],
                successNotification: {
                  message: 'Kategori penilaian kinerja berhasil dihapus',
                  type: 'success',
                  description: 'Sukses',
                },
                errorNotification: (error: any) =>
                  showErrorNotification(
                    error,
                    'There was a problem when removing penilaian kinerja category',
                  ),
              })
            }
          >
            <Button block size="small" danger icon={<Icons.DeleteOutlined />}>
              Hapus
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]
  return (
    <Drawer
      title={`Daftar Kategori Penilaian Kinerja | ${name}`}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      extra={
        <Space>
          <Button
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => {
              createShow()
            }}
          >
            Tambah Kategori
          </Button>
        </Space>
      }
    >
      <Table
        {...tableProps}
        expandable={{
          expandedRowRender: ({ penilaianKinerjas }) => {
            return (
              <Table
                pagination={false}
                title={() => <strong>Daftar Penilaian Kinerja</strong>}
                dataSource={penilaianKinerjas}
                bordered
                columns={[
                  { title: 'ID', dataIndex: 'id' },
                  { title: 'Nama', dataIndex: 'name' },
                  {
                    title: 'Bobot',
                    dataIndex: 'weight',
                    render: (value) => value.toFixed(2) + '%',
                  },
                ]}
              />
            )
          },
        }}
        bordered
        columns={columns}
        rowKey="id"
      />
      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />
      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
    </Drawer>
  )
}

export default PenilaianKinerjaCategoryDrawer
