import { Button, Icons } from '@pankod/refine-antd'
import React, { useState } from 'react'

import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'
import { getGuruToken } from 'src/helpers/session'

type DownloadTemplateData = {
  programId?: string | string[] | number
}
const DownloadTemplateData = (props: DownloadTemplateData) => {
  const [loading, setLoading] = useState(false)
  return (
    <Button
      icon={<Icons.DownloadOutlined />}
      loading={loading}
      onClick={async () => {
        if (!props.programId) return
        try {
          setLoading(true)
          const response = await fetch(
            process.env.NEXT_PUBLIC_LMS_API_URL +
              `/programs/${props.programId}/participant-consolidation/export`,
            {
              headers: {
                Authorization: `Bearer ${getGuruToken()}`,
              },
            },
          )
          const text = await response.text()
          downloadTemplate(text, `data_peserta_${props.programId}`, 'template')
        } catch (_) {
        } finally {
          setLoading(false)
        }
      }}
    >
      Template Data
    </Button>
  )
}

export default DownloadTemplateData
