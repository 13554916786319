import {
  Button,
  Divider,
  FilterDropdownProps,
  Select,
  Space,
} from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import React, { useEffect } from 'react'

import { TLMSProgramLocal } from '@resources/lms-management/program-lokal/types'
import { TCommonResponse } from 'src/interfaces/common'

type ProgramLocalFilter = FilterDropdownProps & {
  show: boolean
  setShow?: (show: boolean) => void
  programId?: string | number | string[]
}
const ProgramLocalFilter = ({
  confirm,
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  programId,
  show,
  visible,
}: ProgramLocalFilter) => {
  const { data: filterOptions, isFetching } = useOne<
    TCommonResponse<TLMSProgramLocal[]>
  >({
    resource: `programs/${programId}`,
    id: `local-programs`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: show,
      staleTime: 30_000,
    },
  })
  useEffect(() => {
    if (!visible) {
      confirm()
    }
  }, [visible, confirm])
  return (
    <div className="flex flex-col rounded-sm">
      <Select
        options={filterOptions?.data?.data?.map(({ name, id }) => ({
          label: name,
          value: id,
        }))}
        loading={isFetching}
        placeholder="Pilih Program Lokal"
        showSearch
        value={selectedKeys[0] || null}
        onChange={(value) => setSelectedKeys(value ? [value] : [])}
        allowClear
        onSearch={() => {}}
        onClear={() => clearFilters?.()}
        filterOption={(input, option) =>
          (option!.label as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        className="w-[250px] !m-2 !mb-0"
      />
      <Divider className="!my-2" />
      <Space className="self-end m-2 mt-0">
        <Button
          size="small"
          onClick={() => {
            setSelectedKeys([])
          }}
        >
          Reset
        </Button>
        <Button size="small" type="primary" onClick={() => confirm()}>
          OK
        </Button>
      </Space>
    </div>
  )
}

export default ProgramLocalFilter
