import {
  AntdList,
  Button,
  Drawer,
  Icons,
  Popconfirm,
  Space,
  Table,
  Tag,
  TextField,
} from '@pankod/refine-antd'
import React from 'react'
import preHttp from 'prepend-http'
import { useCan, useCreate, useInvalidate } from '@pankod/refine-core'

import { LMSClassGroupDetail, ProgramLocalWebinarClassGroup } from '../types'
import { parseDate } from '@resources/webinar-management/utils/dateParser'
import {
  UseWebinarDrawerReturn,
  useUrlDataModal,
  useWebinarDrawer,
} from '../hooks'
import { UrlDataModal } from './UrlDataModal'
import InstructorScheduleModal, {
  useInstructorScheduleModal,
} from './InstructorScheduleModal'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import WebinarPaymentProofModal, {
  useWebinarPaymentProofModal,
} from './WebinarPaymentProofModal'

const NotAvailable = () => (
  <TextField italic type="secondary" value="Belum Tersedia" />
)

type StatusProps = {
  hasInstructor: boolean
  hasWebinarUrl: boolean
  hasInquiryUrl: boolean
  hasReportUrl: boolean
}
const Status = ({
  hasInquiryUrl,
  hasInstructor,
  hasReportUrl,
  hasWebinarUrl,
}: StatusProps) => {
  if (hasReportUrl)
    return (
      <Tag className="uppercase" color="success">
        Laporan Tersedia
      </Tag>
    )
  if (hasWebinarUrl && hasInquiryUrl)
    return (
      <Tag className="uppercase" color="processing">
        Siap Melaksanakan
      </Tag>
    )
  if (hasInstructor)
    return (
      <Tag className="uppercase" color="warning">
        Terjadwal
      </Tag>
    )
  return (
    <Tag className="uppercase" color="error">
      Belum Ada Instruktur
    </Tag>
  )
}

type WebinarClassGroupDrawerProps = Pick<
  UseWebinarDrawerReturn,
  'drawerProps' | 'data' | 'isFetching'
>
export const WebinarClassGroupDrawer = ({
  drawerProps,
  isFetching,
  data: webinarData,
}: WebinarClassGroupDrawerProps) => {
  const {
    drawerProps: classDrawerProps,
    open,
    isFetching: fetchingClass,
    data: classData,
  } = useWebinarDrawer<LMSClassGroupDetail>({
    resource: 'class-group',
    enabled: true,
    width: '30%',
  })
  const { show: showPaymentProofModal, ...paymentProofModalProps } =
    useWebinarPaymentProofModal()
  const { show: showInstructorModal, ...instructorModalProps } =
    useInstructorScheduleModal()
  const {
    isLoading,
    modalProps,
    onSubmit,
    open: openEditUrl,
    data: editUrlData,
  } = useUrlDataModal({
    webinarData,
  })
  const { mutateAsync } = useCreate()
  const invalidate = useInvalidate()
  const { data: canUpdateInstructor } = useCan({
    action: 'field',
    resource: 'webinar-schedule-instructor',
    params: { field: 'update' },
  })
  const { data: canDeleteWebinarInstructor } = useCan({
    action: 'field',
    resource: 'webinar-schedule-instructor',
    params: { field: 'delete' },
  })
  const getUploadPaymentProofData = () => {
    const data = (
      webinarData?.classGroups as ProgramLocalWebinarClassGroup[]
    )?.find(
      ({ webinarKelasGroupId }) =>
        webinarKelasGroupId === paymentProofModalProps.id,
    )
    const amount = data?.paymentProof?.amount || '0'
    return {
      url: data?.paymentProof?.url,
      amount: amount === '0' ? undefined : amount,
      date: data?.paymentProof?.paymentDate,
      notes: data?.paymentProof?.notes,
      status: data?.paymentProof?.status,
    }
  }
  return (
    <Drawer
      {...drawerProps}
      title={`${webinarData?.webinar.title} - ${webinarData?.programLocal.name}`}
      width={'85%'}
    >
      <Table
        dataSource={webinarData?.classGroups}
        rowKey="id"
        loading={isFetching}
        scroll={{ x: 1500 }}
      >
        <Table.Column fixed="left" dataIndex="name" title="Kelompok Kelas" />
        <Table.Column<ProgramLocalWebinarClassGroup>
          title="Waktu Pelaksanaan"
          render={(_, record) => (
            <TextField
              value={
                record.executionDate ? (
                  <>
                    {parseDate(record.executionDate).format('DD MMMM YYYY')}
                    <br />
                    <p className="whitespace-nowrap !m-0">
                      {record.sessionTime?.startTime} -{' '}
                      {record.sessionTime?.endTime}{' '}
                      {record.timezone.toLocaleUpperCase()}
                    </p>
                  </>
                ) : (
                  <NotAvailable />
                )
              }
            />
          )}
        />
        <Table.Column
          dataIndex={['instructor', 'name']}
          title="Instruktur"
          render={(value) => value ?? <NotAvailable />}
        />
        <Table.Column
          dataIndex={['instructor', 'phone']}
          title="Nomor WA"
          render={(value) => value ?? <NotAvailable />}
        />
        <Table.Column
          dataIndex={['instructor', 'email']}
          title="Email"
          render={(value) => value ?? <NotAvailable />}
        />
        <Table.Column<ProgramLocalWebinarClassGroup>
          title="Link Pelaksanaan"
          render={(_, record) => (
            <Space direction="vertical">
              <Button
                target="_blank"
                type="link"
                className="!p-0"
                size="small"
                disabled={!record.webinarUrl}
                href={
                  record.webinarUrl ? preHttp(record.webinarUrl) : undefined
                }
              >
                Link Webinar
              </Button>
              <Button
                target="_blank"
                type="link"
                className="!p-0"
                size="small"
                disabled={!record.inquiryUrl}
                href={
                  record.inquiryUrl ? preHttp(record.inquiryUrl) : undefined
                }
              >
                Link Pertanyaan
              </Button>
              <Button
                target="_blank"
                type="link"
                className="!p-0"
                size="small"
                disabled={!record.reportUrl}
                href={record.reportUrl ? preHttp(record.reportUrl) : undefined}
              >
                Link Laporan
              </Button>
            </Space>
          )}
        />
        <Table.Column<ProgramLocalWebinarClassGroup>
          title="Status Pembayaran"
          render={(_, record) =>
            record.paymentProof?.status === 'PAID' ? (
              <Tag className="uppercase" color="success">
                Sudah Dibayar
              </Tag>
            ) : (
              <Tag className="uppercase" color="error">
                Belum Dibayar
              </Tag>
            )
          }
        />
        <Table.Column<ProgramLocalWebinarClassGroup>
          title="Status Pelaksanaan"
          render={(_, record) => (
            <Status
              hasInquiryUrl={!!record.inquiryUrl}
              hasInstructor={!!record.instructor?.name}
              hasReportUrl={!!record.reportUrl}
              hasWebinarUrl={!!record.webinarUrl}
            />
          )}
        />
        <Table.Column<ProgramLocalWebinarClassGroup>
          render={(
            _,
            { id, instructor, webinarKelasGroupId, executionDate, position },
          ) => {
            const canDeleteInstructor = Boolean(
              canDeleteWebinarInstructor?.can && !!instructor?.name,
            )
            const localProgramId = webinarData?.programLocal.id
            const webinarId = webinarData?.webinar.id
            return (
              <Space direction="vertical">
                <Button
                  icon={<Icons.UnorderedListOutlined />}
                  onClick={() => open(id)}
                  block
                  size="small"
                >
                  Daftar Kelas
                </Button>
                <Button
                  icon={<Icons.EditOutlined />}
                  onClick={() => openEditUrl(id)}
                  block
                  size="small"
                  title={
                    !instructor?.name ? 'Instruktur belum tersedia' : undefined
                  }
                  disabled={!instructor?.name}
                >
                  Ubah Link
                </Button>
                <Button
                  block
                  size="small"
                  icon={<Icons.ContainerOutlined />}
                  onClick={() =>
                    showPaymentProofModal({
                      id: webinarKelasGroupId,
                      name: instructor?.name,
                      webinarId,
                    })
                  }
                >
                  Bukti Bayar
                </Button>
                <Button
                  block
                  size="small"
                  icon={<Icons.UserSwitchOutlined />}
                  disabled={!canUpdateInstructor?.can}
                  onClick={() => {
                    showInstructorModal({
                      localProgramId,
                      webinarId,
                      webinarKelasGroupId,
                      instructorEmail: instructor?.email,
                      instructorName: instructor?.name,
                      executionDate,
                      position,
                    })
                  }}
                >
                  Ubah Instruktur
                </Button>
                <Popconfirm
                  disabled={!canDeleteInstructor}
                  title="Apakah Anda yakin ingin menghapus instruktur dari kelompok webinar ini?"
                  placement="topRight"
                  okText="Hapus"
                  okButtonProps={{ danger: true }}
                  cancelText="Batal"
                  onConfirm={async () => {
                    return mutateAsync(
                      {
                        resource: `local-programs/${localProgramId}/webinars/${webinarId}/class-group/${webinarKelasGroupId}/unassign`,
                        dataProviderName: 'lms',
                        values: {},
                        successNotification: {
                          message:
                            'Instruktur pada kelompok webinar berhasil dihapus',
                          type: 'success',
                          description: 'Sukses',
                        },
                        errorNotification: (err) =>
                          showErrorNotification(
                            err,
                            'Terdapat gangguan saat menghapus instruktur',
                          ),
                      },
                      {
                        onSuccess: () => {
                          invalidate({
                            resource: `local-programs/${localProgramId}/webinars`,
                            id: webinarId,
                            dataProviderName: 'lms',
                            invalidates: ['detail', 'list'],
                          })
                        },
                      },
                    )
                  }}
                >
                  <Button
                    disabled={!canDeleteInstructor}
                    icon={<Icons.UserDeleteOutlined />}
                    danger
                    block
                    size="small"
                  >
                    Hapus Instruktur
                  </Button>
                </Popconfirm>
              </Space>
            )
          }}
        />
      </Table>
      <Drawer {...classDrawerProps} title={`Daftar Kelas - ${classData?.name}`}>
        <AntdList
          dataSource={classData?.classes}
          loading={fetchingClass}
          renderItem={({ id, name }) => (
            <AntdList.Item key={id}>{name}</AntdList.Item>
          )}
        />
      </Drawer>
      <UrlDataModal
        data={editUrlData}
        isLoading={isLoading}
        modalProps={modalProps}
        onSubmit={onSubmit}
      />
      <InstructorScheduleModal {...instructorModalProps} />
      <WebinarPaymentProofModal
        {...paymentProofModalProps}
        {...getUploadPaymentProofData()}
        localProgramId={webinarData?.programLocal.id}
        onSuccess={() => {
          invalidate({
            resource: `local-programs/${webinarData?.programLocal.id}/webinars`,
            id: paymentProofModalProps.webinarId,
            dataProviderName: 'lms',
            invalidates: ['detail', 'list'],
          })
        }}
      />
    </Drawer>
  )
}
