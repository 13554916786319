import {
  AntdList,
  Collapse,
  Descriptions,
  Icons,
  Table,
  TableColumnGroupType,
  TableColumnType,
  Tag,
  TextField,
  Tooltip,
  getDefaultSortOrder,
  Popover,
  Button,
  Space,
  Typography,
  Divider,
} from '@pankod/refine-antd'
import { LogicalFilter, useResource } from '@pankod/refine-core'
import React from 'react'
import _ from 'lodash'

import { dateToStringFmt } from 'src/helpers/date'
import { TProgramCanvasSyncData } from '../types'
import TableDateRangeFilter from '@components/TableHeaderFilter/DateRange'
import ModalFilterIdMigrationTable, {
  useModalFilterIdMigrationTable,
} from './blocks/ModalFilterIdMigrationTable'
import InputFilter from '@components/TableHeaderFilter/Input'
import CheckboxFilter from '@components/TableHeaderFilter/Checkbox'
import RadioFilter from '@components/TableHeaderFilter/Radio'
import useTableWithMeta from 'src/hooks/useTableWithMeta'

const getMigrationStatus = (
  status: string,
): { text: string; color: string; status: string } => {
  switch (status) {
    case 'init':
    case 'processed':
    case 'creating_csv':
      return { text: `processed`, color: 'processing', status }
    case 'failed':
    case 'creating_csv_error':
      return { text: 'failed', color: 'error', status }
    case 'completed_with_messages':
      return { text: 'completed with warning(s)', color: 'warning', status }
    default:
      return { text: 'completed', color: 'success', status }
  }
}

const getFilterIcon = (
  currentFilters: LogicalFilter[],
  filterField: string,
) => {
  const hasFilter = currentFilters.some(({ field }) => field === filterField)
  return hasFilter ? (
    <Icons.FilterFilled style={{ color: '#1890ff' }} />
  ) : (
    <Icons.FilterFilled />
  )
}

const CanvasMigrationTable = () => {
  const { id } = useResource()
  const { tableProps, sorter, setFilters, filters, tableQueryResult } =
    useTableWithMeta<TProgramCanvasSyncData>({
      resource: `programs/${id}/sync_progress`,
      dataProviderName: 'lms',
      metaData: {
        queryArrayFormat: 'bracket',
        querySortKey: 'sortType',
      },
      defaultSetFilterBehavior: 'merge',
    })

  const { show: showFilter, ...modalFilterIdMigrationProps } =
    useModalFilterIdMigrationTable()

  const currentFilters: LogicalFilter[] =
    (filters as LogicalFilter[])?.filter(({ value }) => !!value) || []
  const columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
    },
    {
      title: 'Program Local ID',
      key: 'localProgramId',
      children: [
        {
          title: 'Origin',
          key: '_localProgramIdFrom',
          dataIndex: 'localProgramIdFrom',
          onCell: (data) => ({
            colSpan:
              data.localProgramIdTo === 0 ||
              data.localProgramIdFrom === data.localProgramIdTo
                ? 2
                : 1,
          }),
          render: (value) => (value !== 0 ? value : '-'),
          filterIcon: () => getFilterIcon(currentFilters, 'localProgramIdFrom'),
          filterDropdownVisible: modalFilterIdMigrationProps.visible,
          filterDropdown: () => null,
          onFilterDropdownVisibleChange(visible) {
            if (visible) showFilter('localProgramIdFrom')
          },
          align: 'center',
        },
        {
          title: 'Destination',
          key: '_localProgramIdTo',
          dataIndex: 'localProgramIdTo',
          filterIcon: () => getFilterIcon(currentFilters, 'localProgramIdTo'),
          filterDropdownVisible: modalFilterIdMigrationProps.visible,
          filterDropdown: () => null,
          onFilterDropdownVisibleChange(visible) {
            if (visible) showFilter('localProgramIdTo')
          },
          onCell: (data) => ({
            colSpan:
              data.localProgramIdTo === 0 ||
              data.localProgramIdFrom === data.localProgramIdTo
                ? 0
                : 1,
          }),
          align: 'center',
        },
      ],
    },
    {
      title: 'Class ID',
      key: 'classId',
      children: [
        {
          title: 'Origin',
          key: '_kelasIdFrom',
          dataIndex: 'kelasIdFrom',
          filterIcon: () => getFilterIcon(currentFilters, 'kelasIdFrom'),
          filterDropdownVisible: modalFilterIdMigrationProps.visible,
          filterDropdown: () => null,
          onFilterDropdownVisibleChange(visible) {
            if (visible) showFilter('kelasIdFrom')
          },
          onCell: (data) => ({
            colSpan:
              !Number(data.kelasIdFrom) || data.kelasIdFrom === data.kelasIdTo
                ? 2
                : 1,
          }),
          render: (value) => Number(value) || '-',
          align: 'center',
        },
        {
          title: 'Destination',
          key: '_kelasIdTo',
          dataIndex: 'kelasIdTo',
          filterIcon: () => getFilterIcon(currentFilters, 'kelasIdTo'),
          filterDropdownVisible: modalFilterIdMigrationProps.visible,
          filterDropdown: () => null,
          onFilterDropdownVisibleChange(visible) {
            if (visible) showFilter('kelasIdTo')
          },
          onCell: (data) => ({
            colSpan:
              !Number(data.kelasIdFrom) || data.kelasIdFrom === data.kelasIdTo
                ? 0
                : 1,
          }),
          align: 'center',
        },
      ],
    },
    {
      dataIndex: 'syncType',
      title: 'Migration Type',
      key: '_syncType',
      filterIcon: () => getFilterIcon(currentFilters, 'syncType'),
      filterDropdown(props) {
        const filters = [
          'add',
          'move',
          'deactivate',
          'reactivate',
          'finalize',
          'migrate_kelas',
        ]
        return (
          <RadioFilter
            currentFilters={currentFilters}
            dataIndex="syncType"
            label="Choose action type"
            onClose={() => {
              props.confirm({ closeDropdown: true })
            }}
            options={filters.map((value) => ({
              label: <Tag key={value}>{_.upperCase(value)}</Tag>,
              value,
            }))}
            setFilters={setFilters}
            visible={props.visible}
          />
        )
      },
      render: (value, record) => {
        return (
          <div className="whitespace-nowrap flex justify-between items-center gap-1">
            <Tag>{value ? _.upperCase(value) : 'FINALIZE'}</Tag>
            {Boolean(record.userEmails.length) && (
              <Popover
                content={
                  <AntdList
                    size="small"
                    dataSource={record.userEmails}
                    renderItem={(item: string) => (
                      <AntdList.Item className="!px-0" key={item}>
                        {item}
                      </AntdList.Item>
                    )}
                  />
                }
                title="Affected User(s)"
              >
                <Icons.UserOutlined />
              </Popover>
            )}
          </div>
        )
      },
    },
    {
      dataIndex: 'status',
      title: 'Status',
      key: '_status',
      filterIcon: () => getFilterIcon(currentFilters, 'status'),
      filterDropdown(props) {
        return (
          <CheckboxFilter
            currentFilters={currentFilters}
            dataIndex="status"
            label="Choose status"
            onClose={() => {
              props.confirm({ closeDropdown: true })
            }}
            options={[
              {
                label: (
                  <Tag color="processing" className="uppercase">
                    processed
                  </Tag>
                ),
                value: ['processed', 'init', 'create_csv'],
              },
              {
                label: (
                  <Tag color="success" className="uppercase">
                    completed
                  </Tag>
                ),
                value: 'completed',
              },
              {
                label: (
                  <Tag color="warning" className="uppercase">
                    completed with warning(s)
                  </Tag>
                ),
                value: 'completed_with_messages',
              },
              {
                label: (
                  <Tag color="error" className="uppercase">
                    failed
                  </Tag>
                ),
                value: ['failed', 'create_csv_error'],
              },
            ]}
            setFilters={setFilters}
            visible={props.visible}
          />
        )
      },
      render: (value) => {
        const { color, text, status } = getMigrationStatus(value)
        return (
          <Tooltip title={status} placement="right">
            <Tag color={color} className="uppercase">
              {text}
            </Tag>
          </Tooltip>
        )
      },
    },
    {
      dataIndex: 'createdAt',
      title: 'Created At',
      key: 'createdAt',
      defaultSortOrder: getDefaultSortOrder('createdAt', sorter),
      sorter: true,
      filterIcon: () => getFilterIcon(currentFilters, 'createdAtFrom'),
      filterDropdown(props) {
        return (
          <TableDateRangeFilter
            currentFilters={currentFilters}
            close={() => {
              props.confirm({ closeDropdown: true })
            }}
            dataIndex={{ start: 'createdAtFrom', end: 'createdAtTo' }}
            setFilters={setFilters}
            visible={props.visible}
          />
        )
      },
      render: (value) => (
        <TextField
          value={dateToStringFmt(value, 'YYYY-MM-DDTHH:mm:ssZ')}
          className="whitespace-nowrap"
        />
      ),
    },
    {
      dataIndex: 'updatedAt',
      title: 'Updated At',
      key: 'updatedAt',
      defaultSortOrder: getDefaultSortOrder('updatedAt', sorter),
      sorter: true,
      filterIcon: () => getFilterIcon(currentFilters, 'updatedAtFrom'),
      filterDropdown(props) {
        return (
          <TableDateRangeFilter
            currentFilters={currentFilters}
            close={() => {
              props.confirm({ closeDropdown: true })
            }}
            dataIndex={{ start: 'updatedAtFrom', end: 'updatedAtTo' }}
            setFilters={setFilters}
            visible={props.visible}
          />
        )
      },
      render: (value) => (
        <TextField
          value={dateToStringFmt(value, 'YYYY-MM-DDTHH:mm:ssZ', '-')}
          className="whitespace-nowrap"
        />
      ),
    },
    {
      title: 'Triggered by',
      key: 'actor',
      children: [
        {
          title: 'ID',
          key: 'actorId',
          dataIndex: 'actorId',
          render: (value) => (
            <TextField value={value} className="whitespace-nowrap" />
          ),
        },
        {
          title: 'Email',
          key: '_actorEmail',
          dataIndex: 'actorEmail',
          filterIcon: () => getFilterIcon(currentFilters, 'actorEmail'),
          filterDropdown(props) {
            return (
              <InputFilter
                dataIndex="actorEmail"
                label="Triggerer email"
                onClose={() => {
                  props.confirm({ closeDropdown: true })
                }}
                setFilters={setFilters}
                currentFilters={currentFilters}
                visible={props.visible}
              />
            )
          },
          render: (value) => (
            <TextField className="whitespace-nowrap" value={value} />
          ),
        },
      ],
    },
  ]

  return (
    <>
      <Table<TProgramCanvasSyncData>
        {...tableProps}
        scroll={{ x: 100 }}
        columns={columns}
        rowKey="id"
        bordered
        pagination={{
          current: tableQueryResult.data?.meta.currentPage || 1,
          pageSize: tableQueryResult.data?.meta.pageSize || 10,
          total: tableQueryResult.data?.meta.totalItems,
          onChange(page, pageSize) {
            setFilters([
              {
                field: 'page',
                operator: 'eq',
                value: page,
              },
              {
                field: 'pageSize',
                operator: 'eq',
                value: pageSize,
              },
            ])
          },
        }}
        loading={tableQueryResult.isFetching}
        title={() => (
          <div className="flex justify-between items-center">
            <Typography.Title level={5} className="!p-0 !m-0">
              Canvas Migration List
            </Typography.Title>
            <Space>
              <TextField value={'Updated at:'} />
              <TextField
                value={dateToStringFmt(
                  tableQueryResult.dataUpdatedAt,
                  'YYYY-MM-DD HH:mm:ss Z',
                )}
              />
              <Divider type="vertical" />
              <Button
                icon={<Icons.SyncOutlined />}
                disabled={tableQueryResult.isFetching}
                onClick={() => {
                  tableQueryResult.refetch()
                }}
              >
                Refresh
              </Button>
            </Space>
          </div>
        )}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: ({
            resultCounts,
            csvAttachments,
            warnings,
            errors,
          }) => {
            return (
              <div className="grid grid-cols-2 gap-4">
                <Descriptions
                  title="Migration Details"
                  column={2}
                  bordered
                  size="middle"
                >
                  <Descriptions.Item label="Account synced">
                    {resultCounts.accounts}
                  </Descriptions.Item>
                  <Descriptions.Item label="Course synced">
                    {resultCounts.courses}
                  </Descriptions.Item>
                  <Descriptions.Item label="Enrollment synced">
                    {resultCounts.enrollments}
                  </Descriptions.Item>
                  <Descriptions.Item label="User synced">
                    {resultCounts.users}
                  </Descriptions.Item>
                  {resultCounts.errorCount && (
                    <Descriptions.Item
                      span={2}
                      label={
                        <>
                          Error count
                          <Icons.WarningOutlined className="ml-2" />
                        </>
                      }
                    >
                      {resultCounts.errorCount}
                    </Descriptions.Item>
                  )}
                  {resultCounts.warningCount && (
                    <Descriptions.Item
                      span={2}
                      label={
                        <>
                          Warning count
                          <Icons.ExclamationCircleOutlined className="ml-2" />
                        </>
                      }
                    >
                      {resultCounts.warningCount}
                    </Descriptions.Item>
                  )}
                </Descriptions>
                <Collapse className="col-span-2">
                  <Collapse.Panel
                    key={'csvAttachments'}
                    header={'CSV Attachments'}
                  >
                    <AntdList
                      dataSource={csvAttachments}
                      className="text-center"
                      size="small"
                      split={false}
                      style={{ maxHeight: 400, overflow: 'auto' }}
                      renderItem={(item, i) => (
                        <AntdList.Item key={i}>
                          <Descriptions
                            bordered
                            size="small"
                            column={2}
                            className="w-full"
                          >
                            {Object.entries(item).map(([key, value]) => (
                              <Descriptions.Item
                                key={key}
                                label={_.upperCase(key)}
                                contentStyle={{
                                  minWidth: '180px',
                                  maxWidth: '180px',
                                }}
                                labelStyle={{
                                  minWidth: '50px',
                                  maxWidth: '50px',
                                }}
                              >
                                {value || '-'}
                              </Descriptions.Item>
                            ))}
                          </Descriptions>
                        </AntdList.Item>
                      )}
                    />
                  </Collapse.Panel>
                  {warnings.length > 0 && (
                    <Collapse.Panel key={'warnings'} header={'Warning List'}>
                      <AntdList
                        dataSource={warnings}
                        className="text-center"
                        size="small"
                        split={false}
                        style={{ maxHeight: 400, overflow: 'auto' }}
                        renderItem={(item, i) => (
                          <AntdList.Item key={i}>
                            <Descriptions
                              bordered
                              size="small"
                              column={2}
                              className="w-full"
                            >
                              {Object.entries(item).map(([key, value], j) => (
                                <Descriptions.Item
                                  key={key}
                                  label={_.upperCase(key)}
                                  contentStyle={{
                                    width: j % 2 === 0 ? '180px' : undefined,
                                  }}
                                  labelStyle={{
                                    width: '150px',
                                  }}
                                >
                                  {value || '-'}
                                </Descriptions.Item>
                              ))}
                            </Descriptions>
                          </AntdList.Item>
                        )}
                      />
                    </Collapse.Panel>
                  )}
                  {errors.length > 0 && (
                    <Collapse.Panel key={'errors'} header={'Error List'}>
                      <AntdList
                        dataSource={errors}
                        className="text-center"
                        size="small"
                        split={false}
                        style={{ maxHeight: 400, overflow: 'auto' }}
                        renderItem={(item, i) => (
                          <AntdList.Item key={i}>
                            <Descriptions
                              bordered
                              size="small"
                              column={2}
                              className="w-full"
                            >
                              {Object.entries(item).map(([key, value], j) => (
                                <Descriptions.Item
                                  key={key}
                                  label={_.upperCase(key)}
                                  contentStyle={{
                                    width: j % 2 === 0 ? '180px' : undefined,
                                  }}
                                  labelStyle={{
                                    width: '150px',
                                  }}
                                >
                                  {value || '-'}
                                </Descriptions.Item>
                              ))}
                            </Descriptions>
                          </AntdList.Item>
                        )}
                      />
                    </Collapse.Panel>
                  )}
                </Collapse>
              </div>
            )
          },
        }}
      />
      <ModalFilterIdMigrationTable
        {...modalFilterIdMigrationProps}
        currentFilters={currentFilters}
        setFilters={setFilters}
      />
    </>
  )
}

export default CanvasMigrationTable
