import { useList, useOne } from '@pankod/refine-core'
import React from 'react'

import { TCommonError, TCommonResponse } from 'src/interfaces/common'
import { TLMSClass } from '../class/types'
import { LMSClassGroupCreate } from './CreateClassGroup'
import { LMSClassGroup } from './types'
import { APIListMeta } from 'types/api'
import { WebinarClassGroup } from './WebinarClassGroup'

type ClassGroupProps = {
  programId?: number | string | string[]
  programLocalId?: number | string | string[]
}
export const ClassGroup = ({ programId, programLocalId }: ClassGroupProps) => {
  const CLASS_RESOURCE = `programs/${programId}/local-programs/${programLocalId}/classes`
  const { data: classListData, isFetching: classLoading } = useList<
    TLMSClass,
    TCommonError
  >({
    resource: CLASS_RESOURCE,
    queryOptions: {
      enabled: !!programId,
    },
    config: {
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
    },
  })

  const VACANT_CLASS_GROUP_RESOURCE = `local-programs/${programLocalId}/vacant-class`
  const { data: vacantClassListData, isFetching: vacantClassLoading } = useList<
    Pick<TLMSClass, 'id' | 'name'>,
    TCommonError
  >({
    resource: VACANT_CLASS_GROUP_RESOURCE,
    queryOptions: {
      enabled: !!programLocalId,
    },
    config: {
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
    },
  })

  const CLASS_GROUP_RESOURCE = `local-programs/${programLocalId}`
  const { data: classGroupData, isFetching: classGroupLoading } = useOne<
    TCommonResponse<LMSClassGroup[], APIListMeta & { finalized: boolean }>
  >({
    resource: CLASS_GROUP_RESOURCE,
    id: 'class-group?pageSize=-1',
    queryOptions: {
      enabled: !!programLocalId,
    },
  })

  const totalClassAmount = classListData?.total ?? 0
  const isClassGroupFinalized = !!classGroupData?.data?.meta?.finalized
  if (!isClassGroupFinalized) {
    return (
      <LMSClassGroupCreate
        classGroupData={classGroupData}
        vacantClassData={vacantClassListData}
        totalClass={totalClassAmount}
        programLocalId={programLocalId}
        loading={classLoading || vacantClassLoading || classGroupLoading}
      />
    )
  }

  return <WebinarClassGroup programLocalId={programLocalId} />
}
