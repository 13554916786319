import H from 'react-highlight-words'

export const Highlighter = ({
  search,
  text,
}: {
  text: string
  search: string
}) => (
  <H
    searchWords={[search]}
    highlightClassName="text-orange-40"
    highlightStyle={{ padding: 0 }}
    autoEscape
    textToHighlight={text}
  />
)

type MenuItem = {
  key: string
  children?: MenuItem[]
}

export const filterMenuItemsRecursive = (
  items: MenuItem[],
  searchConfig: string,
): MenuItem[] => {
  const searchTerm = searchConfig.toLowerCase()
  const isItemValid = (menuItem: MenuItem): boolean => {
    let parentValid = false
    let childrenValid = false
    if (typeof menuItem.key === 'string') {
      parentValid = menuItem.key.toLowerCase().includes(searchTerm)
    }
    if (menuItem.children) {
      childrenValid = menuItem.children.some((child) => isItemValid(child))
    }
    return parentValid || childrenValid
  }
  return items.filter(isItemValid)
}
