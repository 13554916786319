import {
  Button,
  Drawer,
  Form,
  Icons,
  Input,
  Space,
  Table,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useUpdate, useDelete } from '@pankod/refine-core'

import { PresensiRequest, PresensiResponse } from './types'
import { TCommonError } from 'src/interfaces/common'
import { PROGRAM_STATE } from '@resources/lms-management/program/types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { ModalForm } from './ModalForm'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'

export const usePresensiDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type PresensiDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  name: string
  programStatus?: PROGRAM_STATE
}
const PresensiDrawer = ({
  id,
  name,
  onClose,
  visible,
}: PresensiDrawerProps) => {
  const [editingKey, setEditingKey] = useState<string | null>(null)
  const { tableProps } = useTable<PresensiResponse, TCommonError>({
    resource: `programs/${id}/presensi`,
    dataProviderName: 'lms',
    queryOptions: { enabled: visible && !!id },
  })
  const { mutateAsync: doUpdate, isLoading: isUpdating } = useUpdate()
  const { mutateAsync: doDelete } = useDelete()
  const { modalProps, formProps, show, close } = useModalForm<
    PresensiResponse,
    TCommonError,
    PresensiRequest
  >({
    action: 'create',
    resource: `programs/${id}/presensi`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Aktivitas presensi berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating aktivitas presensi',
      ),
  })
  const [form] = Form.useForm()
  return (
    <>
      <Drawer
        title={`Daftar Aktivitas Presensi | ${name}`}
        visible={visible}
        onClose={() => {
          setEditingKey(null)
          onClose()
        }}
        destroyOnClose
        width="70%"
        extra={
          <Space>
            <Button type="primary" icon={<Icons.PlusOutlined />} onClick={show}>
              Tambah Aktivitas Presensi
            </Button>
          </Space>
        }
      >
        <Form form={form}>
          <Table {...tableProps} loading={tableProps.loading} rowKey="id">
            <Table.Column title="ID" dataIndex="id" />
            <Table.Column<PresensiResponse>
              title="Nama Aktivitas"
              dataIndex="name"
              width="40%"
              render={(value, record) =>
                editingKey === record.id ? (
                  <>
                    <Form.Item
                      name="name"
                      validateTrigger="onChange"
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: `Nama aktivitas harus diisi`,
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item name="moduleId" hidden>
                      <Input />
                    </Form.Item>
                  </>
                ) : (
                  <TextField value={value} />
                )
              }
            />
            <Table.Column
              title="Modul"
              dataIndex={['module', 'name']}
              width="40%"
            />
            <Table.Column<PresensiResponse>
              render={(_, record) =>
                editingKey === record.id ? (
                  <Space>
                    <Button
                      icon={<Icons.SaveOutlined />}
                      loading={isUpdating}
                      onClick={async () => {
                        await form.validateFields()
                        return doUpdate(
                          {
                            dataProviderName: 'lms',
                            resource: `programs/${id}/presensi`,
                            id: record.id,
                            values: {
                              name: form.getFieldValue('name'),
                            },
                            successNotification: {
                              message: 'Aktivitas presensi berhasil diubah',
                              type: 'success',
                              description: 'Sukses',
                            },
                            errorNotification: (err) =>
                              showErrorNotification(
                                err,
                                'There was a problem when updating aktivitas presensi',
                              ),
                          },
                          {
                            onSuccess: () => {
                              setEditingKey(null)
                            },
                          },
                        )
                      }}
                    >
                      Simpan
                    </Button>
                    <Button
                      icon={<Icons.CloseOutlined />}
                      onClick={() => setEditingKey(null)}
                      danger
                    >
                      Batal
                    </Button>
                  </Space>
                ) : (
                  <Space>
                    <Button
                      icon={<Icons.EditOutlined />}
                      onClick={() => {
                        form.setFieldsValue({
                          name: record.name,
                          moduleId: record.module.id,
                        })
                        setEditingKey(record.id)
                      }}
                    >
                      Ubah
                    </Button>
                    <PopDeleteConfirm
                      title="Apakah Anda yakin ingin menghapus aktivitas ini? Seluruh presensi yang telah diisi akan terhapus."
                      okText="Hapus"
                      cancelText="Batal"
                      okButtonProps={{ danger: true }}
                      placement="topRight"
                      onConfirm={async () =>
                        doDelete({
                          dataProviderName: 'lms',
                          resource: `programs/${id}/presensi`,
                          id: record.id,
                          invalidates: ['list'],
                          successNotification: {
                            message: 'Aktivitas presensi berhasil dihapus',
                            type: 'success',
                            description: 'Sukses',
                          },
                          errorNotification: (err) =>
                            showErrorNotification(
                              err,
                              'There was a problem when removing aktivitas presensi',
                            ),
                        })
                      }
                    >
                      <Button danger icon={<Icons.DeleteOutlined />}>
                        Hapus
                      </Button>
                    </PopDeleteConfirm>
                  </Space>
                )
              }
            />
          </Table>
        </Form>
        <ModalForm
          modalProps={modalProps}
          formProps={formProps}
          onClose={close}
          programId={id}
        />
      </Drawer>
    </>
  )
}

export default PresensiDrawer
