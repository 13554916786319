import { Descriptions } from '@pankod/refine-antd'
import React from 'react'

import RequestTableList from './RequestTableList'

type NonPesertaTableList = {
  programId?: string | string[] | number
}

const NonPesertaTableList = (props: NonPesertaTableList) => {
  return (
    <Descriptions
      title={
        <>
          Daftar <i>Request</i> Perubahan Data Non Peserta
        </>
      }
    >
      <Descriptions.Item span={1}>
        <RequestTableList type="non-peserta" programId={props.programId} />
      </Descriptions.Item>
    </Descriptions>
  )
}

export default NonPesertaTableList
