import {
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
} from '@pankod/refine-antd'
import React from 'react'
import 'dayjs/locale/id'
import locale from 'antd/lib/locale/id_ID'
import dayjs from 'dayjs'
import moment from 'moment'

import { BatchRequest, BatchResponse, BatchUpdateRequest } from '../types'

type TModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
}
export const ModalForm = ({
  modalProps,
  formProps,
  mode,
  onClose,
}: TModalFormProps) => {
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={mode === 'create' ? 'Buat Angkatan Baru' : 'Edit Angkatan'}
      okText="Simpan"
      cancelText="Kembali"
    >
      <Form<BatchRequest | BatchUpdateRequest>
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        initialValues={(() => {
          return formProps?.initialValues?.data || {}
        })()}
      >
        <Form.Item
          label="Nama Angkatan"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" placeholder="Tulis nama angkatan" />
        </Form.Item>
        <Form.Item
          label="Periode Pengelompokan Semester Satu"
          name="firstSemesterCollectionDate"
          getValueProps={(
            dates: BatchResponse['firstSemesterCollectionDate'],
          ) => ({
            value: dates ? [dayjs(dates.startDate), dayjs(dates.endDate)] : [],
          })}
          required
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const secondSemesterCollectionDate = getFieldValue(
                  'secondSemesterCollectionDate',
                )

                if (
                  secondSemesterCollectionDate &&
                  value &&
                  (moment(value.startDate).isBetween(
                    secondSemesterCollectionDate.startDate,
                    secondSemesterCollectionDate.endDate,
                  ) ||
                    moment(value.endDate).isBetween(
                      secondSemesterCollectionDate.startDate,
                      secondSemesterCollectionDate.endDate,
                    ) ||
                    moment(value.endDate).isSameOrAfter(
                      secondSemesterCollectionDate.startDate,
                    ))
                ) {
                  return Promise.reject(
                    'Periode pengelompokan semester satu tidak boleh tumpang tindih dengan periode pengelompokan semester dua',
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
          getValueFromEvent={([startDate, endDate]) => ({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          })}
        >
          <DatePicker.RangePicker
            format="DD MMM YYYY"
            locale={locale.DatePicker}
            onBlur={() => {
              formProps?.form?.validateFields()
            }}
          />
        </Form.Item>
        <Form.Item
          label="Periode Pengelompokan Semester Dua"
          name="secondSemesterCollectionDate"
          getValueProps={(
            dates: BatchResponse['secondSemesterCollectionDate'],
          ) => ({
            value: dates ? [dayjs(dates.startDate), dayjs(dates.endDate)] : [],
          })}
          required
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const firstSemesterCollectionDate = getFieldValue(
                  'firstSemesterCollectionDate',
                )

                if (
                  firstSemesterCollectionDate &&
                  value &&
                  (moment(value.startDate).isBetween(
                    firstSemesterCollectionDate.startDate,
                    firstSemesterCollectionDate.endDate,
                  ) ||
                    moment(value.endDate).isBetween(
                      firstSemesterCollectionDate.startDate,
                      firstSemesterCollectionDate.endDate,
                    ) ||
                    moment(value.startDate).isSameOrBefore(
                      firstSemesterCollectionDate.endDate,
                    ))
                ) {
                  return Promise.reject(
                    'Periode pengelompokan semester dua tidak boleh tumpang tindih dengan periode pengelompokan semester satu',
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
          // add validation to not overlap from firstSemesterCollectionDate

          getValueFromEvent={([startDate, endDate]) => ({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          })}
        >
          <DatePicker.RangePicker
            format="DD MMM YYYY"
            locale={locale.DatePicker}
            onBlur={() => {
              formProps?.form?.validateFields()
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
