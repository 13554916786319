import { OpenNotificationParams, useGetIdentity } from '@pankod/refine-core'
import { useSessionStorage } from 'usehooks-ts'
import { capitalize } from 'lodash'

import { TLMSCommonError } from 'src/interfaces/common'
import { translateErrorCode } from 'utils/errorCodeMap'
const SESSION_LPTK_KEY = 'lms-admin-lptk-id'

export const useGetLptkId = () => {
  const { data: identityData } = useGetIdentity()
  const defaultLptkId = Number(identityData.user.lptkId)
  return useSessionStorage<number | null>(
    SESSION_LPTK_KEY,
    defaultLptkId || null,
  )
}

export const showErrorNotification = (
  error: unknown,
  defaultMessage: string,
): OpenNotificationParams => {
  const errorMessageObj = (error as TLMSCommonError).response?.data?.error || {
    details: [],
    message: defaultMessage,
  }

  const defaultNotification: OpenNotificationParams = {
    description: `Terjadi Kesalahan Pada Sistem: ${errorMessageObj.details?.[0]?.code}`,
    type: 'error',
    message: capitalize(
      errorMessageObj.details?.[0]?.message ||
        errorMessageObj.message ||
        defaultMessage,
    ),
  }

  if (!!errorMessageObj.details?.[0]?.detail?.error_code) {
    const { systemMessage, userMessage, isValidCode } = translateErrorCode(
      errorMessageObj.details?.[0]?.detail?.error_code,
      errorMessageObj.details?.[0]?.detail?.info,
      errorMessageObj.details?.[0]?.detail?.field,
    )

    defaultNotification.description = capitalize(systemMessage)

    if (isValidCode) {
      defaultNotification.message = userMessage
    }
  }

  return defaultNotification
}
