import { capitalize } from 'lodash'
import React, { useEffect } from 'react'
import { Controller, Noop, UseFormReturn, useController } from 'react-hook-form'
import { Select } from '@instructure/ui-select'

const Autocomplete = (props: {
  options: { label: string; value: string; disabled?: boolean }[]
  placeholder?: string
  helper?: string
  name: string
  label: string
  required?: boolean
  control?: UseFormReturn['control']
}) => {
  const { field } = useController({ control: props.control, name: props.name })
  const [state, setState] = React.useState<{
    inputValue: string | null
    isShowingOptions: boolean
    highlightedOptionId: string | null
    selectedOptionId: string | null
    filteredOptions: { label: string; value: string; disabled?: boolean }[]
  }>({
    inputValue:
      props.options.find(({ value }) => value === field.value)?.label || null,
    isShowingOptions: false,
    highlightedOptionId: field.value || null,
    selectedOptionId: field.value || null,
    filteredOptions: props.options,
  })

  useEffect(() => {
    setState((v) => ({
      ...v,
      filteredOptions: props.options,
    }))
  }, [props.options])

  function getOptionById(queryId?: string | null) {
    return props.options.find(({ value }) => value === queryId) || null
  }

  function filterOptions(value: string) {
    return props.options.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase()),
    )
  }

  function matchValue() {
    const {
      filteredOptions,
      inputValue,
      highlightedOptionId,
      selectedOptionId,
    } = state

    // an option matching user input exists
    if (filteredOptions.length === 1) {
      const onlyOption = filteredOptions[0]
      // automatically select the matching option
      if (onlyOption.label.toLowerCase() === inputValue?.toLowerCase()) {
        return {
          inputValue: onlyOption.label,
          selectedOptionId: onlyOption.value,
          filteredOptions: filterOptions(''),
        }
      }
    }

    // allow user to return to empty input and no selection
    if (inputValue?.length === 0) {
      return { selectedOptionId: null }
    }
    // no match found, return selected option label to input
    if (selectedOptionId) {
      const selectedOption = getOptionById(selectedOptionId)
      return { inputValue: selectedOption?.label || null }
    }
    // input value is from highlighted option, not user input
    // clear input, reset options
    if (highlightedOptionId) {
      if (inputValue === getOptionById(highlightedOptionId)?.label) {
        return {
          inputValue: '',
          filteredOptions: filterOptions(''),
        }
      }
    }

    return { inputValue: '', filteredOptions: filterOptions('') }
  }

  function handleShowOptions() {
    setState(({ filteredOptions, ...rest }) => ({
      ...rest,
      isShowingOptions: true,
      filteredOptions,
    }))
  }

  function handleHideOptions() {
    setState((state) => ({
      ...state,
      isShowingOptions: false,
      highlightedOptionId: null,
      ...matchValue(),
    }))
  }

  function handleBlur(e: Noop) {
    setState((state) => ({ ...state, highlightedOptionId: null }))
    e()
  }

  function handleHighlightOption(
    event: React.SyntheticEvent<Element, Event>,
    { id }: { id?: string },
  ) {
    event.persist()
    const option = getOptionById(id)
    if (!option) return // prevent highlighting of empty option
    setState((state) => ({
      ...state,
      highlightedOptionId: id || null,
      inputValue: event.type === 'keydown' ? option.label : state.inputValue,
    }))
  }

  function handleSelectOption(
    event: React.SyntheticEvent<Element, Event>,
    { id }: { id?: string },
    onChange: (...event: unknown[]) => void,
  ) {
    const option = getOptionById(id)
    if (!option) return // prevent selecting of empty option
    setState((state) => ({
      ...state,
      selectedOptionId: id || null,
      inputValue: option.label,
      isShowingOptions: false,
      filteredOptions: props.options,
    }))
    onChange(option.value)
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    const newOptions = filterOptions(value)
    setState((state) => ({
      inputValue: value,
      filteredOptions: newOptions,
      highlightedOptionId: newOptions.length > 0 ? newOptions[0].value : null,
      isShowingOptions: true,
      selectedOptionId: value === '' ? null : state.selectedOptionId,
    }))
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({
        field: { name, onChange, onBlur, ref, value },
        fieldState: { error },
      }) => (
        <Select
          name={name}
          inputRef={ref}
          value={value}
          required={props.required}
          renderLabel={
            <div
              className="form-label ql-editor"
              dangerouslySetInnerHTML={{ __html: props.label }}
            />
          }
          placeholder={props.placeholder || 'Cari..'}
          inputValue={state.inputValue || ''}
          isShowingOptions={state.isShowingOptions}
          onBlur={() => handleBlur(onBlur)}
          onInputChange={(e) => handleInputChange(e)}
          onRequestShowOptions={() => handleShowOptions()}
          onRequestHideOptions={() => handleHideOptions()}
          messages={[
            {
              text: error?.message ? capitalize(error.message) : props.helper,
              type: error?.message ? 'error' : 'hint',
            },
          ]}
          onRequestHighlightOption={(e, data) =>
            handleHighlightOption(e, { id: data.id })
          }
          onRequestSelectOption={(e, data) =>
            handleSelectOption(e, { id: data.id }, onChange)
          }
        >
          {state.filteredOptions.length > 0 ? (
            state.filteredOptions.map((option) => {
              return (
                <Select.Option
                  id={option.value}
                  key={option.value}
                  isHighlighted={option.value === state.highlightedOptionId}
                  isSelected={option.value === state.selectedOptionId}
                  isDisabled={option.disabled}
                >
                  {!option.disabled ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: option.label.replace(
                          new RegExp(state.inputValue || '', 'gi'),
                          (match) => `<strong>${match}</strong>`,
                        ),
                      }}
                    />
                  ) : (
                    `${option.label} (unavailable)`
                  )}
                </Select.Option>
              )
            })
          ) : (
            <Select.Option id="empty-option" key="empty-option">
              ---
            </Select.Option>
          )}
        </Select>
      )}
    />
  )
}

export default Autocomplete
